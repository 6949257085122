import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi } from 'ag-grid-community';
import {
  WppListItem,
  WppSelect,
} from '@platform-ui-kit/components-library-react';
import {
  IDBStatsBaseStat,
  IDBStatsColumn,
  IDBStatsTable,
} from '../../../stores/types';
import { toHumanReadableSize } from '../../../utils';
import { getTableDescription } from '../../../stores/dbstats';
import { loadingOverlay } from '../index';

import './index.scss';

export interface IBasicProps {
  selectedBaseTable: string;
  setSelectedBaseTable: (table: string) => void;
  tables: IDBStatsTable[];
  columns: IDBStatsColumn[];
  baseStats: IDBStatsBaseStat[];
  categoryLabels: { [short: string]: string };
  loading: boolean;
  error: string;
}

export default function Basic(props: IBasicProps) {
  const {
    selectedBaseTable,
    setSelectedBaseTable,
    tables,
    columns,
    baseStats,
    categoryLabels,
    loading,
    error,
  } = props;
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const columnNames = columns
    ?.filter(({ TABLE_NAME }) => TABLE_NAME === selectedBaseTable)
    .map(({ COLUMN_NAME }) => COLUMN_NAME);
  const tableData = baseStats?.filter(
    ({ COLUMN, TABLE }) =>
      columnNames.indexOf(COLUMN) > -1 && TABLE === selectedBaseTable
  );

  useEffect(() => {
    if (gridApi) {
      if (loading) {
        // Temporary fix of ag-grid-react behaviour
        setTimeout(() => gridApi.showLoadingOverlay(), 1);
      } else if (tableData && tableData.length > 0) {
        setTimeout(() => gridApi.hideOverlay(), 1000);
        gridApi.setFilterModel(null);
      }
    }
  }, [gridApi, loading, tableData]);

  const tooltipComponent = (params) => {
    const {
      data,
      value,
      colDef: { field, headerName },
    } = params;
    const fallbackLabel = data.COLUMN.replaceAll('__', ': ').replaceAll(
      '_',
      ' '
    );
    const label =
      categoryLabels && categoryLabels[data.COLUMN]
        ? categoryLabels[data.COLUMN]
        : fallbackLabel;
    return (
      <div
        style={{
          backgroundColor: '#eee',
          border: '1px solid black',
          borderRadius: '0.25rem',
          padding: '0.25rem',
        }}
      >
        <p style={{ fontWeight: 'bold', margin: '0.25rem' }}>{label}</p>
        {field !== 'COLUMN' && (
          <p style={{ margin: '0.25rem' }}>
            {headerName}: {value}
          </p>
        )}
      </div>
    );
  };

  const bounds = {};
  function setBounds(field, value) {
    bounds[field] = value;
  }
  [
    'MIN',
    'MAX',
    'STDDEV',
    'MEDIAN',
    'VARIANCE',
    'IQR',
    'TRUE_COUNT',
    'FALSE_COUNT',
    'DISTINCT_COUNT',
  ].forEach((field) => setBounds(field, { min: Infinity, max: 0 }));
  tableData.forEach((dataItem) => {
    Object.entries(dataItem).forEach(([key, val]) => {
      const value = parseFloat(val);
      if (!Number.isNaN(value) && bounds[key]) {
        bounds[key].min = Math.min(bounds[key].min, value);
        bounds[key].max = Math.max(bounds[key].max, value);
      }
    });
  });

  const numberCellRenderer = (data) => {
    const isValid = data.value && data.value !== 'NA';
    const value = (
      Math.round(parseFloat(String(data.value)) * 100) / 100
    ).toFixed(2);
    return isValid ? toHumanReadableSize(value, true, 2) || '0.00' : 'None';
  };

  const cellStyle = (params) => {
    const {
      value,
      colDef: { field },
    } = params;
    if (
      !value ||
      value === 'NA' ||
      !bounds[field] ||
      Number.isNaN(parseFloat(value))
    ) {
      return {};
    }
    const weight = parseFloat(value) / (bounds[field].max - bounds[field].min);
    const bgCol = Math.round(weight * 10) * 100;
    const col = bgCol < 500 ? 'black' : 'white';
    return {
      backgroundColor: `var(--wpp-dataviz-color-seq-brand-${bgCol})`,
      color: col,
      textAlign: 'right',
      borderRight: '1px solid lightgrey',
    };
  };

  const stringCellRenderer = (data) =>
    data.value !== 'NA' && data.value ? data.value : 'None';

  const columnDefs: ColDef[] = [
    {
      field: 'COLUMN',
      headerName: 'Column',
      width: 300,
      sort: 'asc',
      pinned: 'left',
      cellStyle: {
        borderRight: '1px solid lightgrey',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        // limit filter options
        // see: node_modules/ag-grid-community/dist/lib/filter/provided/simpleFilter.d.ts
        filterOptions: ['contains'],
        textMatcher: (params) => {
          const filterValue = params.filterText.toLowerCase();
          if (categoryLabels[params.data.COLUMN]) {
            return (
              categoryLabels[params.data.COLUMN]
                .toLowerCase()
                .indexOf(filterValue) > -1 ||
              params.value.toLowerCase().indexOf(filterValue) > -1
            );
          }
          return params.value.toLowerCase().indexOf(filterValue) > -1;
        },
      },
      tooltipField: 'COLUMN',
    },
    {
      field: 'MAX',
      headerName: 'Max',
      width: 100,
      tooltipField: 'MAX',
      cellRenderer: numberCellRenderer,
      cellStyle,
    },
    {
      field: 'MIN',
      headerName: 'Min',
      width: 100,
      tooltipField: 'MIN',
      cellRenderer: numberCellRenderer,
      cellStyle,
    },
    {
      field: 'STDDEV',
      headerName: 'StdDev',
      width: 100,
      tooltipField: 'STDDEV',
      cellRenderer: numberCellRenderer,
      cellStyle,
    },
    {
      field: 'MEDIAN',
      headerName: 'Median',
      width: 100,
      tooltipField: 'MEDIAN',
      cellRenderer: numberCellRenderer,
      cellStyle,
    },
    {
      field: 'VARIANCE',
      headerName: 'Variance',
      width: 100,
      tooltipField: 'VARIANCE',
      cellRenderer: numberCellRenderer,
      cellStyle,
    },
    {
      field: 'IQR',
      headerName: 'IQR',
      width: 100,
      tooltipField: 'IQR',
      cellRenderer: numberCellRenderer,
      cellStyle,
    },
    {
      field: 'TRUE_COUNT',
      headerName: 'True Count',
      width: 120,
      tooltipField: 'TRUE_COUNT',
      cellRenderer: numberCellRenderer,
      cellStyle,
    },
    {
      field: 'FALSE_COUNT',
      headerName: 'False Count',
      width: 120,
      tooltipField: 'FALSE_COUNT',
      cellRenderer: numberCellRenderer,
      cellStyle,
    },
    {
      field: 'DISTINCT_COUNT',
      headerName: 'Distinct Count',
      width: 120,
      tooltipField: 'DISTINCT_COUNT',
      cellRenderer: numberCellRenderer,
      cellStyle,
    },
    {
      field: 'MODE',
      headerName: 'Mode',
      width: 100,
      tooltipField: 'MODE',
      cellRenderer: stringCellRenderer,
      cellStyle,
    },
  ];

  return (
    <section className="basic">
      <section className="top">
        <section className="filters">
          <section className="basic-filter">
            <span className="label">Select Table</span>
            <WppSelect
              size="s"
              value={selectedBaseTable || undefined}
              placeholder={error || 'Please select a Database'}
              messageType={error ? 'warning' : undefined}
              onWppChange={(e) => setSelectedBaseTable(e.detail.value)}
            >
              {tables.map(({ TABLE_NAME }) => (
                <WppListItem value={TABLE_NAME} key={TABLE_NAME}>
                  <p slot="label">{TABLE_NAME}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </section>
        </section>
        {selectedBaseTable && !error && (
          <section className="info">
            <p className="label">Table Description</p>
            <span>{getTableDescription(selectedBaseTable)}</span>
          </section>
        )}
      </section>
      {error ? (
        <section className="error">
          <p>{error}</p>
        </section>
      ) : (
        <section className="basic-grid ag-theme-wpp">
          {selectedBaseTable && (
            <AgGridReact
              onGridReady={(gridReadyEvent) => {
                setGridApi(gridReadyEvent.api);
              }}
              rowData={tableData}
              columnDefs={columnDefs}
              defaultColDef={{
                sortable: true,
                resizable: true,
                cellStyle: { textAlign: 'left' },
                menuTabs: ['filterMenuTab'],
                tooltipComponent,
              }}
              animateRows
              tooltipMouseTrack
              columnHoverHighlight
              suppressPaginationPanel
              loadingOverlayComponent={loadingOverlay}
            />
          )}
        </section>
      )}
    </section>
  );
}
