import themeJson from '@platform-ui-kit/components-library/dist/platform-ui-kit/wpp-theme.json';
import { ThemeCatDataviz } from '@platform-ui-kit/components-library';

export const themeType = 'dark';
export const color = Object.values(
  (themeJson.content.light?.color.dataviz.cat as ThemeCatDataviz)[themeType]
);

export const getOptions = (chartData, chartTitle, chartSubtitle) => {
  const sortedChartData = [...(chartData?.data || [])]
    .sort((a, b) => (b ? (b.total || b.value) - (a.total || a.value) : 0))
    .filter(({ total, value }) => !!(total || value));
  const chartLegendData = sortedChartData.map(({ name }) => name) || [];
  const chartSeriesData =
    sortedChartData.map(({ name, total, value, data }) => ({
      name,
      groupId: name,
      value: total || value,
      data,
    })) || [];

  return {
    title: {
      text: chartTitle,
      subtext: chartSubtitle,
      left: 'center',
    },
    color,
    height: '100%',
    tooltip: {
      trigger: 'item',
      formatter: '{b}<br/>{c} ({d}%)',
    },
    legend: {
      bottom: 10,
      left: 'center',
      data: chartLegendData,
    },
    series: [
      {
        type: 'pie',
        radius: '50%',
        center: ['50%', '50%'],
        selectedMode: 'single',
        data: chartSeriesData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
};

export const onChartClick = (
  event,
  chart,
  chartData,
  chartTitle,
  chartSubtitle
) => {
  if (event.data && event.data.data && event.componentType === 'series') {
    const options = getOptions(event.data, chartTitle, event.data.name);
    chart.setOption(
      {
        ...options,
        series: [
          {
            ...options.series[0],
            dataGroupId: event.data.name,
            universalTransition: {
              enabled: true,
              divideShape: 'clone',
            },
          },
        ],
        graphic: [
          {
            type: 'text',
            left: 15,
            top: 20,
            style: {
              text: '⬅️ Back to Overview',
              fontSize: 16,
            },
            onclick: () => {
              chart.setOption(
                getOptions(chartData, chartTitle, chartSubtitle),
                true
              );
            },
          },
        ],
      },
      true
    );
  }
};
