import React from 'react';
import {
  WppButton,
  WppModal,
  WppTypography,
} from '@platform-ui-kit/components-library-react';

import '../../pages/Dashboard/ChipSegmentManager/SegmentList/index.scss';

export interface ISegmentsModalParams {
  showModal: boolean;
  setShowModal: (boolean) => void;
  exportToNGPi: () => void;
  dismissExportInUI: () => void;
}

export default function SegmentsModal(params: ISegmentsModalParams) {
  const { showModal, setShowModal, exportToNGPi } = params;

  return (
    <WppModal open={showModal} className="exportConfirmationModal">
      <div slot="header">
        <WppTypography type="xl-heading">Export to NGPi</WppTypography>
      </div>
      <div slot="body">
        <WppTypography type="m-body">
          Do you wish to export the segment to NGPi
        </WppTypography>
      </div>
      <div slot="actions">
        <WppButton
          variant="primary"
          size="s"
          onClick={() => {
            exportToNGPi();
          }}
        >
          Confirm
        </WppButton>{' '}
        <WppButton
          variant="secondary"
          size="s"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </WppButton>
      </div>
    </WppModal>
  );
}
