import React from 'react';
import {
  WppActionButton,
  WppCheckbox,
  WppIconAddCircle,
  WppIconHelp,
  WppIconRemoveCircle,
  WppInput,
  WppListItem,
  WppSelect,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import { IAccessRequestFormData } from '../types';
import { IRequestDatasets, ISimpleIdName } from '../../../stores/types';
import { getDatasetsPerRegion } from '../../../utils';

export interface IDataAccessDetailsParams {
  regions: ISimpleIdName[];
  requestDatasets: IRequestDatasets;
  get: () => IAccessRequestFormData;
  set: (field: keyof IAccessRequestFormData, key, value) => void;
}

function DataAccessDetails(params: IDataAccessDetailsParams) {
  const { get, set, requestDatasets } = params;
  const { project, datasets, commentsByRegion } = get();

  const onAddDataset = (region: string) => {
    set('datasets', '', [
      ...datasets,
      {
        source: null,
        region,
        dataset: region,
        aoIds: null,
        useCase: null,
      },
    ]);
  };
  const onDatasetChange = (field, e, index: number) => {
    const d = [...datasets];
    let i = index;
    let value = field === 'aoIds' ? e.detail.checked : e.detail.value;
    if (field === 'useCase') {
      value = value.substring(0, 200);
    }
    if (field === 'aoIds' || field === 'useCase') {
      const [aoDataset] = datasets.filter(
        ({ source, region }) =>
          source?.indexOf('Audience Origin') > -1 &&
          region === project.regions[index]
      );
      if (aoDataset) {
        i = datasets.indexOf(aoDataset);
      }
    }

    if (field === 'aoIds' && !e.detail.checked) {
      d[i] = {
        ...d[i],
        [field]: value,
        useCase: '',
      };
    } else {
      d[i] = {
        ...d[i],
        [field]: value,
      };
    }

    set('datasets', '', d);
  };

  const onRemoveDataset = (index: number) => {
    const d = [...datasets];
    d.splice(index, 1);
    set('datasets', '', d);
  };
  const datasetsByRegion = {};
  datasets.forEach((dataset) => {
    if (!datasetsByRegion[dataset.region]) {
      datasetsByRegion[dataset.region] = [];
    }
    datasetsByRegion[dataset.region] = [
      ...datasetsByRegion[dataset.region],
      dataset,
    ];
  });

  const getCommentsFieldByRegion = (region) => {
    if (!commentsByRegion[region]) {
      return '';
    }
    return commentsByRegion[region];
  };

  const onSetComments = ({ detail: { value } }, region) =>
    set('commentsByRegion', region, value);

  const getDatasets = (region) =>
    datasetsByRegion[region] &&
    datasetsByRegion[region].map((d, index) => (
      <span key={`dr-${region}-${index}`}>
        <span className="row-1">
          <span className="col-1">&nbsp;</span>
          <span className="col-1">
            <WppSelect
              value={d.source}
              onWppChange={(e) =>
                onDatasetChange('source', e, datasets.indexOf(d))
              }
              withSearch
            >
              {getDatasetsPerRegion(
                d.source,
                requestDatasets[region]?.data?.map(({ name }) => name) || [],
                region,
                datasetsByRegion[region].map(({ source }) => source)
              ).map((name) => (
                <WppListItem
                  value={name}
                  key={`drs-${region}-${index}-${name}`}
                >
                  <p slot="label">{name}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </span>
          <span className="col-1">
            <WppSelect value={d.dataset} disabled>
              <WppListItem value={region}>
                <p slot="label">{region}</p>
              </WppListItem>
            </WppSelect>
          </span>
          <span className="col-1">
            <WppActionButton
              onClick={() => onRemoveDataset(datasets.indexOf(d))}
              disabled={d.isDefault}
            >
              <WppIconRemoveCircle slot="icon-start" />
              Remove data set
            </WppActionButton>
          </span>
        </span>
        {d?.source?.indexOf('Audience Origin') > -1 && (
          <>
            <span className="row-1 three">
              <span className="col-1">&nbsp;</span>
              <span className="col-2 inline">
                <span className="label" style={{ textAlign: 'left' }}>
                  AO IDs{' '}
                  <WppTooltip text="Static panellist IDs are PII (personal identifying information) and therefore access to these must be limited. They must be stored securely (e.g. restricted OneDrive) and any IDs must be removed when no longer needed (max. after 12 months)">
                    <WppIconHelp size="s" />
                  </WppTooltip>
                </span>
                <WppCheckbox
                  checked={d.aoIds}
                  onWppChange={(e) =>
                    onDatasetChange('aoIds', e, datasets.indexOf(d))
                  }
                />
              </span>
              <span className="col-1">&nbsp;</span>
            </span>
            {d.aoIds && (
              <>
                <span className="row-1 three">
                  <span className="col-1">&nbsp;</span>
                  <span className="col-2">
                    <span className="label" style={{ textAlign: 'left' }}>
                      Please provide use-case details for requesting IDs{' '}
                      <WppTooltip text="Please provide info for your use case for the AO team to consider your request.">
                        <WppIconHelp size="s" />
                      </WppTooltip>
                    </span>
                  </span>
                  <span className="col-1">&nbsp;</span>
                </span>
                <span className="row-1 three">
                  <span className="col-1">&nbsp;</span>
                  <span className="col-2">
                    <WppInput
                      value={d.useCase}
                      onWppChange={(e) =>
                        onDatasetChange('useCase', e, datasets.indexOf(d))
                      }
                    />
                  </span>
                  <span className="col-1">&nbsp;</span>
                </span>
              </>
            )}
          </>
        )}
        {d?.source?.indexOf('Other') > -1 && (
          <>
            <span className="row-1 three">
              <span className="col-1">&nbsp;</span>
              <span className="col-2">
                <span className="label" style={{ textAlign: 'left' }}>
                  Please provide info about &quot;Other&quot; dataset
                </span>
                <span
                  style={{
                    display: 'block',
                    textAlign: 'left',
                    padding: '0',
                  }}
                >
                  (ex. name, region, data owner&apos;s name, location, etc)
                </span>
              </span>
              <span className="col-1">&nbsp;</span>
            </span>
            <span className="row-1 three">
              <span className="col-1">&nbsp;</span>
              <span className="col-2">
                <WppInput
                  value={getCommentsFieldByRegion(region)}
                  onWppChange={(e) => onSetComments(e, region)}
                />
              </span>
              <span className="col-1">&nbsp;</span>
            </span>
          </>
        )}
        <span className="row-1">&nbsp;</span>
      </span>
    ));

  const getDatasetsByRegion = () => {
    return project.regions.map((region) => {
      return (
        <div className="data-access-details-region" key={region}>
          <span className="row-1">
            <WppTypography className="section-label" type="m-strong" tag="h3">
              {`For Region ${region}`}
            </WppTypography>
          </span>
          <span className="row-1">
            <span className="col-1">&nbsp;</span>
            <span className="col-1">
              <span className="label">Data Set</span>
            </span>
            <span className="col-1">
              <span className="label">Region</span>
            </span>
            <span className="col-1">&nbsp;</span>
          </span>
          {getDatasets(region)}
          <span className="row-1">
            <WppActionButton
              className="align-center"
              disabled={
                getDatasetsPerRegion(
                  null,
                  requestDatasets[region]?.data?.map(({ name }) => name) || [],
                  region,
                  datasetsByRegion[region]
                ).length === 0 ||
                datasetsByRegion[region]?.filter(
                  ({ source: dsName }) => !dsName
                ).length > 0
              }
              onClick={() => onAddDataset(region)}
            >
              <WppIconAddCircle slot="icon-start" />
              Add another data set
            </WppActionButton>
          </span>
          <span className="row-1">&nbsp;</span>
        </div>
      );
    });
  };

  return (
    <section className="data-access-details">
      <span className="row-1">
        <span className="col-1">
          <WppTypography className="section-label" type="m-strong" tag="h2">
            Data Access Details{' '}
            <WppTooltip
              text="Please select the core datasets we offer through DSW. Dataset availability changes per region.
              If one or more of the data sets you are requesting is not listed here, after your initial submission,
              please get in touch with us through our Zendesk page with your request ID.

              IMPORTANT: Please make sure you read and understand the data guiderails for data sets
              (https://insidemedia.sharepoint.com/sites/ChoreographDataGovernance)

              IMPORTANT: Data catalogues and table names, etc. are provided by data owners.
              Please review their Sharepoint site here:  https://insidemedia.sharepoint.com/sites/choreograph-product-knowledgebase/SitePages/data-collective.aspx"
            >
              <WppIconHelp size="s" />
            </WppTooltip>
          </WppTypography>
        </span>
      </span>
      {getDatasetsByRegion()}
    </section>
  );
}

export default DataAccessDetails;
