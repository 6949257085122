import React from 'react';
import {
  WppActionButton,
  WppIconAddCircle,
  WppIconHelp,
  WppIconRemoveCircle,
  WppInput,
  WppListItem,
  WppSelect,
  WppTooltip,
} from '@platform-ui-kit/components-library-react';
import { IAccessRequestFormData } from '../types';
import { IDSWRole } from '../../../stores/types';
import { RoleEnum } from '../../../constants';

export interface ITeamDetailsParams {
  roles: IDSWRole[];
  get: () => IAccessRequestFormData;
  set: (field: keyof IAccessRequestFormData, key, value) => void;
}

export const onAddTeamMember = (team, set) => {
  set([
    ...team,
    {
      id: null,
      name: null,
      email: null,
      region: null,
      role: null,
      is_deleted: false,
    },
  ]);
};
export const onTeamMemberChange = (
  team,
  set,
  field,
  rawValue,
  index: number,
  modify = false
) => {
  const t = [...team];
  const selectedMember = t[index];
  const availableMembers = t.filter(({ is_deleted }) => !is_deleted);
  let value = rawValue;
  if (field === 'name' || field === 'email') {
    value = value.substring(0, 100);
  }
  if (field === 'role') {
    if (value === 'Team Lead') {
      availableMembers.forEach((member, i) => {
        if (
          member.region === selectedMember.region &&
          i !== index &&
          member.role === 'Team Lead'
        ) {
          if (!modify) {
            t.push({ ...t[i], is_deleted: true });
            t[i] = { ...t[i], role: 'User', id: null, is_deleted: false };
          } else {
            t[i] = { ...t[i], role: 'User' };
          }
        }
      });
    } else {
      const [firstMemberInRegion] = availableMembers.filter(
        ({ region }, i) => region === selectedMember.region && i !== index
      );
      const firstMemberIndex = t.indexOf(firstMemberInRegion);
      if (
        availableMembers.filter(
          ({ region, role }, i) =>
            region === selectedMember.region &&
            i !== index &&
            role === 'Team Lead'
        ).length === 0
      ) {
        if (!modify) {
          t[firstMemberIndex] = { ...t[firstMemberIndex], role: 'Team Lead' };
        } else {
          t.push({ ...t[firstMemberIndex], is_deleted: true });
          t[firstMemberIndex] = {
            ...t[firstMemberIndex],
            role: 'Team Lead',
            id: null,
            is_deleted: false,
          };
        }
      }
    }
  }
  if (!modify) {
    if (t[index].id > 0) {
      t.push({
        ...t[index],
        is_deleted: true,
      });
    }
    t[index] = {
      ...t[index],
      id: null,
      [field]: value,
      is_deleted: t[index].is_deleted || false,
    };
  } else {
    t[index] = {
      ...t[index],
      [field]: value,
    };
  }
  set(t);
};
export const onRemoveTeamMember = (
  team,
  set,
  index: number,
  removeEntry = true
) => {
  const m = { ...team[index] };
  const t = [...team];
  const availableMembers = t.filter(({ is_deleted }) => !is_deleted);
  const otherMembersSameRegion = availableMembers.filter(
    ({ region }, idx) => region === m.region && idx !== index
  );
  const otherTeamleadsSameRegion = otherMembersSameRegion.filter(
    ({ role }) => role === 'Team Lead'
  );
  if (removeEntry) {
    t.splice(index, 1);
  } else {
    t[index] = {
      ...m,
      is_deleted: true,
    };
  }
  if (
    otherTeamleadsSameRegion.length === 0 &&
    otherMembersSameRegion.length > 0
  ) {
    t[t.indexOf(otherMembersSameRegion[0])] = {
      ...t[t.indexOf(otherMembersSameRegion[0])],
      role: 'Team Lead',
    };
  }
  set(t);
};
export const getAddTeamMember = (team, set) => (
  <WppActionButton
    className="align-center"
    onClick={() => onAddTeamMember(team, set)}
    disabled={team.filter(({ is_deleted }) => !is_deleted).length >= 8}
  >
    <WppIconAddCircle slot="icon-start" />
    Add another user
  </WppActionButton>
);

function TeamDetails(params: ITeamDetailsParams) {
  const { get, set, roles } = params;
  const { team, project } = get();

  const setTeam = (t) => set('team', '', t);

  return (
    <section className="team-details">
      <span className="row-1">
        <span className="col-1">&nbsp;</span>
        <span className="col-1">
          <span className="label">Name & Surname</span>
        </span>
        <span className="col-1">
          <span className="label">E-mail</span>
        </span>
        <span className="col-1">
          <span className="label help">
            Region{' '}
            <WppTooltip text="Please select the region you want to add this user to. You can add the same user to multiple regions. Also, at least one person must be assigned per region that you have selected.">
              <WppIconHelp size="s" />
            </WppTooltip>
          </span>
        </span>
        <span className="col-1">
          <span className="label help">
            Role{' '}
            <WppTooltip text="Please select one Lead role for each region. Team Lead will be able to request additional data sets, add more teammates and ask to create additional notebooks in a project. ">
              <WppIconHelp size="s" />
            </WppTooltip>
          </span>
        </span>
        <span className="col-1">&nbsp;</span>
      </span>
      {team.map((user, index) => (
        <span className="row-1" key={index}>
          <span className="col-1">
            <span className="label">{`USER ${index + 1}`}</span>
          </span>
          <span className="col-1">
            <WppInput
              value={user.name}
              onWppChange={(e) =>
                onTeamMemberChange(
                  team,
                  setTeam,
                  'name',
                  e.detail.value,
                  index,
                  true
                )
              }
            />
          </span>
          <span className="col-1">
            <WppInput
              value={user.email}
              onWppChange={(e) =>
                onTeamMemberChange(
                  team,
                  setTeam,
                  'email',
                  e.detail.value,
                  index,
                  true
                )
              }
            />
          </span>
          <span className="col-1">
            <WppSelect
              value={user.region}
              onWppChange={(e) =>
                onTeamMemberChange(
                  team,
                  setTeam,
                  'region',
                  e.detail.value,
                  index,
                  true
                )
              }
              withSearch
            >
              {project.regions.map((name) => (
                <WppListItem value={name} key={`tmreg-${index}-${name}`}>
                  <p slot="label">{name}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </span>
          <span className="col-1">
            <WppSelect
              value={user.role}
              onWppChange={(e) =>
                onTeamMemberChange(
                  team,
                  setTeam,
                  'role',
                  e.detail.value,
                  index,
                  true
                )
              }
              withSearch
            >
              {roles
                .filter(({ name }) =>
                  team.filter(
                    ({ role, region }, idx) =>
                      role === RoleEnum.TEAM_LEAD &&
                      region === user.region &&
                      idx !== index
                  ).length > 0
                    ? name === RoleEnum.USER || name === RoleEnum.TEAM_LEAD
                    : name === RoleEnum.TEAM_LEAD
                )
                .map(({ name }) => (
                  <WppListItem value={name} key={`tmrol-${index}-${name}`}>
                    <p slot="label">{name}</p>
                  </WppListItem>
                ))}
            </WppSelect>
          </span>
          <span className="col-1">
            <WppActionButton
              onClick={() => onRemoveTeamMember(team, setTeam, index)}
            >
              <WppIconRemoveCircle slot="icon-start" />
              Remove user
            </WppActionButton>
          </span>
        </span>
      ))}
      <span className="row-1">{getAddTeamMember(team, setTeam)}</span>
    </section>
  );
}

export default TeamDetails;
