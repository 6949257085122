import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WppTabs, WppTab } from '@platform-ui-kit/components-library-react';
import { TabsChangeEventDetail } from '@platform-ui-kit/components-library';

import { IDSWRoleAccess } from '../../stores/types';
import { useNavigationStore } from '../../stores/navigation';
import { useRoleStore } from '../../stores/role';
import { PagesEnum, RoleEnum } from '../../constants';

import styles from './NavigationTabs.module.scss';

export interface INavigationTabsProps {
  access: IDSWRoleAccess[];
}

function NavigationTabs(props: INavigationTabsProps) {
  const { access } = props;
  const navigate = useNavigate();
  const accessKeys = access.map(({ name }) => name);
  const { name: userRole } = useRoleStore((state) => state.role);
  const {
    currentPage,
    actions: { changeCurrentPage },
  } = useNavigationStore();

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>): void => {
    const page = event.detail.value as keyof typeof PagesEnum;
    navigate(page);
    changeCurrentPage(page);
  };

  return (
    <div className={styles.navigationTabsWrapper}>
      <WppTabs size="s" value={currentPage} onWppChange={handleTabChange}>
        <WppTab disabled={!accessKeys.includes('main')} value="main">
          Main Page
        </WppTab>
        <WppTab disabled={userRole === RoleEnum.CHIP_ADMIN} value="data">
          Data Sets & Data Catalogs
        </WppTab>
        <WppTab value="tutorials">Tutorials & Documentations</WppTab>
        <WppTab
          disabled={
            !accessKeys.includes('dashboards') &&
            !accessKeys.includes('chipSegmentManager')
          }
          value="dashboard"
        >
          Dashboard
        </WppTab>
        <WppTab disabled={userRole === RoleEnum.CHIP_ADMIN} value="request">
          Access Requests & Support
        </WppTab>
      </WppTabs>
    </div>
  );
}

export default NavigationTabs;
