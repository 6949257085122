import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { MetricsAndReportingPagesEnum } from '../../../constants';
import NavigationTabs from '../../../components/NavigationTabs/NavigationTabs';
import { NavigationItem } from '../../../components/NavigationTabs/types';
import CostReporting from './CostReporting';
import KeyPlatformMetrics from './KeyPlatformMetrics/KeyPlatformMetrics';
import GrowthMetrics from './GrowthMetrics/GrowthMetrics';
import QuickReports from './QuickReports';
import * as ROUTE_PATHS from '../../../routes/routePaths';

import './index.scss';

export const getMetricsAndReportsRouting = (pathname) => {
  switch (pathname) {
    case '/dashboard/metrics-and-reports/key-platform-metrics':
      return ROUTE_PATHS.MetricsAndReportingPagesEnum.KEY_PLATFORM_METRICS;
    case '/dashboard/metrics-and-reports/growth-metrics':
      return ROUTE_PATHS.MetricsAndReportingPagesEnum.GROWTH_METRICS;
    case '/dashboard/metrics-and-reports/cost-reporting':
      return ROUTE_PATHS.MetricsAndReportingPagesEnum.COST_REPORTING;
    case '/dashboard/metrics-and-reports/quick-reports':
      return ROUTE_PATHS.MetricsAndReportingPagesEnum.QUICK_REPORTS;
    default:
      return ROUTE_PATHS.MetricsAndReportingPagesEnum.KEY_PLATFORM_METRICS;
  }
};

export function DashboardEmpty() {
  return (
    <div className="empty-page" style={{ flex: 1, textAlign: 'center' }}>
      <h3>Looks like, you went the wrong way</h3>
      <p>
        👈 Please select one of the available pages - or use the top navigation
        if nothing is available 👆
      </p>
    </div>
  );
}

export default function MetricsAndReports() {
  const navigate = useNavigate();
  const { pathname: currentLocation, state: locationState } = useLocation();
  const [page, setPage] = useState<ROUTE_PATHS.MetricsAndReportingPagesEnum>(
    getMetricsAndReportsRouting(currentLocation)
  );

  const navigationItems: NavigationItem[] = [
    {
      title: 'KEY PLATFORM METRICS',
      value: ROUTE_PATHS.MetricsAndReportingPagesEnum.KEY_PLATFORM_METRICS,
    },
    {
      title: 'GROWTH METRICS',
      value: ROUTE_PATHS.MetricsAndReportingPagesEnum.GROWTH_METRICS,
    },
    {
      title: 'QUICK REPORTS',
      value: ROUTE_PATHS.MetricsAndReportingPagesEnum.QUICK_REPORTS,
    },
    {
      title: 'COST REPORTING',
      value: ROUTE_PATHS.MetricsAndReportingPagesEnum.COST_REPORTING,
    },
  ];
  const disabledItems = [];

  const doSetPage = (value) => {
    // navigate(value as MetricsAndReportingPagesEnum);
    setPage(value);
  };

  const getPageContent = () => {
    switch (page) {
      case ROUTE_PATHS.MetricsAndReportingPagesEnum.KEY_PLATFORM_METRICS:
        return <KeyPlatformMetrics />;
      case ROUTE_PATHS.MetricsAndReportingPagesEnum.GROWTH_METRICS:
        return <GrowthMetrics />;
      case ROUTE_PATHS.MetricsAndReportingPagesEnum.QUICK_REPORTS:
        return <QuickReports />;
      case ROUTE_PATHS.MetricsAndReportingPagesEnum.COST_REPORTING:
        return <CostReporting />;
      default:
        return (
          <div className="empty-page" style={{ flex: 1, textAlign: 'center' }}>
            <h3>Looks like, you went the wrong way</h3>
            <p>
              👈 Please select one of the available pages - or use the top
              navigation if nothing is available 👆
            </p>
          </div>
        );
    }
  };

  useEffect(() => {
    navigate(`${page}`);
  }, [page, currentLocation]);

  return (
    <div className="metrics-and-reports">
      <NavigationTabs
        navigationItems={navigationItems}
        currentPage={page}
        setCurrentPage={doSetPage}
        disabledItems={disabledItems}
      />
      <div className="page">
        <Outlet />
      </div>
    </div>
  );
}
