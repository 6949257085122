import { IAccessRequestFormData } from './types';
import { RoleEnum } from '../../constants';
import { validateEmail } from '../../utils';

export function validateAccessRequest(formData: IAccessRequestFormData) {
  const { user, project, agencyContact, team, datasets, commentsByRegion } =
    formData;
  const val = {
    user: {
      name: () => !!user.name,
      email: () => validateEmail(user.email),
      agency: () => !!user.agency,
      country: () => !!user.country,
    },
    project: {
      reason: () => !!project.reason,
      name: () => !!project.name,
      company: () => !!project.company,
      regions: () => project.regions.filter((name) => !!name).length > 0,
    },
    agencyContact: {
      name: () => !!agencyContact.name,
      email: () => validateEmail(agencyContact.email),
    },
    team: {
      availableMembers: () => team.filter(({ is_deleted }) => !is_deleted),
      validMembers: () =>
        val.team
          .availableMembers()
          .filter(
            ({ name, email, region, role }) =>
              !!name && validateEmail(email) && !!region && !!role
          ),
      uniqueMemberPerRegion: () =>
        val.team
          .validMembers()
          .filter(
            (t) =>
              val.team
                .validMembers()
                .filter(
                  (u) =>
                    (u.name === t.name || u.email === t.email) &&
                    u.region === t.region
                ).length > 1
          ).length < 1,
      teamLeadPerRegion: () =>
        project.regions.filter(
          (region) =>
            val.team
              .validMembers()
              .filter(
                ({ region: r, role }) =>
                  r === region && role === RoleEnum.TEAM_LEAD
              ).length === 1
        ).length === project.regions.length,
    },
    datasets: {
      getDatasetsPerRegion: () => {
        const datasetsByRegion = {};
        datasets.forEach((dataset) => {
          if (!datasetsByRegion[dataset.region]) {
            datasetsByRegion[dataset.region] = [];
          }
          datasetsByRegion[dataset.region] = [
            ...datasetsByRegion[dataset.region],
            dataset,
          ];
        });
        return datasetsByRegion;
      },
      hasDatasets: () => datasets.length > 0,
      validDatasets: () =>
        datasets.filter(
          ({ region, source, aoIds, useCase, dataset }) =>
            !!region &&
            !!source &&
            !!dataset &&
            ((aoIds && useCase) || !aoIds) &&
            (source?.indexOf('Other') < 0 || commentsByRegion[region])
        ),
      matchProjectRegions: () =>
        Object.keys(val.datasets.getDatasetsPerRegion()).length ===
        project.regions.length,
    },
  };
  const validateSection = {
    user: () =>
      val.user.name() &&
      val.user.email() &&
      val.user.agency() &&
      val.user.country(),
    project: () =>
      val.project.reason() &&
      val.project.name() &&
      val.project.company() &&
      val.project.regions(),
    agencyContact: () => val.agencyContact.name() && val.agencyContact.email(),
    team: () =>
      val.team.uniqueMemberPerRegion() &&
      val.team.validMembers().length === val.team.availableMembers().length &&
      val.team.teamLeadPerRegion(),
    datasets: () =>
      val.datasets.hasDatasets() &&
      val.datasets.validDatasets().length === datasets.length &&
      val.datasets.matchProjectRegions(),
  };
  const getMessages = (section: string) => {
    const messages = {
      user: {
        name: val.user.name() ? null : 'Please enter a User Name',
        email: val.user.email() ? null : 'Please enter a valid User Email',
        agency: val.user.agency() ? null : 'Please select an Agency',
        country: val.user.country() ? null : 'Please select a Country',
      },
      project: {
        reason: val.project.reason()
          ? null
          : 'Please select the Reason for the Request',
        projectname: val.project.name()
          ? null
          : 'Please specify the Project Name',
        company: val.project.company()
          ? null
          : 'Please select the Company for the Request',
        specify: val.project.regions()
          ? null
          : 'At least one Region needs to be selected',
      },
      agencyContact: {
        agencyname: val.agencyContact.name()
          ? null
          : 'Please enter the Name of the Client Delivery Lead',
        agencymail: val.agencyContact.email()
          ? null
          : 'Please enter a valid Email of the Client Delivery Lead',
      },
      team: {
        specify: val.team.teamLeadPerRegion()
          ? null
          : 'Please select a Team Lead for each Region',
        members: val.team.uniqueMemberPerRegion()
          ? null
          : 'The same User is only accepted once per Region',
        fill:
          val.team.validMembers().length === val.team.availableMembers().length
            ? null
            : 'Please fill all fields or remove the unnecessary User',
      },
      datasets: {
        fill:
          val.datasets.validDatasets().length === datasets.length
            ? null
            : 'Please add at least one Data Set, add a comment to Other Data Sets and add a Use Case if AO IDs is selected',
        define: val.datasets.matchProjectRegions()
          ? null
          : 'Each Region needs at least one Data Set',
      },
    };
    return Object.values(messages[section]).filter((v) => !!v);
  };
  return {
    validateField: val,
    validateSection,
    getMessages,
  };
}

export const doValidateAccess = (
  formData: IAccessRequestFormData,
  step?: number
) => {
  const { validateSection, getMessages } = validateAccessRequest(formData);

  const validUser = validateSection.user();
  const validProject = validateSection.project();
  const validAgencyContact = validateSection.agencyContact();
  const validTeam = validateSection.team();
  const validDatasets = validateSection.datasets();

  switch (step) {
    case 1:
      return {
        isValid: validUser,
        messages: getMessages('user'),
      };
    case 2:
      return {
        isValid: validProject && validAgencyContact,
        messages: [...getMessages('project'), ...getMessages('agencyContact')],
      };
    case 3:
      return {
        isValid: validTeam,
        messages: getMessages('team'),
      };
    case 4:
      return {
        isValid: validDatasets,
        messages: getMessages('datasets'),
      };
    default:
      return {
        isValid:
          validUser &&
          validProject &&
          validAgencyContact &&
          validTeam &&
          validDatasets,
        messages: [
          ...getMessages('user'),
          ...getMessages('project'),
          ...getMessages('agencyContact'),
          ...getMessages('team'),
          ...getMessages('datasets'),
        ],
      };
  }
};
