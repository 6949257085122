import React from 'react';
import { WppButton, WppModal } from '@platform-ui-kit/components-library-react';

export interface IFestiveModalParams {
  showModal: boolean;
  dismissHoliday: () => void;
  setShowModal: (showModal: boolean) => void;
}

export default function FestiveModal(params: IFestiveModalParams) {
  const { setShowModal, showModal, dismissHoliday } = params;

  return (
    <WppModal open={showModal}>
      <div slot="body">
        <h2>Welcome</h2>
        <p>
          Due to festive season and PTOs in different teams that are required
          for configurations, please expect delays in the configurations of new
          projects until the 1st week of January.
        </p>
        <p>We wish you a great festive season and a happy new year!</p>
        <p>
          <span style={{ fontStyle: 'italic' }}>
            Sincerely, your Enablement Dashboard team{' '}
          </span>
          🎄🎅🎇
        </p>
      </div>
      <div slot="actions">
        <WppButton
          variant="primary"
          size="s"
          onClick={() => {
            dismissHoliday();
            setShowModal(false);
          }}
        >
          Happy Holidays!
        </WppButton>
      </div>
    </WppModal>
  );
}
