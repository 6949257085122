import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  WppButton,
  WppDatepicker,
  WppInput,
  WppListItem,
  WppSelect,
} from '@platform-ui-kit/components-library-react';

import { getCleanDate, validateEmail } from '../../../utils';

import { IRegion, ISimpleAgency } from '../../../stores/types';

import { useAgencyStore } from '../../../stores/agency';
import { useRegionStore } from '../../../stores/region';
import { useDatasetStore } from '../../../stores/dataset';
import { useReasonStore } from '../../../stores/reason';

const minDate = '2022-01-01';
const [maxDate] = new Date().toISOString().split('T');

export interface IRegistryFilters {
  agencyName: string;
  projectName?: string;
  projectType?: string;
  datasetName?: string;
  userEmail?: string;
  region: string;
  instanceUrl?: string;
  startDate?: string;
  endDate?: string;
}

export const defaultFilters: IRegistryFilters = {
  agencyName: '%EMPTY%',
  projectName: '',
  projectType: '%EMPTY%',
  datasetName: '%EMPTY%',
  userEmail: '',
  region: '%EMPTY%',
  instanceUrl: '',
  startDate: minDate,
  endDate: maxDate,
};

export interface IFilterBarParams {
  updateFilters: (filters: IRegistryFilters) => void;
}

export default function FilterBar(params: IFilterBarParams) {
  const { updateFilters } = params;
  const {
    reasons,
    isLoading: reasonsLoading,
    actions: { getReasonApi },
  } = useReasonStore();
  const {
    simpleAgencies: agencies,
    actions: { getSimpleAgenciesApi },
  } = useAgencyStore();
  const {
    regions,
    actions: { getRegionApi },
  } = useRegionStore();
  const {
    actions: { getDatasetApi },
    datasets: apiDatasets,
  } = useDatasetStore();

  useEffect(() => {
    getSimpleAgenciesApi().then();
    getRegionApi().then();
    if (apiDatasets.length === 0) {
      getDatasetApi().then();
    }
  }, [getRegionApi, getSimpleAgenciesApi, apiDatasets, getDatasetApi]);

  useEffect(() => {
    if (reasons.length === 0 && !reasonsLoading) {
      getReasonApi().then();
    }
  }, [reasons, reasonsLoading, getReasonApi]);

  const [filters, setFilters] = useState(defaultFilters);
  const {
    agencyName,
    datasetName,
    projectName,
    userEmail,
    projectType,
    region,
    startDate,
    endDate,
  } = filters;
  const getValue = (field, { detail: { clear, date, value: rawValue } }) => {
    let value;
    if (field === 'startDate') {
      const [d] = getCleanDate(date || minDate)
        .toISOString()
        .split('T');
      value = d;
    } else if (field === 'endDate') {
      const [e] = getCleanDate(date || maxDate)
        .toISOString()
        .split('T');
      value = e;
      if (clear) {
        const [f] = getCleanDate(maxDate).toISOString().split('T');
        value = f;
      }
    } else {
      value = rawValue.substring(0, 100);
    }
    return value;
  };
  const set = _.debounce((event: CustomEvent, field: string) => {
    let value = getValue(field, event);
    if (field === 'projectName') {
      value = value
        .substring(0, 40)
        .toLowerCase()
        .replace(/[^a-z\d-]/gi, '')
        .replace(/-{2,}/gi, '-');
    }
    setFilters({ ...filters, [field]: value });
  }, 25);

  /* FILTER NGPi */
  const projectTypes = ['%EMPTY%', ...reasons.map((r) => r.name.toLowerCase())];
  const datasets = ['%EMPTY%', ...apiDatasets.map(({ name }) => name)];

  const agencyItems = [
    ...([
      { id: -1, name: '%EMPTY%' },
      { id: 0, name: 'ALL' },
    ] as ISimpleAgency[]),
    ...agencies,
  ];
  const regionItems = [
    { id: 0, name: '%EMPTY%' } as unknown as IRegion,
    ...regions,
  ];

  const sortedProjectTypes = [...projectTypes].sort();
  const sortedDatasets = [...datasets].sort();

  const enableFilter =
    _.difference(
      Object.entries(filters).map(([k, v]) => `${k}-${v}`),
      Object.entries(defaultFilters).map(([k, v]) => `${k}-${v}`)
    ).length > 0 &&
    (filters.userEmail ? validateEmail(filters.userEmail) : true) &&
    (filters.projectName ? filters.projectName.length > 1 : true) &&
    startDate <= endDate &&
    startDate >= minDate &&
    endDate <= maxDate;

  return (
    <div className="registry-filters">
      <span className="row-1">
        <span className="col-1 label agency">Agency</span>
        <span className="col-1">
          <WppSelect
            size="s"
            value={agencyName}
            onWppChange={(e) => set(e, 'agencyName')}
          >
            {agencyItems.map(({ id, name: v }) => (
              <WppListItem value={v} key={id}>
                <p slot="label">{v === '%EMPTY%' ? '' : v}</p>
              </WppListItem>
            ))}
          </WppSelect>
        </span>
        <span className="col-1 label project">Project Name</span>
        <span className="col-1">
          <WppInput
            size="s"
            value={projectName}
            messageType={
              filters.projectName.length > 1 || filters.projectName === ''
                ? undefined
                : 'warning'
            }
            onWppChange={(e) => set(e, 'projectName')}
          />
        </span>
        <span className="col-1 label type">Project Type</span>
        <span className="col-1">
          <WppSelect
            size="s"
            value={projectType}
            disabled={reasons.length === 0}
            onWppChange={(e) => set(e, 'projectType')}
          >
            {sortedProjectTypes.map((v) => (
              <WppListItem value={v} key={v}>
                <p slot="label">{v === '%EMPTY%' ? '' : v}</p>
              </WppListItem>
            ))}
          </WppSelect>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1 label dataset">Dataset</span>
        <span className="col-1">
          <WppSelect
            size="s"
            value={datasetName}
            onWppChange={(e) => set(e, 'datasetName')}
          >
            {sortedDatasets.map((v) => (
              <WppListItem value={v} key={v}>
                <p slot="label">{v === '%EMPTY%' ? '' : v}</p>
              </WppListItem>
            ))}
          </WppSelect>
        </span>
        <span className="col-1 label email">User E-mail</span>
        <span className="col-1">
          <WppInput
            size="s"
            value={userEmail}
            messageType={
              validateEmail(filters.userEmail) || filters.userEmail === ''
                ? undefined
                : 'warning'
            }
            onWppChange={(e) => set(e, 'userEmail')}
          />
        </span>
        <span className="col-1 label regions">Regions</span>
        <span className="col-1">
          <WppSelect
            size="s"
            value={region}
            onWppChange={(e) => set(e, 'region')}
          >
            {regionItems.map(({ id, name: v }) => (
              <WppListItem value={v} key={id}>
                <p slot="label">{v === '%EMPTY%' ? '' : v}</p>
              </WppListItem>
            ))}
          </WppSelect>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1 label start">Start Date</span>
        <span className="col-1">
          <WppDatepicker
            minDate={minDate}
            maxDate={maxDate}
            locale={{
              dateFormat: 'yyyy-MM-dd',
            }}
            placeholder={minDate}
            value={startDate}
            size="s"
            messageType={
              startDate <= endDate && startDate >= minDate
                ? undefined
                : 'warning'
            }
            onWppChange={(e) => set(e, 'startDate')}
            onWppDateClear={(e) => set(e, 'startDate')}
          />
        </span>
        <span className="col-1 label end">End Date</span>
        <span className="col-1">
          <WppDatepicker
            minDate={minDate}
            maxDate={maxDate}
            locale={{
              dateFormat: 'yyyy-MM-dd',
            }}
            placeholder={maxDate}
            value={endDate}
            size="s"
            messageType={
              startDate <= endDate && endDate <= maxDate ? undefined : 'warning'
            }
            onWppChange={(e) => set(e, 'endDate')}
            onWppDateClear={(e) => set(e, 'endDate')}
          />
        </span>
        <span className="col-1 button">
          <WppButton
            disabled={!enableFilter}
            size="s"
            onClick={() => updateFilters(filters)}
          >
            FILTER RESULTS
          </WppButton>
        </span>
      </span>
    </div>
  );
}
