import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { camelCase, mapKeys } from 'lodash';
import { IRegistryDataSet, IRegistryStore } from './types';
import { dswRequest, getAuthorizationHeader } from './index';
import { API_BASE_URL } from '../config/config';
import { mapFilters, mapData, mergeData } from './mappers/ProjectRegistry';
import { IRegistryFilters } from '../pages/Dashboard/ProjectRegistry/FilterBar';

export const initialState: IRegistryStore = {
  data: {
    Pending: [],
    Active: [],
    Deleted: [],
  },
  extending: false,
  deleting: false,
  projects: [],
  error: '',
  isLoading: false,
  loadingItems: [],
  actions: {
    getDataApi: () => Promise.resolve(),
    getProjectsByRegionAndAgencyApi: () => Promise.resolve(),
    getDetailsApi: () => Promise.resolve(),
    openExport: () => Promise.resolve(),
    postExtend: () => Promise.resolve(),
    postDelete: () => Promise.resolve(),
    reset: () => undefined,
  },
};

export const useRegistryStore = create<IRegistryStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getDataApi: async (filters) => {
          set((state) => ({
            ...state,
            isLoading: true,
          }));
          try {
            const url = `${API_BASE_URL}/project/registry/search`;
            let searchParams = '';
            if (filters) {
              searchParams = new URLSearchParams(
                mapFilters(filters) as unknown as Record<string, string>
              ).toString();
            }
            const { data = { Pending: [], Active: [], Deleted: [] }, error } =
              await dswRequest.get(`${url}?${searchParams}`);
            set((state) => ({
              ...state,
              data: mergeData(get().data, mapData(data)),
              error: error?.message || '',
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              data: [],
              error: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoading: false,
            }));
          }
        },
        getProjectsByRegionAndAgencyApi: async (filters) => {
          set((state) => ({
            ...state,
            isLoading: true,
          }));
          try {
            const url = `${API_BASE_URL}/namespace-by-region-agency`;
            const { data, error } = await dswRequest.get(
              `${url}?agencyName=${filters?.agencyName}&regionName=${filters?.region}`
            );
            set((state) => ({
              ...state,
              projects: data.map((item) =>
                mapKeys(item, (value, key) =>
                  String(key) === 'name'
                    ? camelCase(String('projectName'))
                    : camelCase(String(key))
                )
              ),
              error: error?.message || '',
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              data: [],
              error: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoading: false,
            }));
          }
        },
        getDetailsApi: async (item, status) => {
          if (!get().loadingItems.includes(item.id)) {
            set((state) => ({
              ...state,
              loadingItems: [item.id],
            }));
            try {
              const statusItems = [...get().data[status]];
              const searchParams = new URLSearchParams({
                agencyName: item.agencyName,
                projectName: item.projectName,
                region: item.regionName,
              } as unknown as Record<string, string>).toString();
              const url = `${API_BASE_URL}/project/registry/project-details?${searchParams}`;
              const { data, error } = await dswRequest.get(url);
              if (!error) {
                statusItems.splice(statusItems.indexOf(item), 1, {
                  ...item,
                  details: data,
                });
              }
              set((state) => ({
                ...state,
                data: mapData({
                  ...state.data,
                  [status]: statusItems,
                }),
                error: error?.message || '',
              }));
            } catch (e) {
              set((state) => ({
                ...state,
                data: [],
                error: e.message,
              }));
            } finally {
              set((state) => ({
                ...state,
                loadingItems: [
                  ...state.loadingItems.filter((id) => id !== item.id),
                ],
              }));
            }
          }
        },
        openExport: async (
          item: IRegistryDataSet,
          requestStatus: string,
          filters: IRegistryFilters
        ) => {
          let params = { ...mapFilters(filters) } as object;

          if (requestStatus) {
            params = { ...params, requestStatus };
          }
          if (item) {
            params = {
              ...params,
              agencyName: item.agencyName,
              region: item.regionName,
              projectName: item.projectName,
            };
          }

          const searchParams = new URLSearchParams({
            ...params,
          } as unknown as Record<string, string>);
          const header = getAuthorizationHeader({});
          // using directly fetch instead of dswRequest because received {data} is having issues when converted to blob
          const response = await fetch(
            `${API_BASE_URL}/project/export?${searchParams}`,
            {
              headers: { ...header },
            }
          )
            .then((r) => r.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              const date = new Date();
              const formattedDate = date.toISOString().replace(/:/g, '_');
              const filename = `DSW_EXPORT_${formattedDate}.xlsx`;
              a.href = url;
              a.download = filename; // Specify the file name here
              document.body.appendChild(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error('Error fetching resource:', error));
          // window.open(`${API_BASE_URL}/project/export?${searchParams}`);
        },
        postExtend: async (item: IRegistryDataSet, callback) => {
          const {
            extending,
            actions: { getDetailsApi },
          } = get();

          if (!extending) {
            set((state) => ({ ...state, extending: true }));
            const errorHandler = (error) =>
              set((state) => ({ ...state, error: error.message }));

            try {
              const params = {
                agencyName: item.agencyName,
                region: item.regionName,
                projectName: item.projectName,
              } as object;

              const searchParams = new URLSearchParams({
                ...params,
              } as unknown as Record<string, string>);

              const url = `${API_BASE_URL}/project/extend`;

              const { data, error } = await dswRequest.post(url, searchParams);
              callback(data?.message, error?.message);

              if (error) {
                errorHandler(error);
              }
            } catch (e) {
              errorHandler(e);
            } finally {
              getDetailsApi(item, 'Active').then(() =>
                set((state) => ({ ...state, extending: false, error: '' }))
              );
            }
          }
        },
        postDelete: async (item: IRegistryDataSet, callback, filters) => {
          const {
            error,
            extending,
            actions: { getDataApi },
          } = get();

          if (!extending) {
            set((state) => ({ ...state, deleting: true }));
            const errorHandler = (e) =>
              set((state) => ({ ...state, error: e.message }));

            try {
              const params = {
                agencyName: item.agencyName,
                region: item.regionName,
                projectName: item.projectName,
              } as object;

              const searchParams = new URLSearchParams({
                ...params,
              } as unknown as Record<string, string>);

              const url = `${API_BASE_URL}/project/delete`;

              const { data, error: e } = await dswRequest.post(
                url,
                searchParams
              );
              callback(data?.message, e?.message);

              if (e) {
                errorHandler(error);
              }
            } catch (e) {
              errorHandler(e);
            } finally {
              if (!error) {
                set((state) => ({
                  ...state,
                  data: {
                    Pending: [],
                    Active: [],
                    Deleted: [],
                  },
                }));
              }
              getDataApi(filters).then(() =>
                set((state) => ({ ...state, deleting: false, error: '' }))
              );
            }
          }
        },
        reset: () => set({ ...initialState, actions: { ...get().actions } }),
      },
    }))
  )
);
