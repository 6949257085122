import React, { useEffect } from 'react';
import {
  WppActionButton,
  WppIconRemoveCircle,
  WppInput,
  WppLabel,
  WppListItem,
  WppSelect,
  WppSpinner,
  WppTypography,
  WppGrid,
} from '@platform-ui-kit/components-library-react';
import {
  getAddTeamMember,
  onRemoveTeamMember,
  onTeamMemberChange,
} from '../../../AccessRequestsAndSupport/AccessRequestForm/TeamDetails';
import {
  EApprovalType,
  IApprovalProjectDetails,
  IMember,
} from '../../../../stores/types';
import { useRegionStore } from '../../../../stores/region';
import { useRoleStore } from '../../../../stores/role';
import {
  RoleEnum,
  RequestStatusType,
  RequestStatusOrder,
  REQUEST_STATUS_EDITABLE,
  DEMO_NAMESPACE,
} from '../../../../constants';

export interface ITeamSectionParams {
  isAdmin: boolean;
  team: IMember[];
  project: IApprovalProjectDetails;
  setTeam: (team: IMember[]) => void;
  approvalType: EApprovalType;
}

export default function TeamSection(params: ITeamSectionParams) {
  const { isAdmin, team, project, setTeam, approvalType } = params;
  const {
    regions,
    actions: { getRegionApi },
  } = useRegionStore();
  const {
    roles,
    actions: { getRolesApi },
  } = useRoleStore();

  useEffect(() => {
    getRegionApi().then();
    getRolesApi().then();
  }, [getRegionApi, getRolesApi]);

  let loading = true;

  if (regions.length > 0 && roles.length > 0) {
    loading = false;
  }

  const getRegionSelect = (u, idx) =>
    isAdmin && approvalType === EApprovalType.ACCESS ? (
      <WppSelect
        value={u.region}
        messageType={!u.region ? 'error' : null}
        size="s"
        className="team-region"
        onWppChange={(e) =>
          onTeamMemberChange(team, setTeam, 'region', e.detail.value, idx)
        }
        withSearch
      >
        {Object.keys(project.region_instance_url_map).map((name) => (
          <WppListItem key={`team-region-${idx}-${name}`} value={name}>
            <p slot="label">{name}</p>
          </WppListItem>
        ))}
      </WppSelect>
    ) : (
      <WppInput disabled value={u.region} size="s" />
    );

  const getRoleSelect = (u, idx) =>
    isAdmin && approvalType === EApprovalType.ACCESS ? (
      <WppSelect
        value={u.role}
        messageType={!u.role ? 'error' : null}
        size="s"
        className="team-role"
        onWppChange={(e) =>
          onTeamMemberChange(team, setTeam, 'role', e.detail.value, idx)
        }
        withSearch
      >
        {roles
          .filter(({ name }) =>
            team.filter(
              ({ role, region }, i) =>
                role === RoleEnum.TEAM_LEAD && region === u.region && i !== idx
            ).length > 0
              ? name === RoleEnum.USER || name === RoleEnum.TEAM_LEAD
              : name === RoleEnum.TEAM_LEAD
          )
          .map(({ name }) => (
            <WppListItem key={`team-role-${idx}-${name}`} value={name}>
              <p slot="label">{name}</p>
            </WppListItem>
          ))}
      </WppSelect>
    ) : (
      <WppInput disabled value={u.role} size="s" />
    );

  return (
    <section className="team">
      <WppGrid container className="team-container" alignItems="center">
        <WppGrid item all={3} className="team-item header">
          <WppTypography className="label main" type="m-strong" tag="h2">
            TEAM DETAILS
          </WppTypography>
        </WppGrid>
        <WppGrid
          item
          all={approvalType === EApprovalType.ACCESS ? 5 : 3}
          className="team-item"
        >
          <WppLabel>Name & Surname</WppLabel>
        </WppGrid>
        <WppGrid
          item
          all={approvalType === EApprovalType.ACCESS ? 7 : 5}
          className="team-item"
        >
          <WppLabel>E-mail</WppLabel>
        </WppGrid>
        <WppGrid
          item
          all={approvalType === EApprovalType.ACCESS ? 3 : 2}
          className="team-item"
        >
          <WppLabel>Region</WppLabel>
        </WppGrid>
        {approvalType === EApprovalType.ACCESS && (
          <WppGrid
            item
            all={approvalType === EApprovalType.ACCESS ? 4 : 3}
            className="team-item"
          >
            <WppLabel>Role</WppLabel>
          </WppGrid>
        )}
        {approvalType === EApprovalType.TEAM && (
          <>
            <WppGrid item all={2} className="team-item">
              <WppLabel>Role</WppLabel>
            </WppGrid>
            <WppGrid item all={5} className="team-item">
              <WppLabel>Requestor E-mail</WppLabel>
            </WppGrid>
            <WppGrid item all={2} className="team-item">
              <WppLabel>Status</WppLabel>
            </WppGrid>
          </>
        )}
        <WppGrid item all={1} className="team-item">
          &nbsp;
        </WppGrid>
      </WppGrid>
      {[...team]
        .map((u, idx) => ({ ...u, originalIndex: idx }))
        .sort((a, b) => {
          if (approvalType === EApprovalType.ACCESS)
            return a.originalIndex - b.originalIndex;
          return (
            RequestStatusOrder.indexOf(a.status) -
            RequestStatusOrder.indexOf(b.status)
          );
        })
        .map((u) => {
          if (u.is_deleted || u.status === 'DELETED') {
            return null;
          }
          return (
            <WppGrid
              container
              alignItems="center"
              key={`u-${u.originalIndex}`}
              className="team-container"
            >
              <WppGrid item all={3} className="team-item header" />
              <WppGrid
                item
                all={approvalType === EApprovalType.ACCESS ? 5 : 3}
                className={`team-item ${
                  u.status === RequestStatusOrder[1] && 'pending'
                }`}
              >
                <WppInput
                  disabled={
                    !isAdmin ||
                    approvalType === EApprovalType.DATASET ||
                    (approvalType === EApprovalType.TEAM &&
                      u.status !== REQUEST_STATUS_EDITABLE)
                  }
                  value={u.name}
                  size="s"
                  onWppChange={(e) =>
                    onTeamMemberChange(
                      team,
                      setTeam,
                      'name',
                      e.detail.value,
                      u.originalIndex
                    )
                  }
                />
              </WppGrid>
              <WppGrid
                item
                all={approvalType === EApprovalType.ACCESS ? 7 : 5}
                className={`team-item ${
                  u.status === 'INPROGRESS' && 'inprogress'
                }`}
              >
                <WppInput
                  disabled={
                    !isAdmin ||
                    approvalType === EApprovalType.DATASET ||
                    (approvalType === EApprovalType.TEAM &&
                      u.status !== REQUEST_STATUS_EDITABLE)
                  }
                  value={u.email}
                  size="s"
                  onWppChange={(e) =>
                    onTeamMemberChange(
                      team,
                      setTeam,
                      'email',
                      e.detail.value,
                      u.originalIndex
                    )
                  }
                />
              </WppGrid>
              <WppGrid
                item
                all={approvalType === EApprovalType.ACCESS ? 3 : 2}
                className="team-item"
              >
                {loading ? <WppSpinner /> : getRegionSelect(u, u.originalIndex)}
              </WppGrid>
              {approvalType === EApprovalType.ACCESS && (
                <WppGrid
                  item
                  all={approvalType === EApprovalType.ACCESS ? 4 : 3}
                  className="team-item"
                >
                  {loading ? <WppSpinner /> : getRoleSelect(u, u.originalIndex)}
                </WppGrid>
              )}
              {approvalType === EApprovalType.TEAM && (
                <>
                  <WppGrid item all={2} className="team-item">
                    {loading ? (
                      <WppSpinner />
                    ) : (
                      getRoleSelect(u, u.originalIndex)
                    )}
                  </WppGrid>
                  <WppGrid item all={5} className="team-item">
                    <WppInput disabled value={u.requestor_email} size="s" />
                  </WppGrid>
                  <WppGrid item all={2} className="team-item">
                    <WppInput
                      disabled
                      value={RequestStatusType[u.status]}
                      size="s"
                    />
                  </WppGrid>
                </>
              )}
              <WppGrid item all={1} className="team-item">
                {isAdmin &&
                (approvalType === EApprovalType.ACCESS ||
                  (approvalType === EApprovalType.TEAM &&
                    project.namespace === DEMO_NAMESPACE &&
                    u.status === REQUEST_STATUS_EDITABLE)) ? (
                  <WppActionButton
                    onClick={() =>
                      onRemoveTeamMember(team, setTeam, u.originalIndex, false)
                    }
                  >
                    <WppIconRemoveCircle slot="icon-start" />
                  </WppActionButton>
                ) : (
                  ''
                )}
              </WppGrid>
            </WppGrid>
          );
        })}
      <span className="row-1">
        <span className="col-1">&nbsp;</span>
        {isAdmin &&
          approvalType === EApprovalType.ACCESS &&
          getAddTeamMember(team, setTeam)}
      </span>
    </section>
  );
}
