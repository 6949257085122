import React, { useEffect } from 'react';
import {
  WppActionButton,
  WppCheckbox,
  WppIconAddCircle,
  WppIconRemoveCircle,
  WppInput,
  WppLabel,
  WppListItem,
  WppSelect,
  WppTypography,
  WppGrid,
} from '@platform-ui-kit/components-library-react';

import { useGlobalStore } from '../../../../stores/global';

import {
  EApprovalType,
  IApprovalDataset,
  IApprovalProjectDetails,
} from '../../../../stores/types';
import { useDatasetStore } from '../../../../stores/dataset';
import { useRegionStore } from '../../../../stores/region';
import { getDatasetsPerRegion } from '../../../../utils';
import LoadingElement from '../../../../components/LoadingElement';
import {
  RequestStatusType,
  RequestStatusOrder,
  REQUEST_STATUS_EDITABLE,
} from '../../../../constants';

export interface IDatasetSectionParams {
  isAdmin: boolean;
  datasets: IApprovalDataset[];
  project: IApprovalProjectDetails;
  setDataSets: (datasets: IApprovalDataset[]) => void;
  approvalType: EApprovalType;
}

export default function DatasetSection(params: IDatasetSectionParams) {
  const { isAdmin, datasets, project, setDataSets, approvalType } = params;
  const userInfo = useGlobalStore((state) => state.userInfo);
  const {
    requestDatasets,
    actions: { getRequestDatasetApi },
  } = useDatasetStore();
  const {
    isLoading: loadingRegions,
    actions: { getRegionApi },
  } = useRegionStore();

  useEffect(() => {
    Object.keys(project.region_instance_url_map).forEach((region) =>
      getRequestDatasetApi(
        project.agency,
        region,
        project.request_reason
      ).then()
    );
    getRegionApi().then();
  }, [getRequestDatasetApi, getRegionApi, project]);

  if (
    Object.keys(requestDatasets).filter(
      (region) => requestDatasets[region].isLoading
    ).length > 0 ||
    loadingRegions
  ) {
    return (
      <section className="datasets">
        <span className="row-1">
          <span className="col-1">
            <WppTypography className="label main" type="m-strong" tag="h2">
              DATA SETS
            </WppTypography>
          </span>
        </span>
        <span className="row-1">
          <LoadingElement message="Loading Data Sets..." />
        </span>
      </section>
    );
  }

  const datasetsByRegion = {};
  Object.keys(project.region_instance_url_map).forEach((region) => {
    const datasetsRegion = datasets.filter(
      ({ instance_region }) => instance_region === region
    );
    let rawDataset;

    if (datasetsRegion.length < 1) {
      datasetsByRegion[region] = {
        items: [],
        aoId: false,
        useCase: '',
        comments: '',
      };
    } else {
      [rawDataset] = datasetsRegion;
      datasetsByRegion[region] = {
        items: [],
        aoId: rawDataset.ao_id || false,
        useCase: rawDataset.use_case_text || '',
        comments: rawDataset.comments || '',
      };
      Object.keys(rawDataset.dataset_region_map).forEach((source) => {
        let item = {};
        rawDataset.dataset_region_map[source].forEach((regionItem) => {
          item = {
            ...regionItem,
            source,
            hasApproval: rawDataset.dataset_approval_map[source] || false,
          };
          datasetsByRegion[region].items.push(item);
        });
      });
    }
  });
  const set = (changedDatasets) => {
    const newDatasets = [];
    Object.keys(changedDatasets).forEach((regionKey) => {
      const changedDataset = changedDatasets[regionKey];
      const dataset_approval_map = {};
      const dataset_region_map = {};
      changedDataset.items.forEach((datasetItem) => {
        dataset_approval_map[datasetItem.source] = datasetItem.hasApproval;
        if (
          !dataset_region_map[datasetItem.source] ||
          dataset_region_map[datasetItem.source].length === 0
        ) {
          dataset_region_map[datasetItem.source] = [];
        }
        dataset_region_map[datasetItem.source].push({
          is_deleted: datasetItem.is_deleted || false,
          added_dataset: datasetItem.added_dataset || false,
          region: datasetItem.region,
          requestor_email: datasetItem.requestor_email,
          status: datasetItem.status,
        });
      });
      newDatasets.push({
        ao_id: changedDataset.aoId,
        use_case_text: changedDataset.useCase,
        comments: changedDataset.comments,
        instance_region: regionKey,
        dataset_approval_map,
        dataset_region_map,
      });
    });
    setDataSets(newDatasets);
  };

  const onDatasetChange = (field, val, regionKey, itemIndex) => {
    let value = val;
    const items = [...datasetsByRegion[regionKey].items];
    const targetItem = datasetsByRegion[regionKey].items[itemIndex];
    if (field === 'useCase') {
      value = val.substring(0, 200);
    }
    if (field === 'hasApproval') {
      items.forEach((itm, index) => {
        if (index !== itemIndex && itm.source === targetItem.source) {
          items[index].hasApproval = value;
        }
      });
    }
    if (itemIndex >= 0) {
      if (
        !targetItem.is_deleted &&
        (field === 'source' || field === 'region')
      ) {
        items.push({
          ...targetItem,
          is_deleted: true,
        });
      }
      items.splice(itemIndex, 1, {
        ...targetItem,
        [field]: value,
      });
      set({
        ...datasetsByRegion,
        [regionKey]: {
          ...datasetsByRegion[regionKey],
          aoId: datasetsByRegion[regionKey].aoId || false,
          useCase: datasetsByRegion[regionKey].useCase || '',
          comments: datasetsByRegion[regionKey].comments || '',
          items,
        },
      });
    } else {
      set({
        ...datasetsByRegion,
        [regionKey]: {
          ...datasetsByRegion[regionKey],
          [field]: value,
        },
      });
    }
  };
  const onRemoveDataset = (regionKey, itemIndex) => {
    const items = [...datasetsByRegion[regionKey].items];
    items.splice(itemIndex, 1, {
      ...datasetsByRegion[regionKey].items[itemIndex],
      is_deleted: true,
    });
    set({
      ...datasetsByRegion,
      [regionKey]: {
        ...datasetsByRegion[regionKey],
        items,
      },
    });
  };
  const onAddDataset = (regionKey) => {
    set({
      ...datasetsByRegion,
      [regionKey]: {
        ...datasetsByRegion[regionKey],
        items: [
          ...datasetsByRegion[regionKey].items,
          {
            region: regionKey,
            source: '',
            hasApproval: false,
            is_deleted: false,
            requestor_email: userInfo.email,
            status: REQUEST_STATUS_EDITABLE,
          },
        ],
      },
    });
  };

  return (
    <section className="datasets">
      <WppGrid container className="dataset-container" alignItems="center">
        <WppGrid item all={3} className="dataset-item header">
          <WppTypography className="label main" type="m-strong" tag="h2">
            DATA SETS
          </WppTypography>
        </WppGrid>
      </WppGrid>
      {Object.keys(project.region_instance_url_map).map((regionKey) => (
        <>
          <WppGrid
            container
            className="dataset-container"
            alignItems="center"
            key={`header-${regionKey}`}
          >
            <WppGrid item all={3} className="dataset-item header" />
            <WppGrid item all={6} className="dataset-item">
              <WppLabel>{`FOR REGION ${regionKey}`}</WppLabel>
            </WppGrid>
            <WppGrid item all={3} />
            {approvalType === EApprovalType.DATASET && (
              <>
                <WppGrid item all={5} className="dataset-item">
                  <WppLabel>Requestor E-mail</WppLabel>
                </WppGrid>
                <WppGrid item all={2} className="dataset-item">
                  <WppLabel>Status</WppLabel>
                </WppGrid>
              </>
            )}
            <WppGrid item all={1} className="dataset-item" />
          </WppGrid>
          {datasetsByRegion[regionKey].items
            .sort(
              (a, b) =>
                RequestStatusOrder.indexOf(a.status) -
                RequestStatusOrder.indexOf(b.status)
            )
            .map(
              (dataset, itemIndex) =>
                !dataset.is_deleted && (
                  <WppGrid
                    container
                    className="dataset-container"
                    alignItems="center"
                    key={`dataset-${regionKey}-${itemIndex}`}
                  >
                    <WppGrid item all={3} className="dataset-item header" />
                    <WppGrid item all={6} className="dataset-item">
                      {isAdmin && approvalType !== EApprovalType.TEAM ? (
                        <WppSelect
                          size="s"
                          value={dataset.source}
                          messageType={!dataset.source ? 'error' : null}
                          disabled={
                            approvalType !== EApprovalType.ACCESS &&
                            dataset.status !== REQUEST_STATUS_EDITABLE
                          }
                          onWppChange={(e) =>
                            onDatasetChange(
                              'source',
                              e.detail.value,
                              regionKey,
                              itemIndex
                            )
                          }
                          withSearch
                        >
                          {getDatasetsPerRegion(
                            dataset.source,
                            requestDatasets[regionKey].data.map(
                              ({ name }) => name
                            ),
                            regionKey,
                            datasetsByRegion[regionKey].items
                              .filter(({ is_deleted }) => !is_deleted)
                              .map(({ source }) => source)
                          ).map((name) => (
                            <WppListItem
                              value={name}
                              key={`dataset-${regionKey}-${name}`}
                            >
                              <p slot="label">{name}</p>
                            </WppListItem>
                          ))}
                        </WppSelect>
                      ) : (
                        <WppInput disabled value={dataset.source} size="s" />
                      )}
                    </WppGrid>
                    <WppGrid item all={3} className="dataset-item">
                      {isAdmin && approvalType !== EApprovalType.TEAM ? (
                        <WppSelect
                          size="s"
                          value={regionKey}
                          disabled
                          className="dataset-region"
                        >
                          <WppListItem value={dataset.region}>
                            <p slot="label">{regionKey}</p>
                          </WppListItem>
                        </WppSelect>
                      ) : (
                        <WppInput
                          disabled
                          value={dataset.region}
                          size="s"
                          className="dataset-region"
                        />
                      )}
                    </WppGrid>
                    {approvalType === EApprovalType.DATASET && (
                      <>
                        <WppGrid item all={5} className="dataset-item">
                          <WppInput
                            disabled
                            value={dataset.requestor_email}
                            size="s"
                          />
                        </WppGrid>
                        <WppGrid item all={2} className="dataset-item">
                          <WppInput
                            disabled
                            value={RequestStatusType[dataset.status]}
                            size="s"
                          />
                        </WppGrid>
                      </>
                    )}
                    <WppGrid item all={1} className="dataset-item">
                      {isAdmin && approvalType === EApprovalType.ACCESS ? (
                        <WppActionButton
                          onClick={() => onRemoveDataset(regionKey, itemIndex)}
                          disabled={
                            project.request_reason === 'NGPi' &&
                            itemIndex === 0 &&
                            dataset.source?.indexOf('ADM') === 0
                          }
                        >
                          <WppIconRemoveCircle slot="icon-start" />
                        </WppActionButton>
                      ) : (
                        ''
                      )}
                    </WppGrid>
                  </WppGrid>
                )
            )}
          {isAdmin && approvalType === EApprovalType.ACCESS && (
            <WppGrid
              container
              className="dataset-container"
              alignItems="center"
            >
              <WppGrid item all={3} className="dataset-item header" />
              <WppGrid
                item
                all={6}
                className="add-dataset dataset-item"
                key={`add-${regionKey}`}
              >
                <WppActionButton
                  className="align-center"
                  disabled={
                    getDatasetsPerRegion(
                      null,
                      requestDatasets[regionKey].data.map(({ name }) => name),
                      regionKey,
                      datasetsByRegion[regionKey].items
                        .filter(({ is_deleted }) => !is_deleted)
                        .map(({ source }) => source)
                    ).length === 0 ||
                    datasetsByRegion[regionKey].items.filter(
                      ({ is_deleted }) => !is_deleted
                    ).length !==
                      datasetsByRegion[regionKey].items.filter(
                        ({ source, is_deleted }) => !!source && !is_deleted
                      ).length
                  }
                  onClick={() => onAddDataset(regionKey)}
                >
                  <WppIconAddCircle slot="icon-start" />
                  Add another data set
                </WppActionButton>
              </WppGrid>
            </WppGrid>
          )}
          {approvalType !== EApprovalType.TEAM && (
            <>
              <WppGrid
                container
                className="dataset-container"
                alignItems="center"
              >
                <WppGrid item all={3} className="dataset-item header" />
                <WppGrid
                  item
                  all={18}
                  className="dataset-item dataset-approval"
                  key={`do-${regionKey}`}
                >
                  <WppLabel>Data Owner Approval</WppLabel>
                </WppGrid>
              </WppGrid>
              {datasetsByRegion[regionKey].items.map((dataset, itemIndex) => {
                const sameSources = [
                  ...datasetsByRegion[regionKey].items.filter(
                    ({ source, is_deleted }) =>
                      source === dataset.source && !is_deleted
                  ),
                ];
                const lastOfSource = sameSources[sameSources.length - 1];
                const isLastOfSource =
                  itemIndex ===
                  datasetsByRegion[regionKey].items.indexOf(lastOfSource);
                const hasAddedDatasets =
                  datasetsByRegion[regionKey].items.filter(
                    ({ source }) =>
                      source === dataset.source &&
                      dataset.status === REQUEST_STATUS_EDITABLE
                  ).length > 0;
                return (
                  !dataset.is_deleted &&
                  ((sameSources.length > 1 && isLastOfSource) ||
                    sameSources.length === 1) &&
                  dataset.source && (
                    <WppGrid
                      container
                      className="dataset-container dataset-approval-item"
                      alignItems="center"
                      key={`ds-${dataset.source}-${itemIndex}`}
                    >
                      <WppGrid item all={3} className="dataset-item header" />
                      <WppGrid
                        item
                        all={4}
                        className={`dataset-item key ${
                          !dataset.region ? 'error' : ''
                        }`}
                        key={`do-${regionKey}`}
                      >
                        {dataset.source}
                      </WppGrid>
                      <WppGrid item all={3} className="value">
                        <WppCheckbox
                          checked={dataset.hasApproval}
                          disabled={
                            !dataset.region ||
                            (approvalType === EApprovalType.DATASET &&
                              !hasAddedDatasets)
                          }
                          onWppChange={(e) =>
                            onDatasetChange(
                              'hasApproval',
                              e.detail.checked,
                              regionKey,
                              itemIndex
                            )
                          }
                        />
                      </WppGrid>
                      <WppGrid item all={11} />
                    </WppGrid>
                  )
                );
              })}
            </>
          )}
          {isAdmin &&
            approvalType !== EApprovalType.TEAM &&
            datasetsByRegion[regionKey].items.filter(
              ({ source: s, is_deleted, added_dataset }) =>
                s?.indexOf('Audience Origin') > -1 &&
                !is_deleted &&
                !added_dataset
            ).length > 0 && (
              <WppGrid
                container
                className="dataset-container"
                alignItems="center"
                key={`ad-${regionKey}`}
              >
                <WppGrid item all={3} className="dataset-item header" />
                <WppGrid item all={2} className={`dataset-item `}>
                  <WppLabel>AO IDs</WppLabel>
                </WppGrid>
                <WppGrid item all={2} className="dataset-item ao-id">
                  <WppCheckbox
                    checked={datasetsByRegion[regionKey].aoId}
                    onWppChange={(e) =>
                      onDatasetChange('aoId', e.detail.checked, regionKey, -1)
                    }
                  />
                </WppGrid>
                <WppGrid item all={14} />

                <WppGrid item all={3} className="dataset-item header" />
                <WppGrid item all={2} className={`dataset-item `}>
                  <WppLabel>Use Case</WppLabel>
                </WppGrid>
                <WppGrid
                  item
                  all={6}
                  className="dataset-item use-case textfield"
                >
                  <WppInput
                    value={datasetsByRegion[regionKey].useCase}
                    size="s"
                    onWppChange={(e) =>
                      onDatasetChange('useCase', e.detail.value, regionKey, -1)
                    }
                  />
                </WppGrid>
                <WppGrid item all={10} />
              </WppGrid>
            )}
          {(approvalType === EApprovalType.DATASET ||
            approvalType === EApprovalType.ACCESS) &&
            datasetsByRegion[regionKey].items.filter(
              ({ source, is_deleted, added_dataset }) =>
                source === 'Other' && !is_deleted && !added_dataset
            ).length > 0 && (
              <WppGrid
                container
                className="dataset-container comments"
                alignItems="center"
                key={`dataset-comment-${regionKey}`}
              >
                <WppGrid item all={3} className="dataset-item header" />
                <WppGrid item all={2} className={`dataset-item `}>
                  <WppLabel>Comments</WppLabel>
                </WppGrid>
                <WppGrid
                  item
                  all={6}
                  className="dataset-item comments textfield"
                >
                  <WppInput
                    disabled={!isAdmin}
                    onWppChange={({ detail: { value } }) =>
                      onDatasetChange(
                        'comments',
                        value.substring(0, 200),
                        regionKey,
                        -1
                      )
                    }
                    value={datasetsByRegion[regionKey].comments}
                  />
                </WppGrid>
                <WppGrid item all={10} />
              </WppGrid>
            )}
          <span className="row-1">
            <span className="col-1">&nbsp;</span>
          </span>
        </>
      ))}
    </section>
  );
}
