import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  WppButton,
  WppTypography,
  WppPill,
  WppPillGroup,
  WppGrid,
} from '@platform-ui-kit/components-library-react';
import {
  PillGroupChangeEvent,
  PillGroupValue,
} from '@platform-ui-kit/components-library';
import { init } from 'echarts';
import type { ECharts } from 'echarts';
import LoadingElement from '../../../../components/LoadingElement';
import { useMetricsAndReportsStore } from '../../../../stores/metrics_and_reports';
import {
  dataTitleMapper,
  getStartAndEndDateByTimespanSelection,
  usePrevious,
} from '../../../../utils';
import { EFeatureToggle, TimespanSelectionEnum } from '../../../../constants';
import useToast from '../../../../hooks/useToast';
import { prepareGraphOptions } from './chartUtils';
import './index.scss';
import { useFeatureToggleStore } from '../../../../stores/featureToggle';

const loadingMessage = (timespan?: TimespanSelectionEnum) => {
  return timespan
    ? `Loading Growth Metrics Information for ${timespan}...`
    : `Loading Growth Metrics Information...`;
};

const noTimespanSelectionMessage =
  '👆 Please select time period for report generation';

const regenerateReportMessage =
  '👆 Re-Generate the report for newly selected time period';
const errorMessage = `We're sorry, an error has occurred. Please reload this page and try again.`;

export default function GrowthMetrics() {
  const {
    growthMetricsData,
    actions: {
      getGrowthMetricsApi,
      exportGrowthMetricsApi,
      resetGrowthMetricsState,
    },
    isLoadingGrowthMetricsData,
    isLoadingGrowthMetricsExportFile,
    error,
  } = useMetricsAndReportsStore((state) => state);
  const {
    actions: { hasFeature },
  } = useFeatureToggleStore();
  const chartRef = useRef<HTMLDivElement[]>([]);
  const { showToast } = useToast();

  const disabledGraphs = useMemo(() => {
    return [
      'monthlyActiveUsers',
      !hasFeature(EFeatureToggle.GROWTH_NOTEBOOKS) ? 'notebooks' : '',
    ];
  }, [hasFeature]);

  const [timespanSelection, setTimespanSelection] =
    useState<PillGroupValue>('');
  const timespanSelectionPrevious = usePrevious(timespanSelection);
  const [
    currentlyRenderedGraphsTimespanSelection,
    setCurrentlyRenderedGraphsTimespanSelection,
  ] = useState<PillGroupValue>('');

  const showRegenerateReportMessage =
    timespanSelectionPrevious !== '' &&
    currentlyRenderedGraphsTimespanSelection !== timespanSelection &&
    timespanSelectionPrevious !== timespanSelection;

  useEffect(() => {
    if (growthMetricsData !== null) {
      Object.keys(growthMetricsData).forEach((key) => {
        if (disabledGraphs.indexOf(key) > -1) {
          return null;
        }
        let chart: ECharts | undefined;

        const option = prepareGraphOptions(growthMetricsData, key);
        if (chartRef.current[key] !== null) {
          chart = init(chartRef.current[key]);
          // apply WPP theme to graphs - disable for now
          // chart = init(chartRef.current[key], {
          //   color: colors,
          // });
          chart.setOption(option);
        }

        function resizeChart() {
          chart?.resize();
        }
        window.addEventListener('resize', resizeChart);

        return () => {
          chart?.dispose();
          window.removeEventListener('resize', resizeChart);
        };
      });
    }
  }, [disabledGraphs, growthMetricsData]);

  useEffect(() => {
    return () => {
      resetGrowthMetricsState();
    };
  }, [resetGrowthMetricsState]);

  const isExportReportButtonDisabled: boolean =
    !growthMetricsData && isLoadingGrowthMetricsData;

  const isExportReportButtonVisible: boolean =
    timespanSelection &&
    growthMetricsData &&
    !isLoadingGrowthMetricsData &&
    !showRegenerateReportMessage &&
    !error;

  const isGenerateReportButtonDisabled: boolean =
    (!timespanSelection && !growthMetricsData) || isLoadingGrowthMetricsData;

  const onGenerateReport = () => {
    const { startDate, endDate } = getStartAndEndDateByTimespanSelection(
      timespanSelection as TimespanSelectionEnum
    );
    getGrowthMetricsApi(startDate, endDate).then();
    setCurrentlyRenderedGraphsTimespanSelection(timespanSelection);
  };

  const handleOnExportError = () => {
    showToast({
      header: `Error while exporting report, please try again.`,
      type: 'error',
      duration: 3000,
    });
  };

  const onExportReport = () => {
    const { startDate, endDate } = getStartAndEndDateByTimespanSelection(
      timespanSelection as TimespanSelectionEnum
    );
    exportGrowthMetricsApi(startDate, endDate, handleOnExportError).then();
  };

  const onTimespanSelectionChange = (
    event: CustomEvent<PillGroupChangeEvent>
  ) => {
    setTimespanSelection(event.detail.value);
  };

  const setChartRef = (key, el) => {
    chartRef.current[key] = el;
  };

  return (
    <div className="growth-metrics">
      <div className="timespan-selection-container">
        <WppTypography type="m-strong" className="span">
          Select Period For Growth
        </WppTypography>
        <div className="selections-buttons">
          <WppPillGroup
            type="single"
            value={timespanSelection}
            onWppChange={onTimespanSelectionChange}
          >
            <WppPill
              label="Last Year"
              value={TimespanSelectionEnum.LAST_YEAR}
            />
            <WppPill
              label="This Year"
              value={TimespanSelectionEnum.THIS_YEAR}
            />
            <WppPill label="Q1" value={TimespanSelectionEnum.Q1} />
            <WppPill label="Q2" value={TimespanSelectionEnum.Q2} />
            <WppPill label="Q3" value={TimespanSelectionEnum.Q3} />
            <WppPill label="Q4" value={TimespanSelectionEnum.Q4} />
          </WppPillGroup>
        </div>
        <WppButton
          variant="primary"
          size="m"
          onClick={onGenerateReport}
          disabled={isGenerateReportButtonDisabled}
        >
          Generate Report
        </WppButton>
      </div>
      {error && (
        <WppGrid container rowSpacing={4} className="error-message">
          <WppGrid item all={24} fluid fullWidth>
            <WppTypography type="m-strong" className="span">
              {errorMessage}
            </WppTypography>
          </WppGrid>
        </WppGrid>
      )}
      {timespanSelection &&
        !isLoadingGrowthMetricsData &&
        growthMetricsData !== null &&
        !error && (
          <div className="rendering-charts-section">
            {showRegenerateReportMessage && (
              <div className="info-message">
                <WppTypography type="m-strong" className="span">
                  {regenerateReportMessage}
                </WppTypography>
              </div>
            )}
            <div
              className={`charts-container ${
                showRegenerateReportMessage && 'disable'
              }`}
            >
              {Object.keys(growthMetricsData).map((key) => {
                if (disabledGraphs.indexOf(key) > -1) {
                  return null;
                }
                return (
                  <div className="single-chart-container" key={key}>
                    <WppTypography type="xl-heading" className="h2 chart-title">
                      {dataTitleMapper[key]}
                    </WppTypography>
                    <div className="chart" ref={(el) => setChartRef(key, el)} />
                  </div>
                );
              })}
            </div>
          </div>
        )}

      {isLoadingGrowthMetricsData && !error && (
        <div className="loading-container">
          <LoadingElement size="m" message={loadingMessage()} />
        </div>
      )}
      {!timespanSelection &&
        !growthMetricsData &&
        !isLoadingGrowthMetricsData &&
        !error && (
          <div className="timespan-selection-info-message">
            <WppTypography type="m-strong" className="span">
              {noTimespanSelectionMessage}
            </WppTypography>
          </div>
        )}
      {isExportReportButtonVisible && (
        <div className="export-container">
          <WppButton
            variant="primary"
            size="s"
            onClick={onExportReport}
            disabled={isExportReportButtonDisabled}
            loading={isLoadingGrowthMetricsExportFile}
          >
            Export Report
          </WppButton>
        </div>
      )}
    </div>
  );
}
