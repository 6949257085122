import React from 'react';
import {
  WppCheckbox,
  WppInput,
  WppLabel,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import {
  EApprovalType,
  IApprovalAdditionalInfo,
} from '../../../../stores/types';

export interface IInfoSectionParams {
  isAdmin: boolean;
  info: IApprovalAdditionalInfo;
  setInfo: (info: IApprovalAdditionalInfo) => void;
  approvalType: EApprovalType;
}

export default function InfoSection(params: IInfoSectionParams) {
  const { isAdmin, info, setInfo, approvalType } = params;

  return (
    <section className="info">
      <span className="row-1">
        <span className="col-1">
          <WppTypography className="label main" type="m-strong" tag="h2">
            ADDITIONAL INFO
          </WppTypography>
        </span>
        {approvalType === EApprovalType.ACCESS && (
          <span className="col-1">
            <WppLabel>BigQuery Write Access</WppLabel>
          </span>
        )}
        {approvalType === EApprovalType.ACCESS && (
          <span className="col-1">
            <WppLabel>Staging Bucket Creation</WppLabel>
          </span>
        )}
        <span className="col-1">
          <WppLabel>Product Approval</WppLabel>
        </span>
        <span className="col-1">
          <WppLabel>Client Delivery Lead Approval</WppLabel>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">&nbsp;</span>
        {approvalType === EApprovalType.ACCESS && (
          <span className="col-1">
            <WppCheckbox
              disabled={!isAdmin || approvalType !== EApprovalType.ACCESS}
              checked={info.big_query_write_access}
              onWppChange={({ detail: { checked } }) =>
                setInfo({ ...info, big_query_write_access: checked })
              }
            />
          </span>
        )}
        {approvalType === EApprovalType.ACCESS && (
          <span className="col-1">
            <WppCheckbox
              disabled={!isAdmin || approvalType !== EApprovalType.ACCESS}
              checked={info.gcs_bucket_creation}
              onWppChange={({ detail: { checked } }) =>
                setInfo({ ...info, gcs_bucket_creation: checked })
              }
            />
          </span>
        )}
        <span className="col-1">
          <WppCheckbox
            disabled={!isAdmin || approvalType === EApprovalType.TEAM}
            checked={info.product_approval}
            onWppChange={({ detail: { checked } }) =>
              setInfo({ ...info, product_approval: checked })
            }
          />
        </span>
        <span className="col-1">
          <WppCheckbox
            disabled={approvalType === EApprovalType.TEAM}
            checked={info.agency_contact_person_approval}
            onWppChange={({ detail: { checked } }) =>
              setInfo({ ...info, agency_contact_person_approval: checked })
            }
          />
        </span>
      </span>
      <span className="row-1 comments">
        <span className="col-1">
          <WppLabel>Comments</WppLabel>
        </span>
        <span className="col-1">
          <WppInput
            disabled={!isAdmin || approvalType === EApprovalType.TEAM}
            onWppChange={({ detail: { value } }) =>
              setInfo({ ...info, comments: value.substring(0, 200) })
            }
            value={
              !isAdmin || approvalType === EApprovalType.TEAM
                ? '-'
                : info.comments
            }
          />
        </span>
      </span>
    </section>
  );
}
