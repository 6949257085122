import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IReasonStore } from './types';
import { dswRequest } from './index';
import { API_BASE_URL } from '../config/config';

export const initialState: IReasonStore = {
  reasons: [],
  isLoading: false,
  actions: {
    getReasonApi: () => Promise.resolve(),
  },
};

export const useReasonStore = create<IReasonStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getReasonApi: async () => {
          const { reasons, isLoading } = get();
          if (reasons.length === 0 && !isLoading) {
            set((state) => ({
              ...state,
              isLoading: true,
            }));
            try {
              const url = `${API_BASE_URL}/request-reason/all`;
              const apiResponse = await dswRequest.get(url);
              set((state) => ({
                ...state,
                reasons: apiResponse.data,
              }));
            } finally {
              set((state) => ({
                ...state,
                isLoading: false,
              }));
            }
          }
        },
      },
    }))
  )
);
