import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IMetricsAndReportsStore } from './types';
import { dswRequest, getAuthorizationHeader } from './index';
import { API_BASE_URL, getAPIURL } from '../config/config';
import { mapData, mergeData } from './mappers/CostReporting';

const keyPlatformMetricsInitialData = {
  totalUsers: '',
  uniqueUsers: '',
  monthlyActiveUsers: '',
  activeProjects: '',
  totalProjects: '',
  agencies: '',
  pitchworks: '',
  notebooks: '',
};

const growthPlatformMetricsInitialData = {
  totalUsers: {},
  uniqueUsers: {},
  monthlyActiveUsers: {},
  activeProjects: {},
  totalProjects: {},
  agencies: {},
  pitchworks: {},
  notebooks: {},
};

export const initialState: IMetricsAndReportsStore = {
  keyPlatformMetricsData: null,
  growthMetricsData: null,
  resourceCostDetailsData: null,
  projectsData: null,
  quickReportData: null,
  error: '',
  exportError: '',
  hardwareCostDetailsError: '',
  dataCostDetailsError: '',
  projectsDataError: '',
  projectDetailsDataError: '',
  quickReportError: '',
  isLoadingKeyPlatformMetricsData: false,
  isLoadingGrowthMetricsData: false,
  isLoadingKeyPlatformMetricsExportFile: false,
  isLoadingGrowthMetricsExportFile: false,
  isLoadingHardwareCostDetails: false,
  isLoadingDataCostDetails: false,
  isLoadingProjects: false,
  isLoadingQuickReport: false,
  isLoadingQuickReportExportFile: false,
  loadingItems: [],
  actions: {
    getKeyPlatformMetricsApi: () => Promise.resolve(),
    getGrowthMetricsApi: () => Promise.resolve(),
    exportKeyPlatformMetricsApi: () => Promise.resolve(),
    exportGrowthMetricsApi: () => Promise.resolve(),
    resetGrowthMetricsState: () => ({}),
    resetCostReportingData: () => ({}),
    getResourceCostDetailsApi: () => Promise.resolve(),
    getHardwareCostDetails: () => Promise.resolve(),
    getDataProcessingCostDetails: () => Promise.resolve(),
    getProjectsApi: () => Promise.resolve(),
    getProjectDetailsApi: () => Promise.resolve(),
    getQuickReportApi: () => Promise.resolve(),
    exportQuickReportApi: () => Promise.resolve(),
    resetQuickReportApi: () => Promise.resolve(),
  },
};

export const useMetricsAndReportsStore = create<IMetricsAndReportsStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getKeyPlatformMetricsApi: async () => {
          set((state) => ({
            ...state,
            isLoadingKeyPlatformMetricsData: true,
            error: '',
          }));
          try {
            const url = `${API_BASE_URL}/metrics/key-platform-metrics`;
            const { data, error } = await dswRequest.get(url);

            set((state) => ({
              ...state,
              keyPlatformMetricsData: data,
              error: error?.message || '',
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              keyPlatformMetricsData: keyPlatformMetricsInitialData,
              error: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoadingKeyPlatformMetricsData: false,
            }));
          }
        },
        getGrowthMetricsApi: async (startDate, endDate) => {
          set((state) => ({
            ...state,
            isLoadingGrowthMetricsData: true,
            error: '',
          }));
          try {
            const url = `${API_BASE_URL}/metrics/growth-platform-metrics`;
            const { data, error } = await dswRequest.get(
              `${url}?startdate=${startDate}&enddate=${endDate}`
            );
            set((state) => ({
              ...state,
              growthMetricsData: data,
              error: error?.message || '',
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              growthMetricsData: growthPlatformMetricsInitialData,
              error: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoadingGrowthMetricsData: false,
            }));
          }
        },
        exportKeyPlatformMetricsApi: async (handleExportErrorCallback) => {
          set((state) => ({
            ...state,
            isLoadingKeyPlatformMetricsExportFile: true,
          }));
          try {
            const url = `${API_BASE_URL}/metrics/export/key-platform-metrics`;
            const header = getAuthorizationHeader({});
            // using directly fetch instead of dswRequest because received {data} is having issues when converted to blob
            const response = await fetch(url, { headers: { ...header } });
            if (!response.ok) {
              const message = `An error has occurred while fetching key-platform-metrics report: ${response.status}`;
              handleExportErrorCallback();
              set((state) => ({
                ...state,
                exportError: message,
              }));
            } else {
              const contentDispositionHeader = response.headers.get(
                'content-disposition'
              );
              const date = new Date();
              const formattedDate = date.toISOString().replace(/:/g, '_');
              let filename = `DSW_key_platform_metrics_${formattedDate}.xlsx`;
              if (contentDispositionHeader) {
                const filenameMatch =
                  contentDispositionHeader.match(/filename="(.+)"/);
                if (filenameMatch) {
                  [, filename] = filenameMatch;
                }
              }
              const blob = await response.blob();
              const downloadUrl = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = downloadUrl;
              a.download = filename;
              a.click();
              URL.revokeObjectURL(downloadUrl);
              set((state) => ({
                ...state,
                exportError: '',
              }));
            }
          } catch (e) {
            set((state) => ({
              ...state,
              exportError: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoadingKeyPlatformMetricsExportFile: false,
            }));
          }
        },
        exportGrowthMetricsApi: async (
          startDate,
          endDate,
          handleExportErrorCallback
        ) => {
          set((state) => ({
            ...state,
            isLoadingGrowthMetricsExportFile: true,
          }));
          try {
            const url = `${API_BASE_URL}/metrics/export/growth-platform-metrics`;
            const header = getAuthorizationHeader({});
            // using directly fetch instead of dswRequest because received {data} is having issues when converted to blob
            const response = await fetch(
              `${url}?startdate=${startDate}&enddate=${endDate}`,
              { headers: { ...header } }
            );
            if (!response.ok) {
              const message = `An error has occurred while fetching growth-platform-metrics report: ${response.status}`;
              handleExportErrorCallback();
              set((state) => ({
                ...state,
                exportError: message,
              }));
            } else {
              const contentDispositionHeader = response.headers.get(
                'content-disposition'
              );

              const date = new Date();
              const formattedDate = date.toISOString().replace(/:/g, '_');
              let filename = `DSW_growth_platform_metrics_${formattedDate}.xlsx`;
              if (contentDispositionHeader) {
                const filenameMatch =
                  contentDispositionHeader.match(/filename="(.+)"/);
                if (filenameMatch) {
                  [, filename] = filenameMatch;
                }
              }
              const blob = await response.blob();
              const downloadUrl = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = downloadUrl;
              a.download = filename;
              a.click();
              URL.revokeObjectURL(downloadUrl);
              set((state) => ({
                ...state,
                exportError: '',
              }));
            }
          } catch (e) {
            set((state) => ({
              ...state,
              exportError: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoadingGrowthMetricsExportFile: false,
            }));
          }
        },
        resetGrowthMetricsState: () => {
          set((state) => ({
            ...state,
            growthMetricsData: initialState.growthMetricsData,
            isLoadingGrowthMetricsData: initialState.isLoadingGrowthMetricsData,
            isLoadingGrowthMetricsExportFile:
              initialState.isLoadingKeyPlatformMetricsExportFile,
          }));
        },
        resetCostReportingData: () => {
          set((state) => ({
            ...state,
            isLoadingHardwareCostDetails: false,
            isLoadingDataCostDetails: false,
            resourceCostDetailsData: null,
            hardwareCostDetailsError: '',
            dataCostDetailsError: '',
          }));
        },
        getHardwareCostDetails: async (
          projectName,
          startDate,
          endDate,
          baseURL
        ) => {
          set((state) => ({
            ...state,
            isLoadingHardwareCostDetails: true,
            hardwareCostDetailsError: '',
          }));
          try {
            let url = `http://localhost:8014/proxy/v3/reporting/cost/run?dimensions=vendor_account_identifier&dimensions=vendor_account_name&end_date=${endDate}&filters=vendor_account_identifier==${projectName}&limit=50&metrics=unblended_cost&relativePeriods=custom&sort=unblended_costDESC&start_date=${startDate}&viewId=232147`;
            if (process.env.NODE_ENV === 'production') {
              url = `${getAPIURL()}/api/v3/reporting/cost/run?dimensions=vendor_account_identifier&dimensions=vendor_account_name&end_date=${endDate}&filters=vendor_account_identifier==${projectName}&limit=50&metrics=unblended_cost&relativePeriods=custom&sort=unblended_costDESC&start_date=${startDate}&viewId=232147`;
            }

            const { data, error } = await dswRequest.get(url, {
              options: {
                headers: {
                  Authorization: `Basic ${btoa(
                    'ZS760gG8bNQFClnKx1Hdd9Qxl5I='
                  )}`,
                  'Content-Type': 'application/json',
                },
              },
            });
            set((state) => ({
              ...state,
              resourceCostDetailsData: {
                ...state.resourceCostDetailsData,
                hardwareCost:
                  data.results?.length > 0
                    ? data.results[0].unblended_cost
                    : null,
              },
              hardwareCostDetailsError: error?.message || '',
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              resourceCostDetailsData: {
                ...state.resourceCostDetailsData,
                hardwareCost: null,
              },
              hardwareCostDetailsError: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoadingHardwareCostDetails: false,
            }));
          }
        },
        getDataProcessingCostDetails: async (
          agencyName,
          region,
          instanceUrl,
          startDate,
          endDate
        ) => {
          set((state) => ({
            ...state,
            isLoadingDataCostDetails: true,
            dataCostDetailsError: '',
          }));
          try {
            const url = `${API_BASE_URL}/cost-reporting/get-snowflake-cost-details`;
            const searchParams = new URLSearchParams({
              agencyName,
              regionName: region,
              startDate,
              endDate,
            } as unknown as Record<string, string>).toString();
            const { data, error } = await dswRequest.get(
              `${url}?${searchParams}`
            );
            set((state) => ({
              ...state,
              resourceCostDetailsData: {
                ...state.resourceCostDetailsData,
                dataProcessingCost: data ?? null,
              },
              dataCostDetailsError: error?.message || '',
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              resourceCostDetailsData: {
                ...state.resourceCostDetailsData,
                dataProcessingCost: null,
              },
              dataCostDetailsError: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoadingDataCostDetails: false,
            }));
          }
        },
        getResourceCostDetailsApi: async (
          agencyName,
          region,
          projectType,
          startDate,
          endDate
        ) => {
          set((state) => ({
            ...state,
            isLoadingResourceCostDetails: true,
            resourceCostDetailsError: '',
          }));
          try {
            const url = `${API_BASE_URL}/cost-reporting/cost-details`;
            const searchParams = new URLSearchParams({
              agencyName,
              regionName: region,
              projectType,
              startDate,
              endDate,
            } as unknown as Record<string, string>).toString();
            const { data, error } = await dswRequest.get(
              `${url}?${searchParams}`
            );
            set((state) => ({
              ...state,
              resourceCostDetailsData: data,
              resourceCostDetailsError: error?.message || '',
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              resourceCostDetailsData: null,
              resourceCostDetailsError: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoadingResourceCostDetails: false,
            }));
          }
        },
        getProjectsApi: async (
          agencyName,
          region,
          projectType,
          startDate,
          endDate
        ) => {
          set((state) => ({
            ...state,
            isLoadingProjects: true,
            projectsDataError: false,
          }));
          try {
            const url = `${API_BASE_URL}/cost-reporting/project-list`;
            const searchParams = new URLSearchParams({
              agencyName,
              regionName: region,
              projectType,
              startDate,
              endDate,
            } as unknown as Record<string, string>).toString();
            const { data = { Active: [], Deleted: [] }, error } =
              await dswRequest.get(`${url}?${searchParams}`);
            set((state) => ({
              ...state,
              projectsData: mergeData(undefined, mapData(data)),
              projectsDataError: error?.message || '',
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              projectsData: { ...state.projectsData },
              projectsDataError: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoadingProjects: false,
            }));
          }
        },
        getProjectDetailsApi: async (item, status, filters) => {
          if (!get().loadingItems.includes(item.id)) {
            set((state) => ({
              ...state,
              loadingItems: [item.id],
            }));
            try {
              const statusItems = [...get().projectsData[status]];
              const searchParams = new URLSearchParams({
                agencyName: item.agencyName,
                namespaceName: item.projectName,
                regionName: item.regionName,
                startDate: filters.startDate,
                endDate: filters.endDate,
              } as unknown as Record<string, string>).toString();
              const url = `${API_BASE_URL}/cost-reporting/project-details?${searchParams}`;
              const { data, error } = await dswRequest.get(url);
              if (!error) {
                statusItems.splice(statusItems.indexOf(item), 1, {
                  ...item,
                  details: data,
                });
              }
              set((state) => ({
                ...state,
                projectsData: mapData({
                  ...state.projectsData,
                  [status]: statusItems,
                }),
                projectsDataError: error?.message || '',
              }));
            } catch (e) {
              set((state) => ({
                ...state,
                projectsData: { ...state.projectsData },
                projectsDataError: e.message,
              }));
            } finally {
              set((state) => ({
                ...state,
                loadingItems: [
                  ...state.loadingItems.filter((id) => id !== item.id),
                ],
              }));
            }
          }
        },
        resetQuickReportApi: async () => {
          set((state) => ({
            ...state,
            isLoadingQuickReport: false,
            quickReportError: '',
          }));
        },
        getQuickReportApi: async (projectType, startDate, endDate) => {
          if (projectType && startDate && endDate) {
            set((state) => ({
              ...state,
              isLoadingQuickReport: true,
              quickReportError: '',
            }));
            try {
              const fetchQuickReportUrl = `${API_BASE_URL}/quick-report/${projectType}`;
              const { data: projectsData, error: projectError } =
                await dswRequest.get(
                  `${fetchQuickReportUrl}?startDate=${startDate}&endDate=${endDate}`
                );
              set((state) => ({
                ...state,
                quickReportData: projectsData,
                quickReportError: projectError?.message || '',
              }));
            } catch (e) {
              set((state) => ({
                ...state,
                quickReportData: null,
                quickReportError: e.message,
              }));
            } finally {
              set((state) => ({
                ...state,
                isLoadingQuickReport: false,
              }));
            }
          }
        },
        exportQuickReportApi: async (
          reportType,
          startDate,
          endDate,
          handleExportErrorCallback
        ) => {
          set((state) => ({
            ...state,
            isLoadingQuickReportExportFile: true,
          }));
          try {
            let params = `?startDate=${startDate}&endDate=${endDate}`;
            if (reportType === 'total-user-per-agency-regionwise') {
              params = '';
            }
            const url = `${API_BASE_URL}/quick-report/export/${reportType}${params}`;
            const header = getAuthorizationHeader({});
            // using directly fetch instead of dswRequest because received {data} is having issues when converted to blob
            const response = await fetch(url, { headers: { ...header } });
            if (!response.ok) {
              const message = `An error has occurred while fetching growth-platform-metrics report: ${response.status}`;
              handleExportErrorCallback();
              set((state) => ({
                ...state,
                exportError: message,
              }));
            } else {
              const contentDispositionHeader = response.headers.get(
                'content-disposition'
              );
              const date = new Date();
              const formattedDate = date.toISOString().replace(/:/g, '_');
              let filename = `DSW_${reportType}_${formattedDate}.xlsx`;
              if (contentDispositionHeader) {
                const filenameMatch =
                  contentDispositionHeader.match(/filename="(.+)"/);
                if (filenameMatch) {
                  [, filename] = filenameMatch;
                }
              }
              const blob = await response.blob();
              const downloadUrl = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = downloadUrl;
              a.download = filename;
              a.click();
              URL.revokeObjectURL(downloadUrl);
              set((state) => ({
                ...state,
                exportError: '',
              }));
            }
          } catch (e) {
            set((state) => ({
              ...state,
              exportError: e.message,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoadingQuickReportExportFile: false,
            }));
          }
        },
      },
    }))
  )
);
