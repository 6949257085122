import React from 'react';
import { WppTabs, WppTab } from '@platform-ui-kit/components-library-react';
import { TabsChangeEventDetail } from '@platform-ui-kit/components-library';
import { NavigationItem } from './types';

export interface INavigationTabsProps {
  navigationItems: NavigationItem[];
  currentPage: string;
  setCurrentPage: (currentPage: string) => void;
  disabledItems: string[];
}

function NavigationTabs(props: INavigationTabsProps) {
  const { navigationItems, currentPage, setCurrentPage, disabledItems } = props;
  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>): void => {
    const page = event.detail.value;
    setCurrentPage(page);
  };

  return (
    <div className="navigation-tabs">
      <WppTabs size="s" value={currentPage} onWppChange={handleTabChange}>
        {navigationItems.map((navItem) => {
          return (
            <WppTab
              key={navItem.value}
              disabled={disabledItems.indexOf(navItem.value) > -1}
              value={navItem.value}
            >
              <span className={navItem.className || ''}>{navItem.title}</span>
            </WppTab>
          );
        })}
      </WppTabs>
    </div>
  );
}

export default NavigationTabs;
