import React from 'react';
import {
  WppInput,
  WppLabel,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import { IRequesterDetails } from '../../../../stores/types';

export interface IUserSectionParams {
  user: IRequesterDetails;
}

export default function UserSection(params: IUserSectionParams) {
  const { user } = params;

  return (
    <section className="user">
      <span className="row-1">
        <span className="col-1">
          <WppTypography className="label main" type="m-strong" tag="h2">
            REQUESTOR&apos;S DETAILS
          </WppTypography>
        </span>
        <span className="col-1">
          <WppLabel>Name & Surname</WppLabel>
        </span>
        <span className="col-1">
          <WppLabel>E-mail</WppLabel>
        </span>
        <span className="col-1">
          <WppLabel>Company Name</WppLabel>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">&nbsp;</span>
        <span className="col-1">
          <WppInput disabled value={user.name} size="s" />
        </span>
        <span className="col-1">
          <WppInput disabled value={user.email} size="s" />
        </span>
        <span className="col-1">
          <WppInput disabled value={user.agency} size="s" />
        </span>
      </span>
    </section>
  );
}
