import React, { useEffect, useState } from 'react';
import {
  WppSpinner,
  WppModal,
  WppButton,
  WppIconWarning,
} from '@platform-ui-kit/components-library-react';
import { GridApi } from 'ag-grid-community';
import { PagesEnum } from '../../constants';
import useToast from '../../hooks/useToast';
import { useAgencyStore } from '../../stores/agency';
import { useGlobalStore } from '../../stores/global';
import { useRoleStore } from '../../stores/role';
import { useNavigationStore } from '../../stores/navigation';
import LoadingElement from '../../components/LoadingElement';
import AgencyGrid, { IAgencyGridData } from './AgencyGrid';
import DetailsGrid from './DetailsGrid';

import './MainPage.scss';

export const loadingOverlay = () => <WppSpinner size="m" />;

export interface ISelectedNotebook {
  instanceUrl: string;
  namespaceName: string;
  notebookName: string;
  stopped: boolean;
  action: string;
}

function MainPage() {
  const { showToast } = useToast();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [detailGridApi, setDetailGridApi] = useState<GridApi>();
  const [notebookDetail, setNotebookDetail] = useState<IAgencyGridData>();
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [selectedNotebook, setSelectedNotebook] = useState<ISelectedNotebook>();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [kubeflowAuthLoading, setKubeflowAuthLoading] =
    useState<IAgencyGridData>();
  const [intervalId, setIntervalId] = useState<number>(null);
  const {
    agencies,
    notebooks,
    notebookErrors,
    loadingAgencies,
    loadingNotebooks,
    actions: { getAgenciesApi, getNotebooks, deleteNotebook },
  } = useAgencyStore();
  const changeCurrentPage = useNavigationStore(
    (state) => state.actions.changeCurrentPage
  );
  const userInfo = useGlobalStore((state) => state.userInfo);
  const access = useRoleStore((state) => state.role.access);
  const accessKeys = access ? access.map(({ name }) => name) : [];

  const mainPageAllowed = accessKeys.includes('main');
  const notebookKey = `${notebookDetail?.namespace}-${notebookDetail?.instance}`;

  useEffect(() => {
    if (gridApi) {
      if (loadingAgencies) {
        setTimeout(() => gridApi.showLoadingOverlay(), 1);
      } else if (agencies.length > 0) {
        setTimeout(() => gridApi.hideOverlay(), 1000);
      }
    }
  }, [gridApi, loadingAgencies, agencies]);

  useEffect(() => {
    if (detailGridApi) {
      if (loadingNotebooks[notebookKey]) {
        setTimeout(() => detailGridApi.showLoadingOverlay(), 1);
      } else if (notebooks[notebookKey]?.notebooks.length > 0) {
        setTimeout(() => detailGridApi.hideOverlay(), 1000);
      }
    }
  }, [detailGridApi, loadingNotebooks, notebookKey, notebooks]);

  useEffect(() => {
    if (agencies.length === 0 && !loadingAgencies && mainPageAllowed) {
      getAgenciesApi().then();
    }
  }, [agencies, getAgenciesApi, userInfo, loadingAgencies, mainPageAllowed]);

  if (!mainPageAllowed) {
    changeCurrentPage(PagesEnum.ACCESS_REQUEST_AND_SUPPORT);
    return null;
  }

  const proceedAction = (params?) => {
    const instanceUrl = params?.instanceUrl || selectedNotebook.instanceUrl;
    const namespaceName =
      params?.namespaceName || selectedNotebook.namespaceName;
    const notebookName = params?.notebookName || selectedNotebook.notebookName;
    const stopped = params?.stopped || selectedNotebook.stopped;
    const action = params?.action || selectedNotebook.action;
    const callback = ({ data: response, error }) => {
      if (!error && response && response.status === 200) {
        const state = stopped ? 'Stopping' : 'Starting';
        setShowWarning(false);
        showToast({
          header:
            action === 'delete' ? response.message : `${state} Notebook...`,
          message: '',
          type: 'success',
          duration: 30000,
          maxMessageLines: 1,
        });
        setSelectedNotebook(null);
      } else {
        const humanReadableAction =
          action !== 'delete'
            ? `Updating status of "${notebookName}" failed`
            : `"${notebookName}" could not get deleted`;
        setShowWarning(false);
        showToast({
          header: humanReadableAction,
          message: response?.message || `${error?.message}: ${error?.status}`,
          type: 'error',
          duration: 0,
          maxMessageLines: 2,
        });
        setSelectedNotebook(null);
      }
    };
    if (action === 'delete') {
      deleteNotebook(instanceUrl, namespaceName, notebookName, callback).then();
    } else if (action === 'patch') {
      /*  patchNotebook(
        instanceUrl,
        namespaceName,
        notebookName,
        stopped,
        callback
      ).then(); */
      const notebookUrl = `${instanceUrl}_/jupyter/?ns=${namespaceName}`;
      window.open(notebookUrl);
    }
  };

  const handleAction = (
    instanceUrl,
    namespaceName,
    notebookName,
    stopped,
    action
  ) => {
    setSelectedNotebook({
      instanceUrl,
      namespaceName,
      notebookName,
      stopped,
      action,
    });
    if (action !== 'patch') {
      setShowWarning(true);
    } else {
      proceedAction({
        instanceUrl,
        namespaceName,
        notebookName,
        stopped,
        action,
      });
    }
  };

  const tableData = [];
  agencies.forEach((agency) => {
    const { agency_name, region_name, instance_url, namespace_details } =
      agency;
    namespace_details.forEach((namespace) => {
      const { namespace_name, lead_detail_list, contact_person, datasets } =
        namespace;
      tableData.push({
        agency: agency_name,
        region: region_name,
        instance: instance_url,
        namespace: namespace_name,
        lead: lead_detail_list,
        contact: contact_person,
        datasets,
      });
    });
  });

  const startKubeflowAuth = (d) => {
    const { instance, namespace } = d;
    const kubeflowWindow = window.open(instance);
    const start = new Date().getTime();
    setKubeflowAuthLoading(d);
    setIntervalId(
      setInterval(async () => {
        const actual = new Date().getTime();
        const fetchingNotebooksHasError = notebookErrors[notebookKey];
        if (!fetchingNotebooksHasError || actual - start > 60000) {
          await getNotebooks(namespace, instance);
          kubeflowWindow.close();
          setKubeflowAuthLoading(null);
          clearInterval(intervalId);
          setIntervalId(null);
        } else {
          await getNotebooks(namespace, instance);
        }
      }, 2500) as unknown as number
    );
  };
  if (!openDetails && intervalId) {
    clearInterval(intervalId);
  }

  return (
    <div id="main-page">
      <WppModal open={showWarning}>
        <h3 slot="header">
          <WppIconWarning />{' '}
          {`${
            selectedNotebook?.action === 'patch' && selectedNotebook?.stopped
              ? 'Stop'
              : 'Delete'
          }`}{' '}
          Notebook
        </h3>
        <p slot="body">
          Are you sure, you want to{' '}
          {selectedNotebook?.action === 'patch' ? 'stop' : 'delete'}
          <br />
          the Notebook{' '}
          <span className="highlight">{selectedNotebook?.notebookName}</span>?
        </p>
        <div slot="actions">
          <WppButton
            variant="destructive-secondary"
            size="s"
            onClick={() => setShowWarning(false)}
          >
            Cancel
          </WppButton>{' '}
          <WppButton
            variant="destructive"
            size="s"
            onClick={() => proceedAction()}
          >
            Proceed
          </WppButton>
        </div>
      </WppModal>
      <div className="content">
        {!openDetails &&
          (loadingAgencies ? (
            <LoadingElement />
          ) : (
            <AgencyGrid
              tableData={tableData}
              setGridApi={setGridApi}
              getNotebooks={getNotebooks}
              setNotebookDetail={setNotebookDetail}
              setOpenDetails={setOpenDetails}
            />
          ))}
        {openDetails &&
          (loadingNotebooks[notebookKey] &&
          !(
            !notebooks[notebookKey]?.notebooks &&
            ((!loadingNotebooks[notebookKey] &&
              !!notebookErrors[notebookKey]) ||
              kubeflowAuthLoading === notebookDetail)
          ) ? (
            <LoadingElement />
          ) : (
            <DetailsGrid
              accessKeys={accessKeys}
              tableData={notebooks[notebookKey]?.notebooks || []}
              notebookDetail={notebookDetail}
              setGridApi={setDetailGridApi}
              handleAction={handleAction}
              setOpenDetails={setOpenDetails}
              error={
                !notebooks[notebookKey]?.notebooks &&
                ((!loadingNotebooks[notebookKey] &&
                  !!notebookErrors[notebookKey]) ||
                  kubeflowAuthLoading === notebookDetail)
              }
              errorHandler={() => startKubeflowAuth(notebookDetail)}
              loadingAuth={kubeflowAuthLoading === notebookDetail}
            />
          ))}
      </div>
    </div>
  );
}

export default MainPage;
