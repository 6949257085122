import React, { useState } from 'react';
import {
  WppActionButton,
  WppButton,
  WppIconAddCircle,
  WppIconRemoveCircle,
  WppInput,
  WppTypography,
} from '@platform-ui-kit/components-library-react';

import { RequestDefaultFields } from '../index';

import { ITeamFormData } from '../types';
import { validateEmail } from '../../../utils';
import { useGlobalStore } from '../../../stores/global';
import { useRegistryStore } from '../../../stores/registry';

import './index.scss';

export interface ITeamFormParams {
  onSubmit: (formData: ITeamFormData) => void;
  onCancel: () => void;
}

const validateNameAndEmailFields = (
  showErrors: boolean,
  member: { name: string; email: string },
  projectTeamNames: string[],
  projectTeamEmails: string[]
) => {
  let nameFieldErrorMessage = '';
  let emailFieldErrorMessage = '';
  if (!showErrors) {
    return { nameFieldErrorMessage, emailFieldErrorMessage };
  }
  nameFieldErrorMessage =
    !nameFieldErrorMessage && !member.name
      ? 'Teammate Name should not be empty.'
      : nameFieldErrorMessage;
  nameFieldErrorMessage =
    !nameFieldErrorMessage && projectTeamNames.indexOf(member.name) > -1
      ? 'Teammate Name should be unique.'
      : nameFieldErrorMessage;
  emailFieldErrorMessage =
    !emailFieldErrorMessage && !member.email
      ? 'Teammate E-Mail should not be empty.'
      : emailFieldErrorMessage;
  emailFieldErrorMessage =
    !emailFieldErrorMessage && !validateEmail(member.email)
      ? 'Please enter a valid Teammate E-Mail address.'
      : emailFieldErrorMessage;
  emailFieldErrorMessage =
    !emailFieldErrorMessage && projectTeamEmails.indexOf(member.email) > -1
      ? 'Teammate E-Mail address should be unique.'
      : emailFieldErrorMessage;
  return { nameFieldErrorMessage, emailFieldErrorMessage };
};

export default function TeamForm(params: ITeamFormParams) {
  const { onSubmit, onCancel } = params;
  const { userInfo } = useGlobalStore();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const { data: registryData } = useRegistryStore();

  const initialState = {
    agency: null,
    region: null,
    project: null,
    projectDetails: [],
    team: [{ name: null, email: null }],
    requesterEmail: userInfo.email,
  };

  const [formData, setFormData] = useState<ITeamFormData>(initialState);

  const projects = [...registryData.Active];
  const projectsById = {};
  projects.forEach((project) => {
    projectsById[project.id] = project;
  });
  const projectDetails = [];
  const t = projectsById[formData.project?.id]?.details?.team_details;
  if (formData.project && t) {
    t.filter(({ added_team_member }) => added_team_member).forEach(
      ({ name, email, region }) => {
        projectDetails.push({
          name,
          email,
          region,
        });
      }
    );
  }
  const setTeam = ({ detail: { value } }, field, index) => {
    const updatedTeam = [...formData.team];
    updatedTeam[index][field] = value;
    setFormData({ ...formData, team: updatedTeam });
  };
  const onAddMember = () => {
    setFormData({
      ...formData,
      team: [...formData.team, { name: null, email: null }],
    });
  };
  const onRemoveMember = (index: number) => {
    const updatedTeam = [...formData.team];
    updatedTeam.splice(index, 1);
    setFormData({
      ...formData,
      team: updatedTeam,
    });
  };

  const getAddMember = () => (
    <span className="col-1">
      <WppActionButton
        onClick={onAddMember}
        disabled={formData.team.length > 7}
      >
        <WppIconAddCircle slot="icon-start" />
        Add another team member
      </WppActionButton>
    </span>
  );

  const errors = [];
  const projectTeamNames = [];
  const projectTeamEmails = [];
  projectDetails
    .filter(({ region }) => region === formData.region.name)
    .forEach(({ name, email }) => {
      projectTeamNames.push(name);
      projectTeamEmails.push(email);
    });

  if (!formData.project?.projectName) {
    errors.push('The Project need to be selected');
  }
  if (
    formData.team.filter(
      ({ name, email }) =>
        projectTeamNames.indexOf(name) > -1 ||
        projectTeamEmails.indexOf(email) > -1
    ).length > 0
  ) {
    errors.push('Teammates need to be unique');
  }
  if (formData.team.filter(({ email }) => !validateEmail(email)).length > 0) {
    errors.push('Teammates need to have a valid email');
  }
  if (formData.team.filter(({ name }) => !name || name.length < 1).length > 0) {
    errors.push('Teammates need to have a name set');
  }

  const validToSubmit = () => {
    return errors.length === 0;
  };

  const getContent = () => (
    <div className="content">
      <section className="centered">
        <RequestDefaultFields
          showErrors={showErrors}
          formData={formData}
          setFormData={setFormData}
          initialState={initialState}
        />
        <span className="row-1">
          <span className="col-1">
            <span className="label">Already assigned team mates</span>
          </span>
          <span className="col-1">
            <span className="team-mate-container">
              {projectDetails.length > 0
                ? projectDetails.map((projectDetail, index) => (
                    <span className="team-mate" key={`team-mate-${index}`}>
                      <span className="item-content">{`${projectDetail.name} (${projectDetail.email})`}</span>
                    </span>
                  ))
                : '-'}
            </span>
          </span>
        </span>
        <span className="row-1">&nbsp;</span>
        <span className="row-1">
          <span className="col-1">&nbsp;</span>
          <span className="col-1">
            <span className="label" style={{ textAlign: 'left' }}>
              Provide team member details
            </span>
          </span>
        </span>
        <span className="row-1">
          <span className="col-1" style={{ width: '20%' }}>
            &nbsp;
          </span>
          <span className="col-1" style={{ width: '30%' }}>
            <span className="label" style={{ textAlign: 'center' }}>
              Name
            </span>
          </span>
          <span className="col-1" style={{ width: '30%' }}>
            <span className="label" style={{ textAlign: 'center' }}>
              E-Mail
            </span>
          </span>
        </span>
        {formData.team.map((member, teamIndex) => {
          const { nameFieldErrorMessage, emailFieldErrorMessage } =
            validateNameAndEmailFields(
              showErrors,
              member,
              projectTeamNames,
              projectTeamEmails
            );
          return (
            <span className="row-1 team-row" key={`team-member-${teamIndex}`}>
              <span className="col-1" style={{ width: '20%' }}>
                &nbsp;
              </span>
              <span className="col-1 has-input-field" style={{ width: '30%' }}>
                <WppInput
                  onWppChange={(e) => setTeam(e, 'name', teamIndex)}
                  value={member.name}
                  message={nameFieldErrorMessage}
                  messageType={
                    showErrors && nameFieldErrorMessage ? 'error' : null
                  }
                />
              </span>
              <span className="col-1 has-input-field" style={{ width: '30%' }}>
                <WppInput
                  onWppChange={(e) => setTeam(e, 'email', teamIndex)}
                  value={member.email}
                  message={emailFieldErrorMessage}
                  messageType={
                    showErrors && emailFieldErrorMessage ? 'error' : null
                  }
                />
              </span>
              {teamIndex + 1 === formData.team.length && !!teamIndex && (
                <span className="col-1" style={{ width: '20%' }}>
                  <WppActionButton onClick={() => onRemoveMember(teamIndex)}>
                    <WppIconRemoveCircle slot="icon-start" />
                    Remove member
                  </WppActionButton>
                </span>
              )}
            </span>
          );
        })}
        <span className="row-1">
          <span className="col-1" style={{ width: '20%' }}>
            &nbsp;
          </span>
          <span className="col-1">{getAddMember()}</span>
        </span>
      </section>
    </div>
  );

  return (
    <div className="team-form">
      <div className="headline">
        <WppTypography type="m-strong" tag="h1">
          DS WORKBENCH - ADDITIONAL TEAMMATES REQUEST FORM
        </WppTypography>
      </div>
      {getContent()}
      <div className="buttons">
        <WppButton
          className="width-200"
          title={errors.length > 0 ? errors.join(', ') : 'Submit'}
          onClick={() =>
            validToSubmit() ? onSubmit(formData) : setShowErrors(true)
          }
        >
          Submit
        </WppButton>
        <WppActionButton
          className="width-200"
          title="cancel and navigate back"
          onClick={() => onCancel()}
        >
          Cancel
        </WppActionButton>
      </div>
    </div>
  );
}
