import React, { Dispatch, SetStateAction, useState } from 'react';
import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library';
import {
  WppAutocomplete,
  WppListItem,
} from '@platform-ui-kit/components-library-react';
import SelectedValues from './SelectedValues';
import styles from './Autocomplete.module.scss';

interface AutoCompleteProps {
  searchList: AutocompleteDefaultOption[];
  setSearchValues: Dispatch<SetStateAction<AutocompleteDefaultOption[]>>;
}

export default function Autocomplete(props: AutoCompleteProps) {
  const { searchList, setSearchValues } = props;
  const [customOptionsValue, setCustomOptionsValue] = useState<
    AutocompleteDefaultOption[]
  >([]);

  return (
    <div className={styles.wrapper} data-testid="autocompletes">
      <div className={styles.item}>
        <WppAutocomplete
          required
          name="custom-option-labels"
          placeholder="Filter By"
          value={customOptionsValue}
          onWppChange={(e) => {
            setCustomOptionsValue(
              e.detail.value as AutocompleteDefaultOption[]
            );
            setSearchValues(e.detail.value as AutocompleteDefaultOption[]);
            // e.target.blur();
          }}
          data-testid="custom-autocomplete"
          autoFocus
          multiple
          simpleSearch
        >
          {searchList.map((option) => {
            return (
              <WppListItem
                key={option.id}
                value={option}
                label={option.label}
                data-column={option.column}
              >
                <div slot="label">
                  <div className={styles.primary}>{option.label}</div>
                </div>
              </WppListItem>
            );
          })}
          <SelectedValues
            values={customOptionsValue}
            onCloseClick={(value) => {
              setCustomOptionsValue(
                customOptionsValue.filter((i) => i.id !== value)
              );
              setSearchValues(customOptionsValue.filter((i) => i.id !== value));
            }}
          />
        </WppAutocomplete>
      </div>
    </div>
  );
}
