import React, { useEffect, useState } from 'react';

import {
  WppLabel,
  WppListItem,
  WppSelect,
} from '@platform-ui-kit/components-library-react';
import { WppSelectCustomEvent } from '@platform-ui-kit/components-library/loader';
import { SelectChangeEventDetail } from '@platform-ui-kit/components-library';

import RequestList from './RequestList/index';

import './index.scss';
import { useApprovalStore } from '../../../stores/approval';
import { EApprovalType } from '../../../stores/types';

export const getAccessRouting = () => {
  const { pathname } = window.location;

  switch (pathname) {
    case '/dashboard/request/dataset':
      return EApprovalType.DATASET;
    case '/dashboard/request/team':
      return EApprovalType.TEAM;
    case '/dashboard/request/access':
    default:
      return EApprovalType.ACCESS;
  }
};

export default function RequestManagement() {
  const [localApprovalType, setLocalApprovalType] = useState<string>(
    getAccessRouting()
  );
  const {
    approvalType,
    actions: { setApprovalType },
  } = useApprovalStore();
  const onSelectApprovalType = (
    event: WppSelectCustomEvent<SelectChangeEventDetail>
  ) => {
    const {
      detail: { value },
    } = event;
    setLocalApprovalType(value);
  };

  useEffect(() => {
    if (!approvalType || approvalType !== localApprovalType) {
      setApprovalType(localApprovalType as EApprovalType);
    }
  }, [approvalType, setApprovalType, localApprovalType]);

  return (
    <div className="request-management">
      <div className="filter">
        <WppLabel>Select Request Type</WppLabel>
        <WppSelect
          value={approvalType}
          size="s"
          onWppChange={onSelectApprovalType}
        >
          <WppListItem value="access">
            <p slot="label">Access Request</p>
          </WppListItem>
          <WppListItem value="dataset">
            <p slot="label">DataSet Request</p>
          </WppListItem>
          <WppListItem value="team">
            <p slot="label">Team Request</p>
          </WppListItem>
        </WppSelect>
      </div>
      <div className="content">
        {approvalType === localApprovalType && (
          <RequestList approvalType={approvalType} />
        )}
      </div>
    </div>
  );
}
