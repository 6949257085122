import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi, ICellRendererParams } from 'ag-grid-community';

import {
  WppIconDataViewList,
  WppGrid,
  WppActionButton,
  WppPopover,
} from '@platform-ui-kit/components-library-react';
import { ISalesForceAccounts } from '../../../../../stores/types';

interface ISalesforceAccountParams {
  cellRendererParams: ICellRendererParams;
}

export default function SalesforceAccount(props: ISalesforceAccountParams) {
  const { cellRendererParams } = props;
  const salesGridRef = useRef(null);
  const defaultPopoverRef = useRef<HTMLWppPopoverElement>(null);
  const [salesGridApi, setSalesGridApi] = useState<GridApi>(null);
  const [, setOnShowPopover] = useState<boolean>(false);
  const ROW_HEIGHT = 47;
  const ROW_MARGIN = 10;

  const disableShowPopover = !(
    cellRendererParams &&
    cellRendererParams.value &&
    cellRendererParams.value.length > 0 &&
    cellRendererParams.value[0].id
  );

  const [detailRowData] = useState<ISalesForceAccounts[]>(
    cellRendererParams.value
      ? [...cellRendererParams.value].sort((a, b) => {
          return b.include - a.include;
        })
      : []
  );

  const gridViewport = document.querySelector(
    `.tippy-content .salesforce-details-container-${cellRendererParams.data.segmentIdDb} .ag-center-cols-viewport`
  ) as HTMLElement;
  const gridWrapper = document.querySelector(
    `.tippy-content .salesforce-details-container-${cellRendererParams.data.segmentIdDb} .ag-center-cols-container`
  ) as HTMLElement;
  const viewPort = document.querySelector(
    `.tippy-content .salesforce-details-container-${cellRendererParams.data.segmentIdDb} .ag-body-viewport`
  ) as HTMLElement;
  const grid = document.querySelector(
    `.tippy-content #agGridSalesforceDetails-${cellRendererParams.data.segmentIdDb}`
  ) as HTMLElement;

  const resizeSalesGrid = (gridEvent) => {
    if (salesGridApi && gridWrapper) {
      const rowCount = cellRendererParams.value.length;
      const totalHeight = (ROW_HEIGHT + ROW_MARGIN) * rowCount;

      if (gridViewport) {
        gridViewport.style.width = `99.8`;
      }
      if (gridWrapper) {
        gridWrapper.style.width = `99.5%`;
      }

      if (viewPort) {
        viewPort.style.height = `${totalHeight}px`;
        viewPort.style.maxHeight = `250px`;
      }

      if (grid) {
        grid.style.width = `99.9%`;
        grid.style.height = `${totalHeight + (ROW_HEIGHT + ROW_MARGIN)}px`;
      }
      gridEvent.api.sizeColumnsToFit();
    }
  };

  useEffect(() => {
    resizeSalesGrid({ api: salesGridApi });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesGridApi, gridWrapper]);

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      headerName: 'Account Id',
      width: 260,
    },
    {
      field: 'name',
      headerName: 'Account Name',
      width: 260,
    },
    {
      field: 'include',
      headerName: 'Status',
      cellRenderer: (dataCellRenderer) => {
        return !dataCellRenderer.value ? 'Excluded' : 'Included';
      },
    },
  ];

  return (
    <WppPopover
      closable
      config={{
        appendTo: () => {
          return (
            document.querySelector(
              `.ag-row[row-id="segment-row-${cellRendererParams.data.segmentId}"]`
            ) || document.querySelector('#agGrid')
          );
        },
        inlinePositioning: true,
        placement: 'bottom-end',
        trigger: `${disableShowPopover ? '' : 'click'}`,
        onShow: () => {
          setOnShowPopover(true);
          resizeSalesGrid({ api: salesGridApi });
        },
        onHide: () => {
          setOnShowPopover(false);
          resizeSalesGrid({ api: salesGridApi });
        },
      }} // This config is required for React to work with different handlers like 'onClick'
      ref={defaultPopoverRef}
    >
      <div
        className="viewSalesforceAccount"
        slot="trigger-element"
        data-disabled="disabled"
      >
        <WppActionButton
          title={
            disableShowPopover
              ? 'No Accounts Available'
              : 'View NGPi Sales Accounts'
          }
          disabled={disableShowPopover}
        >
          <WppIconDataViewList
            size="s"
            color={
              disableShowPopover
                ? 'var(--wpp-grey-color-500)'
                : 'var(--wpp-primary-color-600)'
            }
          />
        </WppActionButton>
      </div>
      <div
        className={`salesforce-details-container salesforce-details-container-${cellRendererParams.data.segmentIdDb}`}
        slot="content"
      >
        <WppGrid container item all={24}>
          <section
            className="ag-theme-wpp"
            id={`agGridSalesforceDetails-${cellRendererParams.data.segmentIdDb}`}
          >
            <AgGridReact
              onGridReady={(gridReadyEvent) => {
                setSalesGridApi(gridReadyEvent.api);
                resizeSalesGrid(gridReadyEvent);
              }}
              onFirstDataRendered={(gridResizeEvent) => {
                resizeSalesGrid(gridResizeEvent);
              }}
              ref={salesGridRef}
              rowData={detailRowData}
              animateRows
              getRowStyle={(param) => {
                const { rowIndex } = param.node;
                return {
                  marginTop: rowIndex === 0 ? '0' : `${10 * rowIndex}px`,
                };
              }}
              columnDefs={columnDefs}
              defaultColDef={{
                sortable: true,
                resizable: true,
                cellStyle: { textAlign: 'left' },
                menuTabs: ['filterMenuTab'],
              }}
              columnHoverHighlight
              suppressPaginationPanel
              suppressHorizontalScroll
              suppressRowTransform
              reactiveCustomComponents
              suppressMovableColumns
            />
          </section>
        </WppGrid>
      </div>
    </WppPopover>
  );
}
