import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {
  WppActionButton,
  WppIconGear,
  WppIconLogout,
} from '@platform-ui-kit/components-library-react';

import { useOs } from '../../hooks/osWrapper';
import logo from '../../assets/images/Choreograph-Black-RGB.png';
import { IError } from '../../types';
import { IDSWRole, IUserInfo } from '../../stores/types';

import './Header.scss';

export interface IHeaderParams {
  role: IDSWRole;
  userInfo: IUserInfo;
  enableSettings: boolean;
  showSettings: () => void;
}

const symbols = {
  christmas: '🎄',
  silvester: '🎇',
};

const date = new Date();
const christmas =
  date >= new Date(`${date.getFullYear()}-12-24`) &&
  date <= new Date(`${date.getFullYear()}-12-27`);
const silvester =
  date >= new Date(`${date.getFullYear()}-12-31`) &&
  date <= new Date(`${date.getFullYear() + 1}-01-01`);

function Header(params: IHeaderParams) {
  const { role, userInfo, enableSettings, showSettings } = params;
  const { access, name } = role;
  const { osContext } = useOs();
  const oktaAuth = useOktaAuth();
  const [, setCorsErrorModalOpen] = React.useState(false);

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err: IError) =>
    err.name === 'AuthApiError' &&
    !err.errorCode &&
    err.xhr.message === 'Failed to fetch';
  const logout = async () => {
    try {
      oktaAuth.oktaAuth.tokenManager.clear();
      await oktaAuth.oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin,
      });
    } catch (err) {
      if (isCorsError(err as IError)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  return (
    <header className="header-wrapper">
      <div className="header">
        <img src={logo} className="brand-logo fade-in" alt="brand-logo" />
        <span className="title">
          Data Science - Enablement Dashboard {christmas && symbols.christmas}
          {silvester && symbols.silvester}
        </span>
      </div>
      {access.length > 0 && (
        <div className="user">
          <span>WELCOME, {userInfo.name.toUpperCase()}!</span>
          {name === 'Admin' && enableSettings && (
            <WppActionButton title="Settings" onClick={showSettings}>
              <WppIconGear />
            </WppActionButton>
          )}
          {osContext && osContext.userDetails.email === '' && (
            <WppActionButton title="Logout" onClick={logout}>
              <WppIconLogout />
            </WppActionButton>
          )}
        </div>
      )}
    </header>
  );
}

export default Header;
