import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { WppToastContainer } from '@platform-ui-kit/components-library-react';

import { useOs, useOsWrapper } from '../../hooks/osWrapper';
import useToast from '../../hooks/useToast';
import { useRoleStore } from '../../stores/role';
import { useGlobalStore } from '../../stores/global';
import { useAuthenticate } from '../../stores/authenticate';
import { useNavigationStore } from '../../stores/navigation';
import { useFeatureToggleStore } from '../../stores/featureToggle';
import { useSettingsStore } from '../../stores/settings';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import MainNavigationTabs from '../../components/MainNavigationTabs/NavigationTabs';
import LoadingElement from '../../components/LoadingElement';
import SettingsModal from '../../components/SettingsModal';
import FestiveModal from '../../components/FestiveModal';
import TechnicalIssuesModal from '../../components/TechnicalIssuesModal';

import { EFeatureToggle, PagesEnum, AccessKeys } from '../../constants';

import * as ROUTE_PATHS from '../../routes/routePaths';

import './Home.scss';

export default function Home() {
  const childRef = useRef(null);
  const { osContext, osApi } = useOs();
  const osWrapper = useOsWrapper();
  const authHook = useOktaAuth();
  const { pathname: currentLocation, state: locationState } = useLocation();
  const navigate = useNavigate();

  const { showToast } = useToast();
  const {
    authInfo,
    actions: { setAuthentication },
  } = useAuthenticate();
  const {
    role,
    actions: { getRoleApi },
  } = useRoleStore();
  const {
    actions: { getFeatureToggles, hasFeature },
  } = useFeatureToggleStore();
  const {
    settings,
    actions: { getSettings, setSettings },
  } = useSettingsStore();
  const {
    userInfo,
    version: { loading: versionLoading, error: versionError, value: version },
    moty,
    actions: {
      getVersionApi,
      setUserInfo,
      moty: { dismissHoliday },
    },
  } = useGlobalStore();
  const changeCurrentPage = useNavigationStore(
    (state) => state.actions.changeCurrentPage
  );

  const currentPage = useNavigationStore((state) => state.currentPage);
  const [pathName, setPathName] = useState<string>('');
  const [showModal, setShowModal] = useState(moty.holiday);
  const [showIssues, setShowIssues] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [updatedSettings, setUpdatedSettings] = useState(settings);
  const accessNames = role.access.map(({ name }) => name);

  const token = osApi.getAccessToken();

  const isLoading =
    !userInfo.name || !userInfo.email || role.id === -1 || !token;

  let loadingMessage = '';

  if (!authInfo || !authInfo?.token) {
    loadingMessage = 'Waiting for Authentication...';
  } else if (!userInfo.name || !userInfo.email) {
    loadingMessage = 'Loading User Information...';
  }

  const doSetSettings = (newSettings) =>
    setSettings(newSettings, (success) => {
      showToast({
        header: `${success ? 'Success' : 'Error'}`,
        message: `Saving settings was${success ? '' : ' NOT'} successful`,
        type: success ? 'success' : 'error',
        duration: 3000,
      });
    });

  const navigationHandlers: Record<PagesEnum, (pathname) => void> = {
    [PagesEnum.MAIN_PAGE]: (pathname) => {
      changeCurrentPage(PagesEnum.MAIN_PAGE);
      navigate(pathname, { state: null });
    },
    [PagesEnum.ACCESS_REQUEST_AND_SUPPORT]: (pathname) => {
      changeCurrentPage(PagesEnum.ACCESS_REQUEST_AND_SUPPORT);
      navigate(pathname, { state: null });
    },
    [PagesEnum.DATA_SETS_AND_DATA_CATALOGS]: (pathname) => {
      changeCurrentPage(PagesEnum.DATA_SETS_AND_DATA_CATALOGS);
      navigate(pathname, { state: null });
    },
    [PagesEnum.DASHBOARD]: (pathname) => {
      changeCurrentPage(PagesEnum.DASHBOARD);
      navigate(pathname, { state: null });
    },
    [PagesEnum.TUTORIALS_AND_DOCUMENTATIONS]: (pathname) => {
      changeCurrentPage(PagesEnum.TUTORIALS_AND_DOCUMENTATIONS);
      navigate(pathname, { state: null });
    },
  };

  useEffect(() => {
    if (!authInfo || !authInfo?.token) {
      setUserInfo({ name: '', email: '' });
    } else {
      setPathName(currentLocation);
      if (authInfo.issuer === 'wppopen') {
        const { firstname, lastname, email } = osContext.userDetails;
        getRoleApi().then(() => {
          setUserInfo({
            name: [firstname, lastname].filter(Boolean).join(' ') || '-',
            email,
          });
        });
      }
      if (authInfo.issuer === 'okta') {
        authHook?.oktaAuth
          .getUser()
          .then((info) => {
            getRoleApi().then(() => {
              setUserInfo({
                name:
                  [info.given_name, info.family_name]
                    .filter(Boolean)
                    .join(' ') || '-',
                email: info.email,
              });
            });
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error Logging in: ', error);
            authHook?.oktaAuth.tokenManager.clear();
            navigate(ROUTE_PATHS.LOGIN);
          });
      }

      getFeatureToggles().then(() => {
        if (hasFeature(EFeatureToggle.SETTINGS)) {
          getSettings().then(() => {
            setUpdatedSettings(settings);
          });
        }
      });
    }
  }, [
    osApi,
    authInfo,
    osContext,
    settings,
    setUserInfo,
    getRoleApi,
    getFeatureToggles,
    getSettings,
    setUpdatedSettings,
    hasFeature,
  ]);

  useEffect(() => {
    if (locationState || pathName !== '') {
      const path = locationState
        ? locationState?.originalURL?.pathname
        : pathName;
      const handler = navigationHandlers[path.split('/')[1]];

      if (handler) {
        setPathName('');
        handler(path);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (accessNames.length !== 0) {
          setPathName('');
          if (accessNames.includes(AccessKeys.MAIN)) {
            changeCurrentPage(PagesEnum.MAIN_PAGE);
            navigate(ROUTE_PATHS.PagesEnum.MAIN_PAGE, { state: null });
          } else if (accessNames.includes(AccessKeys.CHIP_SEGMENT)) {
            changeCurrentPage(PagesEnum.DASHBOARD);
            navigate(ROUTE_PATHS.PagesEnum.DASHBOARD, { state: null });
          } else {
            changeCurrentPage(PagesEnum.ACCESS_REQUEST_AND_SUPPORT);
            navigate(ROUTE_PATHS.PagesEnum.NO_ACCESS, { state: null });
          }
        }
      }
    }
  }, [locationState, accessNames]);

  return (
    <div className="Home">
      <WppToastContainer maxToastsToDisplay={5} ref={childRef} />
      {osContext && osContext.userDetails.email === '' && (
        <Header
          role={role}
          userInfo={userInfo}
          enableSettings={hasFeature(EFeatureToggle.SETTINGS)}
          showSettings={() => setShowSettings(!showSettings)}
        />
      )}
      <SettingsModal
        showSettings={showSettings}
        updatedSettings={updatedSettings}
        setShowSettings={setShowSettings}
        setUpdatedSettings={setUpdatedSettings}
        setSettings={doSetSettings}
        showToast={showToast}
      />
      <FestiveModal
        showModal={showModal}
        dismissHoliday={dismissHoliday}
        setShowModal={setShowModal}
      />
      <TechnicalIssuesModal
        showModal={
          showIssues === null && hasFeature(EFeatureToggle.TECHNICAL_ISSUES)
        }
        setShowModal={setShowIssues}
      />
      {isLoading && <LoadingElement message={loadingMessage} />}
      {!isLoading && (
        <>
          <MainNavigationTabs access={role.access} />
          <div className={`content-wrapper ${currentPage}`}>
            <Outlet />
          </div>
        </>
      )}
      <Footer version={version || versionError} />
    </div>
  );
}
