import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useOs as useOsNative } from '@wppopen/react';
import { useOktaAuth } from '@okta/okta-react';

import { useAuthenticate } from '../stores/authenticate';

let accessToken = '';
const osContext = {
  osContext: {
    baseUrl: '/',
    tenant: {
      name: 'MS',
      subdomain: 'MS',
      azId: '',
    },
    workspace: {
      azId: '',
      mapping: {
        key: {
          type: 'CLIENT',
          azId: 'MS',
          name: 'Mindshare',
        },
      },
    },
    userDetails: {
      email: '',
      firstname: '',
      lastname: '',
    },
  },
  osApi: {
    isAuthenticated: false,
    setIsAuthenticated: (token: string) => {
      osContext.osApi.isAuthenticated = true;
      accessToken = token;
    },
    getAccessToken: () => {
      return accessToken;
    },
  },
};
const OsContext = createContext<any>(osContext);
export const useOsWrapper = () => useContext(OsContext);
export const useOs = () => {
  const osNative = useOsNative();
  const osWrapper = useOsWrapper();
  const authHook = useOktaAuth();

  const {
    authInfo,
    actions: { setAuthentication },
  } = useAuthenticate();
  const [osWrapperContext, setOsWrapperContext] = useState(null);

  useEffect(() => {
    if (authHook?.oktaAuth !== null) {
      const token = authHook?.oktaAuth.getAccessToken();
      if (token) {
        osWrapper.osApi.setIsAuthenticated(token);
        setOsWrapperContext({ ...osWrapper });
        setAuthentication({ issuer: 'okta', token });
      }
    }
  }, [authHook?.oktaAuth, osWrapper.osApi]);

  useEffect(() => {
    if (osNative) {
      const token = osNative?.osApi.getAccessToken();
      if (token) {
        osWrapper.osApi.setIsAuthenticated(token);
        setOsWrapperContext({ ...osNative, isAuthenticated: true });
        setAuthentication({ issuer: 'wppopen', token });
      }
    }
  }, [osNative]);

  useEffect(() => {
    if (osNative?.osContext) {
      setOsWrapperContext({ ...osNative });
    } else {
      setOsWrapperContext({ ...osWrapper });
    }
  }, []);

  return osNative?.osContext ? osNative : osWrapper;
};
