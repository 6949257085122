import React, { useState } from 'react';
import './Footer.scss';
import {
  WppActionButton,
  WppButton,
  WppModal,
} from '@platform-ui-kit/components-library-react';

interface IFooterProps {
  version: string;
}

function Footer(props: IFooterProps) {
  const { version } = props;
  const dockerTag = process.env.REACT_APP_DOCKER_TAG ?? '';
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <footer className="footer-wrapper">
      <span className="copy">
        <span>
          &copy; Choreograph {new Date().getFullYear()}, All rights reserved
        </span>
        {`- ${dockerTag} `}
        <WppActionButton className="copy" onClick={() => setShowModal(true)}>
          About
        </WppActionButton>
      </span>
      <WppModal className="about" open={showModal}>
        <div slot="body">
          <h4>Product Management 🎬</h4>
          <h5>Koray Dinçalp</h5>
          <h5>Xin Zhang</h5>
          <h4>Quality Assurance 🪲</h4>
          <h5>Md. Samsul Kabir</h5>
          <h5>Hitesh Rajput</h5>
          <h5>Vaibhav Shinde</h5>
          <h4>Backend / API Development ⚙️</h4>
          <h5>Endi Modrić</h5>
          <h5>Ramanjaneya Naidu Nalla</h5>
          <h5>David Schneider</h5>
          <h5>Jay Talati</h5>
          <h4>UI Development 🎨</h4>
          <h5>Endi Modrić</h5>
          <h5>Tobias Scheer</h5>
          <h4>Supporting Teams</h4>
          <h5>Nautilus 🐚</h5>
          <h5>Sandbox 🏖️</h5>
          <h5>TechOps 💻</h5>
          <h5>Support 🏥</h5>
          <h4>Thanks to all other contributors missing ❤️</h4>
        </div>
        <div slot="actions">
          <WppButton
            variant="primary"
            size="s"
            onClick={() => setShowModal(false)}
          >
            OK
          </WppButton>
        </div>
      </WppModal>
    </footer>
  );
}

export default Footer;
