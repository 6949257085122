import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { ICountryStore } from './types';
import { dswRequest } from './index';
import { API_BASE_URL } from '../config/config';

export const initialState: ICountryStore = {
  countries: [],
  isLoading: false,
  actions: {
    getCountryApi: () => Promise.resolve(),
  },
};

export const useCountryStore = create<ICountryStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getCountryApi: async () => {
          const { countries, isLoading } = get();
          if (countries.length === 0 && !isLoading) {
            set((state) => ({
              ...state,
              isLoading: true,
            }));
            try {
              const url = `${API_BASE_URL}/countries/all`;
              const apiResponse = await dswRequest.get(url);
              set((state) => ({
                ...state,
                countries: apiResponse.data,
              }));
            } finally {
              set((state) => ({
                ...state,
                isLoading: false,
              }));
            }
          }
        },
      },
    }))
  )
);
