import React, { useEffect, useState, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi } from 'ag-grid-community';
import {
  WppGrid,
  WppTypography,
  WppToastContainer,
  WppActionButton,
  WppIconCopy,
} from '@platform-ui-kit/components-library-react';
import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library';
import { debounce } from 'lodash';
import { copyToClipboard } from '../../../../utils';

import { useGlobalStore } from '../../../../stores/global';
import Autocomplete from './Autocomplete/Autocomplete';
import HeaderCell from './HeaderCell/HeaderCell';
import ExportToggle from './ExportToggle/ExportToggle';
import SalesforceAccount from './SalesforceAccount/SalesforceAccount';
import LoadingElement from '../../../../components/LoadingElement';
import SegmentsModal from '../../../../components/SegmentsModal';
import useToast from '../../../../hooks/useToast';
import { useChipSegmentsManager } from '../../../../stores/segments';

import {
  ISegments,
  ISegmentsData,
  IExportSegmentParams,
} from '../../../../stores/types';
import {
  SegmentColumnDisplayNames,
  SegmentColumnNames,
} from '../../../../constants/constants';

import './index.scss';

export interface IOverviewParams {
  segmentData: ISegmentsData;
}

const ROW_HEIGHT = 47;
const ROW_MARGIN = 10;

const columnsToSearch = [
  'SEGMENT_ID',
  'SEGMENT_NAME',
  'DESCRIPTION',
  'CREATED_BY',
  'DATE_CREATED',
];

const noDataOverlay = () => (
  <div>
    <WppTypography type="l-strong">
      No segment data available at the moment.
    </WppTypography>
  </div>
);
const loadingOverlay = () => <LoadingElement />;

export default function SegmentList(params: IOverviewParams) {
  const { segmentData } = params;
  const { data, isLoading, errorMsg } = segmentData;
  const userInfo = useGlobalStore((state) => state.userInfo);

  const [gridApi, setGridApi] = useState<GridApi>(null);
  const [tableData, setTableData] = useState<ISegments[] | undefined[]>([]);
  const [autoCompleteList, setAutoCompleteList] = useState<
    AutocompleteDefaultOption[]
  >([]);
  const [selectedSearchValues, setSelectedSearchValues] = useState<
    AutocompleteDefaultOption[]
  >([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedExportSegment, setSelectedExportSegment] =
    useState<IExportSegmentParams>();

  const gridRef = useRef(null);
  const wppToastRef = useRef(null);
  const { showToast } = useToast();

  const {
    actions: { exportSegmentApi },
  } = useChipSegmentsManager();

  const onExportSegmentApiResponse = (status: string) => {
    if (status === 'SUCCESS') {
      showToast({
        type: 'success',
        message: 'Successfully exported segment to NGPi',
      });
    }
    if (status === 'ERROR') {
      showToast({
        type: 'error',
        message: 'Failed to export segment to NGPi',
      });
    }
    setSelectedExportSegment(null);
  };

  const onExportInUI = (id: string, segmentId: string) => {
    setSelectedExportSegment({ id, segmentId });
    setOpenModal(true);
  };

  const dismissExportInUI = () => {
    setSelectedExportSegment(null);
    setOpenModal(false);
  };

  const onConfirmExportInUI = () => {
    setOpenModal(false);
    showToast({
      type: 'information',
      message: 'Exporting segment to NGPi',
    });
    exportSegmentApi(
      selectedExportSegment.id,
      userInfo.name,
      onExportSegmentApiResponse
    );
  };

  const resizeGrid = (gridEvent, resizeData = tableData) => {
    if (gridApi) {
      if (isLoading) {
        gridApi.showLoadingOverlay();
      }
      const rowCount = tableData.length;
      const totalHeight = (ROW_HEIGHT + ROW_MARGIN) * rowCount;
      const gridContainer = document.querySelector(
        '.ag-center-cols-viewport'
      ) as HTMLElement;
      gridContainer.style.width = `99.8%`;
      const gridWrapper = document.querySelector(
        '.ag-center-cols-container'
      ) as HTMLElement;
      setTimeout(() => {
        gridWrapper.style.width = `${gridContainer.clientWidth - 10}px`;
      }, 200);

      const viewPort = document.querySelector(
        '.ag-body-viewport'
      ) as HTMLElement;
      viewPort.style.minHeight = '420px';
      viewPort.style.height = `${totalHeight}px`;
      gridEvent.api.sizeColumnsToFit();

      setTimeout(() => {
        if (!isLoading) {
          gridApi.hideOverlay();
          if (resizeData.length === 0 || errorMsg) {
            gridApi.showNoRowsOverlay();
          }
        }
      }, 500);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const debouneSetTableData = debounce((updatedData) => {
    setTableData(updatedData);
  }, 0);

  const simpleTitleRenderer = (cellRenderParams) => {
    return <span title={cellRenderParams.value}>{cellRenderParams.value}</span>;
  };

  const segmentIdCellRenderer = (cellRenderParams) => {
    return (
      <span className="grid-button" style={{ display: 'inline-flex' }}>
        <WppActionButton
          title="Copy Segment Id"
          onClick={() => copyToClipboard(cellRenderParams.value)}
        >
          <WppIconCopy size="s" />
        </WppActionButton>
        &nbsp;
        {cellRenderParams.value}
      </span>
    );
  };

  const exportToNGPiCellRenderer = (cellRenderParams) => {
    return (
      <ExportToggle
        cellRendererParams={cellRenderParams}
        onExportInUI={onExportInUI}
      />
    );
  };

  const segmentNameCellRenderer = (cellRenderParams) => {
    return (
      <span className="grid-button" style={{ display: 'inline-flex' }}>
        <WppActionButton
          title="Copy Segment Name"
          onClick={() => copyToClipboard(cellRenderParams.value)}
        >
          <WppIconCopy size="s" />
        </WppActionButton>
        &nbsp;
        {cellRenderParams.value}
      </span>
    );
  };

  const dateCellRenderer = (cellRenderParams) =>
    cellRenderParams.value
      ? new Date(cellRenderParams.value).toISOString().split('T').shift()
      : 'N/A';

  const salesforceAccountCellRenderer = (cellRenderParams) => {
    return <SalesforceAccount cellRendererParams={cellRenderParams} />;
  };

  const columnDefs: ColDef[] = [
    {
      field: SegmentColumnNames.SEGMENT_ID,
      headerName: SegmentColumnDisplayNames.SEGMENT_ID,
      minWidth: 260,
      width: 260,
      cellRenderer: segmentIdCellRenderer,
    },
    {
      field: SegmentColumnNames.SEGMENT_NAME,
      headerName: SegmentColumnDisplayNames.SEGMENT_NAME,
      minWidth: 150,
      width: 220,
      cellRenderer: segmentNameCellRenderer,
    },
    {
      field: SegmentColumnNames.DESCRIPTION,
      headerName: SegmentColumnDisplayNames.DESCRIPTION,
      minWidth: 125,
      flex: 1,
      cellRenderer: simpleTitleRenderer,
    },
    {
      field: SegmentColumnNames.CREATED_BY,
      headerName: SegmentColumnDisplayNames.CREATED_BY,
      minWidth: 160,
      width: 180,
      cellRenderer: simpleTitleRenderer,
    },
    {
      field: SegmentColumnNames.DATE_CREATED,
      headerName: SegmentColumnDisplayNames.DATE_CREATED,
      minWidth: 115,
      width: 115,
      cellRenderer: dateCellRenderer,
    },
    {
      field: SegmentColumnNames.EXPORT_TO_NGPi,
      headerName: SegmentColumnDisplayNames.EXPORT_TO_NGPi,
      minWidth: 80,
      width: 80,
      cellStyle: { textAlign: 'center' },
      cellRenderer: exportToNGPiCellRenderer,
    },
    {
      field: SegmentColumnNames.SALESFORCE_ACCOUNT,
      headerName: SegmentColumnDisplayNames.SALESFORCE_ACCOUNT,
      minWidth: 130,
      width: 130,
      cellStyle: { textAlign: 'center' },
      valueFormatter: (cellRendererParams) => {
        const accounts = cellRendererParams.value;
        if (accounts && Array.isArray(accounts)) {
          return accounts.map((account) => account.name).join(', ');
        }
        return '';
      },
      cellRenderer: salesforceAccountCellRenderer,
    },
  ];

  useEffect(() => {
    if (gridApi) {
      if (isLoading) {
        gridApi.showLoadingOverlay();
      }
      if (data && data.length > 0 && !errorMsg) {
        gridApi.setFilterModel(null);

        // Generate searchList for autocomplete
        const searchList = [];
        columnsToSearch.forEach((column: string) => {
          searchList.push(
            ...Array.from(
              new Set(
                data
                  .map((rowData: ISegments) =>
                    column === 'DATE_CREATED'
                      ? new Date(rowData[SegmentColumnNames[column]])
                          .toISOString()
                          .split('T')
                          .shift()
                      : rowData[SegmentColumnNames[column]]
                  )
                  .filter(
                    (columnData: string | number) =>
                      columnData && columnData !== null
                  )
              )
            ).map((columnValue: string | number, index) => {
              return {
                id: `${index}-${column}-${columnValue}`,
                label: columnValue,
                column,
              };
            })
          );
        });
        setAutoCompleteList(searchList);
      }

      if (errorMsg) {
        // eslint-disable-next-line no-console
        console.error(errorMsg);
        if (!isLoading) {
          gridApi.hideOverlay();
          gridApi.showNoRowsOverlay();
        }
      }
    }
  }, [gridApi, isLoading, data, errorMsg]);

  useEffect(() => {
    return () => {
      // This cleanup function will be called when the component unmounts
      if (gridApi && !gridApi.isDestroyed()) {
        gridApi.destroy();
      }
    };
  }, [gridApi]);

  useEffect(() => {
    if (!data) return;

    if (gridApi) {
      gridApi.showLoadingOverlay();
    }
    if (selectedSearchValues && selectedSearchValues.length > 0) {
      const filteredData = data.filter((segment: ISegments) =>
        selectedSearchValues.some((value: AutocompleteDefaultOption) =>
          value.column === 'DATE_CREATED'
            ? new Date(segment[SegmentColumnNames[value.column]])
                .toISOString()
                .split('T')
                .shift() === value.label
            : segment[SegmentColumnNames[value.column]] === value.label
        )
      );
      setTableData(filteredData);
      resizeGrid({ api: gridApi }, filteredData);
    } else {
      setTableData(data);
      resizeGrid({ api: gridApi }, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearchValues, data]);

  return (
    <>
      <WppGrid container direction="column" className="segmentContainer">
        <WppGrid item container all={24} className="header">
          <WppGrid item all={16}>
            <WppTypography type="xl-heading" className="heading">
              CHIP CUSTOM SEGMENT MANAGER
            </WppTypography>
          </WppGrid>
          <WppGrid item all={8}>
            {autoCompleteList.length > 0 && (
              <Autocomplete
                searchList={autoCompleteList}
                setSearchValues={setSelectedSearchValues}
              />
            )}
            {autoCompleteList.length === 0 && (
              <Autocomplete
                searchList={autoCompleteList}
                setSearchValues={setSelectedSearchValues}
              />
            )}
          </WppGrid>
        </WppGrid>

        <WppToastContainer
          className="wpp-toast-container"
          maxToastsToDisplay={3}
          ref={wppToastRef}
        />
        <WppGrid container item all={24} className="segments">
          <section className="segments-list ag-theme-wpp" id="agGrid">
            <AgGridReact
              onGridReady={(gridReadyEvent) => {
                setGridApi(gridReadyEvent.api);
                resizeGrid(gridReadyEvent);
                gridReadyEvent.api.sizeColumnsToFit();
              }}
              onFirstDataRendered={(gridResizeEvent) => {
                gridResizeEvent.api.sizeColumnsToFit();
                resizeGrid(gridResizeEvent);
              }}
              ref={gridRef}
              rowData={tableData}
              animateRows
              getRowStyle={(param) => {
                const { rowIndex } = param.node;
                return {
                  marginTop: rowIndex === 0 ? '0' : `${10 * rowIndex}px`,
                };
              }}
              getRowId={(rowParams) => {
                return `segment-row-${rowParams.data.segmentId}`; // Use a field from your row data
              }}
              columnDefs={columnDefs}
              defaultColDef={{
                headerComponent: HeaderCell,
                sortable: true,
                resizable: true,
                cellStyle: { textAlign: 'left' },
                menuTabs: ['filterMenuTab'],
                wrapHeaderText: true,
                autoHeaderHeight: true,
              }}
              columnHoverHighlight
              suppressPaginationPanel
              suppressRowTransform
              loadingOverlayComponent={loadingOverlay}
              noRowsOverlayComponent={noDataOverlay}
              reactiveCustomComponents
              suppressMovableColumns
            />
          </section>
        </WppGrid>
      </WppGrid>
      <SegmentsModal
        showModal={openModal}
        setShowModal={setOpenModal}
        exportToNGPi={onConfirmExportInUI}
        dismissExportInUI={dismissExportInUI}
      />
    </>
  );
}
