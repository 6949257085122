import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IAuthenticationStore } from './types';

export const initialState: IAuthenticationStore = {
  authInfo: null,
  actions: {
    setAuthentication: () => undefined,
  },
};

export const useAuthenticate = create<IAuthenticationStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        setAuthentication: (authInfo) => {
          set((state) => ({
            ...state,
            authInfo,
          }));
        },
      },
    }))
  )
);
