import { EApprovalType, IApproval, IApprovalDataset } from '../types';

export function getFilteredDatasets(datasets: IApprovalDataset[]) {
  const ds = [];

  datasets.forEach((dsRegion) => {
    const dataset_approval_map = {};
    const dataset_region_map = {};
    Object.entries(dsRegion.dataset_region_map)
      .filter(
        ([key, val]) =>
          !!key && !!val && val.filter(({ region }) => !!region).length > 0
      )
      .forEach(([key, val]) => {
        dataset_region_map[key] = val.filter(({ region }) => !!region);
      });
    Object.keys(dsRegion.dataset_approval_map)
      .filter(
        (key) =>
          !!key && dataset_region_map[key] && dataset_region_map[key].length > 0
      )
      .forEach((key) => {
        dataset_approval_map[key] = dsRegion.dataset_approval_map[key];
      });
    ds.push({
      ao_id: dsRegion.ao_id,
      instance_region: dsRegion.instance_region,
      use_case_text: dsRegion.use_case_text,
      comments: dsRegion.comments,
      dataset_approval_map,
      dataset_region_map,
    });
  });

  return ds;
}

export default function mapRequestApproval(
  approval: IApproval,
  approvalType: EApprovalType
) {
  const {
    requester_details,
    agency_contact_person_details,
    project_details,
    team_details,
    datasets,
    additional_info,
  } = approval;
  const namespace =
    approvalType === EApprovalType.ACCESS
      ? `${project_details.request_reason.toLowerCase()}-${project_details.namespace.toLowerCase()}`
      : project_details.namespace.toLowerCase();
  return {
    requester_details,
    agency_contact_person_details: {
      ...agency_contact_person_details,
      is_changed: agency_contact_person_details.is_changed || false,
    },
    project_details: {
      request_reason: project_details.request_reason,
      namespace,
      agency: project_details.agency,
      regions: Object.keys(project_details.region_instance_url_map),
      is_changed: project_details.is_changed,
      deleted_regions: project_details.deleted_regions,
    },
    team_details: team_details
      .map((t) => ({
        ...t,
        is_deleted: t.is_deleted || false,
      }))
      .sort(({ is_deleted }) => (is_deleted ? -1 : 0)),
    datasets: getFilteredDatasets(datasets),
    additional_info,
  };
}
