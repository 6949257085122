import {
  EChartsOption,
  LegendComponentOption,
  SeriesOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { Theme, ThemeCatDataviz } from '@platform-ui-kit/components-library';
import themeJson from '@platform-ui-kit/components-library/dist/platform-ui-kit/wpp-theme.json';
import {
  dataTitleMapper,
  lineDiagramNameMapper,
  optionToContent,
} from '../../../../utils';
import { IGrowthMetricsResponse } from '../../../../stores/types';

export const defaultOption: EChartsOption = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'line',
      crossStyle: {
        color: '#999',
      },
    },
  },
  toolbox: {
    feature: {
      dataView: {
        show: true,
        readOnly: false,
        optionToContent,
      },
      magicType: { show: true, type: ['line', 'bar'] },
      restore: { show: true },
      saveAsImage: { show: true },
    },
    top: 'bottom',
  },
};
export const themeType = 'dark';
export const colors = Object.values(
  ((themeJson as Theme).content.light?.color.dataviz.cat as ThemeCatDataviz)[
    themeType
  ]
);

export const prepareGraphOptions = (
  growthMetricsData: IGrowthMetricsResponse,
  key: string
): EChartsOption => {
  const legendData = [dataTitleMapper[key]];

  if (key !== 'notebooks') {
    legendData.push(lineDiagramNameMapper[key]);
  }

  const legend: LegendComponentOption = {
    data: legendData,
    top: 'bottom',
    left: 'left',
  };
  const xAxis: XAXisComponentOption[] = [
    {
      type: 'category',
      data: Object.keys(growthMetricsData[key].data),
      axisPointer: {
        type: 'shadow',
      },
      axisLabel: {
        hideOverlap: true,
      },
    },
  ];
  const lineSeriesData: (string | number)[] = Object.values(
    growthMetricsData[key].data
  );

  const barSeriesData: (string | number)[] = lineSeriesData.reduce(
    (accumulator, curValue, currentIndex) => {
      if (currentIndex === 0) {
        return accumulator.concat(
          Number(growthMetricsData[key].cumulative) + Number(curValue)
        );
      }
      return accumulator.concat(
        Number(curValue) + Number(accumulator[currentIndex - 1])
      );
    },
    []
  );

  const yAxis: YAXisComponentOption[] = [
    {
      type: 'value',
      name: dataTitleMapper[key],
      axisLabel: {
        formatter: '{value}',
        hideOverlap: true,
      },
      nameTextStyle: {
        align: 'left',
        fontSize: 12,
        lineHeight: 26,
      },
    },
  ];

  if (key !== 'notebooks') {
    yAxis.push({
      type: 'value',
      name: lineDiagramNameMapper[key],
      axisLabel: {
        formatter: '{value}',
        hideOverlap: true,
      },
      nameTextStyle: {
        align: 'right',
        fontSize: 12,
        lineHeight: 26,
      },
    });
  }

  const series: SeriesOption[] = [
    {
      name: dataTitleMapper[key],
      type: 'bar',
      tooltip: {
        valueFormatter(value) {
          return `${value as number}`;
        },
      },
      data: key !== 'notebooks' ? barSeriesData : lineSeriesData,
      label: {
        show: true,
        position: 'top',
      },
      labelLayout: {
        hideOverlap: true,
      },
    },
  ];

  if (key !== 'notebooks') {
    series.push({
      name: lineDiagramNameMapper[key],
      type: 'line',
      yAxisIndex: 1,
      tooltip: {
        valueFormatter(value) {
          return `${value as number}`;
        },
      },
      data: lineSeriesData,
      label: {
        show: true,
        position: 'top',
      },
      labelLayout: {
        hideOverlap: true,
      },
    });
  }

  return {
    ...defaultOption,
    ...{ legend, xAxis, yAxis, series },
  };
};
