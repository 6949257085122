import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IAgencyStore } from './types';
import { API_BASE_URL } from '../config/config';
import { dswRequest } from './index';

export const initialState: IAgencyStore = {
  agenciesByRequestType: [],
  simpleAgencies: [],
  simpleAgenciesByUserMail: [],
  agencies: [],
  notebooks: {},
  loadingAgencies: false,
  agenciesLoaded: false,
  loadingNotebooks: {},
  notebookErrors: {},
  agencyErrors: '',
  loadingAgencyContact: false,
  agencyContact: { name: '', email: '', is_changed: false },
  agencyContactErrors: '',
  actions: {
    getAgenciesByRequestTypeApi: () => Promise.resolve(),
    getSimpleAgenciesApi: () => Promise.resolve(),
    getSimpleAgenciesByUserMailApi: () => Promise.resolve(),
    getAgenciesApi: () => Promise.resolve(),
    getAgencyContactApi: () => Promise.resolve(),
    getNotebooks: () => Promise.resolve(),
    deleteNotebook: () => Promise.resolve(),
    patchNotebook: () => Promise.resolve(),
  },
};

export const useAgencyStore = create<IAgencyStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getAgenciesByRequestTypeApi: async (requestType: string) => {
          try {
            const url = `${API_BASE_URL}/agency-based-on-request-type?requestType=${requestType}`;
            const { data: agenciesByRequestType, error } = await dswRequest.get(
              url
            );
            if (!error) {
              set(() => ({
                agenciesByRequestType,
                agencyErrors: '',
              }));
            } else {
              set(() => ({
                agencyErrors: `${error.status}: ${error.message}`,
              }));
            }
          } catch (error) {
            set(() => ({
              agencyErrors: (error as Error).message,
            }));
          }
        },
        getSimpleAgenciesApi: async () => {
          const { simpleAgencies, loadingAgencies } = get();
          if (simpleAgencies.length === 0 && !loadingAgencies) {
            set(() => ({ loadingAgencies: true }));
            try {
              const url = `${API_BASE_URL}/agency/all`;
              const { data, error } = await dswRequest.get(url);
              if (!error) {
                set(() => ({
                  simpleAgencies: data,
                  agencyErrors: '',
                }));
              } else {
                set(() => ({
                  agencyErrors: `${error.status}: ${error.message}`,
                }));
              }
            } catch (error) {
              set(() => ({
                agencyErrors: (error as Error).message,
              }));
            } finally {
              set(() => ({ loadingAgencies: false }));
            }
          }
        },
        getSimpleAgenciesByUserMailApi: async (force: boolean) => {
          const { simpleAgenciesByUserMail, loadingAgencies } = get();
          if (
            (simpleAgenciesByUserMail.length === 0 && !loadingAgencies) ||
            force
          ) {
            set(() => ({ loadingAgencies: true }));
            try {
              const url = `${API_BASE_URL}/agency`;
              const { data, error } = await dswRequest.get(url);
              if (!error) {
                set(() => ({
                  simpleAgenciesByUserMail: data,
                  agencyErrors: '',
                }));
              } else {
                set(() => ({
                  agencyErrors: `${error.status}: ${error.message}`,
                }));
              }
            } catch (error) {
              set(() => ({
                agencyErrors: (error as Error).message,
              }));
            } finally {
              set(() => ({ loadingAgencies: false }));
            }
          }
        },
        getAgenciesApi: async (force = false) => {
          const { agenciesLoaded, loadingAgencies } = get();
          if ((!agenciesLoaded && !loadingAgencies) || force) {
            set(() => ({ loadingAgencies: true }));
            try {
              const url = `${API_BASE_URL}/main-page-details`;
              const { data, error } = await dswRequest.get(url);
              if (!error) {
                const { user_agency_details } = data;
                set(() => ({
                  agencies:
                    user_agency_details.sort((a, b) => {
                      if (
                        a.agency_name.toLowerCase() >
                        b.agency_name.toLowerCase()
                      ) {
                        return 1;
                      }
                      if (
                        a.agency_name.toLowerCase() <
                        b.agency_name.toLowerCase()
                      ) {
                        return -1;
                      }
                      if (a.region_name > b.region_name) {
                        return 1;
                      }
                      return -1;
                    }) || [],
                  agencyErrors: '',
                }));
              } else {
                set(() => ({
                  agencyErrors: `${error.status}: ${error.message}`,
                }));
              }
            } catch (error) {
              set(() => ({
                agencyErrors: (error as Error).message,
              }));
            } finally {
              set(() => ({ loadingAgencies: false, agenciesLoaded: true }));
            }
          }
        },
        getAgencyContactApi: async (agencyName: string, callback) => {
          set(() => ({ loadingAgencyContact: true }));
          try {
            const url = `${API_BASE_URL}/agency_contact/${agencyName}`;
            const { data, error } = await dswRequest.get(url);
            if (!error) {
              const { contact_user_email: email, contact_user_name: name } =
                data;
              set(() => ({
                agencyContact: { email, name },
                agencyContactErrors: '',
              }));
            } else {
              set(() => ({
                agencyContact: { email: null, name: null },
                agencyContactErrors: `${error.status}: ${error.message}`,
              }));
            }
          } catch (error) {
            set(() => ({
              agencyContact: { email: null, name: null },
              agencyContactErrors: (error as Error).message,
            }));
          } finally {
            set(() => ({ loadingAgencyContact: false }));
            callback(get().agencyContact);
          }
        },
        getNotebooks: async (namespaceName: string, instanceUrl: string) => {
          const key = `${namespaceName}-${instanceUrl}`;
          if (!instanceUrl) {
            set((state) => ({
              notebookErrors: {
                ...state.notebookErrors,
                [`${namespaceName}-${instanceUrl}`]: 'No instance defined',
              },
            }));
          } else if (!get().notebooks[key]) {
            set((state) => ({
              loadingNotebooks: {
                ...state.loadingNotebooks,
                [key]: true,
              },
            }));
            try {
              const fetchNotebook = async (url) => {
                const { data } = await dswRequest.get(url, {
                  options: { credentials: 'include' },
                });
                if (data) {
                  /* eslint-disable @typescript-eslint/no-unused-vars */
                  const { [key]: removedProperty, ...notebookErrors } =
                    get().notebookErrors;
                  set((state) => ({
                    notebooks: {
                      ...state.notebooks,
                      [key]: data,
                    },
                    notebookErrors,
                  }));
                } else {
                  await fetch(url, {
                    credentials: 'include',
                    mode: 'no-cors',
                  }).then(() => {
                    set((state) => ({
                      notebookErrors: {
                        ...state.notebookErrors,
                        [key]: 'fail',
                      },
                    }));
                  });
                }
              };
              let url = `http://localhost:4000/notebook`;
              if (process.env.NODE_ENV === 'production') {
                url = `${instanceUrl}jupyter/api/namespaces/${namespaceName}/notebooks`;
                // Prime Workbench
                await fetchNotebook(url);
              } else {
                await fetchNotebook(url);
              }
            } catch (error) {
              set((state) => ({
                notebookErrors: {
                  ...state.notebookErrors,
                  [key]: error.response?.data?.log || (error as Error).message,
                },
              }));
            } finally {
              set((state) => ({
                loadingNotebooks: {
                  ...state.loadingNotebooks,
                  [key]: false,
                },
              }));
            }
          }
        },
        deleteNotebook: async (
          instanceUrl,
          namespaceName,
          notebookName,
          callback
        ) => {
          try {
            const clearInstanceUrl = instanceUrl.replace('https://', '');
            const url =
              process.env.NODE_ENV !== 'production'
                ? `http://localhost:4000/notebook/delete`
                : `https://${clearInstanceUrl}jupyter/api/namespaces/${namespaceName}/notebooks/${notebookName}`;
            const { data, error } = await dswRequest.delete(url, {
              options: { credentials: 'include' },
            });
            callback({ data, error });
          } catch (e) {
            callback(e);
          }
        },
        patchNotebook: async (
          instanceUrl,
          namespaceName,
          notebookName,
          stopped,
          callback
        ) => {
          try {
            const clearInstanceUrl = instanceUrl.replace('https://', '');
            const url =
              process.env.NODE_ENV !== 'production'
                ? `http://localhost:4000/notebook/patch`
                : `https://${clearInstanceUrl}jupyter/api/namespaces/${namespaceName}/notebooks/${notebookName}`;
            const { data, error } = await dswRequest.patch(
              url,
              { stopped },
              {
                options: { credentials: 'include' },
              }
            );
            callback({ data, error });
          } catch (e) {
            callback(e);
          }
        },
      },
    }))
  )
);
