import React, { useEffect } from 'react';
import {
  WppInput,
  WppLabel,
  WppListItem,
  WppSelect,
  WppSpinner,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import {
  EApprovalType,
  IAgenciesByRequestType,
  IApprovalProjectDetails,
  IRegion,
} from '../../../../stores/types';
import { useAgencyStore } from '../../../../stores/agency';
import { useRegionStore } from '../../../../stores/region';
import { useReasonStore } from '../../../../stores/reason';
import { useDatasetStore } from '../../../../stores/dataset';
import { filterNamespace } from '../../../../utils';

export interface IProjectSectionParams {
  isAdmin: boolean;
  project: IApprovalProjectDetails;
  setProject: (
    project: IApprovalProjectDetails,
    agencyChange?: boolean
  ) => void;
  approvalType: EApprovalType;
}

export default function ProjectSection(params: IProjectSectionParams) {
  const { isAdmin, project, setProject, approvalType } = params;
  const {
    agenciesByRequestType,
    actions: { getAgenciesByRequestTypeApi },
  } = useAgencyStore();
  const {
    regions,
    actions: { getRegionApi },
  } = useRegionStore();
  const {
    reasons,
    actions: { getReasonApi },
  } = useReasonStore();
  const {
    actions: { getRequestDatasetApi },
  } = useDatasetStore();

  useEffect(() => {
    getRegionApi().then();
    getReasonApi().then();
    getAgenciesByRequestTypeApi(project.request_reason).then();
  }, [getReasonApi, getRegionApi, getAgenciesByRequestTypeApi, project]);

  let loading = true;

  if (
    agenciesByRequestType.length > 0 &&
    regions.length > 0 &&
    reasons.length > 0
  ) {
    loading = false;
  }

  return (
    <section className="project">
      <span className="row-1">
        <span className="col-1">
          <WppTypography className="label main" type="m-strong" tag="h2">
            PROJECT DETAILS
          </WppTypography>
        </span>
        <span className="col-1">
          <WppLabel>Reason for Request</WppLabel>
        </span>
        <span className="col-1">
          <WppLabel>Project belongs to</WppLabel>
        </span>
        <span className="col-1">
          <WppLabel>Region(s)</WppLabel>
        </span>
      </span>
      {loading && (
        <span className="row-1">
          <span className="col-1">&nbsp;</span>
          <span className="col-1">
            <WppSpinner />
          </span>
          <span className="col-1">
            <WppSpinner />
          </span>
          <span className="col-1">
            <WppSpinner />
          </span>
        </span>
      )}
      {!loading && (
        <span className="row-1">
          <span className="col-1">&nbsp;</span>
          <span className="col-1">
            {isAdmin && approvalType === EApprovalType.ACCESS ? (
              <WppSelect
                value={project.request_reason}
                size="s"
                onWppChange={({ detail: { value } }) => {
                  getAgenciesByRequestTypeApi(value).then();
                  Object.keys(project.region_instance_url_map).forEach(
                    (region) =>
                      getRequestDatasetApi(project.agency, region, value).then()
                  );
                  setProject({ ...project, request_reason: value });
                }}
                withSearch
              >
                {/* FILTER NGPi */}
                {reasons.map(({ name }) => (
                  <WppListItem key={`project-reason-${name}`} value={name}>
                    <p slot="label">{name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            ) : (
              <WppInput disabled value={project.request_reason} size="s" />
            )}
          </span>
          <span className="col-1">
            {isAdmin && approvalType === EApprovalType.ACCESS ? (
              <WppSelect
                value={project.agency}
                size="s"
                onWppChange={({ detail: { value } }) => {
                  Object.keys(project.region_instance_url_map).forEach(
                    (region) =>
                      getRequestDatasetApi(
                        value,
                        region,
                        project.request_reason
                      ).then()
                  );
                  setProject({ ...project, agency: value }, true);
                }}
                withSearch
              >
                {agenciesByRequestType.map(
                  ({ name }: IAgenciesByRequestType) => (
                    <WppListItem key={`entity-${name}`} value={name}>
                      <p slot="label">{name}</p>
                    </WppListItem>
                  )
                )}
              </WppSelect>
            ) : (
              <WppInput disabled value={project.agency} size="s" />
            )}
          </span>
          <span className="col-1">
            {isAdmin && approvalType === EApprovalType.ACCESS ? (
              <WppSelect
                value={Object.keys(project.region_instance_url_map)}
                size="s"
                type="multiple"
                onWppChange={({ detail: { value } }) => {
                  const region_instance_url_map = {};
                  value.forEach((region) => {
                    region_instance_url_map[region] = null;
                    getRequestDatasetApi(
                      project.agency,
                      region,
                      project.request_reason
                    ).then();
                  });
                  setProject({
                    ...project,
                    region_instance_url_map,
                  });
                }}
                withSearch
              >
                {regions.map(({ name }: IRegion) => (
                  <WppListItem
                    key={`entity-${name}`}
                    value={name}
                    disabled={
                      Object.keys(project.region_instance_url_map).indexOf(
                        name
                      ) === -1 ||
                      Object.keys(project.region_instance_url_map).length === 1
                    }
                  >
                    <p slot="label">{name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            ) : (
              <WppInput
                disabled
                value={Object.keys(project.region_instance_url_map).join(', ')}
                size="s"
              />
            )}
          </span>
        </span>
      )}
      <span className="row-1">
        <span className="col-1">&nbsp;</span>
        <span className="col-1">
          <WppLabel>Namespace</WppLabel>
        </span>
        <span className="col-1">&nbsp;</span>
        <span className="col-1">&nbsp;</span>
      </span>
      <span className="row-1">
        <span className="col-1">&nbsp;</span>
        <span className="col-1">
          <span className="col-1">
            <WppInput
              value={project.namespace}
              disabled={!isAdmin || approvalType !== EApprovalType.ACCESS}
              size="s"
              onWppChange={({ detail: { value } }) =>
                setProject({
                  ...project,
                  namespace: filterNamespace(value),
                })
              }
            />
          </span>
        </span>
        <span className="col-1">&nbsp;</span>
        <span className="col-1">&nbsp;</span>
      </span>
    </section>
  );
}
