import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  WppButton,
  WppTypography,
  WppGrid,
  WppCard,
} from '@platform-ui-kit/components-library-react';
import LoadingElement from '../../../../components/LoadingElement';
import { useMetricsAndReportsStore } from '../../../../stores/metrics_and_reports';
import { dataTitleMapper } from '../../../../utils';
import useToast from '../../../../hooks/useToast';
import './index.scss';

const loadingMessage = 'Loading Key Platform Metrics Information...';
export const errorMessage = `We're sorry, an error has occurred. Please reload this page and try again.`;
export default function KeyPlatformMetrics() {
  const {
    keyPlatformMetricsData,
    actions: { getKeyPlatformMetricsApi, exportKeyPlatformMetricsApi },
    isLoadingKeyPlatformMetricsData,
    isLoadingKeyPlatformMetricsExportFile,
    error,
  } = useMetricsAndReportsStore((state) => state);
  const { showToast } = useToast();

  useEffect(() => {
    getKeyPlatformMetricsApi().then();
  }, [getKeyPlatformMetricsApi]);

  const handleOnExportError = (): void => {
    showToast({
      header: `Error while exporting report, please try again.`,
      type: 'error',
      duration: 3000,
    });
  };

  const onExportMetricsClick = () => {
    exportKeyPlatformMetricsApi(handleOnExportError).then();
  };

  const isExportMetricsButtonDisabled =
    !keyPlatformMetricsData && isLoadingKeyPlatformMetricsData;

  const isExportMetricsButtonVisible =
    keyPlatformMetricsData && !isLoadingKeyPlatformMetricsData;

  return (
    <div className="key-platform-metrics">
      <WppGrid container rowSpacing={4} className="section-container">
        {error && (
          <WppGrid item all={24} fluid fullWidth>
            <WppTypography type="m-strong" className="span">
              {errorMessage}
            </WppTypography>
          </WppGrid>
        )}
        {isLoadingKeyPlatformMetricsData && !error && (
          <WppGrid item all={24} fluid fullWidth>
            <LoadingElement size="m" message={loadingMessage} />
          </WppGrid>
        )}
        {!isLoadingKeyPlatformMetricsData &&
          !error &&
          keyPlatformMetricsData &&
          Object.keys(keyPlatformMetricsData).map((key) => {
            return (
              <WppGrid
                item
                all={6}
                fluid
                fullWidth
                className="section"
                key={key}
              >
                <WppCard>
                  <div className="title">
                    <WppTypography type="m-strong" className="span">
                      {dataTitleMapper[key]}
                    </WppTypography>
                  </div>
                  <div className="value">
                    <WppTypography type="l-strong" className="span">
                      {keyPlatformMetricsData[key]}
                    </WppTypography>
                  </div>
                </WppCard>
              </WppGrid>
            );
          })}
      </WppGrid>
      {isExportMetricsButtonVisible && !error && (
        <div className="export-container">
          <WppButton
            variant="primary"
            size="s"
            onClick={onExportMetricsClick}
            disabled={isExportMetricsButtonDisabled}
            loading={isLoadingKeyPlatformMetricsExportFile}
          >
            Export Metrics
          </WppButton>
        </div>
      )}
    </div>
  );
}
