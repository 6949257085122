import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IChipSegmentsManger } from './types';
import { dswRequest } from './index';
import { SegmentColumnNames } from '../constants';
import { API_BASE_URL } from '../config/config';

export const initialState: IChipSegmentsManger = {
  segmentData: { isLoading: false, errorMsg: null, data: [] },
  actions: {
    getSegmentsApi: () => Promise.resolve(),
    exportSegmentApi: () => Promise.resolve(),
  },
};

export const useChipSegmentsManager = create<IChipSegmentsManger>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getSegmentsApi: async () => {
          const { segmentData } = get();
          if (segmentData.data.length === 0 && !segmentData.isLoading) {
            set((state) => ({
              ...state,
              segmentData: {
                ...state.segmentData,
                isLoading: true,
              },
            }));
            try {
              const url = `${API_BASE_URL}/custom-segment-manager`;
              // const url = `https://dsw-ed.choreograph.com/user-mgmt-service/dsw/custom-segment-manager`;
              const apiResponse = await dswRequest.get(url);
              if (apiResponse && apiResponse.data) {
                set((state) => ({
                  ...state,
                  segmentData: {
                    ...state.segmentData,
                    data: apiResponse.data.map((segment) => {
                      return {
                        ...segment,
                        [SegmentColumnNames.ID]:
                          segment[SegmentColumnNames.ID].toString(),
                      };
                    }),
                    errorMsg: null,
                  },
                }));
              }
              if (apiResponse && apiResponse.error) {
                set((state) => ({
                  ...state,
                  segmentData: {
                    ...state.segmentData,
                    isLoading: false,
                    errorMsg: apiResponse.error.message,
                  },
                }));
              }
            } catch (e) {
              set((state) => ({
                ...state,
                segmentData: {
                  ...state.segmentData,
                  isLoading: false,
                  errorMsg: e.response || e.toString(),
                },
              }));
            } finally {
              set((state) => ({
                ...state,
                segmentData: {
                  ...state.segmentData,
                  isLoading: false,
                },
              }));
            }
          }
        },
        exportSegmentApi: async (segmentId, updatedBy, callback) => {
          const {
            segmentData: { data },
          } = get();
          try {
            const url = `${API_BASE_URL}/custom-segment-manager/toggle-segment-include-in-ngp`;
            const apiResponse = await dswRequest.post(url, {
              segmentId,
              updatedBy,
            });
            if (apiResponse.data && apiResponse.data.message === 'SUCCESS') {
              set((state) => ({
                ...state,
                segmentData: {
                  ...state.segmentData,
                  data: data.map((segment) => {
                    return segment.segmentIdDb.toString() === segmentId
                      ? {
                          ...segment,
                          [SegmentColumnNames.EXPORT_TO_NGPi]: true,
                        }
                      : segment;
                  }),
                },
              }));
              callback('SUCCESS');
            }
            if (
              apiResponse.error &&
              Object.keys(apiResponse.error).includes('message')
            ) {
              callback('ERROR');
            }
          } catch (e) {
            callback('ERROR');
          }
        },
      },
    }))
  )
);
