import React from 'react';
import {
  WppInput,
  WppLabel,
  WppSpinner,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import { IAgencyContact } from '../../../../stores/types';

export interface IAgencySectionParams {
  agency: IAgencyContact;
  setAgency: (agency: IAgencyContact) => void;
}

export default function AgencySection(params: IAgencySectionParams) {
  const { agency } = params;

  return (
    <section className="agency">
      <span className="row-1">
        <span className="col-1">
          <WppTypography className="label main" type="m-strong" tag="h2">
            CLIENT DELIVERY LEAD
          </WppTypography>
        </span>
        <span className="col-1">
          <WppLabel>Name & Surname</WppLabel>
        </span>
        <span className="col-1">
          <WppLabel>E-mail</WppLabel>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">&nbsp;</span>
        <span className="col-1">
          {agency.name !== '' ? (
            <WppInput value={agency.name} size="s" disabled />
          ) : (
            <WppSpinner />
          )}
        </span>
        <span className="col-1">
          {agency.email !== '' ? (
            <WppInput value={agency.email} size="s" disabled />
          ) : (
            <WppSpinner />
          )}
        </span>
      </span>
    </section>
  );
}
