import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IRequestStore } from './types';
import { API_BASE_URL } from '../config/config';
import { dswRequest } from './index';
import {
  IAccessRequestFormData,
  IDatasetsFormData,
  ITeamFormData,
} from '../pages/AccessRequestsAndSupport/types';
import {
  mapAccessRequestForm,
  mapDatasetForm,
  mapTeamForm,
} from './mappers/AccessRequestsAndSupport';

export const initialState: IRequestStore = {
  accessRequest: {
    loading: false,
    error: null,
    data: {
      parent_request_id: null,
      status: { message: '' },
    },
  },
  dataset: {
    loading: false,
    error: null,
    data: {
      parent_request_id: null,
      status: { message: '' },
    },
  },
  actions: {
    postAccessApi: () => Promise.resolve(),
    postDatasetApi: () => Promise.resolve(),
    postTeamApi: () => Promise.resolve(),
  },
};

export const useRequestStore = create<IRequestStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        postAccessApi: async (formData: IAccessRequestFormData, callback) => {
          const url = `${API_BASE_URL}/access-request-form/save `;
          set((state) => ({
            ...state,
            accessRequest: {
              ...state.accessRequest,
              loading: true,
            },
          }));
          try {
            const { data, error } = await dswRequest.post(
              url,
              mapAccessRequestForm(formData)
            );
            set((state) => ({
              ...state,
              accessRequest: {
                ...state.accessRequest,
                error,
                data,
              },
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              accessRequest: {
                ...state.accessRequest,
                error: e.response || e.toString(),
              },
            }));
          } finally {
            set((state) => ({
              ...state,
              accessRequest: {
                ...state.accessRequest,
                loading: false,
              },
            }));
            callback(get().accessRequest);
          }
        },
        postDatasetApi: async (formData: IDatasetsFormData, callback) => {
          const url = `${API_BASE_URL}/project/dataset/add`;
          set((state) => ({
            ...state,
            dataset: {
              ...state.dataset,
              loading: true,
            },
          }));
          try {
            const { data, error } = await dswRequest.post(
              url,
              mapDatasetForm(formData)
            );
            set((state) => ({
              ...state,
              dataset: {
                ...state.dataset,
                error,
                data,
              },
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              dataset: {
                ...state.dataset,
                error: e.response || e.toString(),
              },
            }));
          } finally {
            set((state) => ({
              ...state,
              dataset: {
                ...state.dataset,
                loading: false,
              },
            }));
            callback(get().dataset);
          }
        },
        postTeamApi: async (formData: ITeamFormData, callback) => {
          const url = `${API_BASE_URL}/project/team/add`;
          set((state) => ({
            ...state,
            dataset: {
              ...state.dataset,
              loading: true,
            },
          }));
          try {
            const { data, error } = await dswRequest.post(
              url,
              mapTeamForm(formData)
            );
            set((state) => ({
              ...state,
              dataset: {
                ...state.dataset,
                error,
                data,
              },
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              dataset: {
                ...state.dataset,
                error: e.response || e.toString(),
              },
            }));
          } finally {
            set((state) => ({
              ...state,
              dataset: {
                ...state.dataset,
                loading: false,
              },
            }));
            callback(get().dataset);
          }
        },
      },
    }))
  )
);
