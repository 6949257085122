import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useLocation,
  Outlet,
  matchRoutes,
} from 'react-router-dom';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { useRoleStore } from '../../stores/role';
import {
  DashboardPagesEnum,
  MetricsAndReportingPagesEnum,
  PagesEnum,
  RoleEnum,
} from '../../constants';
import { toTitleCase } from '../../utils';
import Routes from '../../routes/routeConfig';

import * as ROUTE_PATHS from '../../routes/routePaths';
import RequestManagement from './RequestManagement';
import ProjectRegistry from './ProjectRegistry';
import MetricsAndReports from './MetricsAndReports';
import ChipRequestManager from './ChipSegmentManager';

import './index.scss';

export const getDashboardRouting = () => {
  const { pathname } = window.location;

  switch (pathname) {
    case '/dashboard/project':
      return DashboardPagesEnum.PROJECT_REGISTRY;
    case '/dashboard/request':
    case '/dashboard/request/access':
    case '/dashboard/request/dataset':
    case '/dashboard/request/team':
      return DashboardPagesEnum.REQUEST_MANAGEMENT;
    case '/dashboard/quality':
      return DashboardPagesEnum.QUALITY_OF_SERVICE;
    case '/dashboard/cost':
      return DashboardPagesEnum.COST_MONITORING;
    case '/dashboard':
    case '/dashboard/metrics-and-reports':
    case '/dashboard/metrics-and-reports/key-platform-metrics':
    case '/dashboard/metrics-and-reports/growth-metrics':
    case '/dashboard/metrics-and-reports/cost-reporting':
    case '/dashboard/metrics-and-reports/quick-reports':
      return DashboardPagesEnum.METRICS_AND_REPORTS;
    case '/dashboard/segment-manager':
      return DashboardPagesEnum.CHIP_SEGMENT_MANAGER;
    default:
      return DashboardPagesEnum.EMPTY;
  }
};

export default function Dashboard() {
  const navigate = useNavigate();
  const { pathname: currentLocation, state: locationState } = useLocation();
  const { name: userRole, access } = useRoleStore((state) => state.role);
  const [page, setPage] = useState<DashboardPagesEnum>(
    DashboardPagesEnum.METRICS_AND_REPORTS
  );
  const accessKeys = access.map(({ name }) => name);
  const dashboardsAllowed =
    accessKeys.includes('dashboards') ||
    accessKeys.includes('chipSegmentManager');

  useEffect(() => {
    if (!dashboardsAllowed) {
      navigate(ROUTE_PATHS.PagesEnum.ACCESS_REQUEST_AND_SUPPORT);
    }
    if (
      userRole !== RoleEnum.USER &&
      userRole !== RoleEnum.NEW_USER &&
      userRole !== RoleEnum.CHIP_ADMIN
    ) {
      if (currentLocation === `/${ROUTE_PATHS.PagesEnum.DASHBOARD}`) {
        setPage(ROUTE_PATHS.DashboardPagesEnum.METRICS_AND_REPORTS);
        navigate(
          `${ROUTE_PATHS.DashboardPagesEnum.METRICS_AND_REPORTS}/${ROUTE_PATHS.MetricsAndReportingPagesEnum.KEY_PLATFORM_METRICS}`
        );
      } else {
        const matchedRoutes = matchRoutes(Routes, currentLocation);

        if (matchedRoutes) {
          const lastMatchedRoute =
            DashboardPagesEnum[
              Object.keys(ROUTE_PATHS.DashboardPagesEnum).find((key) =>
                matchedRoutes[matchedRoutes.length - 1].pathname.includes(
                  ROUTE_PATHS.DashboardPagesEnum[key]
                )
              )
            ];

          setPage(lastMatchedRoute);
        }
      }
    }
    if (userRole === RoleEnum.CHIP_ADMIN) {
      setPage(DashboardPagesEnum.CHIP_SEGMENT_MANAGER);
      navigate(ROUTE_PATHS.DashboardPagesEnum.CHIP_SEGMENT_MANAGER);
    }
  }, [navigate, dashboardsAllowed, page, userRole]);

  const navigationItems = [
    {
      title: 'METRICS & REPORTS',
      value: DashboardPagesEnum.METRICS_AND_REPORTS,
    },
    { title: 'PROJECT REGISTRY', value: DashboardPagesEnum.PROJECT_REGISTRY },
    {
      title: 'REQUEST MANAGEMENT',
      value: DashboardPagesEnum.REQUEST_MANAGEMENT,
    },
    {
      title: 'CHIP CUSTOM SEGMENT MANAGER',
      value: DashboardPagesEnum.CHIP_SEGMENT_MANAGER,
    },
  ];
  const disabledItems = [];
  const doSetPage = (value) => {
    setPage(value);
    navigate(value as ROUTE_PATHS.DashboardPagesEnum);
  };

  if (
    userRole !== RoleEnum.ADMIN &&
    userRole !== RoleEnum.APPROVER &&
    userRole !== RoleEnum.CHIP_ADMIN
  ) {
    disabledItems.push(DashboardPagesEnum.REQUEST_MANAGEMENT);
    disabledItems.push(DashboardPagesEnum.PROJECT_REGISTRY);
    if (
      page === DashboardPagesEnum.REQUEST_MANAGEMENT ||
      page === DashboardPagesEnum.PROJECT_REGISTRY ||
      page === DashboardPagesEnum.CHIP_SEGMENT_MANAGER
    ) {
      setPage(DashboardPagesEnum.EMPTY);
    }
  }

  if (userRole !== RoleEnum.ADMIN && userRole !== RoleEnum.CHIP_ADMIN) {
    disabledItems.push(DashboardPagesEnum.CHIP_SEGMENT_MANAGER);
  }

  if (userRole === RoleEnum.CHIP_ADMIN) {
    disabledItems.push(DashboardPagesEnum.METRICS_AND_REPORTS);
    disabledItems.push(DashboardPagesEnum.REQUEST_MANAGEMENT);
    disabledItems.push(DashboardPagesEnum.PROJECT_REGISTRY);
  }

  const getPageContent = () => {
    switch (page) {
      case DashboardPagesEnum.PROJECT_REGISTRY:
        return <ProjectRegistry />;
      case DashboardPagesEnum.REQUEST_MANAGEMENT:
        return <RequestManagement />;
      case DashboardPagesEnum.QUALITY_OF_SERVICE:
        return null;
      case DashboardPagesEnum.COST_MONITORING:
        return null;
      case DashboardPagesEnum.METRICS_AND_REPORTS:
        return <MetricsAndReports />;
      case DashboardPagesEnum.CHIP_SEGMENT_MANAGER:
        return <ChipRequestManager />;
      default:
        return (
          <div className="empty-page" style={{ flex: 1, textAlign: 'center' }}>
            <h3>Looks like, you went the wrong way</h3>
            <p>
              👈 Please select one of the available pages - or use the top
              navigation if nothing is available 👆
            </p>
          </div>
        );
    }
  };

  return (
    <div className="dashboard">
      <div className="left-bar">
        <WppTypography className="label secondary" type="m-strong" tag="h3">
          Role: <span className="highlight">{toTitleCase(userRole)}</span>
        </WppTypography>
        <div className="navigation">
          {navigationItems.map(({ title, value }) => (
            <button
              key={value}
              type="button"
              className={`item ${page === value ? 'selected' : ''}`}
              disabled={disabledItems.indexOf(value) > -1}
              onClick={() => doSetPage(value)}
            >
              {title}
            </button>
          ))}
        </div>
      </div>
      <div className="page">
        <Outlet />
      </div>
    </div>
  );
}
