import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../root/App.scss';
import {
  OktaAuth,
  OktaAuthCoreInterface,
  OktaAuthOptions,
  toRelativeUrl,
} from '@okta/okta-auth-js';
import { Security, useOktaAuth } from '@okta/okta-react';
import { useOs } from '../../hooks/osWrapper';
import { useAuthenticate } from '../../stores/authenticate';
import config from '../../config/config';
import * as ROUTE from '../../routes/routePaths';
import { OktaSecurityProps } from './types';

const oktaAuth: OktaAuth = new OktaAuth(config.oidc as OktaAuthOptions);

function OktaSecurity({ children }: OktaSecurityProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    actions: { setAuthentication },
  } = useAuthenticate();
  const { osContext } = useOs();

  const restoreOriginalUri = async (params) => {
    const searchParams = new URLSearchParams(location.search);
    const originalUri = searchParams.get('state');
    navigate(originalUri, {
      state: { originalURL: { pathname: originalUri } },
      replace: true,
    });
  };

  // Listen for token expiration
  useEffect(() => {
    const handleTokenExpiry = async (key) => {
      if (key === 'accessToken') {
        const isAuthenticated = await oktaAuth.isAuthenticated();
        if (isAuthenticated) {
          try {
            // Attempt to refresh the token only if user is authenticated
            const res = await oktaAuth.token.getWithoutPrompt({
              scopes: ['openid', 'profile', 'email'],
            });
            oktaAuth.tokenManager.setTokens(res.tokens);
            const { accessToken } = res.tokens.accessToken;
            if (accessToken) {
              setAuthentication({ issuer: 'okta', token: accessToken });
            }
            // eslint-disable-next-line no-console
            console.log('Access token refreshed.');
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to refresh token:', error);
            // Optionally, handle a failed refresh attempt here
            const searchParams = new URLSearchParams(location.search);
            const originalUri = searchParams.get('state');
            oktaAuth.tokenManager.clear();
            oktaAuth.signInWithRedirect({
              state: originalUri || location.pathname,
            });
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('User is logged out, token will not be refreshed.');
          const searchParams = new URLSearchParams(location.search);
          const originalUri = searchParams.get('state');
          oktaAuth.tokenManager.clear();
          oktaAuth.signInWithRedirect({
            state: originalUri || location.pathname,
          });
          // Optionally, handle logout actions here if needed
        }
      }
    };

    // Set up the listener for token expiration
    oktaAuth.tokenManager.on('expired', handleTokenExpiry);
    // Set up the listener for token expiration
    return () => {
      oktaAuth.tokenManager.off('expired', handleTokenExpiry);
    };
  }, [oktaAuth]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
}

export default OktaSecurity;
