import { EFeatureToggle, NotebookPhaseEnum, RoleEnum } from '../constants';
import {
  IAccessRequestFormData,
  IDatasetsFormData,
  ITeamFormData,
} from '../pages/AccessRequestsAndSupport/types';
import { IRegistryFilters } from '../pages/Dashboard/ProjectRegistry/FilterBar';
import { ICostReportingFilters } from '../pages/Dashboard/MetricsAndReports/CostReporting/FilterBar';

export interface INotebookStatus {
  message: string;
  phase: NotebookPhaseEnum;
  state: string;
}

export interface INotebook {
  name: string;
  age: string;
  cpu: string;
  image: string;
  last_activity: string;
  memory: string;
  namespace: string;
  serverType: string;
  shortImage: string;
  userName: string;
  status: INotebookStatus;
  gpus: {
    count: number;
    message: string;
  };
  volumes: string[];
}

export interface INotebookResponse {
  status: string | number;
  success: string;
  user: string;
  notebooks: INotebook[];
}

export interface IRole {
  name: string;
  access: string[];
}

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isLead: boolean;
  roles: IRole[];
}

export interface ISimpleIdName {
  id: string;
  name: string;
}

export interface ICountry {
  id: string;
  name: string;
  region: string;
}

export interface ICountryStore {
  countries: ICountry[];
  isLoading: boolean;
  actions: {
    getCountryApi: () => Promise<void>;
  };
}

export interface IProjectType {
  projectTypes: ISimpleIdName[];
  isLoading: boolean;
  actions: {
    getProjectTypesByAgencyAndRegionApi: (
      agencyName: string,
      region: string
    ) => Promise<void>;
    reset: () => void;
  };
}

export interface IApprovalAdditionalInfo {
  big_query_write_access: boolean;
  gcs_bucket_creation: boolean;
  product_approval: boolean;
  agency_contact_person_approval: boolean;
  comments: string;
}

export interface IDatasetRegion {
  region: string;
  is_deleted?: boolean;
  added_dataset?: boolean;
}

export interface IApprovalDataset {
  ao_id: boolean;
  use_case_text: string;
  instance_region: string;
  comments?: string;
  dataset_region_map: {
    [region: string]: IDatasetRegion[];
  };
  dataset_approval_map: {
    [dataset: string]: boolean;
  };
}

export interface IMember {
  id: null | number;
  name: null | string;
  email: null | string;
  role: RoleEnum;
  region: null | string;
  is_deleted?: boolean;
  added_team_member?: boolean;
  requestor_email?: string;
  status?: string;
}

export interface IApprovalProjectDetails {
  request_reason: null | string;
  namespace: null | string;
  agency: null | string;
  region_instance_url_map: {
    [region: string]: string;
  };
  is_changed: object;
  deleted_regions: string[];
}

export interface IAgencyContact {
  name: null | string;
  email: null | string;
  is_changed: boolean;
}

export interface IRequesterDetails {
  name: string;
  email: string;
  agency: string;
  country?: string;
}

export interface IApproval {
  requester_details: IRequesterDetails;
  agency_contact_person_details: IAgencyContact;
  project_details: IApprovalProjectDetails;
  team_details: IMember[];
  datasets: IApprovalDataset[];
  additional_info?: IApprovalAdditionalInfo;
}

export interface IApprovalListItem {
  namespace_name: string;
  agency: string;
  regions: string[];
}

export enum EApprovalType {
  ACCESS = 'access',
  DATASET = 'dataset',
  TEAM = 'team',
}

export interface IApprovalStore {
  approvalList: {
    [approvalId: string]: IApprovalListItem;
  };
  approvalListError: string;
  approvalListLoading: boolean;
  approvals: {
    [approvalId: string]: IApproval;
  };
  approvalsLoading: {
    [approvalId: string]: boolean;
  };
  approvalsErrors: {
    [approvalId: string]: string;
  };
  approvalType: EApprovalType;
  actions: {
    setApprovalType: (approvalType: EApprovalType) => void;
    getApprovalList: (
      approvalType: EApprovalType,
      force?: boolean
    ) => Promise<void>;
    getApproval: (
      approvalType: EApprovalType,
      approvalId: string,
      force?: boolean
    ) => Promise<void>;
    saveApproval: (
      approvalType: EApprovalType,
      approval: IApproval,
      approvalId: string,
      callback
    ) => Promise<void>;
    submitApproval: (
      approvalType: EApprovalType,
      approvalId: string,
      callback
    ) => Promise<void>;
  };
}

export interface IReasonStore {
  reasons: ISimpleIdName[];
  isLoading: boolean;
  actions: {
    getReasonApi: () => Promise<void>;
  };
}

export interface IDSWRoleAccess {
  id: string;
  name: string;
}

export interface IDSWRole {
  id: number;
  name: string;
  access: IDSWRoleAccess[];
  isDeleted?: boolean;
  whenCreated?: string;
  whenModified?: string;
}

export interface IRoleStore {
  isRolesLoading: boolean;
  roles: IDSWRole[];
  isRoleLoading: boolean;
  role: IDSWRole;
  actions: {
    getRolesApi: () => Promise<void>;
    getRoleApi: (callback?: (role: IDSWRole) => void) => Promise<void>;
  };
}

export interface IRegion {
  id: string;
  name: string;
}

export interface IRegionStore {
  regions: IRegion[];
  regionsByAgency: IRegion[];
  isLoading: boolean;
  actions: {
    getRegionApi: () => Promise<void>;
    getRegionsByAgencyNameApi: (
      agencyName: string,
      userMail: string
    ) => Promise<void>;
    reset: () => void;
  };
}

export interface IDataset {
  id: number;
  name: string;
  owner_name: string;
  owner_email: string;
  ao_id: boolean;
  isDefault?: boolean;
}

export interface IRequestDatasets {
  AMER: {
    isLoading: boolean;
    data: IDataset[];
  };
  APAC: {
    isLoading: boolean;
    data: IDataset[];
  };
  EMEA: {
    isLoading: boolean;
    data: IDataset[];
  };
}

export interface IAdditionalDatasets {
  isLoading: boolean;
  data: IDataset[];
}

export interface IDatasetStore {
  requestDatasets: IRequestDatasets;
  additionalDatasets: IAdditionalDatasets;
  datasets: IDataset[];
  isLoading: boolean;
  actions: {
    getRequestDatasetApi: (
      agency: string,
      region: string,
      reason: string,
      country?: string
    ) => Promise<IRequestDatasets>;
    getAdditionalDatasetApi: (
      agency: string,
      region: string,
      projectName: string
    ) => Promise<void>;
    getDatasetApi: () => Promise<void>;
  };
}

export interface IUserStore {
  users: IUser[];
  userRole: RoleEnum;
  actions: {
    getUsersApi: () => Promise<void>;
    setUserRole: (userRole: RoleEnum) => void;
  };
}

export interface IRegistryDataSet {
  id: number;
  requestStatus: string;
  agencyName: string;
  projectName: string;
  regionName: string;
  countDatasets: number;
  countUsers: number;
  sla: string;
  remaining: string;
  submitDate: string;
  jiraTicketId: string;
  instanceUrl: string;
  activeDate?: string;
  reviewDate?: string;
  deleteDate?: string;
  details?: IApproval;
  requestId: number;
  projectId?: string;
}

export interface IRegistryDataSetById {
  [status: string]: IRegistryDataSet[];
}

export interface IProjectData {
  id: number;
  projectName: string;
  agencyName: string;
  regionName: number;
  zone: string;
  activeSince: string;
  projectId: string;
  isDeleted: boolean;
  whenCreated: string;
  whenModified: string;
}

export interface IRegistryStore {
  data: IRegistryDataSetById;
  projects: IProjectData[];
  error: string;
  isLoading: boolean;
  loadingItems: number[];
  extending: boolean;
  deleting: boolean;
  actions: {
    getDataApi: (filters?: IRegistryFilters) => Promise<void>;
    getProjectsByRegionAndAgencyApi: (
      filters?: IRegistryFilters
    ) => Promise<void>;
    getDetailsApi: (item: IRegistryDataSet, status: string) => Promise<void>;
    openExport: (
      item: IRegistryDataSet,
      requestStatus: string,
      filters: IRegistryFilters
    ) => Promise<void>;
    postExtend: (
      item: IRegistryDataSet,
      callback: (data: string, error: string) => void
    ) => Promise<void>;
    postDelete: (
      item: IRegistryDataSet,
      callback: (data: string, error: string) => void,
      filters?: IRegistryFilters
    ) => Promise<void>;
    reset: () => void;
  };
}

export interface IContactPerson {
  user_region_name?: string;
  country_name?: string;
  id: string | number;
  email: string;
  name: boolean;
}

export interface ITechnicalLead {
  user_region_name?: string;
  country_name?: string;
  id: string | number;
  email: string;
  name: boolean;
}

export interface INamespaceDetails {
  namespace_name: string;
  datasets: string[];
  lead_detail_list: ITechnicalLead[];
  contact_person: IContactPerson;
  notebook_response: INotebookResponse;
}

export interface IAgency {
  agency_name: string;
  region_name: string;
  instance_url: string;
  namespace_details: INamespaceDetails[];
}

export interface ISimpleAgency {
  id: number;
  name: string;
}

export interface IAgenciesByRequestType {
  id: number;
  name: string;
  shortName: string;
}

export interface IAgencyStore {
  agenciesByRequestType: IAgenciesByRequestType[];
  simpleAgencies: ISimpleAgency[];
  simpleAgenciesByUserMail: ISimpleAgency[];
  agencies: IAgency[];
  notebooks: {
    [key: string]: INotebookResponse;
  };
  loadingAgencies: boolean;
  loadingNotebooks: {
    [key: string]: boolean;
  };
  agenciesLoaded: boolean;
  agencyErrors: string;
  notebookErrors: {
    [key: string]: string;
  };
  loadingAgencyContact: boolean;
  agencyContact: IAgencyContact;
  agencyContactErrors: string;
  actions: {
    getAgenciesByRequestTypeApi: (requestType: string) => Promise<void>;
    getSimpleAgenciesApi: () => Promise<void>;
    getSimpleAgenciesByUserMailApi: (force?: boolean) => Promise<void>;
    getAgenciesApi: (force?: boolean) => Promise<void>;
    getAgencyContactApi: (agencyName: string, callback) => Promise<void>;
    getNotebooks: (agencyName: string, userMail: string) => Promise<void>;
    deleteNotebook: (
      instanceUrl: string,
      namespaceName: string,
      notebookName: string,
      callback: (response) => void
    ) => Promise<void>;
    patchNotebook: (
      instanceUrl: string,
      namespaceName: string,
      notebookName: string,
      stopped: boolean,
      callback: (response) => void
    ) => Promise<void>;
  };
}

export interface IUserInfo {
  name: string;
  email: string;
}

export interface IGlobalStore {
  userInfo: IUserInfo;
  version: {
    loading: boolean;
    error: string;
    value: string;
  };
  moty: {
    holiday: boolean;
  };
  actions: {
    getVersionApi: (basename: string) => Promise<void>;
    setUserInfo: (userInfo: IUserInfo) => void;
    moty: {
      dismissHoliday: () => void;
    };
  };
}

export interface IRequest {
  loading: boolean;
  error: { message?: string };
  data: {
    parent_request_id: number;
    status: { message: string };
    error?: string;
    message?: string;
  };
}

export interface IRequestStore {
  accessRequest: IRequest;
  dataset: IRequest;
  actions: {
    postAccessApi: (
      data: IAccessRequestFormData,
      callback: (request: IRequest) => void
    ) => Promise<void>;
    postDatasetApi: (
      data: IDatasetsFormData,
      callback: (request: IRequest) => void
    ) => Promise<void>;
    postTeamApi: (
      data: ITeamFormData,
      callback: (request: IRequest) => void
    ) => Promise<void>;
  };
}

export interface INavigationStore {
  currentPage: string;
  actions: {
    changeCurrentPage: (currentPage) => void;
  };
}

export interface IKeyPlatformMetricsReponse {
  totalUsers: string;
  uniqueUsers: string;
  monthlyActiveUsers: string;
  activeProjects: string;
  totalProjects: string;
  agencies: string;
  pitchworks: string;
  notebooks: string;
}

export interface MonthlyData {
  Jan?: string;
  Feb?: string;
  Mar?: string;
  Apr?: string;
  May?: string;
  Jun?: string;
  Jul?: string;
  Aug?: string;
  Sep?: string;
  Oct?: string;
  Nov?: string;
  Dec?: string;
}

export interface IGrowthMetricsResponse {
  totalUsers: {
    cumulative: number | string;
    data: MonthlyData;
  };
  uniqueUsers: {
    cumulative: number | string;
    data: MonthlyData;
  };
  monthlyActiveUsers?: {
    cumulative: number | string;
    data: MonthlyData;
  };
  activeProjects: {
    cumulative: number | string;
    data: MonthlyData;
  };
  totalProjects: {
    cumulative: number | string;
    data: MonthlyData;
  };
  agencies: {
    cumulative: number | string;
    data: MonthlyData;
  };
  pitchworks: {
    cumulative: number | string;
    data: MonthlyData;
  };
  notebooks?: {
    cumulative: number | string;
    data: MonthlyData;
  };
}

export interface IResourceCostDetails {
  hardwareCost: number;
  dataProcessingCost: number;
  totalCost: number;
  currency: string;
}

export interface ICostReportingNotebookDetails extends IResourceCostDetails {
  name: string;
}

export interface ICostReportingProjectDetails extends IApproval {
  notebook_details?: ICostReportingNotebookDetails[];
}

export interface ICostReportingProject {
  id: number;
  requestStatus: string;
  agencyName: string;
  projectName: string;
  regionName: string;
  countDatasets: number;
  countUsers: number;
  sla: string;
  remaining: string;
  submitDate: string;
  jiraTicketId: string;
  instanceUrl: string;
  activeDate?: string;
  reviewDate?: string;
  deleteDate?: string;
  details?: ICostReportingProjectDetails;
  totalCost: number;
  currency: string;
}

export interface ICostReportingProjectsByStatus {
  [status: string]: ICostReportingProject[];
}

export interface IQuickReport {
  name?: string;
  total?: number;
  data?: IQuickReport[];
}

export interface IMetricsAndReportsStore {
  keyPlatformMetricsData: IKeyPlatformMetricsReponse;
  growthMetricsData: IGrowthMetricsResponse;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resourceCostDetailsData: any;
  projectsData: ICostReportingProjectsByStatus;
  quickReportData: IQuickReport[];
  error: string;
  exportError: string;
  hardwareCostDetailsError: string;
  dataCostDetailsError: string;
  projectsDataError: string;
  projectDetailsDataError: string;
  quickReportError: string;
  isLoadingKeyPlatformMetricsData: boolean;
  isLoadingGrowthMetricsData: boolean;
  isLoadingKeyPlatformMetricsExportFile: boolean;
  isLoadingGrowthMetricsExportFile: boolean;
  isLoadingHardwareCostDetails: boolean;
  isLoadingDataCostDetails: boolean;
  isLoadingProjects: boolean;
  isLoadingQuickReport: boolean;
  isLoadingQuickReportExportFile: boolean;
  loadingItems: number[];
  actions: {
    getKeyPlatformMetricsApi: () => Promise<void>;
    getGrowthMetricsApi: (startDate: string, endDate: string) => Promise<void>;
    exportKeyPlatformMetricsApi: (handleExportErrorCallback) => Promise<void>;
    exportGrowthMetricsApi: (
      startDate: string,
      endDate: string,
      handleExportErrorCallback
    ) => Promise<void>;
    resetGrowthMetricsState: () => void;
    resetCostReportingData: () => void;
    getResourceCostDetailsApi: (
      agencyName: string,
      region: string,
      projectType: string,
      startDate: string,
      endDate: string
    ) => void;
    getHardwareCostDetails: (
      projectName: string,
      startDate: string,
      endDate: string,
      baseURL: string
    ) => void;
    getDataProcessingCostDetails: (
      agencyName: string,
      region: string,
      instanceUrl: string,
      startDate: string,
      endDate: string
    ) => void;
    getProjectsApi: (
      agencyName: string,
      region: string,
      projectType: string,
      startDate: string,
      endDate: string
    ) => void;
    getProjectDetailsApi: (
      item: ICostReportingProject,
      status: string,
      filters: ICostReportingFilters
    ) => Promise<void>;
    getQuickReportApi: (
      projectType: string,
      startDate: string,
      endDate: string
    ) => Promise<void>;
    exportQuickReportApi: (
      reportType: string,
      startDate: string,
      endDate: string,
      handleExportErrorCallback
    ) => Promise<void>;
    resetQuickReportApi: () => Promise<void>;
  };
}

export type TFeatureToggles = {
  [featureToggle in EFeatureToggle]?: boolean | string;
};

export interface IFeatureToggleStore {
  featureToggles: TFeatureToggles;
  loading: boolean;
  error: string;
  actions: {
    getFeatureToggles: () => Promise<void>;
    hasFeature: (feature: EFeatureToggle) => boolean;
  };
}

export interface ISettings {
  DatasetsAndCatalogs?: {
    allowedDatabases?: string[];
    allowedSchemas?: string[];
  };
}

export interface ISettingsStore {
  settings: ISettings;
  loading: boolean;
  error: string;
  actions: {
    getSettings: (forceReload?: boolean) => Promise<void>;
    setSettings: (
      settings: ISettings,
      callback: (success: boolean) => void
    ) => Promise<void>;
  };
}

export interface IDBStatsStoreLayout {
  loading: boolean;
  error: string;
  data: unknown[];
}

export interface IDBStatsCorrelation {
  [key: string]: number;
}

export interface IDBStatsFeature {
  'Features Important': string;
}

export interface IDBStatsCorrelations extends IDBStatsStoreLayout {
  data: IDBStatsCorrelation[];
}

export interface IDBStatsFeatures extends IDBStatsStoreLayout {
  data: IDBStatsFeature[];
}

export interface IDBStatsDatabase {
  DB_NAME: string;
}

export interface IDBStatsSchema {
  DB_NAME: string;
  SCHEMA_NAME: string;
  SCHEMA_OWNER: string;
  CREATED: number;
}

export interface IDBStatsTable {
  DB_NAME: string;
  SCHEMA_NAME: string;
  TABLE_NAME: string;
  DESCRIPTION: string;
  ROW_COUNT: number;
  BYTES: number;
  TABLE_OWNER: string;
  TABLE_TYPE: string;
  CREATED: number;
  COLUMN_COUNT: number;
  correlation: IDBStatsCorrelations;
  features: IDBStatsFeatures;
}

export interface IDBStatsColumn {
  DB_NAME: string;
  SCHEMA_NAME: string;
  TABLE_NAME: string;
  COLUMN_NAME: string;
  DATA_TYPE: string;
  DESCRIPTION: string;
}

export interface IDBStatsBaseStat {
  COLUMN: string;
  DB: string;
  DISTINCT_COUNT: string;
  FALSE_COUNT: string;
  IQR: string;
  MAX: string;
  MEDIAN: string;
  MIN: string;
  MODE: string;
  SCHEMA: string;
  STDDEV: string;
  TABLE: string;
  TRUE_COUNT: string;
  VARIANCE: string;
}

export interface IDBStatsDatabases extends IDBStatsStoreLayout {
  data: IDBStatsDatabase[];
}

export interface IDBStatsSchemas extends IDBStatsStoreLayout {
  data: IDBStatsSchema[];
}

export interface IDBStatsTables extends IDBStatsStoreLayout {
  data: IDBStatsTable[];
}

export interface IDBStatsColumns extends IDBStatsStoreLayout {
  data: IDBStatsColumn[];
}

export interface IDBStatsBaseStats extends IDBStatsStoreLayout {
  data: IDBStatsBaseStat[];
}

export interface IDBStatsStore {
  database: IDBStatsDatabases;
  schema: IDBStatsSchemas;
  tables: IDBStatsTables;
  columns: IDBStatsColumns;
  'base-stats': IDBStatsBaseStats;
  categoryLabels: { [tableName: string]: { [short: string]: string } };
  categoryLabelsLoading: string[];
  actions: {
    getApi: (
      source: 'database' | 'schema' | 'tables' | 'columns' | 'base-stats',
      database?: string,
      schema?: string
    ) => Promise<void>;
    getAdvancedApi: (
      selectedTable: string,
      database: string,
      schema: string,
      isCorrelation: boolean
    ) => Promise<void>;
    getCategoryLabels: (table: string) => Promise<void>;
  };
}

export interface ISalesForceAccounts {
  id: string;
  include: boolean;
  name: string;
}
export interface ISegments {
  segmentIdDb: string;
  segmentId: string;
  segmentName: string;
  description: string;
  createdBy: string;
  createdDate: string;
  exportToNgpi: boolean;
  sfAccounts: ISalesForceAccounts[];
}

export interface ISegmentsData {
  isLoading: boolean;
  errorMsg: string;
  data: ISegments[];
}

export interface IChipSegmentsManger {
  segmentData: ISegmentsData;
  actions: {
    getSegmentsApi: () => Promise<void>;
    exportSegmentApi: (
      segmentId: string,
      userName: string,
      callback?: (status: string) => void
    ) => Promise<void>;
  };
}

export interface IExportSegmentParams {
  id: string;
  segmentId: string;
}

export interface IAuthentication {
  token: string;
  issuer: string;
}

export interface IAuthenticationStore {
  authInfo: IAuthentication | null;
  actions: {
    setAuthentication: (authInfo: IAuthentication) => void;
  };
}
