import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IGlobalStore } from './types';
import { dswRequest } from './index';

const actualDate = new Date();

export interface IMessageOfTheYear {
  holidayStart?: string;
  holidayEnd?: string;
}

const moty: IMessageOfTheYear = JSON.parse(localStorage.getItem('moty'));

export const initialState: IGlobalStore = {
  userInfo: {
    name: '',
    email: '',
  },
  version: {
    loading: false,
    error: '',
    value: '',
  },
  moty: {
    holiday:
      (!moty &&
        (new Date(`${actualDate.getFullYear()}-12-15`) < actualDate ||
          new Date(`${actualDate.getFullYear()}-01-08`) > actualDate)) ||
      (new Date(moty?.holidayStart) >= actualDate &&
        new Date(moty?.holidayStart) <= actualDate),
  },
  actions: {
    getVersionApi: (basename: string) => Promise.resolve(),
    setUserInfo: () => undefined,
    moty: {
      dismissHoliday: () => undefined,
    },
  },
};

export const useGlobalStore = create<IGlobalStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getVersionApi: async (basename: string) => {
          const { loading, value } = get().version;
          if (!loading && !value) {
            set((state) => ({
              ...state,
              version: {
                ...state.version,
                loading: true,
              },
            }));
            try {
              const apiResponse = await dswRequest.get(`/info.txt`);
              set((state) => ({
                ...state,
                version: {
                  ...state.version,
                  value: apiResponse.data,
                },
              }));
            } catch (e) {
              set((state) => ({
                ...state,
                version: {
                  ...state.version,
                  error: e.toString(),
                },
              }));
            } finally {
              set((state) => ({
                ...state,
                version: {
                  ...state.version,
                  loading: false,
                },
              }));
            }
          }
        },
        setUserInfo: (userInfo) => {
          set((state) => ({
            ...state,
            userInfo,
          }));
        },
        moty: {
          dismissHoliday: () => {
            const year =
              actualDate.getMonth() === 1
                ? actualDate.getFullYear()
                : actualDate.getFullYear() + 1;
            const motyContent = {
              holidayStart: `${year}-12-15`,
              holidayEnd: `${year + 1}-01-08`,
            };
            localStorage.setItem('moty', JSON.stringify(motyContent));
          },
        },
      },
    }))
  )
);
