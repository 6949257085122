import React, { useEffect } from 'react';

import { useChipSegmentsManager } from '../../../stores/segments';

import SegmentList from './SegmentList';

export default function ChipRequestManager() {
  const {
    segmentData,
    actions: { getSegmentsApi },
  } = useChipSegmentsManager();

  useEffect(() => {
    getSegmentsApi();
  }, [getSegmentsApi]);
  return <SegmentList segmentData={segmentData} />;
}
