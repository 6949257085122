import React, { useState } from 'react';
import {
  WppAccordion,
  WppActionButton,
  WppButton,
  WppIconWarning,
  WppModal,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import {
  IDatasetRegion,
  IRegistryDataSet,
  IRegistryDataSetById,
} from '../../../stores/types';
import { useFeatureToggleStore } from '../../../stores/featureToggle';
import useToast from '../../../hooks/useToast';
import LoadingElement from '../../../components/LoadingElement';
import { EFeatureToggle } from '../../../constants';

export interface IRegistryTableParams {
  data: IRegistryDataSetById;
  expandedRecord: string;
  expandedStatus: string;
  getDetails: (item: IRegistryDataSet, status: string) => void;
  handleExport: (item: IRegistryDataSet, status: string) => void;
  handleExtend: (
    item: IRegistryDataSet,
    callback: (data: string, error: string) => void
  ) => void;
  handleDelete: (
    item: IRegistryDataSet,
    callback: (data: string, error: string) => void
  ) => void;
  setExpandedRecord: (itemId: string) => void;
  setExpandedStatus: (status: string) => void;
  error: string;
  isLoading: boolean;
  extending: boolean;
  deleting: boolean;
  loadingItems: number[];
  didFilter: boolean;
}

export default function RegistryTable(params: IRegistryTableParams) {
  const {
    data,
    getDetails,
    handleExport,
    handleExtend,
    handleDelete,
    setExpandedRecord,
    setExpandedStatus,
    expandedStatus,
    expandedRecord,
    error,
    isLoading,
    extending,
    deleting,
    loadingItems,
    didFilter,
  } = params;

  const { hasFeature } = useFeatureToggleStore((state) => state.actions);

  const isEmpty =
    data.Pending.length === 0 &&
    data.Active.length === 0 &&
    data.Deleted.length === 0;

  const { showToast } = useToast();
  const [itemToRemove, setItemToRemove] = useState<IRegistryDataSet>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [preventExpand, setPreventExpand] = useState<boolean>(false);

  const requestDelete = (item: IRegistryDataSet) => {
    setItemToRemove(item);
    setShowModal(true);
  };

  const doExport = (item: IRegistryDataSet = null, status = null) => {
    let exportStatus = '';
    let exportAll = false;
    let exportItem = null as IRegistryDataSet;
    let exportTitle;
    if (!item) {
      if (!status) {
        exportAll = true;
        exportTitle = exportAll ? 'all projects' : '';
      } else {
        exportStatus = status;
        exportTitle = `${exportStatus} projects`;
      }
    } else {
      exportItem = item;
      exportTitle = exportItem.projectName;
    }
    handleExport(item, status);
    showToast({
      header: `Exporting ${exportTitle}...`,
      type: 'success',
      duration: 10000,
      maxMessageLines: 1,
    });
  };

  const doExtend = (item: IRegistryDataSet) => {
    handleExtend(item, (d, e) => {
      let status = 'failure';
      if (d) {
        status = 'success';
      }
      showToast({
        header: `Extending "${item.projectName}": ${status}`,
        message: e || d,
        type: status === 'success' ? 'success' : 'error',
        duration: 10000,
        maxMessageLines: 1,
      });
    });
  };

  const doDelete = (item: IRegistryDataSet) => {
    setShowModal(false);
    handleDelete(item, (d, e) => {
      let status = 'failure';
      if (d) {
        status = 'success';
        setExpandedRecord('');
      }
      showToast({
        header: `Deleting "${item.projectName}": ${status}`,
        message: e || d,
        type: status === 'success' ? 'success' : 'error',
        duration: 10000,
        maxMessageLines: 1,
      });
    });
  };

  const getJiraLink = (jiraTicketId) => {
    const href = `https://wppchoreograph.atlassian.net/browse/${jiraTicketId}`;
    return (
      <WppActionButton
        onClick={() => window.open(href, '_blank')}
        onMouseOver={() => setPreventExpand(true)}
        onMouseOut={() => setPreventExpand(false)}
        title={jiraTicketId}
      >
        {jiraTicketId}
      </WppActionButton>
    );
  };

  const getNamespaceLink = (instanceUrl, projectName, short = false) => {
    const href = `${instanceUrl}_/jupyter/?ns=${projectName}`;
    return (
      <WppActionButton
        onClick={() => window.open(href, '_blank')}
        onMouseOver={() => setPreventExpand(true)}
        onMouseOut={() => setPreventExpand(false)}
        title={href}
      >
        {short ? 'Link' : href}
      </WppActionButton>
    );
  };

  const getLink = (href) => {
    return (
      <WppActionButton
        onClick={() => window.open(href, '_blank')}
        onMouseOver={() => setPreventExpand(true)}
        onMouseOut={() => setPreventExpand(false)}
        title={href}
      >
        {href}
      </WppActionButton>
    );
  };

  const getSpacers = (item: IRegistryDataSet) => {
    const datasetSourcesCount =
      item.details && item.details.datasets[0].dataset_region_map
        ? Object.keys(item.details.datasets[0].dataset_region_map).length
        : 0;
    const spacersLength = datasetSourcesCount > 3 ? datasetSourcesCount - 1 : 1;
    return new Array(spacersLength).fill('').map((_, idx) => (
      <span key={`spacer-${idx}`} className="row-1">
        &nbsp;
      </span>
    ));
  };

  const getDatasetInformation = (item: IRegistryDataSet) => {
    const getSources = (dataset) =>
      Object.entries(dataset.dataset_region_map).map(([source, regions]) => ({
        source,
        regions: (regions as IDatasetRegion[])
          .filter(({ added_dataset }) => added_dataset)
          .map(({ region }) => region)
          .join(', '),
      }));

    const multiple = item.details.datasets.length > 1;

    return (
      <span
        className={`dataset-container ${multiple ? 'multiple' : 'single'}`}
        key={`dataset-container-${item.id}`}
      >
        {item.details.datasets.map((dataset, datasetIndex) => (
          <span
            className={`dataset-item ${
              multiple ? dataset.instance_region : ''
            } light`}
            key={`dataset-item-${item.id}-${datasetIndex}`}
          >
            <span
              className={`source-header ${
                multiple ? dataset.instance_region : ''
              }`}
            >
              {dataset.instance_region}
            </span>
            {getSources(dataset)
              .filter(({ regions }) => regions.length > 0)
              .map(({ source, regions }) => (
                <span
                  className={`source-item ${source}`}
                  key={`dataset-item-${item.id}-${datasetIndex}-${source}`}
                >
                  {`${source} - ${regions}`}
                </span>
              ))}
          </span>
        ))}
      </span>
    );
  };

  const getProjectItem = (item: IRegistryDataSet, status) => (
    <WppAccordion
      className="data-item"
      expanded={expandedRecord === `${item.id}`}
      withDivider={false}
      onWppChange={(e) => {
        if (preventExpand) {
          // eslint-disable-next-line no-param-reassign
          e.target.expanded = !e.target.expanded;
        } else {
          setExpandedRecord(e.detail.expanded ? `${item.id}` : '');
          if (e.target.expanded) {
            getDetails(item, status);
          }
        }
      }}
      key={`data-item-${item.id}`}
    >
      <div slot="header" className="table-item-header">
        <span className="row-1">
          <span className="col-1">{item.requestId}</span>
          <span className="col-1">{item.agencyName}</span>
          <span className="col-1">{item.projectName}</span>
          <span className="col-1">
            <span className={`item-region ${item.regionName}`}>
              {item.regionName}
            </span>
          </span>
          <span className="col-1">{item.countDatasets}</span>
          <span className="col-1">{item.countUsers}</span>
          <span className="col-1">{item.submitDate}</span>
          {status.toLowerCase() === 'active' && (
            <span className="col-1 label">{item.activeDate || 'N/A'}</span>
          )}
          {status.toLowerCase() === 'deleted' && (
            <span className="col-1 label">{item.deleteDate || 'N/A'}</span>
          )}
          {hasFeature(EFeatureToggle.PROJECT_REGISTRY_REVIEW_DATE) &&
            status.toLowerCase() === 'active' && (
              <span className="col-1 label">{item.reviewDate || 'N/A'}</span>
            )}
          <span className="col-1">{getJiraLink(item.jiraTicketId)}</span>
          {status.toLowerCase() === 'active' && (
            <span className="col-1 label">
              {getNamespaceLink(item.instanceUrl, item.projectName, true)}
            </span>
          )}
          <span className="col-1 export">
            <WppActionButton
              className="inline export-single"
              onMouseOver={() => setPreventExpand(true)}
              onMouseOut={() => setPreventExpand(false)}
              onClick={() => doExport(item, status)}
            >
              Export
            </WppActionButton>
          </span>
        </span>
      </div>
      {loadingItems.includes(item.id) ? (
        <LoadingElement size="s" />
      ) : (
        <div className="table-item-content">
          <span className="title">
            <WppTypography tag="h2" type="s-strong">
              Project Details
            </WppTypography>
            <span className="buttons">
              {hasFeature(EFeatureToggle.PROJECT_REGISTRY_EXTEND) &&
                status.toLowerCase() === 'active' && (
                  <WppActionButton
                    className="inline extend"
                    loading={extending}
                    onClick={() => doExtend(item)}
                  >
                    Extend
                  </WppActionButton>
                )}
            </span>
          </span>
          <span className="row-1">
            <span className="col-1 label">Agency Name</span>
            <span className="col-1">{item.agencyName}</span>
            <span className="col-1 label">Instance URL</span>
            <span className="col-1">{getLink(item.instanceUrl)}</span>
            <span className="col-1">
              {hasFeature(EFeatureToggle.PROJECT_REGISTRY_DELETE) &&
                status.toLowerCase() === 'active' && (
                  <WppActionButton
                    className="inline delete"
                    loading={deleting}
                    onClick={() => requestDelete(item)}
                  >
                    Delete
                  </WppActionButton>
                )}
            </span>
          </span>
          <span className="row-1">
            <span className="col-1 label">Project Name</span>
            <span className="col-1">{item.projectName}</span>
            <span className="col-1 label">Namespace URL</span>
            <span className="col-1">
              {getNamespaceLink(item.instanceUrl, item.projectName, false)}
            </span>
          </span>
          <span className="row-1">
            <span className="col-1 label">Region</span>
            <span className="col-1">
              <span className={`item-region ${item.regionName}`}>
                {item.regionName}
              </span>
            </span>
          </span>
          <span className="row-1">&nbsp;</span>
          <span className="row-1">
            <span className="col-1 label">Requestor Name</span>
            <span className="col-1">
              {item.details?.requester_details.name}
            </span>
            <span className="col-1 label">Datasets</span>
            <span
              className={`col-1 ${
                item.details?.datasets.length === 1 ? 'single' : ''
              }`}
            >
              {item.details?.datasets.length > 0
                ? getDatasetInformation(item)
                : null}
            </span>
          </span>
          <span className="row-1">
            <span className="col-1 label">Requestor E-mail</span>
            <span className="col-1">
              {item.details?.requester_details.email}
            </span>
          </span>
          <span className="row-1">
            <span className="col-1 label">Requestor Agency</span>
            <span className="col-1">
              {item.details?.requester_details.agency}
            </span>
          </span>
          <span className="row-1">
            <span className="col-1 label">Request ID</span>
            <span className="col-1">{item.requestId}</span>
          </span>
          {getSpacers(item)}
          <span className="row-1">
            <span className="col-1 label">Team Members</span>
            <span className="col-1 label">Name & Surname</span>
            <span className="col-1 label">E-mail</span>
            <span className="col-1 label">Region</span>
            <span className="col-1 label">Role</span>
          </span>
          {(item.details?.team_details || [])
            .filter(({ added_team_member }) => added_team_member)
            .map(({ id, name, email, region, role }, index) => (
              <span className="row-1 team-member" key={`user-item-${id}`}>
                <span className="col-1 label">{`USER ${index + 1}`}</span>
                <span className="col-1">{name}</span>
                <span className="col-1">{email}</span>
                <span className="col-1">
                  <span className={`team-member-region ${region}`}>
                    {region}
                  </span>
                </span>
                <span className="col-1">{role}</span>
              </span>
            ))}
          <span className="row-1">&nbsp;</span>
        </div>
      )}
    </WppAccordion>
  );

  const getStatusSection = (status) => {
    const items = data[status];
    const title = status.toUpperCase();

    return (
      <WppAccordion
        className="table-section"
        expanded={expandedStatus === status}
        withDivider={false}
        onWppChange={(e) => {
          if (preventExpand) {
            // eslint-disable-next-line no-param-reassign
            e.target.expanded = !e.target.expanded;
          } else {
            setExpandedStatus(e.detail.expanded ? status : '');
          }
        }}
      >
        <div slot="header" className="table-section-header">
          <div className="table-section-header-info">
            <WppTypography tag="h2" type="s-strong">
              {title}
            </WppTypography>{' '}
            <WppActionButton
              className="inline export"
              onMouseOver={() => setPreventExpand(true)}
              onMouseOut={() => setPreventExpand(false)}
              onClick={() => doExport(null, status)}
            >
              Export Group
            </WppActionButton>
            <div className="entries">{`${items.length} entr${
              items.length > 1 || items.length === 0 ? 'ies' : 'y'
            }`}</div>
          </div>
        </div>
        <div className="table-section-content">
          <div className="table-section-content-header">
            <span className="row-1">
              <span className="col-1 id">Request ID</span>
              <span className="col-1 label">Agency Name</span>
              <span className="col-1 label">Project Name</span>
              <span className="col-1 label">Region</span>
              <span className="col-1 label">Datasets</span>
              <span className="col-1 label">Users</span>
              <span className="col-1 label">Submitted</span>
              {status.toLowerCase() === 'active' && (
                <span className="col-1 label">Active</span>
              )}
              {status.toLowerCase() === 'deleted' && (
                <span className="col-1 label">Deleted</span>
              )}
              {hasFeature(EFeatureToggle.PROJECT_REGISTRY_REVIEW_DATE) &&
                status.toLowerCase() === 'active' && (
                  <span className="col-1 label">Review</span>
                )}
              <span className="col-1 label">JIRA</span>
              {status.toLowerCase() === 'active' && (
                <span className="col-1 label">Namespace</span>
              )}
              <span className="col-1">&nbsp;</span>
            </span>
          </div>
          <div className="table-item-content-record">
            {items.map((item) => getProjectItem(item, status))}
          </div>
        </div>
      </WppAccordion>
    );
  };

  return (
    <div className="registry-table">
      <WppModal className="project-registry-remove" open={showModal}>
        <span className="modal-header" slot="header">
          <WppIconWarning size="m" /> Delete Project
        </span>
        <span className="modal-body" slot="body">
          Do you really want to delete the project
          <br />
          <WppTypography type="s-strong">
            {itemToRemove?.projectName}
          </WppTypography>
          ?
        </span>
        <span className="modal-actions" slot="actions">
          <WppButton
            variant="destructive"
            size="s"
            onClick={() => doDelete(itemToRemove)}
          >
            Delete
          </WppButton>
          <WppActionButton
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </WppActionButton>
        </span>
      </WppModal>
      {didFilter && !isLoading && !error && !isEmpty && (
        <div className="title">
          <WppTypography tag="h2" type="l-strong">
            Search Results
          </WppTypography>
        </div>
      )}
      {(!didFilter || isEmpty) && !isLoading && !error && (
        <p>
          Please change the Filters above and click <b>FILTER RESULTS</b> to
          update the Projects shown.
        </p>
      )}
      {!isLoading && error.length > 1 && (
        <div className="table-error">{error}</div>
      )}
      {!error && isLoading && <LoadingElement />}
      {didFilter && !error && !isLoading && !isEmpty && (
        <div className="table-container">
          {Object.keys(data).map(
            (status) =>
              data[status]?.length > 0 && (
                <div
                  className={`status-section ${status.toLowerCase()}`}
                  key={`status-section-${status.toLowerCase()}`}
                >
                  {getStatusSection(status)}
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
}
