import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IDatasetStore, IRequestDatasets } from './types';
import { dswRequest } from './index';
import { API_BASE_URL } from '../config/config';

export const initialState: IDatasetStore = {
  datasets: [],
  requestDatasets: {
    AMER: {
      isLoading: false,
      data: [],
    },
    APAC: {
      isLoading: false,
      data: [],
    },
    EMEA: {
      isLoading: false,
      data: [],
    },
  },
  additionalDatasets: {
    isLoading: false,
    data: [],
  },
  isLoading: false,
  actions: {
    getRequestDatasetApi: () =>
      Promise.resolve({
        AMER: {
          isLoading: false,
          data: [],
        },
        APAC: {
          isLoading: false,
          data: [],
        },
        EMEA: {
          isLoading: false,
          data: [],
        },
      }),
    getAdditionalDatasetApi: () => Promise.resolve(),
    getDatasetApi: () => Promise.resolve(),
  },
};

export const useDatasetStore = create<IDatasetStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getRequestDatasetApi: async (
          agency,
          region,
          reason
        ): Promise<IRequestDatasets> => {
          const { requestDatasets } = get();
          const { isLoading } = requestDatasets[region];
          if (!isLoading) {
            set((state) => ({
              ...state,
              requestDatasets: {
                ...state.requestDatasets,
                [region]: {
                  ...state.requestDatasets[region],
                  isLoading: true,
                },
              },
            }));
            try {
              const country = `ALL-${region}`;
              const params = `agency=${agency}&region=${region}&reason=${reason}&country=${country}`;
              const url = `${API_BASE_URL}/dataset?${params}`;
              const apiResponse = await dswRequest.get(url);
              set((state) => ({
                ...state,
                requestDatasets: {
                  ...state.requestDatasets,
                  [region]: {
                    ...state.requestDatasets[region],
                    data: apiResponse.data,
                  },
                },
              }));
            } finally {
              set((state) => ({
                ...state,
                requestDatasets: {
                  ...state.requestDatasets,
                  [region]: {
                    ...state.requestDatasets[region],
                    isLoading: false,
                  },
                },
              }));
            }
          }
          return get().requestDatasets;
        },
        getAdditionalDatasetApi: async (agency, region, projectName) => {
          const { additionalDatasets } = get();
          const { isLoading } = additionalDatasets;
          if (!isLoading) {
            set((state) => ({
              ...state,
              additionalDatasets: {
                ...state.additionalDatasets,
                isLoading: true,
              },
            }));
            try {
              const params = `agencyName=${agency}&regionName=${region}&projectName=${projectName}`;
              const url = `${API_BASE_URL}/project/additional-dataset?${params}`;
              const apiResponse = await dswRequest.get(url);
              if (!apiResponse.error) {
                set((state) => ({
                  ...state,
                  additionalDatasets: {
                    ...state.additionalDatasets,
                    data: [...apiResponse.data],
                  },
                }));
              }
            } finally {
              set((state) => ({
                ...state,
                additionalDatasets: {
                  ...state.additionalDatasets,
                  isLoading: false,
                },
              }));
            }
          }
        },
        getDatasetApi: async () => {
          const { datasets, isLoading } = get();
          if (datasets.length === 0 && !isLoading) {
            set((state) => ({
              ...state,
              isLoading: true,
            }));
            try {
              const url = `${API_BASE_URL}/dataset/all`;
              const apiResponse = await dswRequest.get(url);
              set((state) => ({
                ...state,
                datasets: apiResponse.data,
              }));
            } finally {
              set((state) => ({
                ...state,
                isLoading: false,
              }));
            }
          }
        },
      },
    }))
  )
);
