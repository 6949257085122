import Choreograph from '../assets/images/agencies/Choreograph.jpg';
import Essencemediacom from '../assets/images/agencies/Essencemediacom.jpg';
import GroupMFinecast from '../assets/images/agencies/GroupM-Finecast.jpg';
import GroupMNexus from '../assets/images/agencies/GroupM-Nexus.jpg';
import GroupMOther from '../assets/images/agencies/GroupM-Other.jpg';
import GroupMXaxis from '../assets/images/agencies/GroupM-Xaxis.jpg';
import Mindshare from '../assets/images/agencies/Mindshare.jpg';
import mSixPartners from '../assets/images/agencies/mSix-Partners.jpg';
import Wavemaker from '../assets/images/agencies/Wavemaker.jpg';

export const AgencyLogos = {
  Choreograph,
  Essencemediacom,
  GroupMFinecast,
  GroupMNexus,
  GroupMOther,
  GroupMXaxis,
  Mindshare,
  mSixPartners,
  Wavemaker,
};

export enum RoleEnum {
  'TEAM_LEAD' = 'Team Lead',
  'ADMIN' = 'Admin',
  'CHIP_ADMIN' = 'Chip Admin',
  'USER' = 'User',
  'APPROVER' = 'Approver',
  'NEW_USER' = 'New User',
}

export enum TimespanSelectionEnum {
  'ALL_ITEMS' = 'all_items',
  'LAST_YEAR' = 'last_year',
  'THIS_YEAR' = 'this_year',
  'Q1' = 'q1',
  'Q2' = 'q2',
  'Q3' = 'q3',
  'Q4' = 'q4',
}

export enum NotebookPhaseEnum {
  READY = 'READY',
  WAITING = 'WAITING',
  STOPPED = 'STOPPED',
}

export enum PagesEnum {
  'MAIN_PAGE' = 'main',
  'DATA_SETS_AND_DATA_CATALOGS' = 'data',
  'TUTORIALS_AND_DOCUMENTATIONS' = 'tutorials',
  'DASHBOARD' = 'dashboard',
  'ACCESS_REQUEST_AND_SUPPORT' = 'request',
}

export enum AccessKeys {
  'NOTEBOOKREAD' = 'notebookRead',
  'DASHBOARD' = 'dashboards',
  'CHIP_SEGMENT' = 'chipSegmentManager',
  'DATASETS' = 'addDataSets',
  'ACCESS' = 'addAccessRequest',
  'TEAM_MEMBERS' = 'addTeamMembers',
  'MAIN' = 'main',
  'DASHBOARD_MANAGEMENT' = 'dashboardManagement',
}

export enum DashboardPagesEnum {
  EMPTY = 'dashboard',
  METRICS_AND_REPORTS = 'metrics-and-reports',
  PROJECT_REGISTRY = 'project',
  REQUEST_MANAGEMENT = 'request',
  QUALITY_OF_SERVICE = 'quality',
  COST_MONITORING = 'cost',
  CHIP_SEGMENT_MANAGER = 'segment-manager',
}

export enum AccessPagesEnum {
  ACCESS = 'request/access',
  DATASETS = 'request/dataset',
  TEAM = 'request/team',
}

export enum MetricsAndReportingPagesEnum {
  EMPTY = 'metrics-and-reports/',
  KEY_PLATFORM_METRICS = 'metrics-and-reports/key-platform-metrics',
  GROWTH_METRICS = 'metrics-and-reports/growth-metrics',
  QUICK_REPORTS = 'metrics-and-reports/quick-reports',
  COST_REPORTING = 'metrics-and-reports/cost-reporting',
}

export enum EQuickReportTypes {
  DATASET = 'dataset-usage',
  USER_AGENCY = 'total-user-per-agency-regionwise',
  PROJECT_AGENCY = 'project-type-per-agency',
  PROJECT_REGION = 'regionwise-project-per-project-type',
  AGENCY_COST_PER_REGION = 'agency-cost-distribution-per-region',
  AGENCY_COST_PER_PROJECT_TYPE = 'agency-cost-distribution-per-project-type',
}

export enum MethodsEnum {
  CREATE = 'POST',
  READ = 'GET',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const SELECT_EMPTY_FIELD = '%EMPTY%';

export enum EFeatureToggle {
  GROWTH_NOTEBOOKS = 'growthNotebooks',
  PROJECT_REGISTRY_DELETE = 'projectRegistryDelete',
  PROJECT_REGISTRY_EXTEND = 'projectRegistryExtend',
  PROJECT_REGISTRY_REVIEW_DATE = 'projectRegistryReviewDate',
  QUICK_REPORT_EXPORT = 'quickReportExport',
  SETTINGS = 'settings',
  TECHNICAL_ISSUES = 'technicalIssues',
  DATA_CATALOGUES_ADM_LINK = 'dataCataloguesADMLink',
}

export enum SegmentColumnNames {
  ID = 'segmentIdDb',
  SEGMENT_ID = 'segmentId',
  SEGMENT_NAME = 'segmentName',
  DESCRIPTION = 'description',
  CREATED_BY = 'createdBy',
  DATE_CREATED = 'createdDate',
  EXPORT_TO_NGPi = 'exportToNgpi',
  SALESFORCE_ACCOUNT = 'sfAccounts',
}

export enum SegmentColumnDisplayNames {
  ID = 'Id',
  SEGMENT_ID = 'Segment Id',
  SEGMENT_NAME = 'Segment Name',
  DESCRIPTION = 'Description',
  CREATED_BY = 'Created By',
  DATE_CREATED = 'Date Created',
  EXPORT_TO_NGPi = 'Export to NGPi',
  SALESFORCE_ACCOUNT = 'Sales Force Accounts',
}

export const TOAST_NOTIFICATION_TIMEOUT = 4000;

export enum RequestStatusType {
  'DONE' = 'Approved',
  'INPROGRESS' = 'In Progress',
  'PENDING' = 'Pending',
}

export const RequestStatusOrder = ['DONE', 'PENDING', 'INPROGRESS'];

export const REQUEST_STATUS_EDITABLE = 'INPROGRESS';

export const DEMO_NAMESPACE = 'demo-dsw';
export const DEMO_REQUEST_REASON = 'demo';
export const DEMO_AGENCY = 'Choreograph';
export const DEMO_REGION = 'AMER';
