import React from 'react';
import {
  WppInput,
  WppListItem,
  WppSelect,
} from '@platform-ui-kit/components-library-react';
import { IAccessRequestFormData } from '../types';
import { ISimpleAgency, ISimpleIdName } from '../../../stores/types';

export interface IUserDetailsParams {
  agencies: ISimpleAgency[];
  countries: ISimpleIdName[];
  get: () => IAccessRequestFormData;
  set: (field: keyof IAccessRequestFormData, key, value) => void;
}

function UserDetails(params: IUserDetailsParams) {
  const { agencies, countries, get, set } = params;
  const { user } = get();

  const setAgency = ({ detail: { value } }) => set('user', 'agency', value);
  const setCountry = ({ detail: { value } }) => set('user', 'country', value);

  return (
    <section className="centered user-details">
      <span className="row-1">
        <span className="col-1">
          <span className="label">Name & Surname</span>
        </span>
        <span className="col-1">
          <WppInput disabled value={user.name} />
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">
          <span className="label">E-mail</span>
        </span>
        <span className="col-1">
          <WppInput disabled value={user.email} />
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">
          <span className="label">Agency</span>
        </span>
        <span className="col-1">
          <WppSelect value={user.agency} onWppChange={setAgency} withSearch>
            {agencies.map(({ name }) => (
              <WppListItem key={`user-agency-${name}`} value={name}>
                <p slot="label">{name}</p>
              </WppListItem>
            ))}
          </WppSelect>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">
          <span className="label">Country</span>
        </span>
        <span className="col-1">
          <WppSelect value={user.country} onWppChange={setCountry} withSearch>
            {countries.map(({ name }) => (
              <WppListItem key={`user-country-${name}`} value={name}>
                <p slot="label">{name}</p>
              </WppListItem>
            ))}
          </WppSelect>
        </span>
      </span>
    </section>
  );
}

export default UserDetails;
