import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { ISettingsStore } from './types';
import { API_BASE_URL } from '../config/config';
import { dswRequest } from './index';

export const initialState: ISettingsStore = {
  settings: null,
  loading: false,
  error: null,
  actions: {
    getSettings: () => undefined,
    setSettings: () => undefined,
  },
};

export const useSettingsStore = create<ISettingsStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getSettings: async (forceReload = false) => {
          const { loading, settings } = get();
          if ((forceReload || settings === null) && !loading) {
            set((state) => ({ ...state, loading: true }));
            try {
              const url = `${API_BASE_URL}/settings`;
              const { data, error } = await dswRequest.get(url);
              set((state) => ({
                ...state,
                error: error?.message || null,
                settings: data || {},
              }));
            } catch (e) {
              set((state) => ({
                ...state,
                error: e.toString() || null,
                settings: {},
              }));
            } finally {
              set((state) => ({
                ...state,
                loading: false,
              }));
            }
          }
        },
        setSettings: async (settings, callback) => {
          try {
            const url = `${API_BASE_URL}/settings`;
            const { data, error } = await dswRequest.post(url, settings);
            set((state) => ({
              ...state,
              error: error?.message || null,
              settings: data || {},
            }));
          } catch (e) {
            set((state) => ({
              ...state,
              error: e.toString() || null,
              settings,
            }));
          } finally {
            callback(!get().error);
          }
        },
      },
    }))
  )
);
