import React from 'react';
import type { RouteObject } from 'react-router-dom';
import * as ROUTE_PATHS from './routePaths';
import LoginRedirect from '../components/Login/LoginRedirect';
import Home from '../pages/Home';
import { RequireAuth, Login } from '../pages/RequireAuth';

import Main from '../pages/MainPage/MainPage';
import Dashboard from '../pages/Dashboard';
import AccessRequestsAndSupport from '../pages/AccessRequestsAndSupport';
import TutorialsAndDocumentations from '../pages/TutorialsAndDocumentations';
import DatasetsAndCatalogs from '../pages/DatasetsAndCatalogs';

import RequestManagement from '../pages/Dashboard/RequestManagement';
import ProjectRegistry from '../pages/Dashboard/ProjectRegistry';
import MetricsAndReports, {
  DashboardEmpty,
} from '../pages/Dashboard/MetricsAndReports';
import ChipRequestManager from '../pages/Dashboard/ChipSegmentManager';

import CostReporting from '../pages/Dashboard/MetricsAndReports/CostReporting';
import KeyPlatformMetrics from '../pages/Dashboard/MetricsAndReports/KeyPlatformMetrics/KeyPlatformMetrics';
import GrowthMetrics from '../pages/Dashboard/MetricsAndReports/GrowthMetrics/GrowthMetrics';
import QuickReports from '../pages/Dashboard/MetricsAndReports/QuickReports';

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
    children: [
      {
        path: ROUTE_PATHS.PagesEnum.MAIN_PAGE,
        element: <Main />,
      },
      {
        path: `${ROUTE_PATHS.PagesEnum.ACCESS_REQUEST_AND_SUPPORT}/*`,
        element: <AccessRequestsAndSupport />,
      },
      {
        path: `${ROUTE_PATHS.PagesEnum.NO_ACCESS}/*`,
        element: <AccessRequestsAndSupport />,
      },
      {
        path: `${ROUTE_PATHS.PagesEnum.TUTORIALS_AND_DOCUMENTATIONS}/*`,
        element: <TutorialsAndDocumentations />,
      },
      {
        path: `${ROUTE_PATHS.PagesEnum.DASHBOARD}`,
        element: <Dashboard />,
        children: [
          {
            path: `${ROUTE_PATHS.DashboardPagesEnum.METRICS_AND_REPORTS}`,
            element: <MetricsAndReports />,
            children: [
              {
                path: `${ROUTE_PATHS.MetricsAndReportingPagesEnum.KEY_PLATFORM_METRICS}`,
                element: <KeyPlatformMetrics />,
              },
              {
                path: `${ROUTE_PATHS.MetricsAndReportingPagesEnum.GROWTH_METRICS}`,
                element: <GrowthMetrics />,
              },
              {
                path: `${ROUTE_PATHS.MetricsAndReportingPagesEnum.QUICK_REPORTS}`,
                element: <QuickReports />,
              },
              {
                path: `${ROUTE_PATHS.MetricsAndReportingPagesEnum.COST_REPORTING}`,
                element: <CostReporting />,
              },
              {
                path: '*',
                element: <DashboardEmpty />,
              },
            ],
          },
          {
            path: `${ROUTE_PATHS.DashboardPagesEnum.PROJECT_REGISTRY}`,
            element: <ProjectRegistry />,
          },
          {
            path: `${ROUTE_PATHS.DashboardPagesEnum.REQUEST_MANAGEMENT}/*`,
            element: <RequestManagement />,
          },
          {
            path: `${ROUTE_PATHS.DashboardPagesEnum.CHIP_SEGMENT_MANAGER}`,
            element: <ChipRequestManager />,
          },
          {
            path: '*',
            element: <DashboardEmpty />,
          },
        ],
      },
      {
        path: `${ROUTE_PATHS.PagesEnum.DATA_SETS_AND_DATA_CATALOGS}/*`,
        element: <DatasetsAndCatalogs />,
      },
    ],
  },
  {
    path: ROUTE_PATHS.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTE_PATHS.LOGIN_CALLBACK,
    element: <LoginRedirect />,
  },
  {
    path: ROUTE_PATHS.LOGIN_CALLBACK_ROOT,
    element: <LoginRedirect />,
  },
];

export default routes;
