import React, { useEffect, useState } from 'react';
import { WppSpinner } from '@platform-ui-kit/components-library-react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi } from 'ag-grid-community';
import { toHumanReadableSize, toTitleCase } from '../../../utils';
import { IDBStatsTables } from '../../../stores/types';
import { loadingOverlay, noDataOverlay } from '../index';

import './index.scss';

export interface IOverviewParams {
  overviewData: {
    databases: string[];
    schemas: string[];
    tables: number;
    columns: number;
    rows: number;
    size: number;
  };
  tables: IDBStatsTables;
}

export default function Overview(params: IOverviewParams) {
  const { overviewData, tables } = params;
  const { data, loading, error } = tables;
  const [gridApi, setGridApi] = useState<GridApi>(null);

  useEffect(() => {
    if (gridApi) {
      if (loading) {
        // Temporary fix of ag-grid-react behaviour
        setTimeout(() => gridApi.showLoadingOverlay());
      } else if (!data || data.length === 0) {
        gridApi.showNoRowsOverlay();
      } else if (data && data.length > 0) {
        setTimeout(() => gridApi.hideOverlay(), 250);
        gridApi.setFilterModel(null);
      }
    }
  }, [gridApi, loading, data]);

  const getOverviewValue = (key, val) => {
    const isByte = key.indexOf('size') > -1;
    let ret;
    if (typeof val === 'object') {
      ret = `${val.length}`;
    } else {
      ret = toHumanReadableSize(val, !isByte, 2);
    }
    return `${ret}${isByte ? 'B' : ''}`;
  };

  const statRenderer = (cellRenderParams) => {
    let field;
    switch (cellRenderParams.colDef.field) {
      case 'COLUMN_COUNT':
        field = 'columns';
        break;
      case 'ROW_COUNT':
        field = 'rows';
        break;
      case 'BYTES':
        field = 'size';
        break;
      default:
        field = '';
    }
    let valueStyle = {};
    let totalStyle = {};
    if (field && cellRenderParams.value && overviewData[field]) {
      const width = cellRenderParams.value / overviewData[field];
      valueStyle = {
        width: `${width * 100}%`,
      };
      totalStyle = {
        width: `${100 - width * 100}%`,
      };
    }
    return (
      <span className="stat-cell">
        <span className="value">
          {toHumanReadableSize(cellRenderParams.value, field !== 'size', 2)}
          {field === 'size' ? 'B' : ''}
        </span>
        <span className="stat">
          <span className="value" style={valueStyle} />
          <span className="total" style={totalStyle} />
        </span>
      </span>
    );
  };

  const simpleTitleRenderer = (cellRenderParams) => (
    <span title={cellRenderParams.value}>{cellRenderParams.value}</span>
  );

  const columnDefs: ColDef[] = [
    {
      field: 'TABLE_NAME',
      headerName: 'Table',
      width: 500,
      sort: 'asc',
      filter: 'agTextColumnFilter',
      cellRenderer: simpleTitleRenderer,
    },
    {
      field: 'SCHEMA_NAME',
      headerName: 'Schema',
      width: 250,
      cellRenderer: simpleTitleRenderer,
    },
    {
      field: 'DB_NAME',
      headerName: 'Database',
      width: 250,
      cellRenderer: simpleTitleRenderer,
    },
    {
      field: 'ROW_COUNT',
      headerName: 'Rows',
      width: 90,
      cellStyle: { textAlign: 'right' },
      cellRenderer: statRenderer,
    },
    {
      field: 'COLUMN_COUNT',
      headerName: 'Columns',
      width: 90,
      cellStyle: { textAlign: 'right' },
      cellRenderer: statRenderer,
    },
    {
      field: 'BYTES',
      headerName: 'Size',
      width: 100,
      cellStyle: { textAlign: 'right' },
      cellRenderer: statRenderer,
    },
    {
      field: 'CREATED',
      headerName: 'Created',
      width: 100,
      cellRenderer: (cellRenderParams) =>
        cellRenderParams.value
          ? new Date(cellRenderParams.value).toISOString().split('T').shift()
          : 'N/A',
    },
    {
      field: 'TABLE_TYPE',
      headerName: 'Table Type',
      width: 120,
    },
    {
      field: 'TABLE_OWNER',
      headerName: 'Owner',
      width: 500,
      cellRenderer: (cellRenderParams) => cellRenderParams.value || 'n/a',
    },
  ];

  return (
    <section className="overview">
      <section className="overview-info">
        {Object.entries(overviewData).map(([key, val]) => (
          <div className="overview-item" key={key}>
            <span className="overview-value">
              {loading ? <WppSpinner /> : getOverviewValue(key, val)}
            </span>
            <span className="overview-label">{toTitleCase(key)}</span>
          </div>
        ))}
      </section>
      {error ? (
        <section className="error">{error}</section>
      ) : (
        <section className="overview-grid ag-theme-wpp">
          <AgGridReact
            alwaysShowHorizontalScroll
            alwaysShowVerticalScroll
            onGridReady={(gridReadyEvent) => {
              setGridApi(gridReadyEvent.api);
            }}
            rowData={loading ? null : data}
            columnDefs={columnDefs}
            defaultColDef={{
              sortable: true,
              resizable: true,
              cellStyle: { textAlign: 'left' },
              menuTabs: ['filterMenuTab'],
            }}
            animateRows
            columnHoverHighlight
            suppressPaginationPanel
            loadingOverlayComponent={loadingOverlay}
            noRowsOverlayComponent={noDataOverlay}
          />
        </section>
      )}
    </section>
  );
}
