import {
  IAccessRequestFormData,
  IDatasetsFormData,
  ITeamFormData,
} from '../../pages/AccessRequestsAndSupport/types';
import { RoleEnum } from '../../constants';

export function mapAccessRequestForm(data: IAccessRequestFormData) {
  const {
    user,
    project,
    team,
    agencyContact,
    datasets: ds,
    commentsByRegion,
  } = data;
  const datasetsByRegion = {};
  ds.forEach((dataset) => {
    if (!datasetsByRegion[dataset.region]) {
      datasetsByRegion[dataset.region] = [];
    }
    datasetsByRegion[dataset.region] = [
      ...datasetsByRegion[dataset.region],
      dataset,
    ];
  });
  return {
    user_details: {
      agency: user.agency,
      country: user.country,
    },
    request_details: {
      request_reason: project.reason,
      namespace: `${project.reason.toLowerCase()}-${project.name.toLowerCase()}`,
      agency: project.company,
      regions: project.regions,
    },
    agency_contact_person_details: {
      name: agencyContact.name,
      email: agencyContact.email,
      is_changed: false,
    },
    team_detail_list: team.map(({ email, name, region, role }) => ({
      name,
      email,
      is_lead: role === RoleEnum.TEAM_LEAD,
      is_contact_person: email === agencyContact.email,
      role,
      region,
      is_deleted: false,
    })),
    data_access_detail_list: Object.keys(datasetsByRegion).map(
      (instance_region) => {
        const datasets = datasetsByRegion[instance_region];
        const aoIdDatasets = datasets.filter(({ aoIds }) => aoIds);
        const aoIdDataset =
          aoIdDatasets.length > 0
            ? aoIdDatasets.pop()
            : { aoIds: false, useCase: '' };
        const dataset_region_map = {};
        datasets.forEach((d) => {
          if (!dataset_region_map[d.source]) {
            dataset_region_map[d.source] = [];
          }
          dataset_region_map[d.source] = [
            ...dataset_region_map[d.source],
            { region: d.dataset, is_deleted: false },
          ];
        });
        const comments = commentsByRegion[instance_region];
        return {
          dataset_region_map,
          instance_region,
          ao_id: aoIdDataset.aoIds,
          use_case_text: aoIdDataset.useCase,
          comments,
        };
      }
    ),
  };
}

export interface IMappedRequestForm {
  namespaceName: string;
  agencyName: string;
  regionName: string;
}

export interface IMappedDatasetForm extends IMappedRequestForm {
  additionalDatasetList: { [key: string]: string[] };
  comments: string;
  aoId?: boolean;
  useCaseText?: string;
}

export interface IMappedTeamForm extends IMappedRequestForm {
  additionalTeamMemberList: { name: string; email: string; role: RoleEnum }[];
}

export function mapDatasetForm(data: IDatasetsFormData): IMappedDatasetForm {
  const additionalDatasetList = {};
  data.datasets.forEach(({ name: datasetName, region }) => {
    if (!additionalDatasetList[datasetName]) {
      additionalDatasetList[datasetName] = [];
    }
    additionalDatasetList[datasetName].push(region);
  });
  const mappedDatasetForm = {
    namespaceName: data.project.projectName,
    agencyName: data.project.agencyName,
    regionName: data.project.regionName,
    additionalDatasetList,
    comments: data.comments,
  };
  if (
    data.datasets.filter(({ name }) => name?.indexOf('Audience Origin') > -1)
      .length === 1
  ) {
    return {
      ...mappedDatasetForm,
      aoId: data.aoIds,
      useCaseText: data.useCaseText,
    };
  }
  return mappedDatasetForm;
}

export function mapTeamForm(data: ITeamFormData): IMappedTeamForm {
  return {
    namespaceName: data.project.projectName,
    agencyName: data.project.agencyName,
    regionName: data.project.regionName,
    additionalTeamMemberList: data.team.map(({ name, email }) => ({
      name,
      email,
      role: RoleEnum.USER,
    })),
  };
}
