import React from 'react';
import { WppButton } from '@platform-ui-kit/components-library-react';

import logo from '../../assets/images/Choreograph-Black-RGB.png';

import './index.scss';

export default function ErrorPage(error) {
  const {
    code = '403',
    name = '',
    message = 'User is not assigned to the client application.',
  } = error;
  return (
    <div className="error-page">
      <div className="header">
        <img alt="logo" src={logo} />
        <span className="product">Data Science - Enablement Dashboard</span>
      </div>
      <div className="error">
        <p className="error-code">{code}</p>
        <p className="error-message">{name || message}</p>
        <p className="error-text">
          WE&apos;RE SORRY, YOUR REQUEST IS UNAUTHORIZED
        </p>
        <p className="error-hint">
          Looks like you don’t have access to the{' '}
          <span className="wpp">Data Science - Enablement Dashboard</span> yet.
          <br />
          You can click <span className="wpp">Contact Support</span> button
          below to request access.
        </p>
        <p className="error-actions">
          <WppButton
            className="width-300"
            title="Contact Support"
            onClick={() => {
              window.open(
                'https://chos-help.choreograph.com/hc/en-us/requests/new',
                '_blank'
              );
            }}
          >
            Contact Support
          </WppButton>
        </p>
      </div>
      <div className="footer">
        <span className="copy">
          &copy; Choreograph, {new Date().getFullYear()} - All rights reserved
        </span>
      </div>
    </div>
  );
}
