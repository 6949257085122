import React, { useEffect, useState } from 'react';
import {
  WppAvatar,
  WppButton,
  WppIconCopy,
  WppIconInfo,
  WppListItem,
  WppSelect,
  WppTypography,
} from '@platform-ui-kit/components-library-react';

import { useRoleStore } from '../../stores/role';
import { useRequestStore } from '../../stores/request';
import { doValidateAccess } from './validation';
import useToast from '../../hooks/useToast';
import { copyToClipboard, noSelectListItems } from '../../utils';
import {
  PagesEnum,
  RoleEnum,
  AgencyLogos,
  DEMO_NAMESPACE,
  DEMO_AGENCY,
  DEMO_REGION,
} from '../../constants';
import DatasetsForm from './DatasetsForm';
import TeamForm from './TeamForm';
import DemoAccessForm from './DemoAccessForm';
import { useRegistryStore } from '../../stores/registry';
import { useAgencyStore } from '../../stores/agency';
import { useRegionStore } from '../../stores/region';
import { useNavigationStore } from '../../stores/navigation';
import { IRegistryFilters } from '../Dashboard/ProjectRegistry/FilterBar';

import AccessRequestForm from './AccessRequestForm';

import './index.scss';
import { useGlobalStore } from '../../stores/global';

export function RequestDefaultFields(params) {
  const { showErrors, formData, setFormData, initialState, fromDSWDemoAccess } =
    params;
  const {
    projects: projectsData,
    isLoading: registryLoading,
    loadingItems: registryLoadingItems,
    actions: {
      getDataApi,
      getProjectsByRegionAndAgencyApi,
      getDetailsApi,
      reset,
    },
  } = useRegistryStore();
  const {
    simpleAgenciesByUserMail: agencies,
    loadingAgencies: agenciesLoading,
    actions: { getSimpleAgenciesByUserMailApi },
  } = useAgencyStore();
  const {
    regions,
    regionsByAgency,
    isLoading: regionsLoading,
    actions: { getRegionApi, getRegionsByAgencyNameApi, reset: resetRegions },
  } = useRegionStore();

  const userInfo = useGlobalStore((state) => state.userInfo);

  useEffect(() => {
    getSimpleAgenciesByUserMailApi(true).then();
    getRegionApi().then();
  }, [
    getSimpleAgenciesByUserMailApi,
    getRegionApi,
    getDataApi,
    userInfo.email,
  ]);

  const projects = fromDSWDemoAccess
    ? [
        {
          id: 0,
          projectName: DEMO_NAMESPACE,
          agencyName: DEMO_AGENCY,
          regionName: DEMO_REGION,
          activeSince: '0000-00-00 00:00:00',
        },
      ]
    : [...projectsData];

  const setAgency = ({ detail: { value } }) => {
    setFormData({
      ...initialState,
      agency: value,
      datasets: initialState.datasets,
    });
    reset();
    resetRegions();
    getRegionsByAgencyNameApi(value.name, userInfo.email).then();
  };
  const setRegion = ({ detail: { value } }) => {
    setFormData({
      ...initialState,
      region: value,
      agency: formData.agency,
      datasets: initialState.datasets,
    });
    reset();
    if (value && formData.agency?.name) {
      const registryFilters = {
        agencyName: formData.agency.name,
        region: value.name,
      } as IRegistryFilters;
      getProjectsByRegionAndAgencyApi(registryFilters).then();
    }
  };
  const setProject = ({ detail: { value } }) => {
    setFormData({
      ...formData,
      project: value,
      datasets: initialState.datasets,
    });
    getDetailsApi(
      {
        projectName: value.name,
        agencyName: formData.agency.name,
        regionName: formData.region.name,
        ...value,
      },
      'Active'
    ).then();
  };

  return fromDSWDemoAccess ? (
    <>
      <span className="row-1">
        <span className="col-1">
          <span className="label">Agency</span>
        </span>
        <span className="col-1">
          <WppSelect
            value={agencies.find((agency) => agency.name === DEMO_AGENCY)}
            disabled
          >
            {agencies.length > 0 &&
              agencies
                .filter((agency) => agency.name === DEMO_AGENCY)
                .map((agency) => (
                  <WppListItem key={`agency-${agency.id}`} value={agency}>
                    <p slot="label" style={{ display: 'flex' }}>
                      <WppAvatar
                        name={agency.name}
                        tooltipConfig={{
                          placement: 'bottom',
                        }}
                        withTooltip
                        size="xs"
                        style={{ marginRight: '10px' }}
                        src={AgencyLogos[agency.name.replace('-', '')]}
                      />
                      {agency.name}
                    </p>
                  </WppListItem>
                ))}
            {agencies.length <= 0 && noSelectListItems('No Agencies available')}
          </WppSelect>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">
          <span className="label">Region</span>
        </span>
        <span className="col-1">
          <WppSelect
            value={regions.find((region) => region.name === 'AMER')}
            disabled
          >
            {regions.length > 0 &&
              regions
                .filter((region) => region.name === 'AMER')
                .map((region) => (
                  <WppListItem key={`project-${region.id}`} value={region}>
                    <p slot="label" style={{ display: 'flex' }}>
                      {region.name}
                    </p>
                  </WppListItem>
                ))}
            {regionsByAgency.length <= 0 &&
              noSelectListItems('No Regions available')}
          </WppSelect>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">
          <span className="label">Project</span>
        </span>
        <span className="col-1">
          <WppSelect value={projects[0]} disabled>
            {projects.length > 0 &&
              projects.map((project) => (
                <WppListItem key={`project-${project.id}`} value={project}>
                  <p slot="label" style={{ display: 'flex' }}>
                    {project.projectName}
                  </p>
                </WppListItem>
              ))}
            {projects.length <= 0 && noSelectListItems('No Projects available')}
          </WppSelect>
        </span>
      </span>
    </>
  ) : (
    <>
      <span className="row-1">
        <span className="col-1">
          <span className="label">Step 1: Select Agency</span>
        </span>
        <span className="col-1">
          <WppSelect
            value={formData.agency}
            onWppChange={setAgency}
            messageType={showErrors && !formData.agency ? 'error' : null}
            disabled={agenciesLoading}
            withSearch
          >
            {agencies.length > 0 &&
              agencies.map((agency) => (
                <WppListItem key={`agency-${agency.id}`} value={agency}>
                  <p slot="label" style={{ display: 'flex' }}>
                    <WppAvatar
                      name={agency.name}
                      tooltipConfig={{
                        placement: 'bottom',
                      }}
                      withTooltip
                      size="xs"
                      style={{ marginRight: '10px' }}
                      src={AgencyLogos[agency.name.replace('-', '')]}
                    />
                    {agency.name}
                  </p>
                </WppListItem>
              ))}
            {agencies.length <= 0 && noSelectListItems('No Agencies available')}
          </WppSelect>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">
          <span className="label">Step 2: Select Region</span>
        </span>
        <span className="col-1">
          <WppSelect
            value={formData.region}
            onWppChange={setRegion}
            messageType={showErrors && !formData.region ? 'error' : null}
            disabled={regionsLoading || !formData.agency?.id}
            withSearch
          >
            {regionsByAgency.length > 0 &&
              regionsByAgency.map((region) => (
                <WppListItem key={`project-${region.id}`} value={region}>
                  <p slot="label" style={{ display: 'flex' }}>
                    {region.name}
                  </p>
                </WppListItem>
              ))}
            {regionsByAgency.length <= 0 &&
              noSelectListItems('No Regions available')}
          </WppSelect>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">
          <span className="label">Step 3: Select Project</span>
        </span>
        <span className="col-1">
          <WppSelect
            value={formData.project}
            onWppChange={setProject}
            messageType={showErrors && !formData.project ? 'error' : null}
            disabled={
              registryLoading ||
              registryLoadingItems.length > 0 ||
              !formData.agency?.id ||
              !formData.region?.name
            }
            withSearch
          >
            {projects.length > 0 &&
              projects.map((project) => (
                <WppListItem key={`project-${project.id}`} value={project}>
                  <p slot="label" style={{ display: 'flex' }}>
                    {project.projectName}
                  </p>
                </WppListItem>
              ))}
            {projects.length <= 0 && noSelectListItems('No Projects available')}
          </WppSelect>
        </span>
      </span>
    </>
  );
}

function NoPermissionContent() {
  return (
    <>
      <h2>OOPS! YOU NEED ACCESS FOR THIS!</h2>
      <p>
        Looks like you don’t have access to DS Workbench yet.
        <br />
        You can click &quot;Request access to DS Workbench&quot; button below to
        request access.
      </p>
      <p>
        <b>NOTE:</b> The Access Request Form is currently automated for GroupM
        agencies.
        <br />
        If you are not part of GroupM and still want to get access, please fill
        out this form:
        <br />
        <a href="https://forms.office.com/e/LggBSVbbdB">
          https://forms.office.com/e/LggBSVbbdB
        </a>
      </p>
      <p>
        Your submission will be reviewed by the relevant teams and necessary
        configurations will be made.
        <br />
        You&apos;ll be informed afterwards.
      </p>
    </>
  );
}

function SuccessPage(params) {
  const {
    actions: { changeCurrentPage },
  } = useNavigationStore();
  const { parentRequestId } = params;
  return (
    <div className="success-page">
      <h3>Your submission is taken and is in review!</h3>
      <WppTypography
        type="m-strong"
        tag="h3"
        onClick={() => copyToClipboard(`${parentRequestId}`)}
        style={{ cursor: 'pointer' }}
      >
        <span title={`${parentRequestId}`}>
          <WppIconCopy /> Request-ID {parentRequestId}
        </span>
      </WppTypography>
      <p>
        Your submission will be reviewed by the Data Science - Enablement
        Dashboard product team & relevant
        <br />
        data owners and necessary configurations will be made. This can take a
        few days. You’ll be informed afterwards.
      </p>
      <p>
        In the meantime, you can familiarise yourself with the Data Science -
        Enablement Dashboard
        <br />
        through the{' '}
        <WppTypography
          type="m-strong"
          tag="span"
          onClick={() =>
            changeCurrentPage(PagesEnum.TUTORIALS_AND_DOCUMENTATIONS)
          }
          style={{ cursor: 'pointer' }}
        >
          <span className="highlight">Tutorials & Documentations</span>
        </WppTypography>{' '}
        section.
      </p>
      <br />
    </div>
  );
}

function EmptyPage() {
  return (
    <div
      className="empty-page"
      style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
    >
      <div
        style={{
          textAlign: 'left',
          margin: '0 auto 2rem',
          flex: 1,
        }}
      >
        <h3 style={{ textAlign: 'center' }}>Access Requests & Support</h3>
        <span className="bold">Please select your next action:</span>
        <br />
        <br />
        &bull; Please select{' '}
        <span className="highlight bold">Request DSW Demo Access</span> to
        request a new DS Workbench demo environment.
        <br />
        &bull; Please select{' '}
        <span className="highlight bold">Create a new Project</span> to request
        a new DS Workbench work environment.
        <br />
        &bull; Please select{' '}
        <span className="highlight bold">Request Additional Datasets</span> to
        add new datasets to an already existing project.
        <br />
        &bull; Please select{' '}
        <span className="highlight bold">Add Teammates</span> to add new
        teammates to an existing project.
        <br />
        <br />
        For troubleshooting and problem reporting, please select{' '}
        <span className="highlight bold">Contact Support</span>.
        <br />
        <br />
        <span
          className="highlight bold"
          style={{ display: 'flex', lineHeight: '1.25rem' }}
        >
          <WppIconInfo className="highlight" /> Please note that the actions
          below are enabled based on your permission levels <br />
          (some buttons may be greyed out).
        </span>
      </div>
    </div>
  );
}

function AccessRequestsAndSupport() {
  const {
    actions: { postAccessApi, postDatasetApi, postTeamApi },
  } = useRequestStore();
  const { access, name: roleName } = useRoleStore((state) => state.role);
  const [showForm, setShowForm] = useState<number>(0);
  const accessKeys = access.map(({ name }) => name);
  const { showToast } = useToast();
  const [parentRequestId, setParentRequestId] = useState<number>(null);

  const onCancel = () => setShowForm(0);

  const getCallback = (workflow) => {
    const unknownError = 'An unknown error occured';
    let errorMessage = '';

    return ({ data, error }) => {
      if (error || data.error) {
        errorMessage = error?.message || data.message || unknownError;
      } else if (data) {
        const { parent_request_id } = data;
        showToast({
          header: `Successfully sent ${workflow}`,
          message: `Request ID ${parent_request_id}`,
          type: 'success',
          duration: 3000,
        });
        setParentRequestId(parent_request_id);
        setShowForm(0);
      } else {
        errorMessage = unknownError;
      }
      if (errorMessage) {
        showToast({
          header: `Error while sending ${workflow}`,
          message: errorMessage,
          type: 'error',
          duration: 10000,
        });
      }
    };
  };

  const doSubmitAccessRequestForm = (formData) => {
    if (doValidateAccess(formData)) {
      const datasetsByRegion = {};
      formData.datasets.forEach((dataset) => {
        if (!datasetsByRegion[dataset.region]) {
          datasetsByRegion[dataset.region] = [];
        }
        datasetsByRegion[dataset.region] = [
          ...datasetsByRegion[dataset.region],
          dataset,
        ];
      });
      postAccessApi(formData, getCallback('Access Request')).then();
    }
  };

  const doSubmitDatasetsForm = (formData) => {
    postDatasetApi(formData, getCallback('Data Sets')).then();
  };

  const doSubmitTeamForm = (formData) => {
    postTeamApi(formData, getCallback('Team Members')).then();
  };

  switch (showForm) {
    case 1:
      return (
        <AccessRequestForm
          onSubmit={doSubmitAccessRequestForm}
          onCancel={onCancel}
        />
      );
    case 2:
      return (
        <DatasetsForm onSubmit={doSubmitDatasetsForm} onCancel={onCancel} />
      );
    case 3:
      return <TeamForm onSubmit={doSubmitTeamForm} onCancel={onCancel} />;
    case 4:
      return <DemoAccessForm onSubmit={doSubmitTeamForm} onCancel={onCancel} />;
    case 0:
    default: {
      const requestDemoAccessTitle = 'Request DSW Demo Access';
      const accessRequestFormTitle =
        roleName === RoleEnum.NEW_USER
          ? 'Request access to DS Workbench'
          : 'Create a new Project';
      const requestAdditionalDatasetsFormTitle =
        roleName === RoleEnum.NEW_USER
          ? 'Request Data Sets for a Project'
          : 'Request Additional Datasets';
      const addTeammatesFormTitle = 'Add Teammates';

      let contentType = 'empty-page';
      if (accessKeys.length === 0 || roleName === RoleEnum.NEW_USER) {
        contentType = 'no-access-page';
      } else if (parentRequestId) {
        contentType = 'success-page';
      }

      const getContent = () => {
        switch (contentType) {
          case 'no-access-page':
            return <NoPermissionContent />;
          case 'success-page':
            return <SuccessPage parentRequestId={parentRequestId} />;
          case 'empty-page':
          default:
            return <EmptyPage />;
        }
      };

      return (
        <div className="access-request">
          {getContent()}
          <div className="buttons">
            <WppButton
              className="width-300"
              title={requestDemoAccessTitle}
              onClick={() => setShowForm(4)}
            >
              {requestDemoAccessTitle}
            </WppButton>
            <br />
            <WppButton
              className="width-300"
              title={accessRequestFormTitle}
              onClick={() => setShowForm(1)}
            >
              {accessRequestFormTitle}
            </WppButton>
            <br />
            <WppButton
              className="width-300"
              title={requestAdditionalDatasetsFormTitle}
              disabled={!accessKeys.includes('addDataSets')}
              onClick={() => setShowForm(2)}
            >
              {requestAdditionalDatasetsFormTitle}
            </WppButton>
            <br />
            <WppButton
              className="width-300"
              title={addTeammatesFormTitle}
              disabled={!accessKeys.includes('addTeamMembers')}
              onClick={() => setShowForm(3)}
            >
              {addTeammatesFormTitle}
            </WppButton>
            <br />
            <br />
            <WppButton
              className="width-300"
              title="Contact Support"
              onClick={() => {
                window.open(
                  'https://chos-help.choreograph.com/hc/en-us/requests/new',
                  '_blank'
                );
              }}
            >
              Contact Support
            </WppButton>
          </div>
        </div>
      );
    }
  }
}

export default AccessRequestsAndSupport;
