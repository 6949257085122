import {
  ICostReportingProject,
  ICostReportingProjectsByStatus,
} from '../types';
import { ICostReportingFilters } from '../../pages/Dashboard/MetricsAndReports/CostReporting/FilterBar';

const setDefaultValue = (data) => {
  if (data) {
    return data;
  }
  return {
    Active: [],
    Deleted: [],
  };
};

export const mapData = (
  data: ICostReportingProjectsByStatus
): ICostReportingProjectsByStatus => {
  const mapFields = (item: ICostReportingProject): ICostReportingProject => {
    const mappedItem = { ...item };
    Object.entries(item).forEach(([key, value]) => {
      let mappedValue = value;
      if (key.indexOf('Date') > -1) {
        [mappedValue] = value.split(' ');
      }
      mappedItem[key] = mappedValue;
    });
    return mappedItem;
  };
  return {
    Active: data.Active && data.Active.length ? data.Active.map(mapFields) : [],
    Deleted:
      data.Deleted && data.Deleted.length ? data.Deleted.map(mapFields) : [],
  };
};

export const mergeData = (
  previousData: ICostReportingProjectsByStatus = {
    Active: [],
    Deleted: [],
  },
  data: ICostReportingProjectsByStatus = { Active: [], Deleted: [] }
) => {
  const getPreviousItem = (status, itemId) => {
    const [previousItem] = setDefaultValue(previousData)[status].filter(
      ({ id }) => id === itemId
    );
    if (previousItem && previousItem.id === itemId) {
      return previousItem;
    }
    return null;
  };
  const merge = (status) => {
    const mergedItems = [];
    data[status].forEach((item) => {
      const previousItem = getPreviousItem(status, item.id);
      mergedItems.push({
        ...previousItem,
        ...item,
      });
    });
    return mergedItems;
  };
  const mergedData = {};
  Object.keys(setDefaultValue(data)).forEach((status) => {
    mergedData[status] = merge(status);
  });
  return mergedData;
};

export const mapFilters = (filters: ICostReportingFilters) => {
  const mappedFilters = {};
  Object.entries(filters).forEach(([filterKey, filterValue]) => {
    mappedFilters[filterKey] = filterValue;
  });
  return mappedFilters;
};
