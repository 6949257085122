import React, { useEffect, useState } from 'react';
import {
  WppButton,
  WppDatepicker,
  WppListItem,
  WppSelect,
  WppLabel,
} from '@platform-ui-kit/components-library-react';
import _ from 'lodash';

import { InputMessageTypes } from '@platform-ui-kit/components-library/dist';

import { useOs } from '../../../../hooks/osWrapper';
import { getCleanDate, noSelectListItems } from '../../../../utils';
import { useAgencyStore } from '../../../../stores/agency';
import { useRegionStore } from '../../../../stores/region';
import { useMetricsAndReportsStore } from '../../../../stores/metrics_and_reports';
import { useRegistryStore } from '../../../../stores/registry';
import { useGlobalStore } from '../../../../stores/global';

const [maxDate] = new Date().toISOString().split('T');
const minYearAgo = new Date();
minYearAgo.setFullYear(minYearAgo.getFullYear() - 1);
const [minDate] = minYearAgo.toISOString().split('T');

export interface ICostReportingFilters {
  agencyName: string;
  projectName: string;
  region: string;
  startDate: string;
  endDate: string;
}

export const defaultFilters: ICostReportingFilters = {
  agencyName: null,
  projectName: null,
  region: null,
  startDate: null,
  endDate: null,
};

export interface IFilterBarParams {
  updateFilters: (filters: ICostReportingFilters) => void;
}

export default function FilterBar(params: IFilterBarParams) {
  const { osContext } = useOs();
  const { updateFilters } = params;
  const {
    simpleAgencies: agencies,
    actions: { getSimpleAgenciesApi },
  } = useAgencyStore();
  const {
    regionsByAgency,
    actions: { getRegionApi, getRegionsByAgencyNameApi, reset: resetRegions },
  } = useRegionStore();

  const {
    data: projectData,
    actions: { getDataApi },
  } = useRegistryStore();
  const {
    isLoadingHardwareCostDetails,
    isLoadingDataCostDetails,
    actions: { getHardwareCostDetails, getDataProcessingCostDetails },
  } = useMetricsAndReportsStore();

  useEffect(() => {
    getSimpleAgenciesApi().then();
    getRegionApi().then();
  }, [getRegionApi, getSimpleAgenciesApi]);
  const userInfo = useGlobalStore((state) => state.userInfo);

  const [filters, setFilters] = useState(defaultFilters);
  const [instanceUrl, setInstanceUrl] = useState(null);

  useEffect(() => {
    resetRegions();
    // resetProjectTypes();
  }, [resetRegions]);

  useEffect(() => {
    if (filters.agencyName !== null && filters.region !== null) {
      const projectId =
        projectData && projectData.Active && projectData.Active.length > 0
          ? projectData.Active[0].projectId
          : null;
      const url =
        projectData && projectData.Active && projectData.Active.length > 0
          ? projectData.Active[0].instanceUrl
          : null;
      if (instanceUrl) {
        setInstanceUrl(url);
      }
      if (projectId) {
        setFilters({ ...filters, projectName: projectId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  const { agencyName, projectName, region, startDate, endDate } = filters;
  const getValue = (field, { detail: { clear, date, value: rawValue } }) => {
    let value;
    if (field === 'startDate') {
      const [d] = getCleanDate(date).toISOString().split('T');
      value = d;
    } else if (field === 'endDate') {
      const [e] = getCleanDate(date || maxDate)
        .toISOString()
        .split('T');
      value = e;
      if (clear) {
        const [f] = getCleanDate(maxDate).toISOString().split('T');
        value = f;
      }
    } else {
      value = rawValue.substring(0, 100);
    }
    return value;
  };
  const set = _.debounce((event: CustomEvent, field: string) => {
    const value = getValue(field, event);
    if (field === 'agencyName') {
      resetRegions();
      // resetProjectTypes();
      filters.region = null;
      filters.projectName = null;
      if (value !== null) {
        getRegionsByAgencyNameApi(value, userInfo.email).then();
      }
    }
    if (field === 'region' && filters.agencyName !== null) {
      // resetProjectTypes();
      filters.projectName = null;

      getDataApi({
        agencyName: filters.agencyName,
        region: value,
      }).then();
    }

    setFilters({ ...filters, [field]: value });
  }, 25);

  const agencyItems = [...agencies];
  const regionItems = [...regionsByAgency];
  // const sortedProjectTypes = [...projectTypesForSelect].sort();

  const enableFilter =
    _.difference(
      Object.entries(filters).map(([k, v]) => `${k}-${v}`),
      Object.entries(defaultFilters).map(([k, v]) => `${k}-${v}`)
    ).length === 5 && startDate <= endDate;

  const handleOnRunReport = (): void => {
    updateFilters(filters);
    getHardwareCostDetails(projectName, startDate, endDate, osContext.baseUrl);
    getDataProcessingCostDetails(
      agencyName,
      region,
      instanceUrl,
      startDate,
      endDate
    );
  };

  const validateDatePicker = (): InputMessageTypes => {
    if (startDate !== null && endDate !== null) {
      return startDate <= endDate ? undefined : 'warning';
    }
    return undefined;
  };

  const isRegionSelectDisabled = filters.agencyName === null;

  return (
    <div className="cost-reporting-filters">
      <div className="section-container">
        <div className="flex-center-center fifty-w label">
          <WppLabel
            config={{ text: 'STEP 1: Select Agency' }}
            htmlFor="agencyName"
          />
          <WppSelect
            size="s"
            name="agencyName"
            value={agencyName || ''}
            onWppChange={(e) => set(e, 'agencyName')}
          >
            {agencyItems.length > 0 &&
              agencyItems.map(({ id, name: v }) => (
                <WppListItem value={v} key={id}>
                  <p slot="label">{v}</p>
                </WppListItem>
              ))}
            {agencyItems.length <= 0 &&
              noSelectListItems('No Agencies available')}
          </WppSelect>
        </div>
        <div className="date-section">
          <div className="flex-center-start">
            <WppLabel config={{ text: 'Start Date' }} htmlFor="start-date" />
            <WppDatepicker
              name="start-date"
              minDate={minDate}
              maxDate={maxDate}
              locale={{
                dateFormat: 'yyyy-MM-dd',
              }}
              tooltipConfig={{
                placement: 'top',
              }}
              placeholder="yyyy-mm-dd"
              value={startDate}
              size="s"
              messageType={validateDatePicker()}
              onWppChange={(e) => set(e, 'startDate')}
              onWppDateClear={(e) => set(e, 'startDate')}
            />
          </div>

          <div className="flex-center-start">
            <WppLabel config={{ text: 'End Date' }} htmlFor="end-date" />
            <WppDatepicker
              name="end-date"
              maxDate={maxDate}
              locale={{
                dateFormat: 'yyyy-MM-dd',
              }}
              tooltipConfig={{
                placement: 'bottom-start',
              }}
              placeholder="yyyy-mm-dd"
              value={endDate}
              size="s"
              messageType={validateDatePicker()}
              onWppChange={(e) => set(e, 'endDate')}
              onWppDateClear={(e) => set(e, 'endDate')}
            />
          </div>
        </div>
      </div>
      <div className="section-container">
        <div className="flex-center-center fifty-w label">
          <WppLabel
            config={{ text: 'STEP 2: Select Region' }}
            htmlFor="region"
          />
          <WppSelect
            size="s"
            value={region || ''}
            disabled={isRegionSelectDisabled}
            onWppChange={(e) => set(e, 'region')}
          >
            {regionItems.length > 0 &&
              regionItems.map(({ id, name: v }) => (
                <WppListItem value={v} key={id}>
                  <p slot="label">{v}</p>
                </WppListItem>
              ))}
            {regionItems.length <= 0 &&
              noSelectListItems('No Regions available')}
          </WppSelect>
        </div>
      </div>
      <div className="section-container">
        <div className="flex-center-center fifty-w label">
          {/* <WppLabel
            config={{ text: 'STEP 3: Select Project Name' }}
            htmlFor="projectName"
          />
          <WppSelect
            size="s"
            value={projectName || ''}
            name="projectName"
            disabled={isProjectTypeSelectDisabled}
            onWppChange={(e) => set(e, 'projectName')}
          >
            {projectNameForSelect.length > 0 &&
              projectNameForSelect.map((v) => (
                <WppListItem value={v.projectId} key={v.id}>
                  <p slot="label">{v.name}</p>
                </WppListItem>
              ))}
            {projectNameForSelect.length <= 0 &&
              noSelectListItems('No Project Types available')}
          </WppSelect> */}
        </div>
        <div>
          <WppButton
            disabled={
              !enableFilter ||
              isLoadingHardwareCostDetails ||
              isLoadingDataCostDetails
            }
            size="s"
            onClick={handleOnRunReport}
          >
            RUN REPORT
          </WppButton>
        </div>
      </div>
    </div>
  );
}
