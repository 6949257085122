import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { INavigationStore } from './types';
import { PagesEnum } from '../constants';

export const getRouting = () => {
  const { pathname } = window.location;
  switch (pathname) {
    case '/data':
      return PagesEnum.DATA_SETS_AND_DATA_CATALOGS;
    case '/tutorials':
      return PagesEnum.TUTORIALS_AND_DOCUMENTATIONS;
    case '/dashboard':
    case '/dashboard/request':
    case '/dashboard/request/access':
    case '/dashboard/request/dataset':
    case '/dashboard/request/team':
    case '/dashboard/project':
    case '/dashboard/metrics-and-reports':
    case '/dashboard/metrics-and-reports/key-platform-metrics':
    case '/dashboard/metrics-and-reports/growth-metrics':
    case '/dashboard/metrics-and-reports/cost-reporting':
    case '/dashboard/metrics-and-reports/quick-reports':
    case '/dashboard/segment-manager':
      return PagesEnum.DASHBOARD;
    case '/request':
    case '/request/access':
    case '/request/dataset':
    case '/request/team':
      return PagesEnum.ACCESS_REQUEST_AND_SUPPORT;
    case '/main':
    default:
      return PagesEnum.MAIN_PAGE;
  }
};

export const initialState: INavigationStore = {
  currentPage: getRouting(),
  actions: {
    changeCurrentPage: () => ({}),
  },
};

export const useNavigationStore = create<INavigationStore>()(
  devtools(
    immer((set) => ({
      ...initialState,
      actions: {
        changeCurrentPage: (currentPage) => {
          set(() => ({
            currentPage,
          }));
        },
      },
    }))
  )
);
