import React, { useEffect, useState } from 'react';
import {
  WppActionButton,
  WppButton,
  WppIconChevron,
  WppListItem,
  WppSelect,
  WppSpinner,
} from '@platform-ui-kit/components-library-react';

import { useDBStatsStore } from '../../stores/dbstats';
import { useSettingsStore } from '../../stores/settings';

import NavigationTabs from '../../components/NavigationTabs/NavigationTabs';
import Overview from './Overview';
import Basic from './Basic';
import Advanced from './Advanced';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import '@platform-ui-kit/components-library/dist/collection/ag-theme-wpp.css';

import './index.scss';
import { useFeatureToggleStore } from '../../stores/featureToggle';
import { EFeatureToggle } from '../../constants';

export const noDataOverlay = () => <div>No Data available</div>;
export const loadingOverlay = () => <WppSpinner size="m" />;

export default function DatasetsAndCatalogs() {
  const {
    database: databases,
    schema: schemas,
    tables,
    columns,
    'base-stats': base_stats,
    categoryLabels,
    actions: { getApi, getAdvancedApi, getCategoryLabels },
  } = useDBStatsStore();
  const { settings } = useSettingsStore();
  const {
    featureToggles,
    actions: { hasFeature },
  } = useFeatureToggleStore();
  const [page, setPage] = useState<string>('overview');
  const [database, setDatabase] = useState<string>(null);
  const [schema, setSchema] = useState<string>(null);
  const [selectedBaseTable, setSelectedBaseTable] = useState<string>(null);
  const [selectedAdvancedTable, setSelectedAdvancedTable] =
    useState<string>(null);
  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true);

  const [selectedTable] = tables.data.filter(
    ({ TABLE_NAME }) => TABLE_NAME === selectedAdvancedTable
  );

  // Load Databases
  useEffect(() => {
    if (databases.data.length === 0 && !databases.loading && !databases.error) {
      getApi('database').then();
    }
  }, [databases, getApi]);

  // Load Columns & Base Stats
  useEffect(() => {
    if (database && schema && selectedBaseTable) {
      if (columns.data.length === 0 && !columns.loading && !columns.error) {
        getApi('columns', database, schema).then();
      }
      if (base_stats.data.length === 0 && !base_stats.loading) {
        getApi('base-stats', database, schema).then();
      } else if (base_stats.error) {
        setSelectedBaseTable(null);
      }
    }
    if (selectedBaseTable && database && schema) {
      getCategoryLabels(selectedBaseTable).then();
    }
  }, [
    database,
    schema,
    selectedBaseTable,
    columns,
    base_stats,
    tables,
    getApi,
    getCategoryLabels,
  ]);

  // Load Correlation & Features
  useEffect(() => {
    if (
      database &&
      schema &&
      selectedAdvancedTable &&
      tables.data.length > 0 &&
      !tables.loading &&
      !tables.error &&
      selectedTable?.correlation.data.length === 0 &&
      !selectedTable?.correlation.loading &&
      !selectedTable?.correlation.error &&
      selectedTable?.features.data.length === 0 &&
      !selectedTable?.features.loading &&
      !selectedTable?.features.error
    ) {
      getAdvancedApi(selectedAdvancedTable, database, schema, true).then();
      getAdvancedApi(selectedAdvancedTable, database, schema, false).then();
    }
    if (selectedAdvancedTable && database && schema) {
      getCategoryLabels(selectedAdvancedTable).then();
    }
  }, [
    database,
    schema,
    selectedAdvancedTable,
    tables,
    selectedTable,
    getAdvancedApi,
    getCategoryLabels,
  ]);

  const overviewData = {
    databases: [],
    schemas: [],
    tables: tables.data.length,
    columns: 0,
    rows: 0,
    size: 0,
  };
  tables.data?.forEach((current) => {
    if (overviewData.databases.indexOf(current.DB_NAME) < 0) {
      overviewData.databases.push(current.DB_NAME);
    }
    if (overviewData.schemas.indexOf(current.SCHEMA_NAME) < 0) {
      overviewData.schemas.push(current.SCHEMA_NAME);
    }
    overviewData.columns += current.COLUMN_COUNT;
    overviewData.rows += current.ROW_COUNT;
    overviewData.size += current.BYTES;
  });

  const navigationItems = [
    {
      title: 'Overview',
      value: 'overview',
    },
    {
      title: 'Basic Statistics',
      value: 'basic',
    },
    {
      title: 'Feature Importance',
      value: 'advanced',
    },
  ];
  const disabledItems = [];

  const doSetPage = (value) => {
    setPage(value);
  };

  const getPageContent = () => {
    if (database && schema && tables.data?.length > 0) {
      switch (page) {
        case 'basic':
          return (
            <Basic
              selectedBaseTable={selectedBaseTable}
              setSelectedBaseTable={setSelectedBaseTable}
              tables={tables.data}
              columns={columns.data}
              baseStats={base_stats.data}
              categoryLabels={categoryLabels[selectedBaseTable]}
              loading={tables.loading || columns.loading || base_stats.loading}
              error={tables.error || columns.error || base_stats.error}
            />
          );
        case 'advanced':
          return (
            <Advanced
              selectedAdvancedTable={selectedAdvancedTable}
              setSelectedAdvancedTable={setSelectedAdvancedTable}
              tables={tables.data}
              correlations={selectedTable?.correlation.data || []}
              features={selectedTable?.features.data || []}
              categoryLabels={categoryLabels[selectedAdvancedTable]}
              loading={
                tables.loading ||
                selectedTable?.correlation.loading ||
                selectedTable?.features.loading
              }
              error={
                tables.error ||
                selectedTable?.correlation.error ||
                selectedTable?.features.error
              }
            />
          );
        case 'overview':
        default:
          return <Overview overviewData={overviewData} tables={tables} />;
      }
    }
    return null;
  };

  const onDatabaseSelect = (e) => {
    setDatabase(e.detail.value);
    setSchema(null);
    getApi('schema', e.detail.value).then();
  };

  const onSchemaSelect = (e) => {
    setSchema(e.detail.value);
    setSelectedAdvancedTable(null);
    setSelectedBaseTable(null);
    getApi('tables', database, e.detail.value).then();
  };

  const { allowedDatabases = [], allowedSchemas = [] } =
    settings?.DatasetsAndCatalogs || {};
  const placeholder = `Please select a ${
    database ? 'Schema' : 'Database, first'
  }`;

  return (
    <div className="datasets-and-catalogs">
      {filtersExpanded && (
        <section className="filters">
          <span className="row-1">
            <span className="col-1">
              <span className="label">Database</span>
            </span>
            <span className="col-1">
              <WppSelect
                size="s"
                value={database || undefined}
                disabled={!!databases.error}
                messageType={databases.error ? 'warning' : undefined}
                onWppChange={onDatabaseSelect}
              >
                {databases.data
                  .filter(({ DB_NAME }) =>
                    allowedDatabases.length > 0
                      ? allowedDatabases.indexOf(DB_NAME) > -1
                      : true
                  )
                  .map(({ DB_NAME }) => (
                    <WppListItem value={DB_NAME} key={DB_NAME}>
                      <p slot="label">{DB_NAME}</p>
                    </WppListItem>
                  ))}
              </WppSelect>
            </span>
            <span className="col-1" style={{ flex: 1 }}>
              &nbsp;
            </span>
            <span
              className="col-1"
              style={{ textAlign: 'right', width: '33%' }}
            >
              <WppButton
                size="s"
                onClick={() =>
                  window.open(
                    'https://wpp.app.data.world/wppopendatacatalog/catalog/resource/9QuwisJpP28p4Pk6SGxgtztEQSb1_JNmOYyfU9okXW4',
                    '_blank'
                  )
                }
              >
                WPP Open Data Catalog (IDN)
              </WppButton>
            </span>
          </span>
          <span className="row-1">
            <span className="col-1">
              <span className="label">Schema</span>
            </span>
            <span className="col-1">
              <WppSelect
                size="s"
                value={schema || undefined}
                disabled={!database || !!schemas.error}
                placeholder={
                  !database || schemas.error ? placeholder : undefined
                }
                messageType={schemas.error ? 'warning' : undefined}
                onWppChange={onSchemaSelect}
              >
                {schemas.data
                  .filter(({ DB_NAME, SCHEMA_NAME }) =>
                    DB_NAME === database && allowedSchemas.length > 0
                      ? allowedSchemas.indexOf(SCHEMA_NAME) > -1
                      : true
                  )
                  .map(({ SCHEMA_NAME }) => (
                    <WppListItem value={SCHEMA_NAME} key={SCHEMA_NAME}>
                      <p slot="label">{SCHEMA_NAME}</p>
                    </WppListItem>
                  ))}
              </WppSelect>
            </span>
            <span className="col-1" style={{ flex: 1 }}>
              &nbsp;
            </span>
            <span
              className="col-1"
              style={{ textAlign: 'right', width: '50%' }}
            >
              {hasFeature(EFeatureToggle.DATA_CATALOGUES_ADM_LINK) && (
                <WppButton
                  size="s"
                  style={{ marginRight: '0.5rem' }}
                  onClick={() =>
                    window.open(
                      featureToggles.dataCataloguesADMLink as string,
                      '_blank'
                    )
                  }
                >
                  NGPi Agencies DataCatalog (Sharepoint)
                </WppButton>
              )}
              <WppButton
                size="s"
                onClick={() =>
                  window.open(
                    'https://insidemedia.sharepoint.com/:x:/r/sites/choreograph-product-knowledgebase/_layouts/15/Doc.aspx?sourcedoc=%7B11F98A83-519F-4D1B-8198-70779B1E6FF1%7D&file=IDN_agencies_DataCatalog_111723.xlsx&action=default&mobileredirect=true',
                    '_blank'
                  )
                }
              >
                IDN Agencies DataCatalog (Sharepoint)
              </WppButton>
            </span>
          </span>
        </section>
      )}
      <span
        className={`row-1 expander ${
          filtersExpanded ? 'expanded' : 'collapsed'
        }`}
      >
        <span className="col-1">
          <WppActionButton
            disabled={!database || !schema}
            title={`${filtersExpanded ? 'Collapse' : 'Expand'} Filters`}
            onClick={() => setFiltersExpanded(!filtersExpanded)}
          >
            <WppIconChevron direction={filtersExpanded ? 'up' : 'down'} />
          </WppActionButton>
        </span>
      </span>
      {database && schema && tables.data?.length > 0 && (
        <NavigationTabs
          navigationItems={navigationItems}
          currentPage={page}
          setCurrentPage={doSetPage}
          disabledItems={disabledItems}
        />
      )}
      {database && schema && tables.data?.length > 0 && (
        <div className="page">{getPageContent()}</div>
      )}
      {(databases.error || schemas.error || tables.error) && (
        <p>{databases.error || schemas.error || tables.error}</p>
      )}
    </div>
  );
}
