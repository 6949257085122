import React from 'react';
import { WppSpinner } from '@platform-ui-kit/components-library-react';

export interface LoadingElementParams {
  message?: string;
  size?: 's' | 'm' | 'l';
}

export default function LoadingElement(params: LoadingElementParams) {
  const { message, size } = params;
  return (
    <div className="loading">
      <WppSpinner size={size || 'l'} />
      <p className="pulse">{message || 'Loading, please wait...'}</p>
    </div>
  );
}
