import React, { useEffect } from 'react';
import {
  WppGrid,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import { upperCase } from 'lodash';
import LoadingElement from '../../../../components/LoadingElement';
import { ICostReportingFilters } from './FilterBar';
import { useMetricsAndReportsStore } from '../../../../stores/metrics_and_reports';

export interface ICostSummaryParams {
  filters: ICostReportingFilters;
}
const errorMessage = `We're sorry, an error has occurred. Please reload this page and try again.`;
const loadingMessage = `Retrieving cost summary... this may take a few minutes.`;

export default function CostSummary(params: ICostSummaryParams) {
  const { filters } = params;
  const {
    agencyName = '',
    region = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    projectName = '',
    startDate = '',
    endDate = '',
  } = filters;
  const {
    hardwareCostDetailsError: hardwareCostError,
    dataCostDetailsError: dataCostError,
    resourceCostDetailsData: data,
    isLoadingHardwareCostDetails,
    isLoadingDataCostDetails,
    actions: { resetCostReportingData },
  } = useMetricsAndReportsStore();

  useEffect(() => {
    return () => {
      resetCostReportingData();
    };
  }, [resetCostReportingData]);

  if (
    !isLoadingHardwareCostDetails &&
    !isLoadingDataCostDetails &&
    !hardwareCostError &&
    !dataCostError &&
    !data &&
    (!filters.agencyName ||
      !filters.projectName ||
      !filters.region ||
      !filters.startDate ||
      !filters.endDate)
  ) {
    return null;
  }

  return (
    <div className="cost-summary">
      {!isLoadingHardwareCostDetails &&
        !isLoadingDataCostDetails &&
        (hardwareCostError || dataCostError) && (
          <WppGrid container rowSpacing={4} className="error-message">
            <WppGrid item all={24} fluid fullWidth>
              <WppTypography type="m-strong" className="span">
                {errorMessage}
              </WppTypography>
            </WppGrid>
          </WppGrid>
        )}
      {(isLoadingHardwareCostDetails || isLoadingDataCostDetails) && (
        <div className="loading-container">
          <LoadingElement size="m" message={loadingMessage} />
        </div>
      )}
      {!hardwareCostError &&
        !dataCostError &&
        data &&
        !isLoadingHardwareCostDetails &&
        !isLoadingDataCostDetails && (
          <div className="summary-report">
            <WppTypography type="s-strong" tag="span">
              For <span className="mark-red">{upperCase(agencyName)}</span>{' '}
              agency in <span className="mark-red">{upperCase(region)}</span>{' '}
              region(s) costs between{' '}
              <span className="mark-red">{startDate}</span> and{' '}
              <span className="mark-red">{endDate}</span> are as following:
            </WppTypography>
            <div>
              <table className="cost-table">
                <tbody>
                  <tr>
                    <td>
                      <WppTypography type="m-strong" tag="span">
                        Hardware Costs
                      </WppTypography>
                    </td>
                    <td>
                      <WppTypography type="m-strong" tag="span">
                        {`$${Number(data.hardwareCost).toFixed(2) || '-'}`}
                      </WppTypography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <WppTypography type="m-strong" tag="span">
                        Data Processing Costs
                      </WppTypography>
                    </td>
                    <td>
                      <WppTypography type="m-strong" tag="span">
                        {`$${
                          Number(data.dataProcessingCost).toFixed(2) || '-'
                        }`}
                      </WppTypography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <WppTypography type="m-strong" tag="span">
                        Total Costs
                      </WppTypography>
                    </td>
                    <td>
                      <WppTypography type="m-strong" tag="span">
                        {`$${(
                          Number(data.hardwareCost || 0) +
                          Number(data.dataProcessingCost || 0)
                        ).toFixed(2)}`}
                      </WppTypography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
    </div>
  );
}
