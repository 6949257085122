export const isLocalDevelopment = process.env.REACT_APP_DEPLOY_ENV === 'LOCAL';

const environment_vars = {
  ENV: 'REACT_APP_DEPLOY_ENV',
  CLIENT_ID: 'REACT_APP_CLIENT_ID',
  ISSUER: 'REACT_APP_ISSUER',
};

const local_environment_vars = {
  ENV: 'LOCAL',
  CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
  ISSUER: process.env.REACT_APP_OKTA_ISSUER,
};

export const getAPIURL = () => {
  if (environment_vars.ENV === 'NPD') {
    return 'https://dsw-ui-npd.choreograph.com';
  }
  if (environment_vars.ENV === 'QA') {
    return 'https://dsw-ui-qa.choreograph.com';
  }
  if (environment_vars.ENV === 'PROD') {
    return 'https://dsw-ed.choreograph.com';
  }

  return 'http://localhost:8012/proxy';
};

export const API_BASE_URL = `${getAPIURL()}/user-mgmt-service/dsw`;

const { CLIENT_ID, ISSUER } = isLocalDevelopment
  ? local_environment_vars
  : environment_vars;
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const BASENAME = '/dsw/';
const REDIRECT_URI = `${window.location.origin}${BASENAME}login/callback`;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  app: {
    basename: BASENAME,
  },
};
