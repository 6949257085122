import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  WppActionButton,
  WppButton,
  WppIconAddCircle,
  WppIconRemoveCircle,
  WppInput,
  WppGrid,
  WppTypography,
} from '@platform-ui-kit/components-library-react';

import { RequestDefaultFields } from '../index';

import { ITeamFormData } from '../types';
import { validateEmail } from '../../../utils';
import { useGlobalStore } from '../../../stores/global';
import { useRegistryStore } from '../../../stores/registry';
import {
  DEMO_NAMESPACE,
  DEMO_AGENCY,
  DEMO_REGION,
} from '../../../constants/constants';

import logo from '../../../assets/images/choreograph-logo.png';

import './index.scss';

export interface ITeamFormParams {
  onSubmit: (formData: ITeamFormData) => void;
  onCancel: () => void;
}

const validateNameAndEmailFields = (
  showErrors: boolean,
  member: { name: string; email: string },
  projectTeamNames: string[],
  projectTeamEmails: string[]
) => {
  let nameFieldErrorMessage = '';
  let emailFieldErrorMessage = '';
  if (!showErrors) {
    return { nameFieldErrorMessage, emailFieldErrorMessage };
  }
  nameFieldErrorMessage =
    !nameFieldErrorMessage && !member.name
      ? 'Teammate Name should not be empty.'
      : nameFieldErrorMessage;
  nameFieldErrorMessage =
    !nameFieldErrorMessage && projectTeamNames.indexOf(member.name) > -1
      ? 'Teammate Name should be unique.'
      : nameFieldErrorMessage;
  emailFieldErrorMessage =
    !emailFieldErrorMessage && !member.email
      ? 'Teammate E-Mail should not be empty.'
      : emailFieldErrorMessage;
  emailFieldErrorMessage =
    !emailFieldErrorMessage && !validateEmail(member.email)
      ? 'Please enter a valid Teammate E-Mail address.'
      : emailFieldErrorMessage;
  emailFieldErrorMessage =
    !emailFieldErrorMessage && projectTeamEmails.indexOf(member.email) > -1
      ? 'Teammate E-Mail address should be unique.'
      : emailFieldErrorMessage;
  return { nameFieldErrorMessage, emailFieldErrorMessage };
};

export default function DemoAccessForm(params: ITeamFormParams) {
  const { onSubmit, onCancel } = params;
  const { userInfo } = useGlobalStore();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const {
    data: registryData,
    actions: { getDetailsApi },
  } = useRegistryStore();

  const initialState = {
    agency: { id: 1, name: DEMO_AGENCY },
    region: { id: '2', name: DEMO_REGION },
    project: {
      id: 206,
      projectName: DEMO_NAMESPACE,
      agencyName: DEMO_AGENCY,
      regionName: DEMO_REGION,
      activeSince: '2024-03-12 09:40:22',
      requestStatus: '',
      countDatasets: 1,
      countUsers: 1,
      sla: '',
      remaining: '',
      submitDate: '2024-03-12 09:40:22',
      jiraTicketId: '',
      instanceUrl: '',
      requestId: 1,
    },
    projectDetails: [],
    team: [{ name: null, email: null }],
    requesterEmail: userInfo.email,
  };

  const [formData, setFormData] = useState<ITeamFormData>(initialState);

  const projects = [...registryData.Active];
  const projectsById = {};
  projects.forEach((project) => {
    projectsById[project.id] = project;
  });
  const projectDetails = [];
  const t = projectsById[formData.project?.id]?.details?.team_details;
  if (formData.project && t) {
    t.forEach(({ name, email, region }) => {
      projectDetails.push({
        name,
        email,
        region,
      });
    });
  }
  const setTeam = ({ detail: { value } }, field, index) => {
    const updatedTeam = [...formData.team];
    updatedTeam[index][field] = value;
    setFormData({ ...formData, team: updatedTeam });
  };
  const onAddMember = () => {
    setFormData({
      ...formData,
      team: [...formData.team, { name: null, email: null }],
    });
  };
  const onRemoveMember = (index: number) => {
    const updatedTeam = [...formData.team];
    updatedTeam.splice(index, 1);
    setFormData({
      ...formData,
      team: updatedTeam,
    });
  };

  const getAddMember = () => (
    <span className="col-1">
      <WppActionButton
        onClick={onAddMember}
        disabled={formData.team.length > 7}
      >
        <WppIconAddCircle slot="icon-start" />
        Add another team member
      </WppActionButton>
    </span>
  );

  const errors = [];
  const projectTeamNames = [];
  const projectTeamEmails = [];
  projectDetails
    .filter(({ region }) => region === formData.region.name)
    .forEach(({ name, email }) => {
      projectTeamNames.push(name);
      projectTeamEmails.push(email);
    });

  if (!formData.project?.projectName) {
    errors.push('The Project need to be selected');
  }
  if (
    formData.team.filter(
      ({ name, email }) =>
        projectTeamNames.indexOf(name) > -1 ||
        projectTeamEmails.indexOf(email) > -1
    ).length > 0
  ) {
    errors.push('Teammates need to be unique');
  }
  if (formData.team.filter(({ email }) => !validateEmail(email)).length > 0) {
    errors.push('Teammates need to have a valid email');
  }
  if (formData.team.filter(({ name }) => !name || name.length < 1).length > 0) {
    errors.push('Teammates need to have a name set');
  }

  const validToSubmit = () => {
    return errors.length === 0;
  };

  const getContent = () => (
    <div className="content">
      <WppGrid container>
        <WppGrid item all={2} />
        <WppGrid item all={20}>
          <div className="demo-instruction">
            <WppTypography type="m-body">
              <em>
                Welcome to the Data Science Workbench (DSW) Demo! You can fill
                in the form below and request access to the demo instance of
                DSW. Please take note of the following:
                <ul>
                  <li>
                    Demo space is for you to gain experience with the DSW tools
                    and functions. It has full functionality but only sample
                    datasets.
                  </li>
                  <li>
                    The notebooks will stay active for 2 weeks and will be
                    deleted afterwards. However, you will be notified 7 days
                    before on the same.
                  </li>
                  <li>
                    Only sample AO and IDN datasets could be accessed through
                    your DSW demo Notebooks.&nbsp;
                    <u>
                      Please remember that these sample data sets are there for
                      demo purposes only and cannot be utilized in production
                      level projects.
                    </u>
                  </li>
                  <li>
                    After you gain familiarity with the DSW, you can request a
                    new project through&nbsp;
                    <a
                      href={window.location.href}
                      target="_blank"
                      rel="noreferrer"
                      className="demo-referer-url"
                    >
                      <span>
                        <img
                          src={logo}
                          className="brand-logo"
                          alt="brand-logo"
                        />
                      </span>
                      <span>Data Science - Enablement Dashboard</span>
                    </a>
                    &nbsp;with full data access in a production environment.
                  </li>
                </ul>
              </em>
            </WppTypography>
          </div>
        </WppGrid>
        <WppGrid item all={2} />
      </WppGrid>
      <section className="centered">
        <span className="demo-default-fields">
          <RequestDefaultFields
            showErrors={showErrors}
            formData={formData}
            setFormData={setFormData}
            initialState={initialState}
            fromDSWDemoAccess
          />
        </span>
        <span className="row-1">
          <span className="col-1" style={{ width: '100%' }}>
            <span className="label" style={{ textAlign: 'center' }}>
              Provide team member details
            </span>
          </span>
        </span>
        <span className="row-1">
          <span className="col-1" style={{ width: '20%' }}>
            &nbsp;
          </span>
          <span className="col-1" style={{ width: '30%' }}>
            <span className="label" style={{ textAlign: 'center' }}>
              Name & Surname
            </span>
          </span>
          <span className="col-1" style={{ width: '30%' }}>
            <span className="label" style={{ textAlign: 'center' }}>
              E-Mail
            </span>
          </span>
        </span>
        {formData.team.map((member, teamIndex) => {
          const { nameFieldErrorMessage, emailFieldErrorMessage } =
            validateNameAndEmailFields(
              showErrors,
              member,
              projectTeamNames,
              projectTeamEmails
            );
          return (
            <span className="row-1 team-row" key={`team-member-${teamIndex}`}>
              <span className="col-1" style={{ width: '20%' }}>
                &nbsp;
              </span>
              <span className="col-1 has-input-field" style={{ width: '30%' }}>
                <WppInput
                  onWppChange={(e) => setTeam(e, 'name', teamIndex)}
                  value={member.name}
                  message={nameFieldErrorMessage}
                  messageType={
                    showErrors && nameFieldErrorMessage ? 'error' : null
                  }
                />
              </span>
              <span className="col-1 has-input-field" style={{ width: '30%' }}>
                <WppInput
                  onWppChange={(e) => setTeam(e, 'email', teamIndex)}
                  value={member.email}
                  message={emailFieldErrorMessage}
                  messageType={
                    showErrors && emailFieldErrorMessage ? 'error' : null
                  }
                />
              </span>
              {teamIndex + 1 === formData.team.length && !!teamIndex && (
                <span className="col-1" style={{ width: '20%' }}>
                  <WppActionButton onClick={() => onRemoveMember(teamIndex)}>
                    <WppIconRemoveCircle slot="icon-start" />
                    Remove member
                  </WppActionButton>
                </span>
              )}
            </span>
          );
        })}
        <span className="row-1">
          <span className="col-1" style={{ width: '20%' }}>
            &nbsp;
          </span>
          <span className="col-1">{getAddMember()}</span>
        </span>
      </section>
    </div>
  );

  useEffect(() => {
    getDetailsApi({ ...initialState.project }, 'Active').then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="team-form">
      <div className="headline">
        <WppTypography type="m-strong" tag="h1">
          DS WORKBENCH - DEMO ACCESS REQUEST FORM
        </WppTypography>
      </div>
      {getContent()}
      <div className="buttons">
        <WppButton
          className="width-200"
          title={errors.length > 0 ? errors.join(', ') : 'Submit'}
          disabled={!validToSubmit()}
          onClick={() =>
            validToSubmit() ? onSubmit(formData) : setShowErrors(true)
          }
        >
          Submit
        </WppButton>
        <WppActionButton
          className="width-200"
          title="cancel and navigate back"
          onClick={() => onCancel()}
        >
          Cancel
        </WppActionButton>
      </div>
    </div>
  );
}
