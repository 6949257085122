import React, { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  Navigate,
  generatePath,
} from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';

import * as ROUTE from '../../routes/routePaths';

import { useOs, useOsWrapper } from '../../hooks/osWrapper';

import LoadingElement from '../../components/LoadingElement';

import './Login.scss';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { osApi } = useOs();
  const location = useLocation();
  const token = osApi.getAccessToken();

  if (!token) {
    const l = { originalURL: location };
    return <Navigate to={ROUTE.LOGIN} state={l} replace />;
  }

  return children;
}

export function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { osApi } = useOsWrapper();
  const { osContext } = useOs();
  const { authState, oktaAuth } = useOktaAuth();
  const token = osApi.getAccessToken();
  const loadingMessage = 'Waiting for Authentication...';

  useEffect(() => {
    if (authState?.isAuthenticated || token) {
      navigate(osContext.baseUrl, { state: location.state, replace: true });
    }
    if (authState && !token && !authState.isPending) {
      oktaAuth.tokenManager.clear();
      oktaAuth.signInWithRedirect({
        state: location.state?.originalURL?.pathname || osContext.baseUrl,
      });
    }
  });

  return (
    <div className="login">
      <LoadingElement message={loadingMessage} />
    </div>
  );
}
