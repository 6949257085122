import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { EApprovalType, IApproval, IApprovalStore } from './types';
import { dswRequest } from './index';
import { API_BASE_URL } from '../config/config';
import { REQUEST_STATUS_EDITABLE } from '../constants';
import mapRequestApproval from './mappers/RequestApproval';

export const initialState: IApprovalStore = {
  approvalList: null,
  approvalListError: '',
  approvalListLoading: false,
  approvals: {},
  approvalsLoading: {},
  approvalsErrors: {},
  approvalType: null,
  actions: {
    setApprovalType: () => undefined,
    getApprovalList: () => Promise.resolve(),
    getApproval: () => Promise.resolve(),
    saveApproval: () => Promise.resolve(),
    submitApproval: () => Promise.resolve(),
  },
};

const filterApprovals = (approval, approvalType) => {
  let modifiedApproval;

  if (approvalType === EApprovalType.DATASET) {
    const { datasets } = approval;

    const filteredDatasets = datasets.map((dataset) => {
      // Filter dataset_region_map to include only entries with status "INPROGRESS"
      const filteredRegionMap = Object.fromEntries(
        Object.entries(dataset.dataset_region_map).filter(([, value]) =>
          (value as { status: string }[]).some(
            (item) => item.status === 'INPROGRESS'
          )
        )
      );

      // Filter dataset_approval_map based on the filtered keys in dataset_region_map
      const filteredApprovalMap = Object.fromEntries(
        Object.entries(dataset.dataset_approval_map).filter(([key]) =>
          Object.keys(filteredRegionMap).includes(key)
        )
      );

      // Return the updated dataset with the filtered maps
      return {
        ...dataset,
        dataset_region_map: filteredRegionMap,
        dataset_approval_map: filteredApprovalMap,
      };
    });

    modifiedApproval = {
      ...approval,
      datasets: filteredDatasets,
    };
  }
  if (approvalType === EApprovalType.TEAM) {
    modifiedApproval = {
      ...approval,
      team_details: approval.team_details.filter(
        (teamDetail) => teamDetail.status === REQUEST_STATUS_EDITABLE
      ),
    };
  }
  if (approvalType === EApprovalType.ACCESS) {
    modifiedApproval = {
      ...approval,
      team_details: approval.team_details.filter(
        (teamDetail) => teamDetail.email
      ),
    };
  }

  return modifiedApproval;
};

export const useApprovalStore = create<IApprovalStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        setApprovalType: (approvalType) =>
          set((state) => ({
            ...state,
            approvalList: null,
            approvalListError: '',
            approvalListLoading: false,
            approvals: {},
            approvalsLoading: {},
            approvalsErrors: {},
            approvalType,
          })),
        getApprovalList: async (approvalType, force = false) => {
          if (
            force ||
            ((get().approvalList === null ||
              approvalType !== get().approvalType) &&
              !get().approvalListLoading)
          ) {
            set((state) => ({
              ...state,
              approvalListLoading: true,
            }));
            try {
              const url = `${API_BASE_URL}/approval-screen/${approvalType}/all`;
              const { data, error } = await dswRequest.get(url);
              if (error) {
                set((state) => ({
                  ...state,
                  approvalListError: `${data.error || error.status}: ${
                    data.message || error.message
                  }`,
                }));
              } else {
                set((state) => ({
                  ...state,
                  approvalList: data,
                  approvalListError: '',
                }));
              }
            } catch (e) {
              set((state) => ({
                ...state,
                approvalListError: e,
              }));
            } finally {
              set((state) => ({
                ...state,
                approvalListLoading: false,
              }));
            }
          }
        },
        getApproval: async (
          approvalType,
          approvalId: string,
          force = false
        ) => {
          if (
            force ||
            (!get().approvals[approvalId] &&
              !get().approvalListError &&
              !get().approvalsLoading[approvalId])
          ) {
            set((state) => ({
              ...state,
              approvalsLoading: {
                ...state.approvalsLoading,
                [approvalId]: true,
              },
            }));
            try {
              const { approvalList } = get();
              let url = `${API_BASE_URL}/approval-screen/${approvalType}/id/${approvalId}`;
              if (approvalType !== EApprovalType.ACCESS && approvalList) {
                const approval = approvalList[approvalId];
                const regions = Array.from(new Set(approval.regions));
                const requestParams = `agencyName=${
                  approval.agency
                }&namespaceName=${
                  approval.namespace_name
                }&regionName=${regions.join(',')}`;
                url = `${url}?${requestParams}`;
              }
              const { data, error } = await dswRequest.get(url);
              if (!error) {
                let adjustedNamespace = data.project_details.namespace;
                if (approvalType === EApprovalType.ACCESS) {
                  adjustedNamespace = data.project_details.namespace.replace(
                    /^(pitch|research|product|ngpi)-/,
                    ''
                  );
                }
                set((state) => ({
                  ...state,
                  approvals: {
                    ...state.approvals,
                    [approvalId]: {
                      ...data,
                      project_details: {
                        ...data.project_details,
                        is_changed: {
                          namespace: false,
                          requestReason: false,
                          agency: false,
                        },
                        deleted_regions: [],
                        namespace: adjustedNamespace,
                      },
                    },
                  },
                }));
              } else {
                set((state) => ({
                  ...state,
                  approvalsErrors: {
                    ...state.approvalsErrors,
                    [approvalId]: error.message,
                  },
                }));
              }
            } catch (e) {
              set((state) => ({
                ...state,
                approvalsErrors: {
                  ...state.approvalsErrors,
                  [approvalId]: (e as Error).message,
                },
              }));
            } finally {
              set((state) => ({
                ...state,
                approvalsLoading: {
                  ...state.approvalsLoading,
                  [approvalId]: false,
                },
              }));
            }
          }
        },
        saveApproval: async (
          approvalType,
          approval: IApproval,
          approvalId: string,
          callback
        ) => {
          try {
            const filteredApprovals = filterApprovals(approval, approvalType);
            const url = `${API_BASE_URL}/approval-screen/save/${approvalType}/${approvalId}`;
            const { data, error } = await dswRequest.post(
              url,
              mapRequestApproval(filteredApprovals, approvalType)
            );
            callback({ data, error });
          } catch (e) {
            callback({ data: null, error: e });
          }
        },
        submitApproval: async (approvalType, approvalId: string, callback) => {
          const approval = get().approvals[approvalId];
          const filteredApprovals = filterApprovals(approval, approvalType);
          if (filteredApprovals) {
            try {
              const url = `${API_BASE_URL}/approval-screen/submit/${approvalType}/${approvalId}`;
              const { data, error } = await dswRequest.post(
                url,
                mapRequestApproval(filteredApprovals, approvalType)
              );
              callback({ data, error });
            } catch (e) {
              callback({ data: null, error: e });
            }
          } else {
            callback({ data: null, error: 'Approval not found!' });
          }
        },
      },
    }))
  )
);
