import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from '../routes/routeConfig';

export default function App() {
  const router = useRoutes(routes);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Suspense fallback={<></>}>
      {router && <div className="App">{router}</div>}
    </Suspense>
  );
}
