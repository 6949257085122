import React, { useState } from 'react';
import {
  WppButton,
  WppGrid,
  WppListItem,
  WppPill,
  WppPillGroup,
  WppSelect,
  WppTypography,
  WppDatepicker,
  WppLabel,
} from '@platform-ui-kit/components-library-react';
import { PillGroupChangeEvent } from '@platform-ui-kit/components-library';
import ReactEcharts from 'echarts-for-react';
import { debounce } from 'lodash';
import { getOptions, onChartClick } from './chartUtils';
import LoadingElement from '../../../../components/LoadingElement';
import { useMetricsAndReportsStore } from '../../../../stores/metrics_and_reports';
import { getISOTimespan, usePrevious, getCleanDate } from '../../../../utils';
import {
  EFeatureToggle,
  EQuickReportTypes,
  TimespanSelectionEnum,
} from '../../../../constants';
import I18n from '../../../../i18n';

import './index.scss';
import useToast from '../../../../hooks/useToast';
import { useFeatureToggleStore } from '../../../../stores/featureToggle';

const noTimespanSelectionMessage =
  '👆 Please select the Report Type and Time Period';
const noTimespanSelectionMessageForCost = `👆 Please select the Time Period and 'Generate Report'`;

const errorMessage = `We're sorry, an error has occurred. Please reload this page and try again.`;

const today = new Date();
// from April
const enableQ2 = today.getMonth() > 2;
// from July
const enableQ3 = today.getMonth() > 5;
// from September
const enableQ4 = today.getMonth() > 8;

const [maxDate] = new Date().toISOString().split('T');
const minYearAgo = new Date();
minYearAgo.setFullYear(minYearAgo.getFullYear() - 1);
const [minDate] = minYearAgo.toISOString().split('T');

export default function QuickReports() {
  const { QUICK_REPORT: labels } = I18n;
  const {
    quickReportData,
    actions: { getQuickReportApi, exportQuickReportApi, resetQuickReportApi },
    isLoadingQuickReport,
    isLoadingQuickReportExportFile,
    quickReportError,
  } = useMetricsAndReportsStore((state) => state);
  const { hasFeature } = useFeatureToggleStore((state) => state.actions);
  const { showToast } = useToast();

  const [timespanSelection, setTimespanSelection] =
    useState<TimespanSelectionEnum>(null);
  const timespanSelectionPrevious = usePrevious(timespanSelection);
  const [
    currentlyRenderedGraphsTimespanSelection,
    setCurrentlyRenderedGraphsTimespanSelection,
  ] = useState<TimespanSelectionEnum>(null);
  const [reportTypeSelection, setReportTypeSelection] =
    useState<EQuickReportTypes>(null);
  const [{ costStartDate, costEndDate }, setCostDateRange] = useState({
    costStartDate: null,
    costEndDate: null,
  });
  const [clearData, setClearData] = useState<boolean>(true);
  const [hasDateChanged, setHasDataChanged] = useState<boolean>(true);

  const showRegenerateReportMessage =
    timespanSelectionPrevious !== '' &&
    currentlyRenderedGraphsTimespanSelection !== timespanSelection &&
    timespanSelectionPrevious !== timespanSelection &&
    reportTypeSelection !== EQuickReportTypes.AGENCY_COST_PER_REGION;

  const timeStampSelected =
    reportTypeSelection &&
    reportTypeSelection === EQuickReportTypes.AGENCY_COST_PER_REGION
      ? costStartDate && costEndDate && costStartDate < costEndDate
      : timespanSelection;

  const isGenerateReportButtonDisabled: boolean =
    (!reportTypeSelection && !timeStampSelected && !quickReportData) ||
    isLoadingQuickReport;

  const warnDatePicker: boolean =
    (costStartDate !== null &&
      costEndDate !== null &&
      costStartDate <= costEndDate) ||
    !costStartDate ||
    !costEndDate;

  const reportTypes = [
    {
      id: EQuickReportTypes.DATASET,
      label: labels.DATASET.SELECT,
      title: labels.DATASET.TITLE,
      sub: labels.DATASET.SUB,
    },
    {
      id: EQuickReportTypes.USER_AGENCY,
      label: labels.USER_AGENCY.SELECT,
      title: labels.USER_AGENCY.TITLE,
      sub: labels.USER_AGENCY.SUB,
    },
    {
      id: EQuickReportTypes.PROJECT_AGENCY,
      label: labels.PROJECT_AGENCY.SELECT,
      title: labels.PROJECT_AGENCY.TITLE,
      sub: labels.PROJECT_AGENCY.SUB,
    },
    {
      id: EQuickReportTypes.PROJECT_REGION,
      label: labels.PROJECT_REGION.SELECT,
      title: labels.PROJECT_REGION.TITLE,
      sub: labels.PROJECT_REGION.SUB,
    },
    {
      id: EQuickReportTypes.AGENCY_COST_PER_REGION,
      label: labels.AGENCY_COST_PER_REGION.SELECT,
      title: labels.AGENCY_COST_PER_REGION.TITLE,
      sub: labels.AGENCY_COST_PER_REGION.SUB,
    },
    /* {
      id: EQuickReportTypes.AGENCY_COST_PER_PROJECT_TYPE,
      label: labels.AGENCY_COST_PER_PROJECT_TYPE.SELECT,
      title: labels.AGENCY_COST_PER_PROJECT_TYPE.TITLE,
      sub: labels.AGENCY_COST_PER_PROJECT_TYPE.SUB,
    }, */
  ];

  const [{ title: chartTitle = '', sub: chartSubtitle = '' } = {}] =
    reportTypes.filter(({ id }) => id === reportTypeSelection);

  const onGenerateReport = ({ reportType, timespan }) => {
    setClearData(false);
    setHasDataChanged(false);
    const { startDate, endDate } =
      reportType === EQuickReportTypes.AGENCY_COST_PER_REGION
        ? timespan
        : getISOTimespan(timespan as TimespanSelectionEnum);
    getQuickReportApi(reportType, startDate, endDate).then();
    setCurrentlyRenderedGraphsTimespanSelection(timespan);
  };

  const onGenerateCostReport = () => {
    onGenerateReport({
      reportType: reportTypeSelection,
      timespan: { startDate: costStartDate, endDate: costEndDate },
    });
  };

  const set = debounce((event: CustomEvent, field: string) => {
    const {
      detail: { clear, date, value: rawValue },
    } = event;
    if (field === 'startDate') {
      if (clear) {
        setCostDateRange({ costStartDate: null, costEndDate });
      } else {
        const [d] = getCleanDate(date).toISOString().split('T');
        setCostDateRange({ costStartDate: d, costEndDate });
      }
    } else if (field === 'endDate') {
      if (clear) {
        const [f] = getCleanDate(maxDate).toISOString().split('T');
        setCostDateRange({ costStartDate, costEndDate: null });
      } else {
        const [e] = getCleanDate(date || maxDate)
          .toISOString()
          .split('T');
        setCostDateRange({ costStartDate, costEndDate: e });
      }
    }
    setHasDataChanged(true);
  }, 25);

  const onReportTypeSelection = (event: CustomEvent) => {
    resetQuickReportApi().then();
    setClearData(true);
    setReportTypeSelection(event.detail.value);
    setTimespanSelection(null);
    setCostDateRange({ costStartDate: null, costEndDate: null });
    if (event.detail.value === EQuickReportTypes.AGENCY_COST_PER_REGION) {
      setCostDateRange({ costStartDate: minDate, costEndDate: maxDate });
      setHasDataChanged(true);
    }
  };

  const onTimespanSelectionChange = (
    event: CustomEvent<PillGroupChangeEvent>
  ) => {
    setTimespanSelection(event.detail.value as TimespanSelectionEnum);
  };

  const handleOnExportError = () => {
    showToast({
      header: `Error while exporting report, please try again.`,
      type: 'error',
      duration: 3000,
    });
  };

  const onExportReport = () => {
    const { startDate, endDate } =
      reportTypeSelection === EQuickReportTypes.AGENCY_COST_PER_REGION
        ? { startDate: costStartDate, endDate: costEndDate }
        : getISOTimespan(timespanSelection);
    exportQuickReportApi(
      reportTypeSelection,
      startDate,
      endDate,
      handleOnExportError
    ).then();
  };

  const fieldsSelected =
    reportTypeSelection &&
    reportTypeSelection === EQuickReportTypes.AGENCY_COST_PER_REGION
      ? costStartDate && costEndDate && costStartDate < costEndDate
      : timespanSelection && reportTypeSelection;

  const isExportReportButtonDisabled: boolean =
    !quickReportData && isLoadingQuickReport;

  const isExportReportButtonVisible: boolean =
    hasFeature(EFeatureToggle.QUICK_REPORT_EXPORT) &&
    fieldsSelected &&
    quickReportData &&
    !isLoadingQuickReport &&
    !showRegenerateReportMessage &&
    !quickReportError;

  return (
    <div className="quick-reports">
      <div className="report-type-selection-container">
        <WppTypography type="m-strong" className="span">
          Step 1: Select Report Type
        </WppTypography>
        <WppSelect
          size="s"
          value={reportTypeSelection}
          onWppChange={onReportTypeSelection}
          withSearch
        >
          {reportTypes.map((reportType) => (
            <WppListItem
              key={`report-type-items-${reportType.id}`}
              value={reportType.id}
            >
              <p slot="label">{reportType.label}</p>
            </WppListItem>
          ))}
        </WppSelect>
      </div>
      <div className="timespan-selection-container">
        <WppTypography type="m-strong" className="span">
          Step 2: Select Time Period
        </WppTypography>
        <div className="selections-buttons">
          {reportTypeSelection === EQuickReportTypes.AGENCY_COST_PER_REGION ? (
            <div className="date-section">
              <div className="flex-center-start">
                <WppLabel
                  config={{ text: 'Start Date' }}
                  htmlFor="start-date"
                />
                <WppDatepicker
                  name="start-date"
                  minDate={minDate}
                  maxDate={maxDate}
                  locale={{
                    dateFormat: 'yyyy-MM-dd',
                  }}
                  tooltipConfig={{
                    placement: 'top',
                  }}
                  placeholder="yyyy-mm-dd"
                  value={costStartDate}
                  size="s"
                  messageType={warnDatePicker ? null : 'warning'}
                  onWppChange={(e) => set(e, 'startDate')}
                  onWppDateClear={(e) => set(e, 'startDate')}
                />
              </div>

              <div className="flex-center-start">
                <WppLabel config={{ text: 'End Date' }} htmlFor="end-date" />
                <WppDatepicker
                  name="end-date"
                  maxDate={maxDate}
                  locale={{
                    dateFormat: 'yyyy-MM-dd',
                  }}
                  tooltipConfig={{
                    placement: 'bottom-start',
                  }}
                  placeholder="yyyy-mm-dd"
                  value={costEndDate}
                  size="s"
                  messageType={warnDatePicker ? null : 'warning'}
                  onWppChange={(e) => set(e, 'endDate')}
                  onWppDateClear={(e) => set(e, 'endDate')}
                />
              </div>
              <div className="flex-center-start generateCostReport">
                <WppButton
                  variant="primary"
                  size="s"
                  onClick={onGenerateCostReport}
                  disabled={
                    !reportTypeSelection ||
                    !timeStampSelected ||
                    isLoadingQuickReport ||
                    !hasDateChanged
                  }
                >
                  Generate Report
                </WppButton>
              </div>
            </div>
          ) : (
            <WppPillGroup
              type="single"
              value={timespanSelection}
              onWppChange={(e) => {
                onTimespanSelectionChange(e);
                if (!isGenerateReportButtonDisabled) {
                  onGenerateReport({
                    reportType: reportTypeSelection,
                    timespan: e.detail.value,
                  });
                }
              }}
            >
              <WppPill
                label="All Items"
                value={TimespanSelectionEnum.ALL_ITEMS}
                disabled={!reportTypeSelection}
              />
              <WppPill
                label="Last Year"
                value={TimespanSelectionEnum.LAST_YEAR}
                disabled={
                  !reportTypeSelection ||
                  reportTypeSelection === EQuickReportTypes.USER_AGENCY
                }
              />
              <WppPill
                label="This Year"
                value={TimespanSelectionEnum.THIS_YEAR}
                disabled={
                  !reportTypeSelection ||
                  reportTypeSelection === EQuickReportTypes.USER_AGENCY
                }
              />
              <WppPill
                label="Q1"
                value={TimespanSelectionEnum.Q1}
                disabled={
                  !reportTypeSelection ||
                  reportTypeSelection === EQuickReportTypes.USER_AGENCY
                }
              />
              <WppPill
                label="Q2"
                value={TimespanSelectionEnum.Q2}
                disabled={
                  !reportTypeSelection ||
                  reportTypeSelection === EQuickReportTypes.USER_AGENCY ||
                  !enableQ2
                }
              />
              <WppPill
                label="Q3"
                value={TimespanSelectionEnum.Q3}
                disabled={
                  !reportTypeSelection ||
                  reportTypeSelection === EQuickReportTypes.USER_AGENCY ||
                  !enableQ3
                }
              />
              <WppPill
                label="Q4"
                value={TimespanSelectionEnum.Q4}
                disabled={
                  !reportTypeSelection ||
                  reportTypeSelection === EQuickReportTypes.USER_AGENCY ||
                  !enableQ4
                }
              />
            </WppPillGroup>
          )}
        </div>
      </div>
      {!fieldsSelected &&
        (!isLoadingQuickReport || clearData) &&
        !quickReportError &&
        reportTypeSelection !== EQuickReportTypes.AGENCY_COST_PER_REGION && (
          <div className="timespan-selection-info-message">
            <WppTypography type="m-strong" className="span">
              {noTimespanSelectionMessage}
            </WppTypography>
          </div>
        )}
      {reportTypeSelection === EQuickReportTypes.AGENCY_COST_PER_REGION &&
        (!isLoadingQuickReport || hasDateChanged) &&
        clearData &&
        !quickReportError && (
          <div className="timespan-selection-info-message">
            <WppTypography type="m-strong" className="span">
              {noTimespanSelectionMessageForCost}
            </WppTypography>
          </div>
        )}
      {fieldsSelected && quickReportError && (
        <WppGrid container rowSpacing={4} className="error-message">
          <WppGrid item all={24} fluid fullWidth>
            <WppTypography type="m-strong" className="span">
              {errorMessage}
            </WppTypography>
          </WppGrid>
        </WppGrid>
      )}
      {fieldsSelected && !quickReportError && !clearData && (
        <div className="rendering-charts-section">
          <div
            className={`charts-container ${
              showRegenerateReportMessage && 'disable'
            }`}
          >
            <div className="single-chart-container">
              {isLoadingQuickReport ? (
                <LoadingElement
                  size="l"
                  message={
                    reportTypeSelection ===
                    EQuickReportTypes.AGENCY_COST_PER_REGION
                      ? 'Loading chart data... This may take a few minutes'
                      : 'Loading chart data...'
                  }
                />
              ) : (
                quickReportData && (
                  <ReactEcharts
                    option={getOptions(
                      quickReportData,
                      chartTitle,
                      chartSubtitle
                    )}
                    onEvents={{
                      click: (event, chart) =>
                        onChartClick(
                          event,
                          chart,
                          quickReportData,
                          chartTitle,
                          chartSubtitle
                        ),
                    }}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )}
      {isExportReportButtonVisible && !clearData && (
        <div className="export-container">
          <WppButton
            variant="primary"
            size="s"
            onClick={onExportReport}
            disabled={isExportReportButtonDisabled}
            loading={isLoadingQuickReportExportFile}
          >
            Export Report
          </WppButton>
        </div>
      )}
    </div>
  );
}
