import { IRegistryFilters } from '../../pages/Dashboard/ProjectRegistry/FilterBar';
import { IRegistryDataSet, IRegistryDataSetById } from '../types';

export const mapFilters = (filters: IRegistryFilters) => {
  const mappedFilters = {};
  Object.entries(filters).forEach(([filterKey, filterValue]) => {
    let value = filterValue;
    if (filterKey === 'projectType') {
      if (value === 'ngpi') {
        value = 'NGPi';
      } else {
        value = value.toUpperCase();
      }
    }
    mappedFilters[filterKey] = value;
  });
  return mappedFilters;
};

export const mapData = (data: IRegistryDataSetById): IRegistryDataSetById => {
  const mapFields = (item: IRegistryDataSet): IRegistryDataSet => {
    const mappedItem = { ...item };
    Object.entries(item).forEach(([key, value]) => {
      let mappedValue = value;
      if (key.indexOf('Date') > -1) {
        [mappedValue] = value.split(' ');
      }
      mappedItem[key] = mappedValue;
    });
    return mappedItem;
  };
  return {
    Pending:
      data.Pending && data.Pending.length ? data.Pending.map(mapFields) : [],
    Active: data.Active && data.Active.length ? data.Active.map(mapFields) : [],
    Deleted:
      data.Deleted && data.Deleted.length ? data.Deleted.map(mapFields) : [],
  };
};

export const mergeData = (
  previousData: IRegistryDataSetById,
  data: IRegistryDataSetById
) => {
  const getPreviousItem = (status, itemId) => {
    const [previousItem] = previousData[status].filter(
      ({ id }) => id === itemId
    );
    if (previousItem && previousItem.id === itemId) {
      return previousItem;
    }
    return null;
  };
  const merge = (status) => {
    const mergedItems = [];
    data[status].forEach((item) => {
      const previousItem = getPreviousItem(status, item.id);
      mergedItems.push({
        ...previousItem,
        ...item,
      });
    });
    return mergedItems;
  };
  const mergedData = {};
  Object.keys(data).forEach((status) => {
    mergedData[status] = merge(status);
  });
  return mergedData;
};
