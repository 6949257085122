import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IRoleStore } from './types';
import { dswRequest } from './index';
import { API_BASE_URL } from '../config/config';
import { toTitleCase } from '../utils';

export const initialState: IRoleStore = {
  roles: [],
  isRolesLoading: false,
  role: {
    id: -1,
    name: '',
    isDeleted: false,
    access: [],
    whenCreated: '',
    whenModified: '',
  },
  isRoleLoading: false,
  actions: {
    getRolesApi: () => Promise.resolve(),
    getRoleApi: () => Promise.resolve(),
  },
};

export const useRoleStore = create<IRoleStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getRolesApi: async () => {
          const { roles, isRolesLoading } = get();
          if (roles.length === 0 && !isRolesLoading) {
            set((state) => ({
              ...state,
              isRolesLoading: true,
            }));
            try {
              const url = `${API_BASE_URL}/roles`;
              const { data, error } = await dswRequest.get(url);
              if (!error) {
                set((state) => ({
                  ...state,
                  roles: data,
                }));
              }
            } finally {
              set((state) => ({
                ...state,
                isRolesLoading: false,
              }));
            }
          }
        },
        getRoleApi: async (callback) => {
          const { role, isRoleLoading } = get();
          if (role.id === -1 && !isRoleLoading) {
            set((state) => ({
              ...state,
              isRoleLoading: true,
            }));
            try {
              const url = `${API_BASE_URL}/user/role`;
              const { data, error } = await dswRequest.get(url);
              if (callback) {
                callback(role);
              }
              if (!error) {
                set((state) => ({
                  ...state,
                  role: {
                    ...data,
                    name: toTitleCase(data.name),
                    access: data.access || [],
                  },
                }));
              }
            } finally {
              set((state) => ({
                ...state,
                isRoleLoading: false,
              }));
            }
          }
        },
      },
    }))
  )
);
