import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useOs } from '../hooks/osWrapper';
import OktaSecurity from '../components/OktaSecurity/OktaSecurity';
import App from './App';
import '@platform-ui-kit/components-library/dist/platform-ui-kit/global.css';
import '@platform-ui-kit/components-library/dist/platform-ui-kit/wpp-theme.css';
import '../index.css';

export default function AppContainer() {
  const { osContext, osApi } = useOs();

  // eslint-disable-next-line no-console
  console.log('Loading Data Science Workbench v1.6.3-3.....');
  return (
    <React.StrictMode>
      <BrowserRouter basename={osContext.baseUrl}>
        <OktaSecurity>
          <App />
        </OktaSecurity>
      </BrowserRouter>
    </React.StrictMode>
  );
}
