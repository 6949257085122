/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  WppTypography,
  WppIconTableSort,
  WppIconTableSortAsc,
  WppIconTableSortDesc,
} from '@platform-ui-kit/components-library-react';
import { IHeaderParams } from 'ag-grid-community';

export default function HeaderCell(props: IHeaderParams) {
  const { enableSorting, column, displayName, setSort } = props;
  const [sortType, setSortType] = useState<string | null>(null);
  const handleHeaderCellClick = () => {
    if (!enableSorting) return;
    const items: ('asc' | 'desc' | null)[] = ['asc', 'desc', null];
    const selectedSort = column.getSort();
    const selectedSortIndex = selectedSort ? items.indexOf(selectedSort) : -1;
    const nextSortType =
      selectedSortIndex === -1 || selectedSortIndex === items.length - 1
        ? 'asc'
        : items[selectedSortIndex + 1];
    setSortType(nextSortType);
    setSort(nextSortType);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="ag-header-cell-comp"
      onClick={() => handleHeaderCellClick()}
    >
      <div>
        <WppTypography type="s-strong">{displayName}</WppTypography>
      </div>
      {enableSorting && (
        <div className="ag-sorting">
          {sortType &&
            (sortType === 'desc' ? (
              <WppIconTableSortDesc />
            ) : (
              <WppIconTableSortAsc />
            ))}
          {!sortType && <WppIconTableSort />}
        </div>
      )}
    </div>
  );
}
