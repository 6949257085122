import React from 'react';
import {
  WppToggle,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import { IAccessRequestFormData } from '../types';

export interface ISummaryAndSubmitParams {
  get: () => IAccessRequestFormData;
  agreedDisclaimer: boolean;
  setAgreedDisclaimer: (agreed: boolean) => void;
}

function SummaryAndSubmit(params: ISummaryAndSubmitParams) {
  const { get, agreedDisclaimer, setAgreedDisclaimer } = params;
  const { user, project, team, datasets, commentsByRegion } = get();
  const datasetsByRegion = {};
  datasets.forEach((dataset) => {
    if (!datasetsByRegion[dataset.region]) {
      datasetsByRegion[dataset.region] = [];
    }
    datasetsByRegion[dataset.region] = [
      ...datasetsByRegion[dataset.region],
      dataset,
    ];
  });

  return (
    <section className="summary-and-submit">
      <div className="step">
        <span className="row-1 user-label">
          <span className="col-1">
            <WppTypography className="section-label" type="m-strong" tag="h3">
              Requestor&apos;s Details
            </WppTypography>
          </span>
          <span className="col-1">&nbsp;</span>
          <span className="col-1">
            <span className="label">Name & Surname</span>
          </span>
          <span className="col-1">
            <span className="label">Email</span>
          </span>
          <span className="col-1">
            <span className="label">Company Name</span>
          </span>
        </span>
        <span className="row-1 user-value">
          <span className="col-1">&nbsp;</span>
          <span className="col-1">&nbsp;</span>
          <span className="col-1">{user.name}</span>
          <span className="col-1" title={user.email}>
            {user.email}
          </span>
          <span className="col-1">{user.agency}</span>
        </span>
      </div>
      <div className="step project-details">
        <span className="row-1">
          <span className="col-1">
            <WppTypography className="section-label" type="m-strong" tag="h3">
              Project Details
            </WppTypography>
          </span>
          <span className="col-1">&nbsp;</span>
          <span className="col-1">
            <span className="label">Reason for Request</span>
          </span>
          <span className="col-1">
            <span className="label">Project Name</span>
          </span>
          <span className="col-1">
            <span className="label">Project Belongs to</span>
          </span>
          <span className="col-1">
            <span className="label">Region(s)</span>
          </span>
        </span>
        <span className="row-1">
          <span className="col-1">&nbsp;</span>
          <span className="col-1">&nbsp;</span>
          <span className="col-1">{project.reason}</span>
          <span className="col-1">{project.name}</span>
          <span className="col-1">{project.company}</span>
          <span className="col-1">{project.regions.join(', ')}</span>
        </span>
      </div>
      <div className="step">
        <span className="row-1 team-label">
          <span className="col-1">
            <WppTypography className="section-label" type="m-strong" tag="h3">
              Team Details
            </WppTypography>
          </span>
          <span className="col-1">&nbsp;</span>
          <span className="col-1">
            <span className="label">Name & Surname</span>
          </span>
          <span className="col-1">
            <span className="label">E-mail</span>
          </span>
          <span className="col-1">
            <span className="label">Region</span>
          </span>
          <span className="col-1">
            <span className="label">Role</span>
          </span>
        </span>
        {team.map((member, index) => (
          <span className="row-1 team-member" key={`team-${index}`}>
            <span className="col-1">&nbsp;</span>
            <span className="col-1">
              <span className="label">{`User ${index + 1}`}</span>
            </span>
            <span className="col-1">{member.name}</span>
            <span className="col-1" title={member.email}>
              {member.email}
            </span>
            <span className="col-1">{member.region}</span>
            <span className="col-1">{member.role}</span>
          </span>
        ))}
      </div>
      <div className="step">
        <span className="row-1 datasets">
          <span className="col-1">
            <WppTypography className="section-label" type="m-strong" tag="h3">
              Data Sets
            </WppTypography>
          </span>
          {Object.keys(datasetsByRegion).map((region) => (
            <span className="col-2" key={region}>
              <span className="label">For Region {region}</span>
            </span>
          ))}
        </span>
        <span className="row-1 datasets">
          <span className="col-1">&nbsp;</span>
          {Object.keys(datasetsByRegion).map((region) => (
            <span className="col-2" key={region}>
              {datasetsByRegion[region].map(
                ({ source, dataset, aoIds, useCase }) => (
                  <span
                    className="dataset-item"
                    key={`${region}-${source}-${dataset}`}
                  >
                    {source?.indexOf('Audience Origin') > -1 &&
                      (aoIds || useCase ? (
                        <WppTooltip
                          className="source"
                          text={`Use AO IDs: ${
                            aoIds ? 'yes' : 'no'
                          }, Use Case: "${useCase}"`}
                        >
                          <span className="source">{source}</span>
                        </WppTooltip>
                      ) : (
                        <span className="source">{source}</span>
                      ))}
                    {source?.indexOf('Other') > -1 &&
                      (commentsByRegion[region] ? (
                        <WppTooltip
                          className="source"
                          text={`Comments: "${commentsByRegion[region]}"`}
                        >
                          <span>{source}</span>
                        </WppTooltip>
                      ) : (
                        <span>{source}</span>
                      ))}
                    {!(
                      source?.indexOf('Audience Origin') > -1 ||
                      source?.indexOf('Other') > -1
                    ) && <span className="source">{source}</span>}
                    <span className="dataset">{dataset}</span>
                  </span>
                )
              )}
            </span>
          ))}
        </span>
      </div>
      <div className="disclaimer">
        <WppTypography className="section-label" type="m-strong" tag="h2">
          DISCLAIMER
        </WppTypography>
        <span className="row-1 disclaimer-field">
          <span className="col-1">
            <span className="disclaimer-text">
              I have read, understood and agree to the data governance rules
              mentioned{' '}
              <a
                href="https://insidemedia.sharepoint.com/sites/ChoreographDataGovernance"
                target="_blank"
                rel="noreferrer"
                className="disclaimer-url"
              >
                here
              </a>
              .
              <br />
              <br />
              I have read, understood, and agree to the principles below
              regarding the data I’ll be working with and that I’m responsible
              from informing my team:
              <br />
              <ul>
                <li>
                  I’m responsible to use these data assets solely for the
                  project described in the project details. Any usage outside of
                  the intended reason should be discussed with data owners and
                  legal first.
                </li>
                <li>
                  I agree not to download, remove, export, or extract the data
                  outside of Data Science Workbench environment or utilize the
                  data for other use-cases.
                </li>
                <li>
                  I agree not to share, transmit, distribute, or make available
                  the data to any non-approved user or any third party,
                  including clients.
                </li>
              </ul>
            </span>
          </span>
        </span>
        <span
          className={`row-1 disclaimer-toggle ${
            agreedDisclaimer ? 'no-border' : ''
          }`}
        >
          <span className="col-1">
            <WppToggle
              checked={agreedDisclaimer}
              onWppChange={({ detail: { checked } }) =>
                setAgreedDisclaimer(checked)
              }
            />
            <span>{agreedDisclaimer ? 'I agree' : 'I disagree'}</span>
          </span>
        </span>
      </div>
    </section>
  );
}

export default SummaryAndSubmit;
