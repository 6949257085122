import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
  WppActionButton,
  WppButton,
  WppIconChevron,
  WppSpinner,
} from '@platform-ui-kit/components-library-react';
import { useOs } from '../../../../hooks/osWrapper';
import { useRoleStore } from '../../../../stores/role';
import { useApprovalStore } from '../../../../stores/approval';
import useToast from '../../../../hooks/useToast';
import {
  EApprovalType,
  IAgencyContact,
  IApproval,
  IApprovalAdditionalInfo,
  IApprovalDataset,
  IApprovalProjectDetails,
  IDatasetRegion,
  IMember,
} from '../../../../stores/types';
import { useAgencyStore } from '../../../../stores/agency';
import { getFilteredDatasets } from '../../../../stores/mappers/RequestApproval';
import {
  RoleEnum,
  DEMO_NAMESPACE,
  REQUEST_STATUS_EDITABLE,
} from '../../../../constants';
import LoadingElement from '../../../../components/LoadingElement';
import { validateEmail } from '../../../../utils';
import UserSection from './UserSection';
import AgencySection from './AgencySection';
import ProjectSection from './ProjectSection';
import TeamSection from './TeamSection';
import DatasetSection from './DatasetSection';
import InfoSection from './InfoSection';

let initialLoad = true;
const initialLoadDone = () => {
  initialLoad = false;
};

export interface IAccessFormParams {
  approvalType: EApprovalType;
  approval: IApproval;
  approvalId: string;
  actionPending: boolean;
  userRole: string;
  saveApproval: (approval: IApproval, approvalId: string) => Promise<void>;
  submitApproval: (approval: IApproval, approvalId: string) => Promise<void>;
}

export function AccessForm(params: IAccessFormParams) {
  const {
    approvalType,
    approval: source,
    approvalId,
    actionPending,
    userRole,
    saveApproval,
    submitApproval,
  } = params;
  const [approval, setApproval] = useState<IApproval>(source);
  const [hideRequestorDetails] = useState<boolean>(approvalType !== 'access');
  const {
    datasets: ds,
    agency_contact_person_details: agency,
    project_details: project,
    requester_details: user,
    team_details: team,
    additional_info: info,
  } = approval;
  const {
    actions: { getAgencyContactApi },
  } = useAgencyStore();
  const setAgency = (agency_contact_person_details: IAgencyContact) => {
    let is_changed = false;
    if (
      agency_contact_person_details.name !==
        source.agency_contact_person_details.name ||
      agency_contact_person_details.email !==
        source.agency_contact_person_details.email
    ) {
      is_changed = true;
    }
    setApproval({
      ...approval,
      agency_contact_person_details: {
        ...agency_contact_person_details,
        is_changed,
      },
    });
  };
  const setProject = (
    rawUpdatedProjectDetails: IApprovalProjectDetails,
    agencyChange = false
  ) => {
    const is_changed = {
      namespace: false,
      requestReason: false,
      agency: false,
    };
    let updatedProjectDetails = rawUpdatedProjectDetails;
    if (
      updatedProjectDetails.request_reason !==
      source.project_details.request_reason
    ) {
      is_changed.requestReason = true;
    }
    if (
      updatedProjectDetails.request_reason !==
      source.project_details.request_reason
    ) {
      is_changed.namespace = true;
    }
    if (updatedProjectDetails.namespace !== source.project_details.namespace) {
      is_changed.namespace = true;
    }
    if (updatedProjectDetails.agency !== source.project_details.agency) {
      is_changed.agency = true;
    }
    if (
      updatedProjectDetails.request_reason === 'NGPi' &&
      is_changed.requestReason
    ) {
      updatedProjectDetails = {
        ...updatedProjectDetails,
        region_instance_url_map: { AMER: null },
      };
    }
    const deleted_regions = [
      ...Object.keys(source.project_details.region_instance_url_map).filter(
        (region) =>
          Object.keys(updatedProjectDetails.region_instance_url_map).indexOf(
            region
          ) === -1
      ),
    ];
    const t = team.filter(
      ({ region }) =>
        Object.keys(updatedProjectDetails.region_instance_url_map).indexOf(
          region
        ) > -1
    );
    const d = ds.filter(
      ({ instance_region }) =>
        Object.keys(updatedProjectDetails.region_instance_url_map).indexOf(
          instance_region
        ) > -1
    );
    if (
      !_.isEqual(
        updatedProjectDetails.region_instance_url_map,
        project.region_instance_url_map
      )
    ) {
      Object.keys(updatedProjectDetails.region_instance_url_map).forEach(
        (regionKey) => {
          if (
            d.filter(({ instance_region }) => instance_region === regionKey)
              .length < 1
          ) {
            d.push({
              ao_id: false,
              use_case_text: '',
              instance_region: regionKey,
              dataset_approval_map: {},
              dataset_region_map: {},
            });
          }
        }
      );
    }
    const a = { ...agency };
    if (agencyChange) {
      getAgencyContactApi(updatedProjectDetails.agency, ({ name, email }) => {
        setApproval({
          ...approval,
          project_details: {
            ...updatedProjectDetails,
            is_changed,
            deleted_regions,
          },
          datasets: d,
          team_details: t,
          agency_contact_person_details: agencyChange
            ? { email, name, is_changed: true }
            : a,
        });
      }).then();
    } else {
      setApproval({
        ...approval,
        project_details: {
          ...updatedProjectDetails,
          is_changed,
          deleted_regions,
        },
        datasets: d,
        team_details: t,
        agency_contact_person_details: agencyChange
          ? { email: '', name: '', is_changed: true }
          : a,
      });
    }
  };
  const setTeam = (team_details: IMember[]) =>
    setApproval({ ...approval, team_details });
  const setDataSets = (datasets: IApprovalDataset[]) =>
    setApproval({ ...approval, datasets });
  const setInfo = (additional_info: IApprovalAdditionalInfo) =>
    setApproval({ ...approval, additional_info });

  const filteredDatasets = getFilteredDatasets(ds);
  const allSourcesAreChecked =
    filteredDatasets.filter(
      ({ dataset_approval_map: dam }, index) =>
        Object.entries(dam).filter(
          ([key, val]) =>
            !!val ||
            filteredDatasets[index].dataset_region_map[key].filter(
              ({ is_deleted }) => !is_deleted
            ).length === 0
        ).length ===
        Object.keys(filteredDatasets[index].dataset_approval_map).length
    ).length === filteredDatasets.length && filteredDatasets.length > 0;
  const allSourcesAreSet =
    filteredDatasets.filter(
      ({ dataset_approval_map: dam }) =>
        Object.keys(dam).filter((key) => !!key).length > 0
    ).length === filteredDatasets.length;
  const hasChanges = !_.isEqual(source, approval);
  const teamNotEmpty = team.length > 0;
  const notDeletedHaveSetFields =
    team.filter(
      ({ name, email, region, role, is_deleted }) =>
        !!name && validateEmail(email) && !!region && !!role && !is_deleted
    ).length === team.filter(({ is_deleted }) => !is_deleted).length;

  const uniqueMemberPerRegion =
    team
      .filter(({ is_deleted }) => !is_deleted)
      .filter(
        (t) =>
          team.filter(
            (u) =>
              !u.is_deleted &&
              t.region === u.region &&
              (t.email === u.email || t.name === u.name)
          ).length === 1
      ).length === team.filter(({ is_deleted }) => !is_deleted).length;
  const everyRegionHasATeamLead =
    (approvalType === EApprovalType.TEAM &&
      project.namespace === DEMO_NAMESPACE) ||
    Object.keys(project.region_instance_url_map).filter(
      (region) =>
        team.filter(
          ({ region: r, role, is_deleted }) =>
            r === region && role === RoleEnum.TEAM_LEAD && !is_deleted
        ).length === 1
    ).length === ds.length;

  const teamHasInProgress = team.some(
    (teamMember) =>
      teamMember.status === REQUEST_STATUS_EDITABLE && !teamMember.is_deleted
  );

  const validTeam =
    teamNotEmpty &&
    notDeletedHaveSetFields &&
    uniqueMemberPerRegion &&
    everyRegionHasATeamLead;
  const allDatasetRegionsHaveEntries =
    filteredDatasets.filter(
      ({ dataset_region_map: dsm }) =>
        Object.values(dsm).filter(
          (dsmRegion: IDatasetRegion[]) =>
            dsmRegion.filter(
              (dsmRegionItem) =>
                !dsmRegionItem.is_deleted &&
                (approvalType !== EApprovalType.DATASET ||
                  (approvalType === EApprovalType.DATASET &&
                    dsmRegionItem.added_dataset))
            ).length > 0
        ).length > 0
    ).length ===
    filteredDatasets.filter(
      ({ dataset_region_map: dsm }) =>
        Object.values(dsm).filter(
          (dsmRegion: IDatasetRegion[]) =>
            dsmRegion.filter(
              (dsmRegionItem) =>
                approvalType !== EApprovalType.DATASET ||
                (approvalType === EApprovalType.DATASET &&
                  dsmRegionItem.added_dataset)
            ).length > 0
        ).length > 0
    ).length;

  const validToSave =
    hasChanges &&
    allSourcesAreSet &&
    validTeam &&
    ((approvalType !== EApprovalType.TEAM && allDatasetRegionsHaveEntries) ||
      approvalType === EApprovalType.TEAM);
  const validToSubmit =
    ((approvalType === EApprovalType.ACCESS && allSourcesAreChecked) ||
      approvalType !== EApprovalType.ACCESS) &&
    allSourcesAreSet &&
    ((approvalType !== EApprovalType.TEAM &&
      ((info && info.product_approval) || !info) &&
      ((info && info.agency_contact_person_approval) || !info)) ||
      approvalType === EApprovalType.TEAM) &&
    validTeam &&
    ((approvalType !== EApprovalType.TEAM && allDatasetRegionsHaveEntries) ||
      (approvalType === EApprovalType.TEAM && teamHasInProgress));

  const isAdmin = userRole === RoleEnum.ADMIN;
  return (
    <div className="approval-form">
      {!hideRequestorDetails && user && user.name && (
        <UserSection user={user} />
      )}
      <AgencySection agency={agency} setAgency={setAgency} />
      <ProjectSection
        isAdmin={isAdmin}
        project={project}
        setProject={setProject}
        approvalType={approvalType}
      />
      {approvalType !== EApprovalType.DATASET && (
        <TeamSection
          isAdmin={isAdmin}
          team={team}
          project={project}
          setTeam={setTeam}
          approvalType={approvalType}
        />
      )}
      {approvalType !== EApprovalType.TEAM && (
        <DatasetSection
          isAdmin={isAdmin}
          datasets={ds}
          project={project}
          setDataSets={setDataSets}
          approvalType={approvalType}
        />
      )}
      {approvalType !== EApprovalType.TEAM && info && (
        <InfoSection
          isAdmin={isAdmin}
          info={info}
          setInfo={setInfo}
          approvalType={approvalType}
        />
      )}
      <div className="buttons">
        <WppActionButton
          disabled={!validToSave || actionPending}
          loading={actionPending}
          onClick={() => saveApproval(approval, approvalId)}
        >
          SAVE
        </WppActionButton>
        {isAdmin && (
          <WppButton
            disabled={!validToSubmit || actionPending}
            size="m"
            loading={actionPending}
            onClick={() => submitApproval(approval, approvalId)}
          >
            SUBMIT
          </WppButton>
        )}
      </div>
    </div>
  );
}

export default function RequestList(params) {
  const { approvalType } = params;
  const { osContext, osApi } = useOs();
  const url = new URL(window.location.href);
  const requestId = url.searchParams.get('requestId');

  const { showToast } = useToast();
  const navigate = useNavigate();
  const [loadApproval, setLoadApproval] = useState<string>(requestId);
  const [expandedApproval, setExpandedApproval] = useState<string>(requestId);
  const [actionPending, setActionPending] = useState<boolean>(false);
  const { name: userRole } = useRoleStore((state) => state.role);
  const {
    approvalList,
    approvals,
    approvalsErrors,
    approvalsLoading,
    approvalListLoading,
    approvalType: selectedApprovalType,
    actions: { getApprovalList, getApproval, submitApproval, saveApproval },
  } = useApprovalStore();

  useEffect(() => {
    if (loadApproval && approvalList && approvalList[loadApproval]) {
      getApproval(approvalType, loadApproval).then();
    }
  }, [
    approvalList,
    approvalType,
    selectedApprovalType,
    getApproval,
    loadApproval,
    approvalListLoading,
  ]);

  useEffect(() => {
    getApprovalList(approvalType, true).then();
  }, [getApprovalList, approvalType]);

  if (
    approvalList &&
    Object.keys(approvalList).length > 0 &&
    loadApproval &&
    selectedApprovalType === approvalType &&
    !approvalList[loadApproval] &&
    !approvalListLoading
  ) {
    window.history.replaceState(
      window.history.state,
      '',
      `${
        osContext.baseUrl.startsWith('/')
          ? osContext.baseUrl
          : `/${osContext.baseUrl}/`
      }dashboard/request/${approvalType}`
    );
  }

  useEffect(() => {
    if (expandedApproval) {
      navigate(
        `/dashboard/request/${approvalType}?requestId=${expandedApproval}`
      );
    } else {
      navigate(`/dashboard/request/${approvalType}`);
    }
  }, [approvalType, expandedApproval, navigate]);

  useEffect(() => {
    if (
      initialLoad &&
      Object.keys(approvals).length > 0 &&
      Object.keys(approvalList).length > 0 &&
      requestId
    ) {
      initialLoadDone();
      setTimeout(() => {
        const node = document.querySelector('.approval.expanded');
        node.scrollIntoView();
      }, 100);
    }
  }, [approvals, approvalList, requestId]);

  const doSaveApproval = async (
    approval: IApproval,
    approvalId: string,
    hideMessage = false,
    onSuccess = null
  ) => {
    setActionPending(true);

    await saveApproval(
      approvalType,
      approval,
      approvalId,
      ({ data, error }) => {
        if ((error && hideMessage) || !hideMessage) {
          showToast({
            header: error
              ? 'Error while Saving Approval'
              : 'Successfully Saved Approval',
            message: error ? error.message : data.message,
            type: error ? 'error' : 'success',
            duration: 10000,
          });
        }
        setActionPending(false);
        if (!error) {
          getApprovalList(approvalType, true).then();
          if (approvalId) {
            getApproval(approvalType, approvalId, true).then(() => {
              if (onSuccess) {
                onSuccess();
              }
            });
          }
        }
      }
    ).then();
  };
  const doSubmitApproval = async (approval: IApproval, approvalId: string) => {
    setActionPending(true);
    await doSaveApproval(approval, approvalId, true, async () => {
      setActionPending(true);
      await getApproval(approvalType, approvalId, true);
      setActionPending(true);
      await submitApproval(approvalType, approvalId, ({ data, error }) => {
        showToast({
          header: error
            ? 'Error while Submitting Approval'
            : 'Successfully Submitted Approval',
          message: error ? error.message : data.message,
          type: error ? 'error' : 'success',
          duration: 10000,
        });
        setActionPending(false);
        if (!error) {
          getApprovalList(approvalType, true).then(() => {
            if (approvalId) {
              getApproval(approvalType, approvalId, true).then();
            }
          });
        }
      }).then();
    });
  };

  const handleApprovalClick = (approvalId) => {
    if (expandedApproval === approvalId) {
      setExpandedApproval('');
    } else {
      setExpandedApproval(approvalId);
      setLoadApproval(approvalId);
    }
  };

  const getAccessContent = (approvalId) => {
    if (approvalsLoading[approvalId]) {
      return <WppSpinner />;
    }
    if (approvalsErrors[approvalId]) {
      return (
        <span className="approval-error">{approvalsErrors[approvalId]}</span>
      );
    }

    if (approvals[approvalId]) {
      return (
        <span className="approval-success">
          <AccessForm
            approvalType={approvalType}
            approval={approvals[approvalId]}
            approvalId={approvalId}
            submitApproval={doSubmitApproval}
            saveApproval={doSaveApproval}
            actionPending={actionPending}
            userRole={userRole}
          />
        </span>
      );
    }
    return null;
  };

  const getAccessPage = () => {
    return Object.keys(approvalList)
      .reverse()
      .map((approvalId) => {
        const { namespace_name, agency, regions } = approvalList[approvalId];
        const isExpanded = expandedApproval === approvalId;
        return (
          <React.Fragment key={`approval-${approvalId}`}>
            <button
              type="button"
              className={`approval ${isExpanded ? 'expanded' : ''}`}
              onClick={() => handleApprovalClick(approvalId)}
            >
              {isExpanded ? (
                <WppIconChevron direction="down" />
              ) : (
                <WppIconChevron direction="right" />
              )}
              <span className="title">
                <span className="id">ID-{approvalId}</span>
                <span className="info">
                  {agency} &middot; {namespace_name}
                </span>
                <span className="regions">
                  {[...Array.from(new Set(regions))].sort().map((region) => (
                    <span
                      key={`title-regions-${region}`}
                      className={`region ${region}`}
                    >
                      {region}
                    </span>
                  ))}
                </span>
              </span>
            </button>
            {isExpanded && (
              <span className="approval-content">
                {getAccessContent(approvalId)}
              </span>
            )}
          </React.Fragment>
        );
      });
  };

  if (Object.keys(approvals).length === 0 && !loadApproval && requestId) {
    handleApprovalClick(requestId);
  }

  const getRequest = () => {
    if (approvalType && approvalList && Object.keys(approvalList).length > 0) {
      return getAccessPage();
    }
    return approvalListLoading ? (
      <LoadingElement />
    ) : (
      <span className="empty-table">No Records found</span>
    );
  };

  return <div className="request">{getRequest()}</div>;
}
