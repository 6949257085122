import React, { useState } from 'react';
import FilterBar, { ICostReportingFilters } from './FilterBar';
import CostSummary from './CostSummary';
/* import ProjectList from './ProjectList'; */
import './index.scss';

export default function CostReporting() {
  const [filters, setFilters] = useState<ICostReportingFilters>(
    {} as ICostReportingFilters
  );
  const updateFilters = (updatedFilters: ICostReportingFilters) => {
    const cleanUpdatedFilters = {} as ICostReportingFilters;
    Object.entries(updatedFilters).forEach(([key, val]) => {
      if (key && val && val !== 'ALL' && val !== '%EMPTY%') {
        cleanUpdatedFilters[key] = val;
      }
    });
    setFilters(cleanUpdatedFilters);
  };

  return (
    <div className="cost-reporting">
      <FilterBar updateFilters={updateFilters} />
      <CostSummary filters={filters} />
      {/* <ProjectList filters={filters} /> */}
    </div>
  );
}
