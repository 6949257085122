import React from 'react';
import {
  WppActionButton,
  WppButton,
  WppModal,
} from '@platform-ui-kit/components-library-react';

export interface ITechnicalIssuesModalParams {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}

export default function TechnicalIssuesModal(
  params: ITechnicalIssuesModalParams
) {
  const { setShowModal, showModal } = params;

  return (
    <WppModal open={showModal}>
      <div slot="header">Technical Issues</div>
      <div slot="body">
        <p>
          We are currently having some technical issues and you might encounter
          access problems. Our team is trying to resolve the issue as soon as
          possible. We are sorry for the inconvenience.
        </p>
      </div>
      <div slot="actions">
        <WppActionButton
          variant="secondary"
          title="Contact Support"
          onClick={() => {
            window.open(
              'https://chos-help.choreograph.com/hc/en-us/requests/new',
              '_blank'
            );
          }}
        >
          Contact Support
        </WppActionButton>

        <WppButton
          variant="primary"
          size="s"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Dismiss
        </WppButton>
      </div>
    </WppModal>
  );
}
