export interface I18n {
  QUICK_REPORT: {
    DATASET: {
      SELECT: string;
      TITLE: string;
      SUB: string;
    };
    USER_AGENCY: {
      SELECT: string;
      TITLE: string;
      SUB: string;
    };
    PROJECT_AGENCY: {
      SELECT: string;
      TITLE: string;
      SUB: string;
    };
    PROJECT_REGION: {
      SELECT: string;
      TITLE: string;
      SUB: string;
    };
    AGENCY_COST_PER_REGION: {
      SELECT: string;
      TITLE: string;
      SUB: string;
    };
    AGENCY_COST_PER_PROJECT_TYPE: {
      SELECT: string;
      TITLE: string;
      SUB: string;
    };
  };
}

export default {
  QUICK_REPORT: {
    DATASET: {
      SELECT: 'Data Set Usage',
      TITLE: 'Dataset usage',
      SUB: 'Click on a segment to see agency distributions.',
    },
    USER_AGENCY: {
      SELECT: 'Total user distribution per Agency',
      TITLE: 'Total user distribution per agency',
      SUB: 'Click on a segment to see regional distributions.',
    },
    PROJECT_AGENCY: {
      SELECT: 'Project Type distribution per Agency',
      TITLE: 'Number of projects per agency',
      SUB: 'Click on a segment to see project type distributions.',
    },
    PROJECT_REGION: {
      SELECT: 'Project Type distribution per Region',
      TITLE: 'Distribution of projects per type',
      SUB: 'Click on a segment to see regional distributions.',
    },
    AGENCY_COST_PER_REGION: {
      SELECT: 'Agency Cost Distribution Per Region',
      TITLE: 'Agency Cost Distribution Per Region',
      SUB: 'Click on a segment to see regional distributions.',
    },
    AGENCY_COST_PER_PROJECT_TYPE: {
      SELECT: 'Agency Cost Distribution Per Project Type',
      TITLE: 'Agency Cost Distribution Per Project Type',
      SUB: 'Click on a segment to see regional distributions.',
    },
  },
} as I18n;
