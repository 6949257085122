import React, { useEffect } from 'react';
import { useNavigate, useLocation, generatePath } from 'react-router-dom';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { useOs, useOsWrapper } from '../../hooks/osWrapper';
import * as ROUTE_PATHS from '../../routes/routePaths';
import LoadingElement from '../LoadingElement';
import ErrorPage from '../ErrorPage';
import './LoginRedirect.scss';

function LoginRedirect() {
  const { authState } = useOktaAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { osContext } = useOs();
  const isAuthenticated =
    authState && !authState.isPending && authState.isAuthenticated;

  useEffect(() => {
    if (isAuthenticated) {
      navigate(osContext.baseUrl, { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <LoginCallback
      errorComponent={ErrorPage}
      loadingElement={
        <div className="loginRedirect">
          <LoadingElement />
        </div>
      }
    />
  );
}

export default LoginRedirect;
