import React from 'react';
import {
  WppButton,
  WppInput,
  WppLabel,
  WppModal,
} from '@platform-ui-kit/components-library-react';
import { ISettings } from '../../stores/types';

export interface ISettingsModalParams {
  showSettings: boolean;
  updatedSettings: ISettings;
  setShowSettings: (showSettings: boolean) => void;
  setUpdatedSettings: (settings: ISettings) => void;
  setSettings: (settings: ISettings) => void;
  showToast: (toastConfig) => void;
}

export default function SettingsModal(params: ISettingsModalParams) {
  const {
    showSettings,
    updatedSettings,
    setShowSettings,
    setUpdatedSettings,
    setSettings,
  } = params;

  return (
    <WppModal open={showSettings}>
      <div slot="header">Settings</div>
      <div slot="body">
        <h3>Data Sets & Data Catalogs</h3>
        <p>Multiple values are comma separated</p>
        <WppLabel>Allowed Databases</WppLabel>
        <WppInput
          value={
            updatedSettings?.DatasetsAndCatalogs?.allowedDatabases?.join(',') ||
            null
          }
          onWppChange={(e) =>
            setUpdatedSettings({
              ...(updatedSettings || {}),
              DatasetsAndCatalogs: {
                ...(updatedSettings?.DatasetsAndCatalogs || {}),
                allowedDatabases: e.detail.value.split(','),
              },
            })
          }
        />
        <br />
        <WppLabel>Allowed Schemas</WppLabel>
        <WppInput
          value={
            updatedSettings?.DatasetsAndCatalogs?.allowedSchemas?.join(',') ||
            null
          }
          onWppChange={(e) =>
            setUpdatedSettings({
              ...(updatedSettings || {}),
              DatasetsAndCatalogs: {
                ...(updatedSettings?.DatasetsAndCatalogs || {}),
                allowedSchemas: e.detail.value.split(','),
              },
            })
          }
        />
      </div>
      <div slot="actions">
        <WppButton
          variant="secondary"
          size="s"
          onClick={() => {
            setSettings(updatedSettings);
          }}
        >
          Save
        </WppButton>{' '}
        <WppButton
          variant="primary"
          size="s"
          onClick={() => {
            setShowSettings(false);
          }}
        >
          Close
        </WppButton>
      </div>
    </WppModal>
  );
}
