import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IRegionStore } from './types';
import { dswRequest } from './index';
import { API_BASE_URL } from '../config/config';

export const initialState: IRegionStore = {
  regions: [],
  regionsByAgency: [],
  isLoading: false,
  actions: {
    getRegionApi: () => Promise.resolve(),
    getRegionsByAgencyNameApi: () => Promise.resolve(),
    reset: () => undefined,
  },
};

export const useRegionStore = create<IRegionStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getRegionApi: async () => {
          const { regions, isLoading } = get();
          if (regions.length === 0 && !isLoading) {
            set((state) => ({
              ...state,
              isLoading: true,
            }));
            try {
              const url = `${API_BASE_URL}/region/all`;
              const apiResponse = await dswRequest.get(url);
              set((state) => ({
                ...state,
                regions: apiResponse.data,
              }));
            } finally {
              set((state) => ({
                ...state,
                isLoading: false,
              }));
            }
          }
        },
        getRegionsByAgencyNameApi: async (agencyName, userMail) => {
          set((state) => ({
            ...state,
            isLoading: true,
          }));
          try {
            const url = `${API_BASE_URL}/regions/${agencyName}/user`;
            const apiResponse = await dswRequest.get(url);
            set((state) => ({
              ...state,
              regionsByAgency: apiResponse.data,
            }));
          } finally {
            set((state) => ({
              ...state,
              isLoading: false,
            }));
          }
        },
        reset: () => set((state) => ({ ...state, regionsByAgency: [] })),
      },
    }))
  )
);
