import { WppPill } from '@platform-ui-kit/components-library-react';
import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library';

import React from 'react';
import styles from './Autocomplete.module.scss';

interface SelectedValuesComponent {
  values: AutocompleteDefaultOption[];
  onCloseClick: (a: number | string) => void;
}

export default function SelectedValues({
  values,
  onCloseClick,
}: SelectedValuesComponent) {
  return (
    <div slot="selected-values" className={styles.selectedValues}>
      {values.map((value) => (
        <WppPill
          label={value.label}
          removable
          value={value.id}
          onWppClose={() => onCloseClick(value.id)}
          type="display"
          className={styles.pill}
          key={value.id}
        >
          {value.label}
        </WppPill>
      ))}
    </div>
  );
}
