import { useEffect, useState } from 'react';
import { WppToast } from '@platform-ui-kit/components-library/components/wpp-toast';

const useToast = () => {
  const [toastRef, setToastRef] =
    useState<HTMLWppToastContainerElement | null>();

  const showToast = (config) => {
    toastRef?.addToast(config).then();
  };

  useEffect(() => {
    setToastRef(
      document.querySelector<HTMLWppToastContainerElement>(
        '.wpp-toast-container'
      )
    );
  }, [setToastRef]);

  return {
    showToast,
    toastRef: toastRef as unknown as {
      toasts: WppToast[];
      removeToastById: (toastId: string) => void;
    },
  };
};

export default useToast;
