import React from 'react';

export default function TutorialsAndDocumentations() {
  return (
    <div className="tutorials">
      <iframe
        src="https://insidemedia-my.sharepoint.com/personal/tobias_scheer_choreograph_com/_layouts/15/embed.aspx?UniqueId=4cfe5540-f998-4fc5-b011-4de851e4b233&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
        width="853"
        height="480"
        allowFullScreen
        title="DS Workbench - Technical Demo"
      >
        &nbsp;
      </iframe>
    </div>
  );
}
