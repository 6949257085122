import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IDBStatsStore } from './types';
import { dswRequest } from './index';
import { API_BASE_URL } from '../config/config';

export const getTableDescription = (table) => {
  switch (table) {
    case 'AGENCY_IDN_CPG_BAKING_COOKING':
    case 'AGENCY_IDN_CPG_BEVERAGES':
    case 'AGENCY_IDN_CPG_CANDIES_SNACKS_TOBACCO':
    case 'AGENCY_IDN_CPG_BREAKFAST_DAIRY':
    case 'AGENCY_IDN_CPG_PASTA_PREPARED':
    case 'AGENCY_IDN_CPG_CONDIMENTS_SAUCES_SEASONINGS':
    case 'AGENCY_IDN_CPG_BABY_PERSONAL_BEAUTY':
    case 'AGENCY_IDN_CPG_HOUSEHOLD_PAPER_PET':
    case 'AGENCY_IDN_CPG_MEAT_SEAFOOD_FRUIT_VEG':
    case 'AGENCY_IDN_CPG_CATEGORIES':
      return 'Consumer Packaged Goods (CPG) loyalty card data (across 151+ categories, 10K+ brands and 300K+ products) collected from U.S. grocery retailers combined with the deep AmerLINK dataset';
    case 'AGENCY_IDN_DEMOGRAPHICS':
      return 'Human insights like age, family makeup and homeownership';
    case 'AGENCY_IDN_DIGITAL_INSIGHTS':
    case 'AGENCY_IDN_DIGITAL_INSIGHTS_PART2':
      return 'Behavior data across different categories';
    case 'AGENCY_IDN_FINANCE_INVESTING':
      return 'Finance and Investing Insights';
    case 'AGENCY_IDN_HEALTH':
      return 'Health insights';
    case 'AGENCY_IDN_LIFESTYLE':
      return 'Consumer interest and hobbies created from multiple survey and license sources ';
    case 'AGENCY_IDN_NONPROFIT':
      return 'Donor insights by category';
    case 'AGENCY_IDN_PROPERTY':
      return 'Property insights sourced from tax assessor/deed information including tax coverage on 3000+ counties and property deed on 98% of US population';
    case 'AGENCY_IDN_RETAIL_BUYER_PROPENSITIES':
      return 'Likelihood of buying a retail product';
    case 'AGENCY_IDN_SEGMENTATION_PART1':
      return 'Aggregated and 3rd Party proprietary segmentation insights';
    case 'AGENCY_IDN_SURVEY':
      return 'Audience Origin survey data';
    case 'AGENCY_IDN_VEHICLE':
      return 'Vehicle insights compiled from vehicle warranty and after market purchases - available in IDN agencies for Pitch Work Only';
    case 'AGENCY_IDN_CAN_DEMOGRAPHICS':
      return 'Canadian Demographic data';
    case 'AGENCY_IDN_CAN_DIGITAL_INSIGHTS':
    case 'AGENCY_IDN_CAN_DIGITAL_INSIGHTS_PART2':
      return 'Canadian Digital Insights data';
    default:
      return 'No Table Description available';
  }
};

export const featureImportanceTables = [
  'AGENCY_IDN_DEMOGRAPHICS',
  'AGENCY_IDN_FINANCE_INVESTING',
  'AGENCY_IDN_HEALTH',
  'AGENCY_IDN_LIFESTYLE',
  'AGENCY_IDN_RETAIL_BUYER_PROPENSITIES',
  'AGENCY_IDN_SEGMENTATION_PART1',
  'AGENCY_IDN_SURVEY',
];

export const initialState: IDBStatsStore = {
  database: {
    loading: false,
    error: null,
    data: [],
  },
  schema: {
    loading: false,
    error: null,
    data: [],
  },
  tables: {
    loading: false,
    error: null,
    data: [],
  },
  columns: {
    loading: false,
    error: null,
    data: [],
  },
  'base-stats': {
    loading: false,
    error: null,
    data: [],
  },
  categoryLabels: {},
  categoryLabelsLoading: [],
  actions: {
    getApi: () => Promise.resolve(),
    getAdvancedApi: () => Promise.resolve(),
    getCategoryLabels: () => Promise.resolve(),
  },
};

export const useDBStatsStore = create<IDBStatsStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getApi: async (source, database = null, schema = null) => {
          const {
            [source]: { loading },
          } = get();
          if (!loading) {
            set((state) => ({
              ...state,
              [source]: {
                ...state[source],
                loading: true,
              },
            }));

            try {
              let url = `${API_BASE_URL}/dbstats/${source}`;
              if (database) {
                url += `?database=${database}`;
              }
              if (schema) {
                url += `&schema=${schema}`;
              }
              const response = await dswRequest.get(url);
              const e = response.error;
              let d = response.data;
              let isDataArray = Array.isArray(d);

              // Handle data stream
              if (!isDataArray) {
                d = JSON.parse(d);
                isDataArray = Array.isArray(d);
              }

              const error = !isDataArray ? e?.message || 'Request failed' : e;
              if (source === 'tables') {
                const sub = { data: [], loading: false, error: null };
                set((state) => ({
                  ...state,
                  [source]: {
                    ...state[source],
                    data: error
                      ? []
                      : [...d]
                          .sort((a, b) =>
                            a.TABLE_NAME > b.TABLE_NAME ? 1 : -1
                          )
                          .map((item) => ({
                            ...item,
                            correlation: { ...sub },
                            features: { ...sub },
                          })),
                    error,
                  },
                }));
              } else {
                set((state) => ({
                  ...state,
                  [source]: {
                    ...state[source],
                    data: error ? [] : d,
                    error,
                  },
                }));
              }
            } catch (e) {
              set((state) => ({
                ...state,
                [source]: {
                  ...state[source],
                  data: [],
                  error: e.message,
                },
              }));
            } finally {
              set((state) => ({
                ...state,
                [source]: {
                  ...state[source],
                  loading: false,
                },
              }));
            }
          }
        },
        getAdvancedApi: async (
          selectedTable,
          database,
          schema,
          isCorrelation = false
        ) => {
          const field = isCorrelation ? 'correlation' : 'features';
          const getTable = () => {
            const [table] = get().tables.data.filter(
              ({ TABLE_NAME }) => TABLE_NAME === selectedTable
            );
            return table;
          };
          const { data, loading, error } = getTable()[field];
          const tableIndex = get().tables.data.indexOf(getTable());
          if (data.length === 0 && !loading) {
            const updatedTableData = [...get().tables.data];
            updatedTableData.splice(tableIndex, 1, {
              ...getTable(),
              [field]: {
                data,
                loading: true,
                error,
              },
            });
            set((state) => ({
              ...state,
              tables: {
                ...state.tables,
                data: [...updatedTableData],
              },
            }));
            const key = isCorrelation ? 'correlation' : 'feature-importance';
            try {
              const url = `${API_BASE_URL}/dbstats/${key}?database=${database}&schema=${schema}&table=${selectedTable}`;
              const response = await dswRequest.get(url);
              const e = response.error;
              let d = response.data;
              let isDataArray = Array.isArray(d);

              // Handle data stream
              if (!isDataArray) {
                d = JSON.parse(d);
                isDataArray = Array.isArray(d);
              }

              const err = !isDataArray ? 'Request failed' : e;
              updatedTableData.splice(tableIndex, 1, {
                ...getTable(),
                [field]: {
                  data: err ? [] : d,
                  error: err,
                  loading: get().tables.data[tableIndex][field].loading,
                },
              });
              set((state) => ({
                ...state,
                tables: {
                  ...state.tables,
                  data: [...updatedTableData],
                },
              }));
            } catch (e) {
              set((state) => ({
                ...state,
                tables: {
                  ...state.tables,
                  error: e.message,
                },
              }));
            } finally {
              updatedTableData.splice(tableIndex, 1, {
                ...getTable(),
                [field]: {
                  data: [...get().tables.data[tableIndex][field].data],
                  loading: false,
                  error: get().tables.data[tableIndex][field].error,
                },
              });
              set((state) => ({
                ...state,
                tables: {
                  ...state.tables,
                  data: [...updatedTableData],
                },
              }));
            }
          }
        },
        getCategoryLabels: async (table) => {
          const { categoryLabels, categoryLabelsLoading } = get();
          const tableName = table.indexOf('CPG') > -1 ? 'CPG' : table;
          if (
            !categoryLabels[tableName] &&
            categoryLabelsLoading.indexOf(tableName) < 0
          ) {
            set((state) => ({
              ...state,
              categoryLabelsLoading: [
                ...state.categoryLabelsLoading,
                tableName,
              ],
            }));
            const catUrl = `categories/${tableName}.json`;
            const { data: cd, error: ce } = await dswRequest.get(catUrl);
            if (cd && !ce && typeof cd !== 'string') {
              set((state) => ({
                ...state,
                categoryLabels: {
                  ...state.categoryLabels,
                  [tableName]: cd,
                },
              }));
            }
            set((state) => ({
              categoryLabelsLoading: state.categoryLabelsLoading.filter(
                (t) => t !== tableName
              ),
            }));
          }
        },
      },
    }))
  )
);
