import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IFeatureToggleStore } from './types';
import { EFeatureToggle } from '../constants';
import { API_BASE_URL } from '../config/config';
import { dswRequest } from './index';

export const initialState: IFeatureToggleStore = {
  featureToggles: null,
  loading: false,
  error: null,
  actions: {
    getFeatureToggles: () => undefined,
    hasFeature: () => false,
  },
};

export const useFeatureToggleStore = create<IFeatureToggleStore>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      actions: {
        getFeatureToggles: async () => {
          const { featureToggles } = get();
          if (!featureToggles) {
            set((state) => ({ ...state, loading: true }));
            try {
              const url = `${API_BASE_URL}/feature-toggle`;
              const { data, error } = await dswRequest.get(url);
              set((state) => ({
                ...state,
                error: error?.message || null,
                featureToggles: data || null,
              }));
            } catch (e) {
              set((state) => ({
                ...state,
                error: e.toString() || null,
                featureToggles: null,
              }));
            } finally {
              set((state) => ({
                ...state,
                loading: false,
              }));
            }
          }
        },
        hasFeature: (feature: EFeatureToggle): boolean => {
          const { featureToggles } = get();
          return featureToggles && !!featureToggles[feature];
        },
      },
    }))
  )
);
