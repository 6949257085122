import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi } from 'ag-grid-community';
import {
  WppActionButton,
  WppAvatar,
  WppIconCopy,
} from '@platform-ui-kit/components-library-react';
import { IContactPerson, ITechnicalLead } from '../../stores/types';
import { AgencyLogos } from '../../constants';
import { copyToClipboard, getSourceTitle } from '../../utils';
import { loadingOverlay } from './MainPage';

// Add another row for every 4 data sets
const enableHeightByDatasets = false;

export interface IAgencyGridData {
  agency: string;
  region: string;
  instance: string;
  namespace: string;
  lead: ITechnicalLead[];
  contact: IContactPerson;
  datasets: string[];
}

export interface IAgencyGrid {
  tableData: IAgencyGridData[];
  setGridApi: (gridApi: GridApi) => void;
  getNotebooks: (namespace: string, instanceUrl: string) => Promise<void>;
  setNotebookDetail: (data: IAgencyGridData) => void;
  setOpenDetails: (open: boolean) => void;
}

export default function AgencyGrid(agencyGridParams: IAgencyGrid) {
  const {
    tableData,
    setGridApi,
    getNotebooks,
    setNotebookDetail,
    setOpenDetails,
  } = agencyGridParams;

  const agencyCellRenderer = (params) => {
    return (
      <span style={{ display: 'flex', flexDirection: 'row' }}>
        <WppAvatar
          name={params.value}
          tooltipConfig={{
            placement: 'bottom',
          }}
          withTooltip
          size="s"
          style={{ margin: 'auto 0.5rem auto 0' }}
          src={AgencyLogos[params.value.replace('-', '')]}
        />
        <span>{params.value}</span>
      </span>
    );
  };

  const regionCellRenderer = (params) => {
    return <span className={`region ${params.value}`}>{params.value}</span>;
  };

  const instanceCellRenderer = (params) => {
    return (
      <span className="grid-button" style={{ display: 'inline-flex' }}>
        <WppActionButton
          title="Copy Instance URL"
          onClick={() => copyToClipboard(params.value)}
        >
          <WppIconCopy size="s" />
          &nbsp;
          {params.value.replace('https://', '').replace(/(\..*)+/gi, '')}
        </WppActionButton>
      </span>
    );
  };

  const namespaceCellRenderer = (params) => {
    return (
      <span className="grid-button" style={{ display: 'inline-flex' }}>
        <WppActionButton
          title="Copy Namespace URL"
          onClick={() =>
            copyToClipboard(`${params.data.instance}?ns=${params.value}`)
          }
        >
          <WppIconCopy size="s" />
          &nbsp;
          {params.value}
        </WppActionButton>
      </span>
    );
  };

  const datasetsCellRenderer = (params) => {
    const { datasets } = params.data;
    return (
      <span className="sources">
        {datasets.map((source, idx) => (
          <span
            title={getSourceTitle(source)}
            className={`source ${source}`}
            key={`source-${idx}`}
          >
            {source
              .split(/[()]/)
              .filter((val) => !!val)
              .map((str, itemIdx) => (
                <span
                  key={`source-item-${idx}-${str.trim()}`}
                  className={itemIdx === 0 ? 'ds' : 'gov'}
                >
                  {str.trim()}
                </span>
              ))}
          </span>
        ))}
      </span>
    );
  };

  const leadCellRenderer = (params) => {
    const { lead } = params.data;
    const values = lead as unknown as ITechnicalLead[];

    if (!values || !Array.isArray(values) || values.length < 1) {
      return null;
    }

    const [value] = values;

    return (
      <span className="grid-button" style={{ display: 'inline-flex' }}>
        <WppActionButton
          title="Open E-Mail Address"
          onClick={() => window.open(`mailto:${value.email}`, '_blank')}
        >
          {value.name}
        </WppActionButton>
      </span>
    );
  };

  const contactCellRenderer = (params) => {
    const { contact } = params.data;
    const value = contact as unknown as IContactPerson;

    if (!contact) {
      return null;
    }

    return (
      <span className="grid-button" style={{ display: 'inline-flex' }}>
        <WppActionButton
          title="Open E-Mail Address"
          onClick={() => window.open(`mailto:${value.email}`, '_blank')}
        >
          {value.name}
        </WppActionButton>
      </span>
    );
  };

  const columnDefs: ColDef[] = [
    {
      field: 'agency',
      headerName: 'Agency Name',
      minWidth: 250,
      flex: 25,
      sort: 'asc',
      pinned: 'left',
      filter: 'agTextColumnFilter',
      cellRenderer: agencyCellRenderer,
    },
    {
      field: 'region',
      headerName: 'Region Name',
      width: 130,
      filter: 'agTextColumnFilter',
      cellRenderer: regionCellRenderer,
    },
    {
      field: 'instance',
      headerName: 'Instance',
      minWidth: 250,
      flex: 25,
      filter: 'agTextColumnFilter',
      cellRenderer: instanceCellRenderer,
    },
    {
      field: 'namespace',
      headerName: 'Namespace',
      minWidth: 250,
      flex: 25,
      filter: 'agTextColumnFilter',
      cellRenderer: namespaceCellRenderer,
    },
    {
      field: '',
      headerName: 'Data Sets',
      minWidth: enableHeightByDatasets ? 500 : 1150,
      flex: enableHeightByDatasets ? 50 : 115,
      cellRenderer: datasetsCellRenderer,
    },
    {
      field: '',
      headerName: 'Lead Details',
      minWidth: 300,
      flex: 30,
      cellRenderer: leadCellRenderer,
    },
    {
      field: '',
      headerName: 'Contact Details',
      minWidth: 300,
      flex: 30,
      cellRenderer: contactCellRenderer,
    },
  ];

  return (
    <section className="basic-grid ag-theme-wpp">
      <h2>Agency Overview</h2>
      <p>
        You can open the Details by clicking on the row, you&apos;re interested
        in.
        <br />
        To copy the Instance- or Namespace URL, you can click the value
        accordingly.
      </p>
      <AgGridReact
        onGridReady={(gridReadyEvent) => {
          setGridApi(gridReadyEvent.api);
        }}
        onRowClicked={(rowClickedEvent) => {
          if (
            (rowClickedEvent.event.target as Element).className !==
              'wpp-action-button' &&
            !(rowClickedEvent.event.target as Element).classList.contains(
              'wpp-icon'
            )
          ) {
            setOpenDetails(true);
            setNotebookDetail(rowClickedEvent.data);
            getNotebooks(
              rowClickedEvent.data.namespace,
              rowClickedEvent.data.instance
            ).then();
          }
        }}
        rowData={tableData}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          resizable: true,
          cellStyle: { textAlign: 'left' },
          menuTabs: ['filterMenuTab'],
        }}
        getRowHeight={(params) => {
          if (enableHeightByDatasets) {
            return Math.ceil(params.node.data.datasets.length / 4) * 47;
          }
          return 47;
        }}
        animateRows
        tooltipMouseTrack
        columnHoverHighlight
        suppressPaginationPanel
        loadingOverlayComponent={loadingOverlay}
      />
    </section>
  );
}
