import React, { useState } from 'react';
import {
  WppActionButton,
  WppButton,
  WppCheckbox,
  WppIconAddCircle,
  WppIconHelp,
  WppIconRemoveCircle,
  WppInput,
  WppListItem,
  WppSelect,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react';

import { IDatasetsFormData } from '../types';
import { RequestDefaultFields } from '../index';
import { IApprovalDataset, IDatasetRegion } from '../../../stores/types';
import { useGlobalStore } from '../../../stores/global';
import { useRegistryStore } from '../../../stores/registry';
import { useDatasetStore } from '../../../stores/dataset';

import './index.scss';

export interface IDatasetsFormParams {
  onSubmit: (formData: IDatasetsFormData) => void;
  onCancel: () => void;
}

export default function DatasetsForm(params: IDatasetsFormParams) {
  const { onSubmit, onCancel } = params;
  const { userInfo } = useGlobalStore();
  const { data: registryData } = useRegistryStore();
  const {
    additionalDatasets: { isLoading: datasetsLoading, data: datasets },
    actions: { getAdditionalDatasetApi },
  } = useDatasetStore();

  const initialState = {
    agency: null,
    region: null,
    project: null,
    datasets: [],
    comments: '',
    aoIds: false,
    useCaseText: '',
    requesterEmail: userInfo.email,
  };

  const [formData, setFormData] = useState<IDatasetsFormData>(initialState);

  const beforeSetFormData = (newFormData) => {
    if (
      newFormData.agency &&
      newFormData.region &&
      newFormData.project &&
      !datasetsLoading
    ) {
      getAdditionalDatasetApi(
        newFormData.agency.name,
        newFormData.region.name,
        newFormData.project.projectName
      ).then();
    }
    setFormData(newFormData);
  };

  const projects = [...registryData.Active];
  const projectsById = {};
  projects.forEach((project) => {
    projectsById[project.id] = project;
  });
  const projectDetails = {} as { [datasetName: string]: IDatasetRegion[] };
  const ds = projectsById[formData.project?.id]?.details
    ?.datasets[0] as IApprovalDataset;
  if (formData.project && ds?.dataset_region_map) {
    Object.entries(ds.dataset_region_map).forEach(([name, entries]) => {
      if (!projectDetails[name] && entries.length > 0) {
        projectDetails[name] = entries;
      }
    });
  }
  const availableDatasets = datasets
    .filter(({ name }) => {
      const usedDatasets = Object.keys(projectDetails);
      const selectedDatasets = formData.datasets.map(
        ({ name: dsName }) => dsName
      );
      return (
        name &&
        selectedDatasets.indexOf(name) < 0 &&
        usedDatasets.indexOf(name) < 0
      );
    })
    .map(({ name }) => name);

  const setDataset = ({ detail: { value } }, index, field) => {
    const updatedDatasets = [...formData.datasets];
    updatedDatasets[index][field] = value;
    setFormData({ ...formData, datasets: updatedDatasets });
  };
  const setComments = ({ detail: { value } }) =>
    setFormData({ ...formData, comments: value.substring(0, 200) });
  const setAoIds = ({ detail: { checked } }) => {
    const tFormData = {
      ...formData,
      aoIds: checked,
      useCaseText: !checked ? '' : formData.useCaseText,
    };
    setFormData(tFormData);
  };
  const setUseCaseText = ({ detail: { value } }) =>
    setFormData({ ...formData, useCaseText: value.substring(0, 200) });

  const onAddDataset = () => {
    setFormData({
      ...formData,
      datasets: [
        ...formData.datasets,
        { name: null, region: formData.region.name },
      ],
    });
  };
  const onRemoveDataset = (index: number) => {
    const updatedDatasets = [...formData.datasets];
    updatedDatasets.splice(index, 1);
    setFormData({
      ...formData,
      datasets: updatedDatasets,
    });
  };
  const getAddDataset = () => (
    <span className="col-1">
      <WppActionButton
        onClick={onAddDataset}
        disabled={
          !formData.project ||
          availableDatasets.length === 0 ||
          formData.datasets.filter(({ name: dsName }) => !dsName).length > 0
        }
      >
        <WppIconAddCircle slot="icon-start" />
        Add another data set
      </WppActionButton>
    </span>
  );

  const errors = [];
  if (!formData.project?.projectName) {
    errors.push('The Project need to be selected');
  }
  if (formData.datasets.length < 1) {
    errors.push(
      'At least one additional data set selection is required to continue'
    );
  }
  if (
    formData.datasets.filter(({ name, region }) => !!name && !!region)
      .length !== formData.datasets.length
  ) {
    errors.push('All Datasets need to have a Dataset and Region');
  }
  if (
    formData.datasets.filter(({ name }) => name?.indexOf('Other') > -1).length >
      0 &&
    !formData.comments.length
  ) {
    errors.push('For Other data sets, an info needs to be provided');
  }
  if (
    formData.datasets.filter(
      ({ name }) => name?.indexOf('Audience Origin') > -1
    ).length > 0 &&
    formData.aoIds &&
    !formData.useCaseText.length
  ) {
    errors.push('If AO IDs are required, use-case details have to be provided');
  }

  const validToSubmit = () => {
    return errors.length === 0;
  };
  const getContent = () => (
    <div className="content">
      <section className="centered">
        <RequestDefaultFields
          showErrors={false}
          formData={formData}
          setFormData={beforeSetFormData}
          initialState={initialState}
        />
        <span className="row-1">
          <span className="col-1">&nbsp;</span>
          <span className="col-1" style={{ textAlign: 'left' }}>
            <span className="label" style={{ textAlign: 'left' }}>
              Already available data sets
            </span>
            <span className="dataset-container">
              <span className="dataset-item">
                {Object.keys(projectDetails).length > 0
                  ? Object.entries(projectDetails).map(
                      ([datasetName, r], index) => {
                        if (
                          r.filter(({ added_dataset }) => added_dataset)
                            .length > 0
                        ) {
                          return (
                            <span
                              className={`source-item ${datasetName}`}
                              key={`dataset-item-${index}`}
                            >
                              {`${datasetName} - ${r
                                .filter(({ added_dataset }) => added_dataset)
                                .map(({ region }) => region)
                                .join(', ')}`}
                            </span>
                          );
                        }
                        return null;
                      }
                    )
                  : '-'}
              </span>
            </span>
          </span>
        </span>
        <span className="row-1">&nbsp;</span>
        <span className="row-1 dataset-title-content">
          <span className="col-1">
            <span className="label">Step 4: Select Additional Data Sets</span>
          </span>
          <span className="col-1">
            <span className="label" style={{ textAlign: 'left' }}>
              Data Set
            </span>
          </span>
          <span className="col-1">
            <span className="label" style={{ textAlign: 'left' }}>
              Region
            </span>
          </span>
          <span className="col-1">&nbsp;</span>
        </span>
        {formData.datasets.map((dataset, index) => (
          <span
            className="dataset-item"
            key={`datasets-row-${dataset}-${index}`}
          >
            <span className="row-1 dataset-item-content">
              <span className="col-1">&nbsp;</span>
              <span className="col-1">
                <WppSelect
                  value={dataset.name || ''}
                  onWppChange={(e) => setDataset(e, index, 'name')}
                  disabled={
                    datasetsLoading ||
                    !formData.agency?.id ||
                    !formData.region?.name
                  }
                  withSearch
                >
                  {[dataset?.name, ...availableDatasets]
                    .filter((name) => !!name)
                    .map((name) => (
                      <WppListItem
                        key={`dataset-row-${index}-${name}`}
                        value={name}
                      >
                        <p slot="label">{name}</p>
                      </WppListItem>
                    ))}
                </WppSelect>
              </span>
              <span className="col-1">
                <WppSelect value={dataset.region} disabled>
                  <WppListItem value={dataset.region}>
                    <p slot="label">{dataset.region}</p>
                  </WppListItem>
                </WppSelect>
              </span>
              <span className="col-1">
                <WppActionButton onClick={() => onRemoveDataset(index)}>
                  <WppIconRemoveCircle slot="icon-start" />
                  Remove data set
                </WppActionButton>
              </span>
            </span>
            {dataset?.name?.indexOf('Audience Origin') > -1 && (
              <>
                <span className="row-1">
                  <span className="col-1">&nbsp;</span>
                  <span className="col-1 inline">
                    <span className="label" style={{ textAlign: 'left' }}>
                      AO IDs{' '}
                      <WppTooltip text="Static panellist IDs are PII (personal identifying information) and therefore access to these must be limited. They must be stored securely (e.g. restricted OneDrive) and any IDs must be removed when no longer needed (max. after 12 months)">
                        <WppIconHelp size="s" />
                      </WppTooltip>
                    </span>
                    <WppCheckbox
                      checked={
                        formData.project?.details?.datasets[0]?.ao_id ||
                        formData.aoIds
                      }
                      onWppChange={setAoIds}
                    />
                  </span>
                </span>
                {(formData.project?.details?.datasets[0]?.ao_id ||
                  formData.aoIds) && (
                  <>
                    <span className="row-1">
                      <span className="col-1">&nbsp;</span>
                      <span className="col-1">
                        <span className="label" style={{ textAlign: 'left' }}>
                          Please provide use-case details for requesting IDs{' '}
                          <WppTooltip text="Please provide info for your use case for the AO team to consider your request.">
                            <WppIconHelp size="s" />
                          </WppTooltip>
                        </span>
                      </span>
                    </span>
                    <span className="row-1">
                      <span className="col-1">&nbsp;</span>
                      <span className="col-1">
                        <WppInput
                          value={
                            formData.project?.details?.datasets[0]
                              ?.use_case_text || formData.useCaseText
                          }
                          onWppChange={setUseCaseText}
                        />
                      </span>
                    </span>
                  </>
                )}
              </>
            )}
            {dataset?.name?.indexOf('Other') > -1 && (
              <>
                <span className="row-1">
                  <span className="col-1">&nbsp;</span>
                  <span className="col-1">
                    <span className="label" style={{ textAlign: 'left' }}>
                      Please provide info about &quot;Other&quot; dataset
                    </span>
                    <span
                      style={{
                        display: 'block',
                        textAlign: 'left',
                        padding: '0',
                      }}
                    >
                      (ex. name, region, data owner&apos;s name, location, etc)
                    </span>
                  </span>
                </span>
                <span className="row-1">
                  <span className="col-1">&nbsp;</span>
                  <span className="col-1">
                    <WppInput
                      value={formData.comments}
                      onWppChange={(e) => setComments(e)}
                    />
                  </span>
                </span>
              </>
            )}
            <span className="row-1">&nbsp;</span>
          </span>
        ))}
        <span className="row-1">
          <span className="col-1">&nbsp;</span>
          <span className="col-1">{getAddDataset()}</span>
        </span>
      </section>
    </div>
  );

  return (
    <div className="datasets-form">
      <div className="headline">
        <WppTypography type="m-strong" tag="h1">
          DS WORKBENCH - ADDITIONAL DATASETS REQUEST FORM
        </WppTypography>
      </div>
      {getContent()}
      <div className="buttons">
        <WppButton
          className="width-200"
          title={errors.length > 0 ? errors.join(', ') : 'Submit'}
          disabled={!validToSubmit()}
          onClick={() => onSubmit(formData)}
        >
          Submit
        </WppButton>
        <WppActionButton
          className="width-200"
          title="cancel and navigate back"
          onClick={() => onCancel()}
        >
          Cancel
        </WppActionButton>
      </div>
    </div>
  );
}
