import moment from 'moment';
import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line
import { ECUnitOption } from 'echarts/types/src/util/types';
import { WppTypography } from '@platform-ui-kit/components-library-react';
import { IDataTypeMapper } from '../pages/Dashboard/MetricsAndReports/types';
import { TimespanSelectionEnum } from '../constants';

export const handleOnExternalUrlClick = (
  url: string,
  target = '_blank'
): void => {
  window.open(url, target, 'noopener,noreferrer');
};

export const getSourceTitle = (source) => {
  switch (source) {
    case 'CPG':
      return 'Consumer Packaged Goods';
    case 'IDN':
      return 'Identity Driven Networking';
    default:
      return source;
  }
};

export const toTitleCase = (text: string) => {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then();
}

export function filterNamespace(text: string) {
  return text
    .toLowerCase()
    .replace(/[^a-z\d-]/gi, '')
    .substring(0, 40);
}

export const getDatasetsPerRegion = (
  selectedDataset,
  availableDatasets,
  region,
  selectedDatasets: string[] = []
) => {
  const availableDatasetsFilter = (dataset) =>
    selectedDatasets?.indexOf(dataset) < 0 ||
    (dataset && dataset === selectedDataset);
  return availableDatasets?.filter(availableDatasetsFilter);
};

export const getCleanDate = (date: Date | string): Date => {
  return moment(new Date(date)).utcOffset(0, true).toDate();
};

export const dataTitleMapper: IDataTypeMapper = {
  totalUsers: 'Total Users',
  uniqueUsers: 'Unique Users',
  monthlyActiveUsers: 'Monthly Active Users',
  activeProjects: 'Active Projects',
  totalProjects: 'Total Projects',
  agencies: 'Agencies',
  pitchworks: 'Pitchworks',
  notebooks: 'Notebooks',
};

export const lineDiagramNameMapper: IDataTypeMapper = {
  totalUsers: 'New Users',
  uniqueUsers: 'New Unique Users',
  monthlyActiveUsers: 'New Monthly Active Users',
  activeProjects: 'New Active Projects',
  totalProjects: 'New Total Projects',
  agencies: 'New Agencies',
  pitchworks: 'New Pitchworks',
  notebooks: 'New Notebooks',
};

export const emailValidation =
  /^[a-zA-Z\d.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z\d-]+(\.+[a-zA-Z]{2,})+$/;

export function validateEmail(email) {
  return emailValidation.test(email);
}

export const getKeyByValue = (object, value) => {
  const indexOf = Object.values(object).indexOf(value as unknown);
  return Object.keys(object)[indexOf];
};

export const getISOTimespan = (
  timespanSelection: TimespanSelectionEnum
): { startDate: string | null; endDate: string | null } => {
  switch (timespanSelection) {
    case TimespanSelectionEnum.ALL_ITEMS: {
      return {
        startDate: '2022-01-01',
        endDate: `${new Date().getFullYear()}-12-31`,
      };
    }
    case TimespanSelectionEnum.LAST_YEAR: {
      return {
        startDate: `${new Date().getFullYear() - 1}-01-01`,
        endDate: `${new Date().getFullYear() - 1}-12-31`,
      };
    }
    case TimespanSelectionEnum.THIS_YEAR: {
      return {
        startDate: `${new Date().getFullYear()}-01-01`,
        endDate: `${new Date().getFullYear()}-12-31`,
      };
    }
    case TimespanSelectionEnum.Q1: {
      return {
        startDate: `${new Date().getFullYear()}-01-01`,
        endDate: `${new Date().getFullYear()}-03-31`,
      };
    }
    case TimespanSelectionEnum.Q2: {
      return {
        startDate: `${new Date().getFullYear()}-04-01`,
        endDate: `${new Date().getFullYear()}-06-30`,
      };
    }
    case TimespanSelectionEnum.Q3: {
      return {
        startDate: `${new Date().getFullYear()}-07-01`,
        endDate: `${new Date().getFullYear()}-09-30`,
      };
    }
    case TimespanSelectionEnum.Q4: {
      return {
        startDate: `${new Date().getFullYear()}-10-01`,
        endDate: `${new Date().getFullYear()}-12-31`,
      };
    }
    default: {
      return { startDate: null, endDate: null };
    }
  }
};

export const getStartAndEndDateByTimespanSelection = (
  timespanSelection: TimespanSelectionEnum
): { startDate: string | null; endDate: string | null } => {
  switch (timespanSelection) {
    case TimespanSelectionEnum.ALL_ITEMS: {
      return {
        startDate: '01-01-2022',
        endDate: `31-12-${new Date().getFullYear()}`,
      };
    }
    case TimespanSelectionEnum.LAST_YEAR: {
      return {
        startDate: `01-01-${new Date().getFullYear() - 1}`,
        endDate: `31-12-${new Date().getFullYear() - 1}`,
      };
    }
    case TimespanSelectionEnum.THIS_YEAR: {
      return {
        startDate: `01-01-${new Date().getFullYear()}`,
        endDate: `31-12-${new Date().getFullYear()}`,
      };
    }
    case TimespanSelectionEnum.Q1: {
      return {
        startDate: `01-01-${new Date().getFullYear()}`,
        endDate: `31-03-${new Date().getFullYear()}`,
      };
    }
    case TimespanSelectionEnum.Q2: {
      return {
        startDate: `01-04-${new Date().getFullYear()}`,
        endDate: `30-06-${new Date().getFullYear()}`,
      };
    }
    case TimespanSelectionEnum.Q3: {
      return {
        startDate: `01-07-${new Date().getFullYear()}`,
        endDate: `30-09-${new Date().getFullYear()}`,
      };
    }
    case TimespanSelectionEnum.Q4: {
      return {
        startDate: `01-10-${new Date().getFullYear()}`,
        endDate: `31-12-${new Date().getFullYear()}`,
      };
    }
    default: {
      return { startDate: null, endDate: null };
    }
  }
};

// echarts optionToContent is graph toolbox dataview option formatting
// https://echarts.apache.org/en/option.html#toolbox.feature.dataView.optionToContent
export const optionToContent = (opt: ECUnitOption) => {
  const axisData = opt.xAxis[0].data;
  const { series } = opt as any;
  let table = `<div class="option-to-content"><table style="width:100%;text-align:center;"><tbody><tr>
      <td>Months:</td>
      ${series.map((s) => ` <td> ${s.name} </td>`).join('')}
    </tr>`;

  axisData.forEach((aData, index) => {
    table += `<tr>
      <td> ${aData} </td>
     ${series.map((s) => `<td> ${s.data[index]} </td>`).join('')}
      </tr>`;
  });
  table += `</tbody></table></div>`;
  return table;
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const noSelectListItems = (message: string) => {
  return (
    <div className="select-no-list-items-container">
      <WppTypography type="xs-strong" className="span">
        {message}
      </WppTypography>
    </div>
  );
};

export const toHumanReadableSize = (n, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;
  let num = n;

  if (Math.abs(num) < thresh) {
    return num;
  }

  const units = si
    ? ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
    : ['Ki', 'Mi', 'Gi', 'Ti', 'Pi', 'Ei', 'Zi', 'Yi'];
  let u = -1;
  const r = 10 ** dp;

  do {
    num /= thresh;
    u += 1;
  } while (Math.round(Math.abs(num) * r) / r >= thresh && u < units.length - 1);

  return `${num.toFixed(dp)} ${units[u]}`;
};
