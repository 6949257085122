export const HOME = '/';
export const LOGIN = 'login';
export const LOGIN_CALLBACK = 'dsw/login/callback';
export const LOGIN_CALLBACK_ROOT = '/login/callback';

export enum PagesEnum {
  'MAIN_PAGE' = 'main',
  'DATA_SETS_AND_DATA_CATALOGS' = 'data',
  'TUTORIALS_AND_DOCUMENTATIONS' = 'tutorials',
  'DASHBOARD' = 'dashboard',
  'ACCESS_REQUEST_AND_SUPPORT' = 'request',
  'NO_ACCESS' = 'access',
}

export enum DashboardPagesEnum {
  METRICS_AND_REPORTS = 'metrics-and-reports',
  PROJECT_REGISTRY = 'project',
  REQUEST_MANAGEMENT = 'request',
  CHIP_SEGMENT_MANAGER = 'segment-manager',
}

export enum AccessPagesEnum {
  ACCESS = 'access',
  DATASETS = 'dataset',
  TEAM = 'team',
}

export enum MetricsAndReportingPagesEnum {
  KEY_PLATFORM_METRICS = 'key-platform-metrics',
  GROWTH_METRICS = 'growth-metrics',
  QUICK_REPORTS = 'quick-reports',
  COST_REPORTING = 'cost-reporting',
}
