import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi } from 'ag-grid-community';
import moment from 'moment';
import {
  WppActionButton,
  WppButton,
  WppIconButton,
  WppIconNotification,
  WppIconPending,
  WppIconRecordStop,
  WppIconTrash,
  WppIconPlay,
  WppTooltip,
} from '@platform-ui-kit/components-library-react';
import { loadingOverlay } from './MainPage';
import { NotebookPhaseEnum } from '../../constants';
import { handleOnExternalUrlClick } from '../../utils';
import { INotebook } from '../../stores/types';
import { IAgencyGridData } from './AgencyGrid';

export interface IDetailsGridParams {
  accessKeys: string[];
  tableData: INotebook[];
  notebookDetail: IAgencyGridData;
  setGridApi: (gridApi: GridApi) => void;
  setOpenDetails: (open: boolean) => void;
  handleAction: (
    instanceUrl: string,
    namespace: string,
    notebookName: string,
    isReady: boolean,
    action: string
  ) => void;
  loadingAuth: boolean;
  error: boolean;
  errorHandler: () => void;
}

export default function DetailsGrid(detailsGridParams: IDetailsGridParams) {
  const {
    accessKeys,
    tableData,
    notebookDetail,
    handleAction,
    setGridApi,
    setOpenDetails,
    error,
    errorHandler,
    loadingAuth,
  } = detailsGridParams;

  const actionCellRenderer = (params) => {
    const { status } = params.data;
    const phase = status.phase.toUpperCase();
    const isReady = phase === NotebookPhaseEnum.READY;
    const isWaiting = phase === NotebookPhaseEnum.WAITING;
    const isStopped = phase === NotebookPhaseEnum.STOPPED;
    let notebookIcon;
    let buttonText;
    if (isReady) {
      notebookIcon = <WppIconRecordStop size="m" style={{ color: 'black' }} />;
      buttonText = ''; // 'Stop';
    }
    if (isWaiting) {
      notebookIcon = <WppIconPending size="m" style={{ color: 'black' }} />;
      buttonText = ''; // 'Stop';
    }
    if (isStopped) {
      notebookIcon = <WppIconPlay size="m" style={{ color: 'black' }} />;
      buttonText = ''; // 'Start';
    }

    return (
      <span className="detail-actions">
        <WppActionButton
          className="detail-status-button"
          /* disabled={!accessKeys.includes('notebookActions')} */
          onClick={() => {
            handleOnExternalUrlClick(
              `${notebookDetail.instance}_/jupyter/?ns=${notebookDetail.namespace}`
            );
          }}
        >
          {notebookIcon}
          <span className="show-on-hover">&nbsp;{buttonText}</span>
        </WppActionButton>
      </span>
    );
  };

  const statusCellRenderer = (params) => {
    const { status, name } = params.data;
    const phase = status.phase.toUpperCase();
    const isReady = phase === NotebookPhaseEnum.READY;

    return (
      <span className="detail-actions">
        {accessKeys.includes('notebookRead') &&
          (!isReady ? (
            <WppTooltip text="This notebook is in stopped state. Please use the start/stop button on the left side to start the notebook within Kubeflow interface.">
              <WppActionButton
                className="detail-connect-button"
                variant="primary"
                disabled={!isReady}
                onClick={() => {
                  handleOnExternalUrlClick(
                    `${notebookDetail.instance}/notebook/${notebookDetail.namespace}/${name}/lab?`
                  );
                }}
              >
                CONNECT
              </WppActionButton>
            </WppTooltip>
          ) : (
            <WppActionButton
              className="detail-connect-button"
              variant="primary"
              disabled={!isReady}
              onClick={() => {
                handleOnExternalUrlClick(
                  `${notebookDetail.instance}/notebook/${notebookDetail.namespace}/${name}/lab?`
                );
              }}
            >
              CONNECT
            </WppActionButton>
          ))}
      </span>
    );
  };

  const imageCellRenderer = (params) => {
    return <span title={params.data.image}>{params.value}</span>;
  };

  const lastActivityCellRenderer = (params) => {
    if (!params.value) {
      return 'never';
    }
    return moment(params.value).format('YYYY-MM-DD HH:mm:ss');
  };

  const hardwareCellRenderer = (params) => {
    const { cpu, memory, gpus } = params.data;
    return `CPU: ${cpu}, Memory: ${memory}, GPU: ${gpus.count}`;
  };

  const detailActionsCellRenderer = (params) => {
    const { status, name } = params.data;
    const phase = status.phase.toUpperCase();
    const isReady = phase === NotebookPhaseEnum.READY;
    return (
      <div className="detail-actions">
        {accessKeys.includes('notebookModify') && (
          <WppIconButton
            title="Delete"
            size="s"
            disabled={!accessKeys.includes('notebookModify')}
            onClick={() =>
              handleAction(
                notebookDetail.instance,
                notebookDetail.namespace,
                name,
                isReady,
                'delete'
              )
            }
          >
            <WppIconTrash />
          </WppIconButton>
        )}
      </div>
    );
  };

  const detailColumnDefs: ColDef[] = [
    {
      field: '',
      headerName: 'Start/Stop Notebook',
      width: 100,
      pinned: 'left',
      cellRenderer: actionCellRenderer,
    },
    {
      field: '',
      headerName: 'Connect to Notebook',
      width: 100,
      pinned: 'left',
      cellRenderer: statusCellRenderer,
    },
    {
      field: 'name',
      headerName: 'Notebook Name',
      minWidth: 240,
      flex: 24,
      sort: 'asc',
      pinned: 'left',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'age',
      headerName: 'Created',
      width: 120,
      cellStyle: { textAlign: 'right' },
    },
    {
      field: 'last_activity',
      headerName: 'Last Activity',
      width: 180,
      cellRenderer: lastActivityCellRenderer,
    },
    {
      field: 'shortImage',
      headerName: 'Image',
      minWidth: 450,
      flex: 45,
      filter: 'agTextColumnFilter',
      cellRenderer: imageCellRenderer,
    },
    {
      field: '',
      headerName: 'Hardware Specifications',
      minWidth: 250,
      flex: 25,
      cellRenderer: hardwareCellRenderer,
    },
    {
      field: '',
      headerName: '',
      width: 40,
      pinned: 'right',
      cellRenderer: detailActionsCellRenderer,
    },
  ];

  return (
    <div slot="body" className="details-grid ag-theme-wpp">
      <WppButton
        className="details-overlay"
        variant="primary"
        size="s"
        onClick={() => setOpenDetails(false)}
      >
        &nbsp;
      </WppButton>
      <h2>Namespace Details</h2>
      <h4>{`${notebookDetail.agency} - ${notebookDetail.region} - ${notebookDetail.namespace}`}</h4>
      {error ? (
        <span className="error">
          <WppButton
            className="notebook-error"
            disabled={loadingAuth}
            loading={loadingAuth}
            onClick={errorHandler}
          >
            <WppIconNotification slot="icon-start" size="m" />
            Please click here to authenticate on Kubeflow - You will be
            automatically redirected to this page afterwards
          </WppButton>
        </span>
      ) : (
        <AgGridReact
          onGridReady={(gridReadyEvent) => {
            setGridApi(gridReadyEvent.api);
          }}
          rowData={tableData}
          columnDefs={detailColumnDefs}
          defaultColDef={{
            sortable: true,
            resizable: true,
            wrapText: true,
            cellStyle: { textAlign: 'left' },
            menuTabs: ['filterMenuTab'],
          }}
          animateRows
          tooltipMouseTrack
          columnHoverHighlight
          suppressPaginationPanel
          loadingOverlayComponent={loadingOverlay}
        />
      )}
      <span className="footer">
        <WppButton
          variant="primary"
          size="s"
          onClick={() => setOpenDetails(false)}
        >
          Back to Agency Overview
        </WppButton>
      </span>
    </div>
  );
}
