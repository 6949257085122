import React, { useState } from 'react';

import { useRegistryStore } from '../../../stores/registry';
import { IRegistryDataSet } from '../../../stores/types';

import FilterBar, { IRegistryFilters } from './FilterBar';
import RegistryTable from './RegistryTable';

import './index.scss';

export default function ProjectRegistry() {
  const {
    data,
    error,
    isLoading,
    loadingItems,
    extending,
    deleting,
    actions: { getDataApi, getDetailsApi, openExport, postExtend, postDelete },
  } = useRegistryStore();
  const [didFilter, setDidFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState<IRegistryFilters>(null);
  const [expandedRecord, setExpandedRecord] = useState<string>('');
  const [expandedStatus, setExpandedStatus] = useState<string>('');

  const updateFilters = (updatedFilters: IRegistryFilters) => {
    const cleanUpdatedFilters = {} as IRegistryFilters;
    Object.entries(updatedFilters).forEach(([key, val]) => {
      if (key && val && val !== 'ALL' && val !== '%EMPTY%') {
        cleanUpdatedFilters[key] = val;
      }
    });
    getDataApi(cleanUpdatedFilters).then(() => {
      setDidFilter(true);
      setExpandedRecord('');
      setExpandedStatus('');
      setFilters(cleanUpdatedFilters);
    });
  };

  const retrieveDetails = (item, status) => {
    getDetailsApi(item, status).then();
  };

  const handleExport = (item: IRegistryDataSet, requestStatus: string) => {
    openExport(item, requestStatus, filters).then();
  };

  const handleExtend = (item: IRegistryDataSet, callback) => {
    postExtend(item, callback).then();
  };

  const handleDelete = (item: IRegistryDataSet, callback) => {
    postDelete(item, callback, filters).then();
  };

  return (
    <div className="project-registry">
      <FilterBar updateFilters={updateFilters} />
      <RegistryTable
        data={data}
        expandedRecord={expandedRecord}
        expandedStatus={expandedStatus}
        getDetails={retrieveDetails}
        handleExport={handleExport}
        handleExtend={handleExtend}
        handleDelete={handleDelete}
        setExpandedRecord={setExpandedRecord}
        setExpandedStatus={setExpandedStatus}
        error={error}
        isLoading={isLoading}
        extending={extending}
        deleting={deleting}
        loadingItems={loadingItems}
        didFilter={didFilter}
      />
    </div>
  );
}
