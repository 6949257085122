import React, { useEffect, useState } from 'react';
import {
  WppActionButton,
  WppButton,
  WppStep,
  WppStepper,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react';

import { useAgencyStore } from '../../../stores/agency';
import { useRegionStore } from '../../../stores/region';
import { useReasonStore } from '../../../stores/reason';
import { useDatasetStore } from '../../../stores/dataset';
import { useCountryStore } from '../../../stores/country';
import { useRoleStore } from '../../../stores/role';

import { IAccessRequestFormData } from '../types';

import { useGlobalStore } from '../../../stores/global';
import { doValidateAccess } from '../validation';

import UserDetails from './UserDetails';
import ProjectAgencyContactDetails from './ProjectAgencyContactDetails';
import TeamDetails from './TeamDetails';
import DataAccessDetails from './DataAccessDetails';
import SummaryAndSubmit from './SummaryAndSubmit';

import './index.scss';

export interface IAccessRequestFormParams {
  onSubmit: (formData: IAccessRequestFormData) => void;
  onCancel: () => void;
}

export default function AccessRequestForm(params: IAccessRequestFormParams) {
  const { onSubmit, onCancel } = params;
  const { userInfo } = useGlobalStore();

  const [currentStep, changeCurrentStep] = useState<number>(1);
  const [formData, setFormData] = useState<IAccessRequestFormData>({
    user: {
      name: userInfo.name,
      email: userInfo.email,
      agency: null,
      country: null,
    },
    project: {
      company: null,
      name: null,
      regions: [null],
      reason: null,
    },
    agencyContact: {
      name: null,
      email: null,
      is_changed: false,
    },
    team: [],
    datasets: [],
    commentsByRegion: {},
  });
  const [agreedDisclaimer, setAgreedDisclaimer] = useState<boolean>(false);

  const {
    countries,
    actions: { getCountryApi },
  } = useCountryStore();
  const {
    simpleAgencies: agencies,
    agenciesByRequestType,
    actions: { getSimpleAgenciesApi, getAgenciesByRequestTypeApi },
  } = useAgencyStore();
  const {
    regions,
    actions: { getRegionApi },
  } = useRegionStore();
  const {
    reasons,
    actions: { getReasonApi },
  } = useReasonStore();
  const {
    requestDatasets,
    actions: { getRequestDatasetApi },
  } = useDatasetStore();
  const {
    roles,
    actions: { getRolesApi },
  } = useRoleStore();

  const get = () => formData;
  const set = (field, key, value) => {
    if (field === 'project' && key !== 'name') {
      const formRegions = key !== 'regions' ? formData.project.regions : value;
      const formReason = key !== 'reason' ? formData.project.reason : value;
      const formCompany = key !== 'company' ? formData.project.company : value;
      if (!!value && formRegions.length > 0 && !!formReason && !!formCompany) {
        if (formReason === 'NGPi') {
          const region = 'AMER';
          getRequestDatasetApi(formCompany, region, formReason).then(
            (response) => {
              // MLKIT-3510: Set default selection
              if (formReason === 'NGPi') {
                const [ADMDataSet] = response[region].data.filter(
                  (ds) => ds.name.indexOf('ADM') === 0
                );
                if (ADMDataSet) {
                  set('datasets', '', [
                    {
                      source: ADMDataSet.name,
                      region,
                      dataset: region,
                      aoIds: null,
                      useCase: null,
                      isDefault: true,
                    },
                  ]);
                }
              }
            }
          );
        } else {
          formRegions.forEach((region) => {
            if (region) {
              getRequestDatasetApi(formCompany, region, formReason).then();
            }
          });
        }
      }
    }
    if (field === 'project' && key === 'reason') {
      getAgenciesByRequestTypeApi(value).then();
    }
    if (key !== '') {
      setFormData((p) => ({ ...p, [field]: { ...p[field], [key]: value } }));
    } else {
      setFormData((p) => ({ ...p, [field]: value }));
    }
  };

  useEffect(() => {
    getCountryApi().then();
    getSimpleAgenciesApi().then();
    getRegionApi().then();
    getReasonApi().then();
    getRolesApi().then();
  }, [
    getCountryApi,
    getSimpleAgenciesApi,
    getRegionApi,
    getReasonApi,
    getRolesApi,
  ]);

  const getContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <UserDetails
            agencies={agencies}
            countries={countries}
            get={get}
            set={set}
          />
        );
      case 2:
        return (
          <ProjectAgencyContactDetails
            agencies={agenciesByRequestType}
            regions={regions}
            reasons={reasons}
            get={get}
            set={set}
          />
        );
      case 3:
        return <TeamDetails roles={roles} get={get} set={set} />;
      case 4:
        return (
          <DataAccessDetails
            requestDatasets={requestDatasets}
            regions={regions}
            get={get}
            set={set}
          />
        );
      case 5:
        return (
          <SummaryAndSubmit
            get={get}
            agreedDisclaimer={agreedDisclaimer}
            setAgreedDisclaimer={setAgreedDisclaimer}
          />
        );
      default:
        return null;
    }
  };

  const doChangeCurrentStep = (step) => {
    setFormData({
      ...formData,
      project: {
        ...formData.project,
        regions: formData.project.regions.filter(
          (name, idx) => !!name || idx === 0
        ),
      },
      team: formData.team.filter(({ name, email }) => !!name && !!email),
      datasets: formData.datasets.filter(
        ({ source, region }) => !!source && !!region
      ),
    });
    changeCurrentStep(step);
  };

  const { isValid: enableSubmit, messages: submitMessages } =
    currentStep === 5
      ? doValidateAccess(formData)
      : { isValid: null, messages: null };
  const submitMessage =
    submitMessages?.join(', ') || !agreedDisclaimer
      ? 'Please read the Disclaimer and accept by switching the toggle next to it'
      : '';
  const { isValid: enableNext, messages: nextMessages } =
    currentStep < 5
      ? doValidateAccess(formData, currentStep)
      : { isValid: null, messages: null };
  const nextMessage = nextMessages?.join(', ');

  const getSubmitButton = () => {
    if (currentStep < 5) {
      return null;
    }
    const btn = (
      <WppButton
        className="width-200"
        title="submit this form"
        disabled={!enableSubmit || !agreedDisclaimer}
        onClick={() => onSubmit(formData)}
      >
        Submit
      </WppButton>
    );
    if (submitMessage) {
      return <WppTooltip text={submitMessage}>{btn}</WppTooltip>;
    }
    return btn;
  };
  const getNextButton = () => {
    if (currentStep === 5) {
      return null;
    }
    const nextBtn = (
      <WppButton
        className="width-200"
        title="navigate to next step"
        disabled={!enableNext}
        onClick={() => doChangeCurrentStep(currentStep + 1)}
      >
        Next
      </WppButton>
    );
    if (nextMessage) {
      return <WppTooltip text={nextMessage}>{nextBtn}</WppTooltip>;
    }
    return nextBtn;
  };

  return (
    <div className="access-request-form">
      <div className="headline">
        <WppTypography type="m-strong" tag="h1">
          DS WORKBENCH - ACCESS REQUEST FORM
        </WppTypography>
      </div>
      <div className="navigation">
        <WppStepper activeStep={currentStep} orientation="horizontal">
          <WppStep>
            <p slot="label">Requestor&apos;s Details</p>
          </WppStep>
          <WppStep>
            <p slot="label">Project Details</p>
          </WppStep>
          <WppStep>
            <p slot="label">Team Details</p>
          </WppStep>
          <WppStep>
            <p slot="label">Data Access Details</p>
          </WppStep>
          <WppStep>
            <p slot="label">Summary & Submit</p>
          </WppStep>
        </WppStepper>
      </div>
      <div className="content">{getContent()}</div>
      <div className="buttons">
        {currentStep !== 1 && (
          <WppButton
            className="width-200"
            title="navigate to previous step"
            variant="secondary"
            size="m"
            onClick={() => doChangeCurrentStep(currentStep - 1)}
          >
            Back
          </WppButton>
        )}
        {getSubmitButton()}
        {getNextButton()}
        <WppActionButton
          className="width-200"
          title="cancel and navigate back"
          onClick={() => onCancel()}
        >
          Cancel
        </WppActionButton>
      </div>
    </div>
  );
}
