import React from 'react';
import { renderToString } from 'react-dom/server';
import {
  WppActionButton,
  WppIconAddCircle,
  WppIconHelp,
  WppIconRemoveCircle,
  WppInput,
  WppListItem,
  WppSelect,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import { IRegion, ISimpleAgency, ISimpleIdName } from '../../../stores/types';
import { IAccessRequestFormData } from '../types';
import { useGlobalStore } from '../../../stores/global';
import { useAgencyStore } from '../../../stores/agency';
import { filterNamespace } from '../../../utils';
import { RoleEnum } from '../../../constants';

export interface IProjectAgencyContactDetailsParams {
  agencies: ISimpleAgency[];
  regions: IRegion[];
  reasons: ISimpleIdName[];
  get: () => IAccessRequestFormData;
  set: (field: keyof IAccessRequestFormData, key, value) => void;
}

function ProjectAgencyContactDetails(
  params: IProjectAgencyContactDetailsParams
) {
  const { agencies, regions, reasons, get, set } = params;
  const { project, agencyContact: agencyContactFormData } = get();
  const { userInfo } = useGlobalStore();

  const {
    agencyContact,
    loadingAgencyContact,
    agencyContactErrors,
    actions: { getAgencyContactApi },
  } = useAgencyStore();

  const availableRegions = regions.filter(
    ({ name }) => project.regions.indexOf(name) < 0
  );
  const cleanUp = (index: number) => {
    const { team: t, datasets: d } = get();
    const c = project.regions[index];
    set(
      'team',
      '',
      t.filter(({ region }) => !region || region !== c)
    );
    set(
      'datasets',
      '',
      d.filter(({ region }) => region !== c)
    );
  };
  const onProjectRegionChange = (region, index: number, onlyAMER = false) => {
    let r = ['AMER'];
    if (!onlyAMER) {
      r = [...project.regions];
      r[index] = region;
    }
    cleanUp(index);
    const { team: t } = get();
    const newTeam = [...t.filter(({ region: fr }) => r.indexOf(fr) > -1)];
    r.forEach((reg) => {
      if (newTeam.filter(({ region: treg }) => treg === reg).length === 0) {
        newTeam.push({
          id: null,
          name: userInfo.name || null,
          email: userInfo.email || null,
          region: reg,
          role: RoleEnum.TEAM_LEAD,
          is_deleted: false,
        });
      }
    });
    set('team', '', newTeam);
    set('project', 'regions', r);
  };
  const onRemoveProjectRegion = (index: number) => {
    const r = [...project.regions];
    r.splice(index, 1);
    cleanUp(index);
    set('project', 'regions', r);
  };
  const onAddProjectRegion = () => {
    set('project', 'regions', [...project.regions, null]);
  };

  const getAddProjectRegion = () => (
    <span className="col-1">
      <WppActionButton
        onClick={onAddProjectRegion}
        disabled={
          !availableRegions.length ||
          !!project.regions.filter((v) => !v).length ||
          project.regions.length > 2 ||
          project.reason === 'NGPi'
        }
      >
        <WppIconAddCircle slot="icon-start" />
        Add another region
      </WppActionButton>
    </span>
  );

  const setReason = ({ detail: { value } }) => {
    set('project', 'reason', value);
    set('datasets', '', []);
    if (value === 'NGPi') {
      onProjectRegionChange('AMER', 0, true);
    }
    set('project', 'company', null);
    setTimeout(() => {
      set('project', 'company', null);
    }, 250);
  };
  const setName = ({ detail: { value } }) =>
    set('project', 'name', filterNamespace(value));
  const setRegion = ({ detail: { value } }, index) =>
    onProjectRegionChange(value, index);
  const setAgencyName = (value) => set('agencyContact', 'name', value);
  const setAgencyEmail = (value) => set('agencyContact', 'email', value);

  const setCompany = ({ detail: { value } }) => {
    getAgencyContactApi(value, ({ name, email }) => {
      setAgencyEmail(email);
      setAgencyName(name);
    }).then(() => {
      set('project', 'company', value);
      set('datasets', '', []);
    });
  };

  /* FILTER NGPi */
  let requestReasonTip = renderToString(
    <div className="projectDetails-tooltip-wrapper">
      <WppTypography type="s-body">
        <ul style={{ padding: 0, margin: 0 }}>
          <li>
            All projects are classified under 4 general topics. If you are
            requiring access for a pitch, please select PITCH.
          </li>
          <li>
            If you are working on a project that is going to be persistent or
            that will fuel a product, please select PRODUCT.
          </li>
          <li>
            If you are working for a temporary project or if you are an
            exploring DS, please select RESEARCH.
          </li>
          <li>
            Please select project type as NGPi if you want to create segments to
            be utilized in CHIP.
          </li>
        </ul>
      </WppTypography>
    </div>
  );
  if (project.reason === 'NGPi') {
    requestReasonTip = renderToString(
      <div className="projectDetails-tooltip-wrapper">
        <ul style={{ padding: 0, margin: 0 }}>
          <li>
            <WppTypography type="s-body">
              - The related ADM version (Choreograph, Mindshare, Wavemaker &
              Essencemediacom) should be automatically assigned to data sets.
              This data set CANNOT be removed.{' '}
            </WppTypography>
          </li>
          <li>
            <WppTypography type="s-body">
              - The user can request other data sets that are only LOWGOV (for
              now, this might change later) available in AMER. These would be
              AO, Aggregated Census World and DataSpine. We won’t give IDN
              Agency, as ADM includes IDN as base.{' '}
            </WppTypography>
          </li>
          <li>
            <WppTypography type="s-body">
              - To the tooltip for project type we can write: “Please select
              project type as NGPi if you want to create segments to be utilized
              in CHIP.”{' '}
            </WppTypography>
          </li>
          <li>
            <WppTypography type="s-body">
              - When NGPi project type is selected, we should limit the number
              of agencies we see in “This is for…” menu to Choreograph,
              Mindshare, Essencemediacom and Wavemaker. The number of agencies
              will increase in near future.{' '}
            </WppTypography>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <section className="centered">
      <span className="row-1">
        <span className="col-1">
          <span className="label help reason-for-request">
            Reason for request
            <WppTooltip
              config={{
                allowHTML: true,
                content: requestReasonTip,
                placement: 'bottom',
              }}
            >
              <WppIconHelp size="s" />
            </WppTooltip>
          </span>
        </span>
        <span className="col-1">
          <WppSelect value={project.reason} onWppChange={setReason} withSearch>
            {/* FILTER NGPi */}
            {reasons.map(({ name }) => (
              <WppListItem key={`project-reason-${name}`} value={name}>
                <p slot="label">{name}</p>
              </WppListItem>
            ))}
          </WppSelect>
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">
          <span className="label help">
            Type your project name{' '}
            <WppTooltip text="Please provide a name for your project. This name is generally a client name or product name, used to configure your workspace. The name can’t be more than 40 characters long. ">
              <WppIconHelp size="s" />
            </WppTooltip>
          </span>
        </span>
        <span className="col-1">
          <WppInput
            value={project.reason ? `${project.reason}-` : undefined}
            disabled
          />
        </span>
        <span className="col-1">
          <WppInput
            value={project.name || undefined}
            onWppChange={setName}
            required
          />
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">
          <span className="label help">
            This is for...(company name){' '}
            <WppTooltip text="If you are part of a pitch team, or a Choreograph Data Scientist, the company you work for and the agency this project belongs to can be different. We need that information to notify the correct Agency Engagement Teams and configure your workspace in a correct manner. (ex. you can be part of a mixed pitch team from Choreograph that is working on a pitch for EssenceMediacom) ">
              <WppIconHelp size="s" />
            </WppTooltip>
          </span>
        </span>
        <span className="col-1">
          <WppSelect
            disabled={loadingAgencyContact}
            value={project.company || undefined}
            onWppChange={setCompany}
            withSearch
          >
            {agencies.map(({ name }: ISimpleAgency) => (
              <WppListItem key={`entity-${name}`} value={name}>
                <p slot="label">{name}</p>
              </WppListItem>
            ))}
          </WppSelect>
        </span>
      </span>
      {project.regions.map((region, index) => (
        <span className="row-1" key={`project-region-row-${region}`}>
          <span className="col-1">
            {!index && (
              <span className="label help">
                Region(s) of operation{' '}
                <WppTooltip
                  text="Due to data privacy restrictions, we'll need to configure different work environments for different regions, especially if you are planning to bring client data that belongs to a certain region and cannot leave that region. Also, please keep in mind that the data assets available in regions differ.
 IMPORTANT: You can select multiple regions but do so only if you are absolutely sure that you need them. If you have any questions, please inquire via our Zendesk page (https://chos-help.choreograph.com/hc/en-us)."
                >
                  <WppIconHelp size="s" />
                </WppTooltip>
              </span>
            )}
          </span>
          <span className="col-1">
            <WppSelect
              value={region}
              onWppChange={(e) => setRegion(e, index)}
              disabled={project.reason === 'NGPi'}
              withSearch
            >
              {[
                ...regions.filter(({ name }) => name === region),
                ...availableRegions,
              ].map(({ name }: ISimpleIdName) => (
                <WppListItem
                  key={`project-region-row-${region}-${name}`}
                  value={name}
                >
                  <p slot="label">{name}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </span>
          {!!index && (
            <span className="col-1">
              <WppActionButton onClick={() => onRemoveProjectRegion(index)}>
                <WppIconRemoveCircle slot="icon-start" />
                Remove region
              </WppActionButton>
            </span>
          )}
        </span>
      ))}
      <span className="row-1">
        <span className="col-1">&nbsp;</span>
        <span className="col-1">{getAddProjectRegion()}</span>
      </span>
      <br />
      <WppTypography className="section-label" type="m-strong" tag="h2">
        CHOREOGRAPH CLIENT DELIVERY LEAD
      </WppTypography>
      <span className="row-1">
        <span className="col-1">
          <span className="label">Name & Surname</span>
        </span>
        <span className="col-1">
          <WppInput
            disabled={!agencyContactErrors}
            value={agencyContact.name || agencyContactFormData.name}
            onWppChange={({ detail: { value } }) => setAgencyName(value)}
          />
        </span>
      </span>
      <span className="row-1">
        <span className="col-1">
          <span className="label">E-mail</span>
        </span>
        <span className="col-1">
          <WppInput
            disabled={!agencyContactErrors}
            value={agencyContact.email || agencyContactFormData.email}
            onWppChange={({ detail: { value } }) => setAgencyEmail(value)}
          />
        </span>
      </span>
    </section>
  );
}

export default ProjectAgencyContactDetails;
