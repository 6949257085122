import React from 'react';
import { WppToggle } from '@platform-ui-kit/components-library-react';
import { ISegments } from '../../../../../stores/types';

import { useRoleStore } from '../../../../../stores/role';
import { RoleEnum } from '../../../../../constants';

interface ICellRendererParams {
  data: ISegments;
  value: string;
}
interface IExportToggleParams {
  cellRendererParams: ICellRendererParams;
  onExportInUI: (id: string, segmentId: string) => void;
}

export default function ExportToggle(props: IExportToggleParams) {
  const { cellRendererParams, onExportInUI } = props;
  const { name: userRole } = useRoleStore((state) => state.role);
  return (
    <WppToggle
      id={cellRendererParams.data.segmentIdDb}
      data-segmentId={cellRendererParams.data.segmentId}
      value={cellRendererParams.value}
      checked={cellRendererParams.value.toString().toLowerCase() === 'true'}
      disabled={
        cellRendererParams.value.toString().toLowerCase() === 'true' ||
        userRole !== RoleEnum.CHIP_ADMIN
      }
      onWppChange={(ev) =>
        onExportInUI(ev.target.id, ev.target.dataset.segmentId)
      }
    />
  );
}
