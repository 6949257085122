import { useAuthenticate } from './authenticate';
import { isLocalDevelopment } from '../config/config';

export type RequestMethod = 'POST' | 'GET' | 'UPDATE' | 'DELETE' | 'PATCH';

export interface IRequestParams {
  method?: RequestMethod;
  headers?: HeadersInit;
  options?: RequestInit;
}

export const getAuthorizationHeader = (requestParams?: IRequestParams) => {
  const { options = {} } = requestParams;
  const { authInfo } = useAuthenticate.getState();
  const header: any = {};

  if (authInfo?.token && (!options || !options?.credentials)) {
    header.Accept = 'application/json';
    header['Content-Type'] = 'application/json';
    header.authorization = !authInfo.token.includes('Bearer ')
      ? `Bearer ${authInfo.token}`
      : authInfo.token;
    if (isLocalDevelopment) {
      header.authorization = `Bearer ${localStorage.getItem('authorization')}`;
    }
    header.issuer = authInfo.issuer;
  }

  return header;
};

const dswCom = (url: string, requestParams?: IRequestParams) => {
  const { method = 'GET', headers, options } = requestParams;
  const header = getAuthorizationHeader(requestParams ?? {});
  const requestInit = {
    method,
    headers: {
      ...headers,
      ...header,
    },
    ...options,
  } as RequestInit;

  const handleStatus = (response: Response) => {
    try {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
      }
      if (response.status === 0 || response.status === 403) {
        return Promise.reject({
          error: {
            status: 'Forbidden (403)',
            message: 'Transaction failed',
          },
          response,
        });
      }
      if (response.status === 401) {
        // window.location.href = '/login';
        return Promise.reject({
          error: {
            status: 'Unauthorized (401)',
            message: 'Invalid token! Failed to Authorize!',
          },
          response,
        });
      }

      return response.json().then((resp) => {
        return Promise.reject({
          error: {
            status: resp.status || `${resp.statusText} (${resp.status})`,
            message: resp.message || resp.error || resp.exception,
          },
          response: resp,
        });
      });
    } catch {
      return Promise.reject({
        error: {
          status: response.status,
          message: response.statusText,
        },
        response,
      });
    }
  };

  const handleContent = async (response: Response) => {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return { response, data: await response.json() };
    }
    return { response, data: await response.text() };
  };

  return fetch(url, requestInit)
    .then(handleStatus)
    .then(handleContent)
    .then(({ response, data }) => {
      const css = 'color: lime; background-color: black;';
      // eslint-disable-next-line no-console
      console.groupCollapsed(`%c[${method}] %s`, css, url);
      // eslint-disable-next-line no-console
      console.log('Request', requestInit);
      // eslint-disable-next-line no-console
      console.log('Response', response);
      // eslint-disable-next-line no-console
      console.log('Data', data);
      // eslint-disable-next-line no-console
      console.groupEnd();
      return { data, error: null };
    })
    .catch(({ response, error }) => {
      const css = 'color: red; background-color: black;';
      // eslint-disable-next-line no-console
      console.groupCollapsed(`%c[${method}] %s`, css, url);
      // eslint-disable-next-line no-console
      console.log('Request', requestInit);
      // eslint-disable-next-line no-console
      console.log('Response', response);
      // eslint-disable-next-line no-console
      console.log('Error', error);
      // eslint-disable-next-line no-console
      console.groupEnd();
      return {
        data: null,
        error: error || { status: 'Error', message: 'Request failed' },
      };
    });
};

export const dswRequest = {
  get(url, requestParams?: IRequestParams) {
    return dswCom(url, { ...requestParams, method: 'GET' });
  },
  post(url, data, requestParams?: IRequestParams) {
    const header = getAuthorizationHeader(requestParams ?? {});
    return dswCom(url, {
      ...requestParams,
      method: 'POST',
      options: {
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...header },
        ...requestParams?.options,
      },
    });
  },
  update(url, data, requestParams?: IRequestParams) {
    const header = getAuthorizationHeader(requestParams ?? {});
    return dswCom(url, {
      ...requestParams,
      method: 'UPDATE',
      options: {
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...header },
        ...requestParams?.options,
      },
    });
  },
  delete(url, requestParams?: IRequestParams) {
    return dswCom(url, { ...requestParams, method: 'DELETE' });
  },
  patch(url, data, requestParams?: IRequestParams) {
    return dswCom(url, {
      ...requestParams,
      method: 'PATCH',
      options: {
        body: JSON.stringify(data),
        ...requestParams?.options,
      },
    });
  },
};
