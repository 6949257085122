import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi } from 'ag-grid-community';
import {
  WppListItem,
  WppSelect,
} from '@platform-ui-kit/components-library-react';
import {
  IDBStatsCorrelation,
  IDBStatsFeature,
  IDBStatsTable,
} from '../../../stores/types';
import {
  featureImportanceTables,
  getTableDescription,
} from '../../../stores/dbstats';
import { loadingOverlay } from '../index';

import './index.scss';

const cellRenderer = (data) =>
  data.value
    ? (Math.round(parseFloat(String(data.value)) * 100) / 100).toFixed(2) ||
      '0.00'
    : 'None';

const cellStyle = (params, min, max) => {
  if (!params.value) {
    return {};
  }
  const weight = (parseFloat(params.value) - min) / (max - min);
  const bgCol = Math.round(weight * 10) * 100;
  const col = bgCol < 500 ? 'black' : 'white';
  return {
    backgroundColor: `var(--wpp-dataviz-color-seq-brand-${bgCol})`,
    color: col,
    textAlign: 'center',
    borderRight: '1px solid lightgrey',
  };
};

export interface IAdvancedProps {
  selectedAdvancedTable: string;
  setSelectedAdvancedTable: (table: string) => void;
  tables: IDBStatsTable[];
  features: IDBStatsFeature[];
  correlations: IDBStatsCorrelation[];
  categoryLabels: { [short: string]: string };
  loading: boolean;
  error: string;
}

export default function Advanced(props: IAdvancedProps) {
  const {
    selectedAdvancedTable,
    setSelectedAdvancedTable,
    tables,
    features,
    correlations,
    categoryLabels,
    loading,
    error,
  } = props;
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const featureStrings = features.map(({ 'Features Important': f }) => f);
  const data = correlations.map((c, index) => ({
    INDEX: featureStrings.indexOf(Object.keys(c)[index]),
    KEY: Object.keys(c)[index],
    ...c,
  }));
  const [table] = tables.filter(
    ({ TABLE_NAME }) => TABLE_NAME === selectedAdvancedTable
  );

  useEffect(() => {
    if (gridApi) {
      if (loading) {
        // Temporary fix of ag-grid-react behaviour
        setTimeout(() => gridApi.showLoadingOverlay(), 1);
      } else if (data && data.length > 0) {
        setTimeout(() => gridApi.hideOverlay(), 1000);
        gridApi.setFilterModel(null);
      }
    }
  }, [gridApi, loading, data]);

  let min = 1;
  let max = 0;
  data.forEach((d) => {
    const keys = Object.keys(d).filter((s) => s !== 'INDEX' && s !== 'KEY');
    keys.forEach((k) => {
      if (!d[k]) return;
      if (d[k] < min) {
        min = d[k];
      } else if (d[k] > max) {
        max = d[k];
      }
    });
  });

  const featureColumnDefs: ColDef[] = [
    {
      field: 'INDEX',
      headerName: '',
      width: 50,
      sort: 'asc',
      pinned: 'left',
    },
    {
      field: 'KEY',
      headerName: 'Feature',
      width: 150,
      pinned: 'left',
      cellStyle: { textAlign: 'left' },
      filter: 'agTextColumnFilter',
      filterParams: {
        // limit filter options
        // see: node_modules/ag-grid-community/dist/lib/filter/provided/simpleFilter.d.ts
        filterOptions: ['contains'],
        textMatcher: (params) => {
          const filterValue = params.filterText.toLowerCase();
          if (categoryLabels[params.data.KEY]) {
            return (
              categoryLabels[params.data.KEY]
                .toLowerCase()
                .indexOf(filterValue) > -1 ||
              params.value.toLowerCase().indexOf(filterValue) > -1
            );
          }
          return params.value.toLowerCase().indexOf(filterValue) > -1;
        },
      },
      tooltipField: 'KEY',
    },
    ...features.map(({ 'Features Important': name }) => ({
      field: name,
      width: 100,
      tooltipField: name,
      cellStyle: (params) => cellStyle(params, min, max),
      cellRenderer,
    })),
  ];

  const tooltipComponent = (params) => {
    const {
      data: d,
      value,
      colDef: { field },
    } = params;
    const fallbackLabel = d.KEY.replaceAll('__', ': ').replaceAll('_', ' ');
    const label =
      categoryLabels && categoryLabels[d.KEY]
        ? categoryLabels[d.KEY]
        : fallbackLabel;
    return (
      <div
        style={{
          backgroundColor: '#eee',
          border: '1px solid black',
          borderRadius: '0.25rem',
          padding: '0.25rem',
        }}
      >
        <p style={{ fontWeight: 'bold', margin: '0.25rem' }}>{label}</p>
        {field !== 'KEY' && (
          <p style={{ margin: '0.25rem' }}>
            {categoryLabels[field] || field}: {value}
          </p>
        )}
      </div>
    );
  };

  return (
    <section className="advanced">
      <section className="top">
        <section className="filters">
          <section className="basic-filter">
            <span className="label">Select Table</span>
            <WppSelect
              size="s"
              value={
                error || !selectedAdvancedTable
                  ? undefined
                  : selectedAdvancedTable
              }
              placeholder={error || 'Please select a Table'}
              messageType={error ? 'warning' : undefined}
              onWppChange={(e) => setSelectedAdvancedTable(e.detail.value)}
            >
              {featureImportanceTables.map((tableName) => (
                <WppListItem value={tableName} key={tableName}>
                  <p slot="label">{tableName}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </section>
        </section>
        {selectedAdvancedTable && !error && (
          <section className="info">
            <p className="label">Table Description</p>
            <span>{getTableDescription(table?.TABLE_NAME)}</span>
          </section>
        )}
      </section>
      {error ? (
        <section className="error">
          <p>{error}</p>
        </section>
      ) : (
        <section className="advanced-grid ag-theme-wpp">
          {selectedAdvancedTable && (
            <AgGridReact
              onGridReady={(gridReadyEvent) => {
                setGridApi(gridReadyEvent.api);
              }}
              rowData={data}
              columnDefs={featureColumnDefs}
              defaultColDef={{
                sortable: true,
                resizable: true,
                cellStyle: {
                  borderRight: '1px solid lightgrey',
                },
                menuTabs: ['filterMenuTab'],
                tooltipComponent,
              }}
              columnHoverHighlight
              animateRows
              tooltipMouseTrack
              suppressPaginationPanel
              loadingOverlayComponent={loadingOverlay}
            />
          )}
        </section>
      )}
    </section>
  );
}
